import firebase from "firebase/app";
import {db} from "../../../../global/services/firebase";
import {useState, useEffect} from 'react'

const getMyRequestsList =async(uid)=>{
    
    let RequestList = []
    
    await db.collection("requestlist").where("uid", "==", uid)
    .get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const requestItem = {...docData,itemId:doc.id}
            RequestList.push(requestItem)
        })
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    return RequestList
    
}


export const useFetchMyRequests = (event) => {
    
    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{

        setState(state=>({data:state.data,loading:true}))

        async function fetchMyRequests(){
            firebase.auth().onAuthStateChanged(async function(user) {
                if (user) {
                    const requestList = await getMyRequestsList(user.uid)

                    setState({data:requestList,loading:false})
                } else {
                    console.log("No User Found!")
                }
            })
        }
        
        fetchMyRequests()
        
    },[event])

    return state
};

export const getPledgerProfiles =async(pledgerId)=>{

    await db.collection("users").doc(pledgerId)
    .get()
    .then(function(doc) {
        if(!doc.exists){
            throw "Document does not exist"
        }
        return doc.data()
    })
    .catch(function(error) {
        console.log("profile ", error);
    });
}


const getMyItemPledgersList =async(itemId)=>{
    let ItemPledgersList = []
  
    await db.collection("pledgelist").where("docId", "==", itemId)
    .get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const itemPledger = {...docData,pledgeId:doc.id}
            ItemPledgersList.push(itemPledger)
        })
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    return ItemPledgersList
    
}


export const useFetchItemPledgersList = (event,itemId) => {
    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{

        setState(state=>({data:state.data,loading:true}))

        async function fetchItemPledgers(){
            const pledgersList = await getMyItemPledgersList(itemId)
            setState({data:pledgersList,loading:false})
        }
        
        fetchItemPledgers()
        
    },[event])

    return state
};


const getMyItemPledgedItemList =async(itemId, userId)=>{
    let ItemPledgedList = []

    var pledgedItemList = db.collection("pledgelist");
    var pledgedItemListByItem = pledgedItemList.where("docId", "==", itemId);
    var pledgedItemListByUser = pledgedItemListByItem.where("pledgerId", "==", userId);

    await pledgedItemListByUser
    .get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const itemPledged = {...docData,itemPledgedId:doc.id}

            if (docData.pledgerId == userId) {
                ItemPledgedList.push(itemPledged)
            }
        })
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    return ItemPledgedList
    
}


export const useFetchItemPledgedList = (event,itemId,userId) => {
    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{

        setState(state=>({data:state.data,loading:true}))

        async function fetchItemPledged(){
            const pledgedList = await getMyItemPledgedItemList(itemId, userId)
            setState({data:pledgedList,loading:false})
        }
        
        fetchItemPledged()
        
    },[event])

    return state
};