import React,{useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import AlertIcon from '../../assets/ic_error.png'
import '../styles/InvalidPledgePrompt.css'

const InvalidPledgePrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    return(
        <React.Fragment>
            <div id="InvalidPledgePrompt">
                <div className="InvalidPledge-Body">
                    <img src={AlertIcon} />
                    <div className="InvalidPledge-Title">Invalid Pledge</div>
                    <div className="InvalidPledge-Description">Please check your input quantity and try again.</div>
                </div>

                <div className="InvalidPledge-Footer">
                    <div className="InvalidPledge-SubmitButton" onClick={()=>setModalDetails({...modalDetails,'state':false})}>Ok</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default InvalidPledgePrompt