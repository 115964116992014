export const Regions = ['Select','I','II','III','IVA','IVB','V','VI','VII','VIII','IX','X','XII','XIII','ARMM','NCR','CAR']
export const Provinces = {'Select':['Select'],'I': ['Select','Ilocos Norte','Ilocos Sur','La Union','Pangasinan'],
'II': ['Select','Batanes','Cagayan','Isabela','Nueva Vizcaya','Quirino'], 
'III': ['Select','Aurora','Bataan','Bulacan','Nueva Ecija','Pampanga','Tarlac','Zambales'], 
'IVA': ['Select','Batangas','Cavite','Laguna','Quezon','Rizal'], 
'IVB': ['Select','Occidental Mindoro','Oriental Mindoro','Marinduque','Romblon','Palawan'],
'V': ['Select','Albay','Camarines Norte','Camarines Sur','Catanduanes','Masbate','Sorsogon'],
'VI': ['Select','Aklan','Antique','Capiz','Guimaras','Iloilo','Negros Occidental'],
'VII': ['Select','Bohol','Cebu','Siquijor','Negros Oriental'],
'VIII': ['Select','Biliran','Eastern Samar','Leyte','Northern Samar','Samar','Southern Leyte'],
'IX': ['Select','Zamboanga Del Norte', 'Zamboanga Del Sur','Zamboanga Sibugay'],
'X': ['Select','Bukidnon','Camiguin','Lanao Del Norte','Misamis Occidental','Misamis Oriental'],
'XI': ['Select','Compostela Valley','Davao Del Norte','Davao Del Sur','Davao Occidental','Davao Oriental'],
'XII': ['Select','Cotabato','Sarangani','South Cotabato','Sultan Kudarat'],
'XIII': ['Select','Agusan Del Norte','Agusan Del Sur','Dinagat Islands','Surigao Del Norte','Surigao Del Sur'],
'ARMM': ['Select','Basilan','Lanao del Sur','Maguindanao','Sulu','Tawi-Tawi'],
'NCR': ['Select','Metro Manila'],
'CAR': ['Select','Abra','Apayao','Benguet','Ifugao','Kalinga','Mountain Province']}

export const Cities = {'Select':['Select'],
'Ilocos Norte':['Select','Laoag','Batac','Pagudpud','Adams','Bacarra','Badoc','Bangui','Burgos','Carasi','Currimao','Dingras','Dumalneg','Banna',
                                        'Marcos','Nueva Era','Paoay','Pasuquin','Piddig','Pinili','San Nicolas','Sarrat','Solsona','Vintar'],
'Ilocos Sur':['Select','Alilem','Banayoyo','Bantay','Burgos','Cabugao','Candon','Caoayan', 'Cervantes', 'Galimuyod', 'Gregorio del pilar', 'Lidlidda', 'Magsingal', 'Nagbukel',
                'Narvacan', 'Quirino', 'Salcedo', 'San Emilio', 'San Esteban', 'San ildefonso', 'San juan', 'San vicente', 'Santa', 'Santa Catalina', 'Santa Cruz',
                'Santa Lucia', 'Santa Maria', 'Santiago', 'Santo Domingo', 'Sigay', 'Sinait', 'Sugpon', 'Suyo', 'Tagudin', 'Vigan'],
'La Union':['Select','Agoo','Aringay','Bacnotan','Bagulin','Balaoan','Bangar','Bauang','Burgos','Caba','Luna','Naguilian','Pugo','Rosario','San Fernando','San Gabriel','San Juan',
                'Santo Tomas','Santol','Sudipen','Tubao'],
'Pangasinan':['Select','Agno','Aguilar','Alaminos','Alcala','Anda','Asingan','Balungao','Bani','Basista','Bautista','Bayambang','Binalonan','Binmaley','Bolinao','Bugallon','Burgos',
                'Calasiao','Dagupan','Dasol','Infanta','Labrador','Lingayen','Mabini','Malasiqui','Manaoag','Mangaldan','Mangatarem','Mapandan','Natividad','Pozorrubio',
                'Rosales','San Carlos','San Fabian','San Jacinto','San Manuel','San Nicolas','San Quintin','Santa Barbara','Santa Maria','Santo Tomas','Sison','Sual',
                'Tayug','Umingan','Urbiztondo', 'Urdaneta', 'Villasis', 'Laoac'],
'Batanes':['Select','Basco','Itbayat','Ivana','Mahatao','Sabtang','Uyugan'],
'Cagayan':['Select','Abulug','Alcala','Allacapan','Amulung','Aparri','Baggao','Ballesteros','Buguey','Calayan','Camalaniugan','Claveria','Enrile','Gattaran','Gonzaga','Iguig',
                'Lal-Lo','Lasam','Pamplona','Peñablanca','Piat','Rizal','Sanchez-Mira','Santa Ana','Santa Praxedes','Santa Teresita','Santo Niño','Solana','Tuao','Tuguegarao'],
'Isabela':['Select','Alicia','Angadanan','Aurora','Benito Soliven','Burgos','Cabagan','Cabatuan','Cauayan','Cordon','Dinapigue','Divilacan','Echague','Gamu','Ilagan','Jones','Luna',
                'Maconacon','Delfin Albano','Mallig','Naguilian','Palanan','Quezon','Quirino','Ramon','Reina Mercedes','Roxas','San Agustin','San Guillermo','San Isidro',
                'San Manuel','San Mariano','San Mateo','San Pablo','Santa Maria','City Of Santiago','Santo Tomas','Tumauini'],
'Nueva Vizcaya':['Select','Ambaguio','Aritao','Bagabag','Bambang','Bayombong','Diadi','Dupax Del Norte','Dupax Del Sur',
                        'Kasibu','Kayapa','Quezon','Santa Fe','Solano','Villaverde','Alfonso Castaneda'],
'Quirino':['Select','Aglipay','Cabarroguis', 'Diffun','Maddela','Saguday','Nagtipunan'],
'Aurora':['Select','Baler','Casiguran','Dilasag','Dinalungan','Dingalan','Dipaculao','Maria Aurora','San Luis'],
'Bataan':['Select','Abucay','Bagac','Balanga','Dinalupihan','Hermosa','Limay','Mariveles','Morong','Orani','Orion','Pilar','Samal'],
'Bulacan':['Select','Angat','Balagtas','Baliuag','Bocaue','Bulacan','Bustos','Calumpit','Guiguinto','Hagonoy','Malolos','Marilao','Meycauayan','Norzagaray','Obando',
        'Pandi','Paombong','Plaridel','Pulilan','San Ildefonso','San Jose Del Monte','San Miguel','San Rafael','Santa Maria','Doña Remedios Trinidad'],
'Nueva Ecija':['Select','Aliaga','Bongabon','Cabanatuan','Cabiao','Carranglan','Cuyapo','Gabaldon', 'Gapan','General Mamerto Natividad','General Tinio','Guimba',
                'Jaen','Laur','Licab','Llanera','Lupao','Muñoz','Nampicuan','Palayan','Pantabangan','Peñaranda','Quezon','Rizal','San Antonio','San Isidro',
                'San Jose City','San Leonardo','Santa Rosa','Santo Domingo','Talavera','Talugtug','Zaragoza'],
'Pampanga':['Select','Angeles','Apalit','Arayat','Bacolor','Candaba','Floridablanca','Guagua','Lubao','Mabalacat','Macabebe','Magalang','Masantol','Mexico','Minalin',
                'Porac','San Fernando','San Luis','San Simon','Santa Ana','Santa Rita','Santo Tomas','Sasmuan'],
'Tarlac':['Select','Anao','Bamban','Camiling','Capas','Concepcion','Gerona','La Paz','Mayantoc','Moncada','Paniqui','Pura','Ramos','San Clemente','San Manuel',
        'Santa Ignacia','Tarlac','Victoria','San Jose'],
'Zambales':['Select','Botolan','Cabangan','Candelaria','Castillejos','Iba','Masinloc','Olongapo','Palauig','San Antonio','San Felipe','San Marcelino','San Narciso','Santa Cruz','Subic'],
'Batangas':['Select','Agoncillo','Alitagtag','Balayan','Balete','Batangas','Bauan','Calaca','Calatagan','Cuenca','Ibaan','Laurel','Lemery','Lian','Lipa','Lobo','Mabini','Malvar',
                'Mataasnakahoy','Nasugbu','Padre Garcia','Rosario','San Jose','San Juan','San Luis','San Nicolas','San Pascual','Santa Teresita','Santo Tomas','Taal',
                'Talisay','Tanauan','Taysan','Tingloy','Tuy'],
'Cavite':['Select','Alfonso','Amadeo','Bacoor','Carmona','Cavite','Dasmariñas','General Emilio Aguinaldo','General Trias','Imus','Indang','Kawit','Magallanes','Maragondon',
                'Mendez','Naic','Noveleta','Rosario','Silang','Tagaytay','Tanza','Ternate','Trece Martires','General Mariano Alvarez'],
'Laguna':['Select','Alaminos','Bay', 'Biñan','Cabuyao','Calamba','Calauan','Cavinti','Famy','Kalayaan','Liliw','Los Baños','Luisiana','Lumban','Mabitac','Magdalena','Majayjay',
                'Nagcarlan','Paete','Pagsanjan','Pakil','Pangil','Pila','Rizal','San Pablo','San Pedro','Santa Cruz','Santa Maria','Santa Rosa','Siniloan','Victoria'],
'Quezon':['Select','Agdangan','Alabat','Atimonan','Buenavista','Burdeos','Calauag','Candelaria','Catanauan','Dolores','General Luna','General Nakar','Guinayangan','Gumaca','Infanta',
                'Jomalig','Lopez','Lucban','Lucena','Macalelon','Mauban','Mulanay','Padre Burgos','Pagbilao','Panukulan','Patnanungan','Perez','Pitogo','Plaridel','Polillo',
                'Quezon','Real','Sampaloc','San Andres','San Antonio','San Francisco','San Narciso','Sariaya','Tagkawayan','Tayabas','Tiaong','Unisan'],
'Rizal':['Select','Angono','Antipolo','Baras','Binangonan','Cainta','Cardona','Jala-Jala','Rodriguez','Morong','Pililla','San Mateo','Tanay','Taytay','Teresa'],
'Occidental Mindoro':['Select','Abra De Ilog','Calintaan','Looc','Lubang','Magsaysay','Mamburao','Paluan','Rizal','Sablayan','San Jose','Santa Cruz'],
'Oriental Mindoro':['Select','Baco','Bansud','Bongabong','Bulalacao', 'Calapan', 'Gloria','Mansalay','Naujan','Pinamalayan','Pola','Puerto Galera','Roxas','San Teodoro','Socorro','Victoria'],
'Marinduque':['Select','Boac','Buenavista','Gasan','Mogpog','Santa Cruz','Torrijos'],
'Romblon':['Select','Alcantara','Banton','Cajidiocan','Calatrava','Concepcion','Corcuera','Looc','Magdiwang','Odiongan','Romblon','San Agustin','San Andres','San Fernando',
        'San Jose','Santa Fe','Ferrol','Santa Maria'],
'Palawan':['Select','Aborlan','Agutaya','Araceli','Balabac','Bataraza','Brookes Point','Busuanga','Cagayancillo','Coron','Cuyo','Dumaran','El Nido','Linapacan','Magsaysay','Narra',
                'Puerto Princesa','Quezon','Roxas','San Vicente','Taytay','Kalayaan','Culion','Rizal','Sofronio Española'],
'Albay':['Select','Bacacay','Camalig','Daraga','Guinobatan','Jovellar','Legazpi','Libon','Ligao','Malilipot','Malinao','Manito','Oas','Pio Duran','Polangui','Rapu-Rapu','Santo Domingo','Tabaco','Tiwi'],
'Camarines Norte':['Select','Basud','Capalonga','Daet','San Lorenzo Ruiz','Jose Panganiban','Labo','Mercedes','Paracale','San Vicente','Santa Elena','Talisay','Vinzons'],
'Camarines Sur':['Select','Baao','Balatan','Bato','Bombon','Buhi','Bula','Cabusao','Calabanga','Camaligan','Canaman','Caramoan','Del Gallego','Gainza','Garchitorena','Goa','Iriga',
                'Lagonoy','Libmanan','Lupi','Magarao','Milaor','Minalabac','Nabua','Naga','Ocampo','Pamplona','Pasacao','Pili','Presentacion','Ragay','Sagñay','San Fernando',
                'San Jose','Sipocot','Siruma','Tigaon','Tinambac'],
'Catanduanes':['Select','Bagamanoc','Baras','Bato','Caramoran','Gigmoto','Pandan','Panganiban', 'San Andres','San Miguel','Viga','Virac'],
'Masbate':['Select','Aroroy','Baleno','Balud','Batuan','Cataingan','Cawayan','Claveria','Dimasalang','Esperanza','Mandaon','Masbate','Milagros','Mobo','Monreal','Palanas',
                'Pio V Corpuz','Placer','San Fernando','San Jacinto','San Pascual','Uson'],
'Sorsogon':['Select','Barcelona','Bulan','Bulusan','Casiguran','Castilla','Donsol','Gubat','Irosin','Juban','Magallanes','Matnog','Pilar','Prieto Diaz','Santa Magdalena','Sorsogon'],
'Aklan':['Select','Altavas','Balete','Banga','Batan','Buruanga','Ibajay','Kalibo','Lezo','Libacao','Madalag','Makato','Malay','Malinao','Nabas','New Washington','Numancia','Tangalan'],
'Antique':['Select','Anini-Y','Barbaza','Belison','Bugasong','Caluya','Culasi','Tobias Fornier','Hamtic','Laua-An','Libertad','Pandan','Patnongon','San Jose','San Remigio','Sebaste',
                'Sibalom','Tibiao','Valderrama'],
'Capiz':['Select','Cuartero','Dao','Dumalag','Dumarao','Ivisan','Jamindan','Ma-Ayon','Mambusao','Panay','Panitan','Pilar','Pontevedra','President Roxas','Roxas City',
                'Sapi-An','Sigma','Tapaz'],
'Guimaras':['Select','Buenavista','Jordan','Nueva Valencia','San Lorenzo','Sibunag'],
'Iloilo':['Select','Ajuy','Alimodian','Anilao','Badiangan','Balasan','Banate','Barotac Nuevo','Barotac Viejo','Batad','Bingawan','Cabatuan','Calinog','Carles','Concepcion','Dingle',
                'Dueñas','Dumangas','Estancia','Guimbal','Igbaras','Iloilo','Janiuay','Lambunao','Leganes','Lemery','Leon','Maasin','Miagao','Mina','New Lucena','Oton',
                'Passi','Pavia','Pototan','San Dionisio','San Enrique','San Joaquin','San Miguel','San Rafael','Santa Barbara','Sara','Tigbauan','Tubungan','Zarraga'],
'Negros Occidental':['Select','Bacolod','Bago','Binalbagan','Cadiz','Calatrava','Candoni','Cauayan','Enrique B Magalona','Escalante','Himamaylan','Hinigaran','Hinoba-An','Ilog',
                        'Isabela','Kabankalan','La Carlota','La Castellana','Manapla','Moises Padilla','Murcia','Pontevedra','Pulupandan','Sagay','San Carlos','San Enrique',
                        'Silay','Sipalay','Talisay','Toboso','Valladolid','Victorias','Salvador Benedicto'],
'Bohol':['Select','Alburquerque','Alicia','Anda','Antequera','Baclayon','Balilihan','Batuan','Bilar','Buenavista','Calape','Candijay','Carmen','Catigbian','Clarin','Corella','Cortes',
                'Dagohoy','Danao','Dauis','Dimiao','Duero','Garcia Hernandez','Guindulman','Inabanga','Jagna','Jetafe','Lila','Loay','Loboc','Loon','Mabini','Maribojoc','Panglao',
                'Pilar','Pres. Carlos P. Garcia','Sagbayan','San Isidro','San Miguel','Sevilla','Sierra Bullones','Sikatuna','Tagbilaran','Talibon','Trinidad','Tubigon','Ubay','Valencia','Bien Unido'],
'Cebu':['Select','Alcantara','Alcoy','Alegria','Aloguinsan','Argao','Asturias','Badian','Balamban','Bantayan','Barili','Bogo','Boljoon','Borbon','Carcar','Carmen','Catmon','Cebu',
        'Compostela','Consolacion','Cordova','Daanbantayan','Dalaguete','Danao','Dumanjug','Ginatilan','Lapu-Lapu','Liloan','Madridejos','Malabuyoc','Mandaue','Medellin',
        'Minglanilla','Moalboal','Naga','Oslob','Pilar','Pinamungahan','Poro','Ronda','Samboan','San Fernando','San Francisco','San Remigio','Santa Fe','Santander','Sibonga',
        'Sogod','Tabogon','Tabuelan','Talisay','Toledo','Tuburan','Tudela'],
'Siquijor':['Select','Enrique Villanueva','Larena','Lazi','Maria','San Juan','Siquijor'],
'Negros Oriental':['Select','Amlan','Ayungon','Bacong','Bais','Basay','Bayawan','Bindoy','Canlaon','Dauin','Dumaguete','Guihulngan','Jimalalud','La Libertad','Mabinay','Manjuyod',
        'Pamplona','San Jose','Santa Catalina','Siaton','Sibulan','Tanjay','Tayasan','Valencia','Vallehermoso','Zamboanguita'],
'Biliran':['Select','Almeria','Biliran','Cabucgayan','Caibiran','Culaba','Kawayan','Maripipi','Naval'],
'Eastern Samar':['Select','Arteche','Balangiga','Balangkayan','Borongan','Can-Avid','Dolores','General Macarthur','Giporlos','Guiuan','Hernani','Jipapad','Lawaan','Llorente',
                        'Maslog','Maydolong','Mercedes','Oras','Quinapondan','Salcedo','San Julian','San Policarpo','Sulat','Taft'],
'Leyte':['Select','Abuyog','Alangalang','Albuera','Babatngon','Barugo','Bato','Baybay','Burauen','Calubian','Capoocan','Carigara','Dagami','Dulag','Hilongos','Hindang','Inopacan',
                'Isabel','Jaro','Javier','Julita','Kananga','La Paz','Leyte','Macarthur','Mahaplag','Matag-Ob','Matalom','Mayorga','Merida','Ormoc','Palo','Palompon',
                'Pastrana','San Isidro','San Miguel','Santa Fe','Tabango','Tabontabon','Tacloban','Tanauan','Tolosa','Tunga','Villaba'],
'Northern Samar':['Select','Allen','Biri','Bobon','Capul','Catarman','Catubig','Gamay','Laoang','Lapinig','Las Navas','Lavezares','Mapanas','Mondragon','Palapag','Pambujan',
                        'Rosario','San Antonio','San Isidro','San Jose','San Roque','San Vicente','Silvino Lobos','Victoria','Lope De Vega'],
'Samar':['Select','Almagro','Basey','Calbayog','Calbiga','Catbalogan','Daram','Gandara','Hinabangan','Jiabong','Marabut','Matuguinao','Motiong','Pinabacdao','San Jose De Buan',
                'San Sebastian','Santa Margarita','Santa Rita','Santo Niño','Talalora','Tarangnan','Villareal','Paranas','Zumarraga','Tagapul-An','San Jorge','Pagsanghan'],
'Southern Leyte':['Select','Anahawan','Bontoc','Hinunangan','Hinundayan','Libagon','Liloan','Maasin','Macrohon','Malitbog','Padre Burgos','Pintuyan','Saint Bernard','San Francisco',
                'San Juan','San Ricardo','Silago','Sogod','Tomas Oppus','Limasawa'],
'Zamboanga Del Norte':['Select','Dapitan','Dipolog','Katipunan','La Libertad','Labason','Liloy','Manukan','Mutia','Piñan','Polanco','Pres. Manuel A. Roxas','Rizal','Salug','Sergio Osmeña Sr.',
                        'Siayan','Sibuco','Sibutad','Sindangan','Siocon','Sirawai','Tampilisan','Jose Dalman','Gutalac','Baliguian','Godod','Bacungan', 'Kalawit'],
'Zamboanga Del Sur':['Select','Aurora','Bayog','Dimataling','Dinas','Dumalinao','Dumingag','Kumalarang','Labangan','Lapuyan','Mahayag','Margosatubig','Midsalip','Molave','Pagadian',
                        'Ramon Magsaysay','San Miguel','San Pablo','Tabina','Tambulig','Tukuran','Zamboanga','Lakewood','Josefina','Pitogo','Sominot','Vincenzo A. Sagun','Guipos','Tigbao'],
'Zamboanga Sibugay':['Select','Alicia','Buug','Diplahan','Imelda','Ipil','Kabasalan','Mabuhay','Malangas','Naga','Olutanga','Payao','Roseller Lim','Siay','Talusan','Titay','Tungawan'],
'Bukidnon':['Select','Baungon','Damulog', 'Dangcagan','Don Carlos','Impasug-Ong','Kadingilan','Kalilangan','Kibawe','Kitaotao','Lantapan','Libona','Malaybalay','Malitbog',
                'Manolo Fortich','Maramag','Pangantucan','Quezon','San Fernando','Sumilao','Talakag','Valencia','Cabanglasan'],
'Camiguin':['Select','Catarman','Guinsiliban','Mahinog','Mambajao','Sagay'],
'Lanao Del Norte':['Select','Bacolod','Baloi','Baroy','Iligan','Kapatagan','Sultan Naga Dimaporo','Kauswagan','Kolambugan','Lala','Linamon','Magsaysay','Maigo','Matungao','Munai',
                        'Nunungan','Pantao Ragat','Poona Piagapo','Salvador','Sapad','Tagoloan','Tangcal','Tubod','Pantar'],
'Misamis Occidental':['Select','Aloran','Baliangao','Bonifacio','Calamba','Clarin','Concepcion','Jimenez','Lopez Jaena','Oroquieta','Ozamis','Panaon','Plaridel',
                        'Sapang Dalaga','Sinacaban','Tangub','Tudela','Don Victoriano Chiongbian'],
'Misamis Oriental':['Select','Alubijid','Balingasag','Balingoan','Binuangan','Cagayan De Oro City','Claveria','El Salvador','Gingoog','Gitagum','Initao','Jasaan','Kinoguitan','Lagonglong',
                        'Laguindingan','Libertad','Lugait','Magsaysay','Manticao','Medina','Naawan','Opol','Salay','Sugbongcogon','Tagoloan','Talisayan','Villanueva'],
'Compostela Valley':['Select','Compostela','Laak','Mabini','Maco','Maragusan','Mawab','Monkayo','Montevista','Nabunturan','New Bataan','Pantukan'],
'Davao Del Norte':['Select','Asuncion','Carmen','Kapalong','New Corella','Panabo','Island Garden City Of Samal','Santo Tomas','Tagum','Talaingod','Braulio E. Dujali','San Isidro'],
'Davao Del Sur':['Select','Bansalan','Davao','Digos','Hagonoy','Kiblawan','Magsaysay','Malalag','Matanao','Padada','Santa Cruz','Sulop'],
'Davao Occidental':['Select','Don Marcelino','Jose Abad Santos','Malita','Santa Maria','Sarangani'],
'Davao Oriental':['Select','Baganga','Banaybanay','Boston','Caraga','Cateel','Governor Generoso','Lupon','Manay','Mati','San Isidro','Tarragona'],
'Cotabato':['Select','Alamada','Carmen','Kabacan','Kidapawan','Libungan','Magpet','Makilala','Matalam','Midsayap','MLang','Pigkawayan','Pikit',
                'President Roxas','Tulunan','Antipas','Banisilan','Aleosan','Arakan'],
'Sarangani':['Select','Alabel','Glan','Kiamba','Maasim','Maitum','Malapatan','Malungon'],
'South Cotabato':['Select','Banga','General Santos','Koronadal','Norala','Polomolok','Surallah','Tampakan','Tantangan','TBoli','Tupi','Santo Niño','Lake Sebu'],
'Sultan Kudarat':['Select','Bagumbayan','Columbio','Esperanza','Isulan','Kalamansig','Lebak','Lutayan','Lambayong','Palimbang','President Quirino','Tacurong','Sen. Ninoy Aquino'],
'Agusan Del Norte':['Select','Buenavista','Butuan','Cabadbaran','Carmen','Jabonga','Kitcharao','Las Nieves','Magallanes','Nasipit','Santiago','Tubay','Remedios T. Romualdez'],
'Agusan Del Sur':['Select','Bayugan','Bunawan','Esperanza','La Paz','Loreto','Prosperidad','Rosario','San Francisco','San Luis','Santa Josefa','Talacogon','Trento','Veruela','Sibagat'],
'Dinagat Islands':['Select','Basilisa','Cagdianao','Dinagat','Libjo','Loreto','San Jose','Tubajon'],
'Surigao Del Norte':['Select','Alegria','Bacuag','Burgos','Claver','Dapa','Del Carmen','General Luna','Gigaquit','Mainit','Malimono','Pilar','Placer','San Benito','San Francisco',
                        'San Isidro','Santa Monica','Sison','Socorro','Surigao','Tagana-An','Tubod'],
'Surigao Del Sur':['Select','Barobo','Bayabas','Bislig','Cagwait','Cantilan','Carmen','Carrascal','Cortes','Hinatuan','Lanuza','Lianga','Lingig','Madrid','Marihatag','San Agustin',
                        'San Miguel','Tagbina','Tago','Tandag'],
'Basilan':['Select','Isabela','Lamitan','Lantawan','Maluso','Sumisip','Tipo-Tipo','Tuburan','Akbar','Al-Barka','Hadji Mohammad Ajul','Ungkaya Pukan','Hadji Muhtamad','Tabuan-Lasa'],
'Lanao del Sur':['Select','Bacolod-Kalawi','Balabagan','Balindong','Bayang','Binidayan','Bubong','Butig','Ganassi','Kapai','Lumba-Bayabao','Lumbatan','Madalum','Madamba',
                'Malabang','Marantao','Marawi','Masiu','Mulondo','Pagayawan','Piagapo','Poona Bayabao','Pualas','Ditsaan-Ramain','Saguiaran','Tamparan','Taraka','Tubaran',
                'Tugaya','Wao','Marogong','Calanogas','Buadiposo-Buntong','Maguing','Picong','Lumbayanague','Bumbaran','Tagoloan II','Kapatagan','Sultan Dumalondong','Lumbaca-Unayan'],
'Maguindanao':['Select','Ampatuan','Buldon','Buluan','Datu Paglas','Datu Piang','Datu Odin Sinsuat','Shariff Aguak','Matanog','Pagalungan','Parang','Sultan Kudarat','Sultan Sa Barongis',
                'Kabuntalan','Upi','Talayan','South Upi','Barira','Gen. S. K. Pendatun','Mamasapano','Talitay','Pagagawan','Paglat','Sultan Mastura','Guindulungan','Datu Saudi-Ampatuan',
                'Datu Unsay','Datu Abdullah Sangki','Rajah Buayan','Datu Blah T. Sinsuat','Datu Anggal Midtimbang','Mangudadatu','Pandag','Northern Kabuntalan','Datu Hoffer Ampatuan','Datu Salibo','Shariff Saydona Mustapha'],
'Sulu':['Select','Indanan','Jolo','Kalingalan Caluang','Luuk','Maimbung','Hadji Panglima Tahil','Old Panamao','Pangutaran','Parang','Pata','Patikul','Siasi','Talipao','Tapul',
        'Tongkil','Panglima Estino','Lugus','Pandami','Omar'],
'Tawi-Tawi':['Select','Panglima Sugala','Bongao','Mapun','Simunul','Sitangkai','South Ubian','Tandubas','Turtle Islands','Languyan','Sapa-Sapa','Sibutu'],
'Metro Manila':['Select',
'Mandaluyong','Marikina','Pasig','Quezon','San Juan','Caloocan','Malabon','Navotas','Valenzuela','Makati','Muntinlupa','Parañaque','Pasay','Pateros','Taguig'],
'Abra':['Select','Bangued','Boliney','Bucay','Bucloc','Daguioman','Danglas','Dolores','La Paz','Lacub','Lagangilang','Lagayan','Langiden','Licuan-Baay','Luba','Malibcong','Manabo',
        'Peñarrubia','Pidigan','Pilar','Sallapadan','San Isidro','San Juan','San Quintin','Tayum','Tineg','Tubo','Villaviciosa'],
'Apayao':['Select','Calanasan','Conner','Flora','Kabugao','Luna','Pudtol','Santa Marcela'],
'Benguet':['Select','Atok','Baguio','Bakun','Bokod','Buguias','Itogon','Kabayan','Kapangan','Kibungan','La Trinidad','Mankayan','Sablan','Tuba','Tublay'],
'Ifugao':['Select','Banaue','Hungduan','Kiangan','Lagawe','Lamut','Mayoyao','Alfonso Lista','Aguinaldo','Hingyon','Tinoc','Asipulo'],
'Kalinga':['Select','Balbalan','Lubuagan','Pasil','Pinukpuk','Rizal','Tabuk','Tanudan','Tinglayan'],
'Mountain Province':['Select','Barlig','Bauko','Besao','Bontoc','Natonin','Paracelis','Sabangan','Sadanga','Sagada','Tadian']}

export const ItemList = [
    '70% Ethyl Alcohol',
    'N95 Mask',
    'Surgical Mask',
    'Acrylic Face Shield',
    'Surgical Gowns',
    'Disposable Goggles',
    'Disposable Shoe cover',
    'PPEs Kits',
    'Nitrile Gloves',
    'Head Cap',
    'ET Cube',
    'Ventilators',
    'Transportation',
    'Food',
    'Others'
]

export const UnitTypes = [
    'Pieces',
    'Kilograms',
    'Gallons',
    'Vehicles'
]



