import React,{useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/PledgerInformationPrompt.css'

const PledgerInformationPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    return(
        <React.Fragment>
            <div id="PledgerInformationPrompt">
                <div className="PledgerInformation-Header">
                    <div className="PledgerInformation-PreHeaderTitle">Pledger</div>
                    <div className="PledgerInformation-HeaderTitle">Contact information</div>
                </div>

                <div className="PledgerInformation-Body">
                    <div className="PledgerInformation-InputLayout">
                        <div className="PledgerInformation-InputLayout-Left">
                            <div className="PledgerInformation-InputLeft">
                                <label className="PledgerInformation-InputLabelSmall">First name</label>
                                <div className="PledgerInformation-InputValue">{modalDetails.data.firstName}</div>
                            </div>
                        </div>
                        <div className="PledgerInformation-InputLayout-Right">
                            <div className="PledgerInformation-InputRight">
                                <label className="PledgerInformation-InputLabelSmall">Last name</label>
                                <div className="PledgerInformation-InputValue">{modalDetails.data.lastName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="PledgerInformation-InputLayout">
                        <div className="PledgerInformation-InputLayout-Left">
                            <div className="PledgerInformation-InputLeft">
                                <label className="PledgerInformation-InputLabelSmall">Mobile number</label>
                                <div className="PledgerInformation-InputValueBlue">{modalDetails.data.mobileNumber}</div>
                            </div>
                        </div>
                        <div className="PledgerInformation-InputLayout-Right">
                            <div className="PledgerInformation-InputRight">
                                <label className="PledgerInformation-InputLabelSmall">Email address</label>
                                <div className="PledgerInformation-InputValueBlue">{modalDetails.data.emailAddress}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="PledgerInformation-Footer">
                    <div className="PledgerInformation-CancelButton" onClick={()=>setModalDetails({...modalDetails,'state':false})}>Close</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default PledgerInformationPrompt