import React,{useState,useEffect,useContext} from 'react'
import '../styles/AdminVerificationList.css'
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import {useFetchUnverifiedUsersList,verifyUserAccount,rejectUserAccount} from '../ui/AdminVerificationListPageViewModel.jsx'
import FilteredVerificationList from '../components/FilteredVerificationList.jsx'
import { db } from '../../../../global/services/firebase';
import { sendMessageNotification } from './../../../../global/services/sephamore'
const AdminVerificationListPage =()=> {

    const [modalDetails,setModalDetails] = useState(useContext(ModalState))
    
    const [fetchTrigger,setFetchTrigger] = useState(0)

    const [search, setSearch] = useState(null);

    const {data, loading} = useFetchUnverifiedUsersList(fetchTrigger)

    async function searchSpace(event) {
        let keyword = event.target.value;
        return await setSearch(keyword);
       }
    function viewId(item){
        setModalDetails({...modalDetails,'type':'ViewIdentification','data':item,'state':true})
    }

    function contactInfo(item){
        setModalDetails({...modalDetails,'type':'ContactInfo','data':item,'state':true})
    }
    async function sendEmail(item,contents) {
        try {
            return await db.collection('mail').add({
                to: item.emailAddress,
                bcc: "support@wecare.ph",
                 message: {
                   subject: contents.title,
                   html: contents.message,
                 },
               })
        } catch (error) {
            console.log(error);
        }
    }
    async function verifyUser(item){
        const isUserVerified = await verifyUserAccount(item.uid)
        if(isUserVerified){
            const emailContents = {
                title: 'WeCare Philippines - Account Verified',
                message: "Taus pusong pasasalamat sa iyo at sa iyong pagtulong sa ating kapwa kababayan.<br/><br/> This is to let you know that you have successfully been verified and we thank you for caring.<br/> Let people know what you need. You are now one step away from receiving support.<br/><br/> <b>Maaari niyo na pong ilagay ang inyong mga pangangailangan. Salamat po.</b><br/><br/><i>- WeCare Team -</i><br/>Please follow us on facebook<br/> https://www.facebook.com/WeCAREPlatform/"
            }
            const smsData = {
                number: item.mobileNumber,
                message: "Taus pusong pasasalamat sa iyo! This is to let you know that you have been verified in the WeCARE platform and we thank you for caring. Maaari niyo na pong ilagay ang inyong mga pangangailangan.",
            }
            await sendMessageNotification(smsData);
            await sendEmail(item,emailContents);
            alert("User has been verified!")
            await setFetchTrigger(fetchTrigger+1)
        }
    }
    async function rejectUser(item){
        setModalDetails({...modalDetails,'type':'RejectUser','data':item,'state':true})
    }
    function closeModal(e){
        if (e.target.className === "modalOpen") {
            setModalDetails({...modalDetails,'state':false}) 
        }
    }
    useEffect(()=>{
        if(modalDetails.data === "RejectUserSuccess"){
            const emailContents = {
                title: 'WeCare Philippines - Account Rejected',
                message: "Hi "+`${modalDetails.pledgee.firstName} ${modalDetails.pledgee.lastName},`+ "<br/><br/> This is to let you know that your account verification has been rejected due to having insufficient information.<br/> Please try setting up your account again with more information. <br/><br/> WeCare has a strict verification process in order to prevent fake entries and provide users with a safe and smooth environment to request their needs.<br/> For more information, please send an email to support@wecare.ph and our team will get in touch with you as soon as possible.<br/><br/> Thank you for your kind understanding.<br/><br/><i>- WeCare Team -</i><br/>Please follow us on facebook<br/> https://www.facebook.com/WeCAREPlatform/"
            }
            sendEmail(modalDetails.pledgee,emailContents);
            setFetchTrigger(fetchTrigger+1)
        }
        if(modalDetails.data === "RejectUserFailed"){
            alert("Failed to reject user application.")
        }
    },[modalDetails.state])

    return(
        <React.Fragment>
            <div className={modalDetails.state ? "modalOpen":"modalClose"} onClick={(e)=>closeModal(e)}>
                <ModalState.Provider value={{modalDetails,setModalDetails}}>
                    <Modal />
                </ModalState.Provider>
            </div>
            <div id="AdminVerificationListContainer">
                <div className="AdminVerificationList-Header">
                    <div className="AdminVerificationList-HeaderLeft">
                        <div className="AdminVerificationList-HeaderTitle">Pending verification</div>
                    </div>
                    <div className="AdminVerificationList-HeaderRight">
                        <div className="AdminVerificationList-InputHolderLarge">
                            <input type="text" placeholder="Search" id="AdminVerificationList-TextInput"  onChange={(event)=>searchSpace(event)}  />
                        </div>
                    </div>
                </div>
                {
                    loading
                    ? <div className="AdminVerificationList-Loading">Loading...</div>
                    :
                    <table id="AdminVerificationList">
                        <tbody>
                            <tr>
                                <th><div className="thRow">First Name</div></th>
                                <th><div className="thRow">Last Name</div></th>
                                <th><div className="thRow">Institution/Hospital</div></th>
                                <th><div className="thRow" >LinkedIn</div></th>
                                <th style={{width:390,whiteSpace:"nowrap"}}><div className="thRowLast">Actions</div></th>
                            </tr>
                            <FilteredVerificationList data={data} value={search} onClickViewId={viewId} 
                            onClickContactInfo={contactInfo} onClickVerify={verifyUser} onClickReject={rejectUser} />
                        </tbody>
                    </table>
                }
                
            </div>
        </React.Fragment>
    )
}
export default AdminVerificationListPage