import React from 'react'
import ListingsTracker from '../components/ListingsTracker.jsx'
import '../styles/MyListingsPage.css'

const MyListingsPage =({user})=>{

    return(
        <React.Fragment>
            <div id="MyListingsPage">
                <ListingsTracker  user={user} />
            </div>
        </React.Fragment>
    )
}
export default MyListingsPage