import React,{useState,useEffect,useContext} from 'react'
import '../styles/PledgesTracker.css'
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import {useFetchMyPledges} from '../ui/MyPledgesPageViewModel.jsx'
import {getUserProfile} from '../../../main/screens/ui/MyAccountPageViewModel.jsx'
import { Select } from 'antd';
import {db} from "../../../../global/services/firebase";
import ReactGa from 'react-ga'
import InitializeReactGa from '../../../../global/services/googleanalytics/index.jsx'

const PledgesTable =()=> {

    const { Option } = Select;

    const [modalDetails,setModalDetails] = useState(useContext(ModalState))
    const [loading,setLoading] = useState(true)
    const [myPledgeList,setMyPledgeList] = useState(null)
    const [fetchTrigger,setFetchTrigger] = useState(0)

    const {data} = useFetchMyPledges(fetchTrigger)

    async function populatePledgeList(pledgeList){
        let pledgeItems = []

        await pledgeList.map(async (item) =>{
            await db.collection("users").doc(item.uid)
            .get()
            .then(async function(doc) {
                if(doc.exists){
                    const newPledgeItem = {...item,pledgeeProfile:doc.data()}
                    pledgeItems.push(newPledgeItem)
                    if(pledgeItems.length === pledgeList.length){
 
                        setMyPledgeList(pledgeItems)
                        setLoading(false)
                    }
                }

            })
            .catch(function(error) {
                console.log("profile ", error);
                setLoading(false)
            });

            
        })
    }

    function cancelPledge(item){
        InitializeReactGa();
        ReactGa.event({category: 'Cancel Pledge', action: 'Click cancel pledge on My Pledges', label: 'Cancel Pledge'})
        if(item.status === "Received"){
            const promptDetails = {title:'Unable to cancel pledge',message: 'Item has already been received by the pledgee.',icon:'Alert'}
            setModalDetails({'type':'GenericPrompt','data':promptDetails,'state':true})
        }
        else{
            setModalDetails({'type':'CancelPledge','data':item,'state':true})
        }
    }

    function moreActions(item){
        setModalDetails({'type':'PledgeeInformation','data':item,'state':true})
    }

    function viewReason(item){
        const promptDetails = 
        {
            title:'Rejected Pledge',
            message: '- Display the explanation here as to why the pledge has been rejected by the pledgee. This explanation should be provided by the pledgee upon rejecting the pledge.  -',
            icon:'Alert'
        }
        setModalDetails({'type':'GenericPrompt','data':promptDetails,'state':true})
    }


    function closeModal(e){
        if (e.target.className === "modalOpen") {
            setModalDetails({...modalDetails,'state':false}) 
        }
    }

    useEffect(()=>{
     
        if(data){
            if(data.length>0){
                setLoading(true)
                populatePledgeList(data)
            }
            else{
                setLoading(false)
                setMyPledgeList(null)
            }
        }
        
    },[data])

    useEffect(()=>{
        if(modalDetails.data === "ChangeStatusSuccess"){
            setLoading(true)
            setFetchTrigger(fetchTrigger+1)
        }
        if(modalDetails.data === "CancelPledgeSuccess"){
            setLoading(true)
            setFetchTrigger(fetchTrigger+1)
        }
    },[modalDetails.state])

    return(
        <React.Fragment>
            <div className={modalDetails.state ? "modalOpen":"modalClose"} onClick={(e)=>closeModal(e)}>
                <ModalState.Provider value={{modalDetails,setModalDetails}}>
                    <Modal />
                </ModalState.Provider>
            </div>
            <div id="PledgesTrackerContainer">
                <div className="PledgesTracker-Header">
                    <div className="PledgesTracker-HeaderTitle">My Pledges List</div>
                </div>
                {
                    loading
                    ? <div className="PledgesTracker-Loading">Loading...</div>
                    :
                    <div>
                        {
                            myPledgeList && myPledgeList.length>0 
                            ?
                            <table id="PledgesTracker">
                                <tbody>
                                    <tr>
                                        <th><div className="thRow">Pledged Item</div></th>
                                        <th><div className="thRow">Quantity</div></th>
                                        <th><div className="thRow" >Item Specs</div></th>
                                        <th><div className="thRow" >Institution/Hospital</div></th>
                                        <th><div className="thRow" >Contribution Type</div></th>
                                        <th><div className="thRow">Status</div></th>
                                        <th style={{width:260,whiteSpace:"nowrap"}}><div className="thRowLast">Actions</div></th>
                                    </tr>
                                    {
                                    myPledgeList.map((item,index) => {
                                        return <tr key={index} style={item.status === "Received" ? {backgroundColor:"#F8FFF4",height:"55px"} : {}}>
                                                    <td style={item.status === "Rejected" ? {color:"#A9A9A9"} : {}}>{item.itemName}</td>
                                                    <td style={item.status === "Rejected" ? {color:"#A9A9A9"} : {}}>{item.pledgeQuantity} {item.unit}</td>
                                                    <td style={item.status === "Rejected" ? {color:"#A9A9A9"} : {}}>{item.itemSpecs}</td>
                                                    <td style={item.status === "Rejected" ? {color:"#A9A9A9"} : {}}>{item.location}</td>
                                                    <td style={item.status === "Rejected" ? {color:"#A9A9A9"} : {}}>Donation/Free</td>
                                                    <td style={item.status === "Rejected" ? {color:"#A9A9A9"} : {}}>{item.status}</td>
                                                    
                                                    {
                                                        item.status === "Received"
                                                        ? <td></td>
                                                        : item.status === "Rejected"
                                                            ?
                                                            <td>
                                                                <div className="PledgesTracker-ActionButtonsLayout">
                                                                    <div className="PledgesTracker-ActionButtonsLayout-Left">
                                                                        <div className="PledgesTracker-ActionButton-ViewReason" onClick={()=>viewReason(item)}>View reason</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            : 
                                                            <td>
                                                                <div className="PledgesTracker-ActionButtonsLayout">
                                                                    <div className="PledgesTracker-ActionButtonsLayout-Left">
                                                                        <div className="PledgesTracker-ActionButton-CancelRequest" onClick={()=>cancelPledge(item)}>Cancel Pledge</div>
                                                                    </div>
                                                                    <div className="PledgesTracker-ActionButtonsLayout-Right">            
                                                                        <div className="PledgesTracker-ActionButton-ViewPledgers" onClick={()=>moreActions(item)}>More Actions</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                    }
                                                </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                            :
                            <div id="PledgesTracker-RequestsPlaceholder">Your pledges will appear here. Pledge donations to community requests now.</div>
                        }
                    </div>
                }
                
            </div>
        </React.Fragment>
    )
}
export default PledgesTable