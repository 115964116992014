import React, { useState, useContext, useEffect } from "react";

import "../styles/LocationForm.css";
import Modal from "../../../../global/components/Modal";
import { db } from "../../../../global/services/firebase";
import { checkLocationInput } from "../ui/AccountSetupPageViewModel.jsx";
import { useFetchCommunityRequests } from "../ui/CommunityRequestsPageViewModel.jsx";
import { AccountSetupDetails } from "../../../../global/services/Context.jsx";
import "antd/dist/antd.css";
import { ModalState } from "../../../../global/services/Context.jsx";

const LocationForm = () => {
  const [modalDetails, setModalDetails] = useState(useContext(ModalState));
  const { accountSetupDetails, setAccountSetupDetails } = useContext(
    AccountSetupDetails
  );
  const [locationName, setLocationName] = useState("");
  // const [fetchTrigger,setFetchTrigger] = useState(0)
  const [locationAddress, setLocationAddress] = useState("");
  const [cityValue, setCityValue] = useState();
  const [provinceValue, setProvinceValue] = useState("");
  const [errorCode, setErrorCode] = useState(0);
  let hospital_list = [];
  //Visibility & Availability
  const [hide, setHide] = useState(true);
  const [value, setValue] = useState([]);
  const [resultValue, setResultValue] = useState();
  const {
    hospitalName,
    hospitalAddress,
    hospitalCityMunicipality,
    hospitalProvince,
  } = !resultValue ? "" : JSON.parse(resultValue);
  const { data } = useFetchCommunityRequests();

  function handleChangeLocationName(e) {
    setHide(false);
    setLocationName(e.target.value);
    if (e.target.value.length === 0) {
      setLocationName("");
      setLocationAddress("");
      return setValue(null);
    } else {
      db.collection("philippineshospitals")
        .where("name", ">=", e.target.value.toUpperCase())
        .where("name", "<=", e.target.value.toUpperCase() + "\uf8ff")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const hospital = doc.data();
            hospital_list.push({
              hospitalName: hospital.name,
              hospitalAddress: hospital.address,
              hospitalCityMunicipality: hospital.city_municipality,
              hospitalProvince: hospital.province,
              hospitalPhone_number: hospital.phone,
              hospitalLatitude: hospital.latitude,
              hospitalLongitude: hospital.longitude,
            });
          });
          setValue(hospital_list);
        });
    }
  }
  function handleChangeLocationAddress(e) {
    setLocationAddress(e.target.value);
  }
  function handleClicklAutoComplete(e) {
    e.preventDefault();
    setHide(true);
    return value.filter(function (item) {
      setResultValue(e.currentTarget.dataset.league);
    });
  }

  function showAutoComplete() {
    if (locationName === "") {
      return;
    } else {
      return hide ? (
        ""
      ) : (
        <ul>
          {!value
            ? ""
            : value.map((item, i) => {
                return (
                  <li
                    key={i}
                    data-league={JSON.stringify(item)}
                    onClick={(e) => handleClicklAutoComplete(e)}
                  >
                    {item.hospitalName}
                  </li>
                );
              })}
        </ul>
      );
    }
  }

  function populateLocationForm() {
    setLocationName(hospitalName);
    setLocationAddress(hospitalAddress);
    setCityValue(hospitalCityMunicipality);
    setProvinceValue(hospitalProvince);
  }

  function showAlert() {
    return !data
      ? ""
      : data.map(async (item) => {
          if (hospitalName === item.location) {
            await setLocationName("");
            await setLocationAddress("");
            await setValue(null);
            return setModalDetails({
              type: "AlreadyRegisteredPrompt",
              state: true,
            });
          }
        });
  }
  async function proceedToNextStep() {
    const isValidInput = await checkLocationInput(
      locationName,
      locationAddress
    );
    if (isValidInput === 0) {
      return setAccountSetupDetails({
        ...accountSetupDetails,
        step: "3",
        locationName: locationName,
        locationAddress: locationAddress,
        city: cityValue,
        province: provinceValue,
      });
    } else {
      setErrorCode(isValidInput);
    }
  }
  useEffect(() => {
    populateLocationForm();
    showAlert();
    if (
      accountSetupDetails.locationName !== "" &&
      accountSetupDetails.locationName !== undefined &&
      accountSetupDetails.locationAddress !== "" &&
      accountSetupDetails.locationAddress !== undefined
    ) {
      setLocationName(accountSetupDetails.locationName);
      setLocationAddress(accountSetupDetails.locationAddress);
      setCityValue(accountSetupDetails.city);
      setProvinceValue(accountSetupDetails.province);
      setAccountSetupDetails({
        ...accountSetupDetails,
        locationName: "",
        locationAddress: "",
        city: "",
        province: "",
      });
    }
  }, [hospitalName, hospitalAddress, data]);

  function closeModal(e) {
    if (e.target.className === "modalOpen") {
      setModalDetails({ ...modalDetails, state: false });
    }
  }

  return (
    <React.Fragment>
      <div
        className={modalDetails.state ? "modalOpen" : "modalClose"}
        onClick={(e) => closeModal(e)}
      >
        <ModalState.Provider value={{ modalDetails, setModalDetails }}>
          <Modal />
        </ModalState.Provider>
      </div>
      <div id="LocationFormContainer">
        <div id="LocationForm">
          <div className="LocationForm-Header">
            <div id="LocationForm-Pretitle">We need things</div>
            <div id="LocationForm-Title">Account Setup</div>
            <div id="LocationForm-Subtitle">Step 1 of 2</div>
          </div>
          <div className="LocationForm-Contents">
            <label id="LocationForm-InputLabel">Location</label>
            <label id="LocationForm-Description">
              For better tracking of things we will need the location of your
              institution/hospital.
            </label>
            <label id="LocationForm-InputLabelSmall">
              Institution/Hospital Name:
            </label>
            <div className="LocationForm-AutoComplete">
              <input
                type="text"
                id={`${
                  errorCode === 1 || errorCode === 3
                    ? "LocationForm-TextInputError"
                    : "LocationForm-TextInput"
                }`}
                value={locationName}
                onChange={(e) => handleChangeLocationName(e)}
              />
              {showAutoComplete()}
            </div>
            <div
              id={`${
                errorCode === 1 || errorCode === 3
                  ? "LocationForm-TextError"
                  : "Hidden"
              }`}
            >
              This field is required
            </div>
            <label id="LocationForm-InputLabelSmall">
              Institution/Hospital Address:
            </label>
            <input
              type="text"
              id={`${
                errorCode === 2 || errorCode === 3
                  ? "LocationForm-AddressInputError"
                  : "LocationForm-AddressInput"
              }`}
              value={locationAddress}
              onChange={handleChangeLocationAddress}
            />
            <div
              id={`${
                errorCode === 2 || errorCode === 3
                  ? "LocationForm-TextError"
                  : "Hidden"
              }`}
            >
              Complete address is required.
            </div>
          </div>
          <div className="LocationForm-ButtonsLayout">
            {accountSetupDetails.partner &&
            accountSetupDetails.partner === "ABS-CBN" ? null : (
              <div
                id="LocationForm-BackButton"
                onClick={() =>
                  setAccountSetupDetails({ ...accountSetupDetails, step: "1" })
                }
              >
                Back
              </div>
            )}

            <div
              id="LocationForm-SaveButton"
              onClick={() => proceedToNextStep()}
            >
              Next
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default LocationForm;
