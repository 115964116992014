import React, { useState, useContext, useEffect,useRef } from "react";
import { useHistory } from 'react-router-dom';
import { registerWithEmail, checkAuth ,isNewUser } from "../ui/LandingPageViewModel.jsx";
import WeCareLogo from "../../../../assets/logo_wecare.png";
import "../styles/RegisterPage.css";
import Modal from "../../../../global/components/Modal.jsx";
import ShowPassword from './../../../../assets/open_password.svg';
import HidePassword from './../../../../assets/close_password.svg';
import { ModalState } from "../../../../global/services/Context.jsx";
import ReactGa from 'react-ga';
import InitializeReactGa from '../../../../global/services/googleanalytics/index.jsx';
import BackIcon from '../../../../assets/blue_back_button.png';
import { register } from '../../../../global/services/Constants.js';

export  default () => {
  
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const history = useHistory();
  const [modalDetails, setModalDetails] = useState(useContext(ModalState));
  const [error, setError] = useState();
  const [passwordOption, setPasswordOption] = useState(false);
  const [confirmPasswordOption, setConfirmPasswordOption] = useState(false);
  // ref
  const emailInput = useRef();
  const passwordInput = useRef();
  const confirmPasswordInput = useRef();

  function handlePasswordOption(){
    setPasswordOption(!passwordOption);
  }
  function handleConfirmPasswordOption(){
   setConfirmPasswordOption(!confirmPasswordOption);
  }

  async function checkUser(){
    const user = await checkAuth()
    if(user){
      console.log("user",user)
      setIsLoggingIn(true);
      isNewUser(user.uid);
    }
    else{
      setIsLoggingIn(false)
    }
  }

  InitializeReactGa();  

  async function doEmailRegister() {
    setIsLoggingIn(true);
    if (!emailInput.current.value && !passwordInput.current.value && !confirmPasswordInput.current.value) {
      setIsLoggingIn(false);
      return setError({ message: { email: register.emailRequired, password: register.passwordRequired} });
    }
    else if (!emailInput.current.value) {
      setIsLoggingIn(false);
      return setError({ message: { email: register.emailRequired} });
    }
    else if (!passwordInput.current.value) {
      setIsLoggingIn(false);
      return setError({ message: { password: register.passwordRequired } });
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(passwordInput.current.value) && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(passwordInput.current.value)) {
      setIsLoggingIn(false);  
      return setError({ message: { password: register.passwordRegexExpression} });
    } else if (!confirmPasswordInput.current.value) {
      setIsLoggingIn(false);
      return setError({ message: { confirmPassword: register.confirmPasswordRequired }});
    } 
    else if (passwordInput.current.value !== confirmPasswordInput.current.value) {
      setIsLoggingIn(false);
      return setError({ message: {confirmPassword: register.passwordConfirmDoNotMatch }});
    }
    else {
      setIsLoggingIn(true);
      if (emailInput.current.value && passwordInput.current.value && confirmPasswordInput.current.value){
        let data = await registerWithEmail(emailInput.current.value, passwordInput.current.value)
        if (passwordInput.current.value === confirmPasswordInput.current.value) {
           setError("");
        }
        if (data && data.user){
          const promptDetails = {title:'Email registration successful!',icon:'Success',message:"Upon clicking Ok below you will be redirected to the account setup page.",'isSuccessRegistration':true}
          setModalDetails({ 'type': 'GenericPrompt', 'data': promptDetails,'state':true})
        }
        else if (data.code === "auth/invalid-email") {
            setIsLoggingIn(false);
            setError({ message: { emailFormat: register.emailFormatRequired } });
        }
        else if (data.code === "auth/email-already-in-use") {
          setIsLoggingIn(false);
          setError({ message: { email:"Email is already in use by another account"} });
      }
        else if (!data && !data.code && !data.user) {
          const promptDetails = {title:'Email registration failed.',icon:'',message:"There seems to be a problem connecting to WeCare’s server please try again later.",'isFailLogin':true}
          setModalDetails({ 'type': 'GenericPrompt', 'data': promptDetails,'state':true})
        }
       }
    }
  }

  useEffect(() => {
    checkUser()
  },[]);

  function closeModal(e) {
    if (e.target.className === "modalOpen") {
      setModalDetails({ ...modalDetails, state: false });
    }
  }
  return (
      <React.Fragment>
    <div id="RegisterPage-Body">
      <div
        className={modalDetails.state ? "modalOpen" : "modalClose"}
        onClick={e => closeModal(e)}
      >
        <ModalState.Provider value={{ modalDetails, setModalDetails }}>
          <Modal />
        </ModalState.Provider>
          </div>
     <header id="RegisterPage-Header">
        <div onClick={()=>history.push("/login")}>
                <span id="LoginUsingEmailPage-HeaderTitle"> <img src={BackIcon} id="RegisterPage-HeaderIcon" alt="backButton"/> Back to WeCARE Home</span>     
        </div>
    </header>
     <div id="RegisterPage">
        <div id="RegisterPageContents1">
          <img src={WeCareLogo} id="RegisterPage-Logo" />
          <div id="RegisterPage-Title">
            Email register
          </div>
                <div className="RegisterPage-InputContainer">
                    <label className="RegisterPage-InputLabel">Email</label>
                    <input ref={emailInput} className={error && error.message.email ?"RegisterPage-InputErrorGen": error && error.message.emailFormat? "RegisterPage-InputErrorGen":"RegisterPage-InputGen"} />   
                     <span className="RegisterPage-InputErrorMessage">{error && error.message.email}</span>   
                     <span className="RegisterPage-InputErrorMessage">{error && error.message.emailFormat}</span>   
                </div>
          </div>
          <div id="RegisterPage-PasswordInformation">
             <div id="RegisterPage-PasswordInformation-Description">Passwords must be at least 8-16 characters in length, must have one upper case letter, one lower case letter, one number, and an optional special character</div>
          </div>
          <div id="RegisterPageContents1"> 
                <div className="RegisterPage-InputContainer">
                     <label className="RegisterPage-InputLabel">Password</label>
                     <img src={ passwordOption? HidePassword : ShowPassword } className="RegisterPage-PasswordOption" alt="showPassword" onClick={handlePasswordOption}/>
                    <input ref={passwordInput} type={passwordOption ? "text" : "password"} className={error && error.message.password ?"RegisterPage-InputErrorPass":"RegisterPage-InputPass"} />   
                    <span className="RegisterPage-InputErrorMessage">{error && error.message.password}</span>   
            </div>
                <div className="RegisterPage-InputContainer">
                      <label className="RegisterPage-InputLabel"> Confirm Password</label>
                      <img src={ confirmPasswordOption ? HidePassword : ShowPassword } className="RegisterPage-PasswordOption" alt="showConfirmPassword" onClick={handleConfirmPasswordOption}/>
                     <input ref={confirmPasswordInput}  type={confirmPasswordOption ? "text" : "password"} className={error && error.message.confirmPassword ?"RegisterPage-InputErrorPass":"RegisterPage-InputPass"} />   
                      <span className="RegisterPage-InputErrorMessage">{error && error.message.confirmPassword }</span>   
                </div>
          {isLoggingIn ? (
            <div id="RegisterPageContents-RegisterButtonDisabled">
              <div id="RegisterPageContents-RegisterButtonLoading" />
            </div>
          ) : (
            <div
              id="RegisterPageContents-RegisterButton"
              onClick={() => doEmailRegister()}
            >
              Register 
            </div>
              )}
         <div id="RegisterPage-DataPrivacy">
            By clicking Register I hereby agree and consent to the {""}
            <a href="/terms-and-conditions" target="_blank">
              User Agreement
            </a>
            , its policies, and the{" "}
            <a href="https://cortex.ph/privacypolicy" target="_blank">
              Privacy Policy.
            </a>
          </div>
        </div>
        <div id="RegisterPageContentsFooter">
          <div
            id="RegisterPageContentsFooter-NewToWecare"
          >
            Email already registered?
          </div>
            <div
              id="RegisterPageContents-LoginNewbutton"
              onClick={() =>history.push('/login/email')}
            >
              Login using email
            </div>
            </div>
        </div>
    </div>
    </React.Fragment>
  );
};

