import React,{useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/ItemListingMoreDetailsPrompt.css'

const ItemListingMoreDetailsPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    return(
        <React.Fragment>
            <div id="ItemListingMoreDetailsPrompt">
                <div className="ItemListingMoreDetails-Header">
                    <div className="ItemListingMoreDetails-PreHeaderTitle">Additional item details</div>
                    <div className="ItemListingMoreDetails-HeaderTitle">{modalDetails.data.itemName}</div>
                </div>
                
                <div className="ItemListingMoreDetails-Body">
                    <div className="ItemListingMoreDetails-ValueLabel">Minimum order unit quantity</div>
                    <div className="ItemListingMoreDetails-Value">{modalDetails.data.minQuantity}</div>

                    <div className="ItemListingMoreDetails-ImageContainer">
                        <div className="ItemListingMoreDetails-ValueLabel">Item image</div>
                        <div className="ItemListingMoreDetails-ImageHolder">
                            {
                                modalDetails.data.itemImage !== ''
                                ?<img src={modalDetails.data.itemImage} />
                                :<div className="ItemListingMoreDetails-NoImage">No Image</div>
                            }
                        </div>
                    </div>
                </div>

                <div className="ItemListingMoreDetails-Footer">
                    <div className="ItemListingMoreDetails-CloseButton" onClick={()=>setModalDetails({...modalDetails,'state':false})} >Close</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ItemListingMoreDetailsPrompt