import React from 'react'
import '../styles/LandingFAQPage.css'
import LandingMainMenu from '../components/LandingMainMenu.jsx'
import LandingFAQRenderer from '../components/LandingFAQRenderer.jsx'

const LandingFAQPage =()=> {

    return(
        <React.Fragment>
            <div id="LandingFAQPage">
                <LandingMainMenu />
                <LandingFAQRenderer />
            </div>
        </React.Fragment>
    )
}
export default LandingFAQPage