export const csvHeaders = [
	{ label: 'Item Request', key: 'itemName' },
	{ label: 'Item Specs', key: 'itemSpecs' },
	{ label: 'Date', key: 'createdAt' },
	{ label: 'City/Town', key: 'city' },
	{ label: 'Institution/Hospital', key: 'location' },
	{ label: 'Progress', key: 'progress' },
	{ label: 'Quantity', key: 'quantity' },
	{ label: 'Unit', key: 'unit' },
];
