import firebase from "firebase/app";
import {db} from "../../../../global/services/firebase";
import {useState, useEffect} from 'react'
import { timeStamp } from "../../../../global/services/moment";

export const verifyUserAccount =async(docId)=>{
    let userVerificationState = false
    var docRef = db.collection("users").doc(docId);
    var benRef = db.collection("beneficiaries").doc(docId);
    await db.runTransaction(function(transaction) {
        return  transaction.get(benRef).then(function(sfDoc) {
             if (!sfDoc.exists) {
                 throw "Document does not exist!";
             }
             transaction.update(benRef, { emailVerified: true , userType:"NeedsHelp",updatedAt:timeStamp});
          }); 
 
    }).then(function (res) {
        console.log(res);
         //setModalDetails({...modalDetails,'data':'PledgeSuccess','state':false})
     }).catch(function(err) {
         console.log(err);
         //setModalDetails({...modalDetails,'data':'PledgeFailed','state':false})
     });
    await db.runTransaction(function(transaction) {
       return  transaction.get(docRef).then(function(sfDoc) {
            if (!sfDoc.exists) {
                throw "Document does not exist!";
            }
            transaction.update(docRef, { emailVerified: true , userType:"NeedsHelp",updatedAt:timeStamp});
         }); 
    }).then(function(res) {
        userVerificationState = true
        //setModalDetails({...modalDetails,'data':'PledgeSuccess','state':false})
    }).catch(function(err) {
        userVerificationState = false
        //setModalDetails({...modalDetails,'data':'PledgeFailed','state':false})
    });
    return userVerificationState
}

export const rejectUserAccount =async(docId)=>{
    let userRejectedState = false
    var docRef = db.collection("users").doc(docId);
    var benRef = db.collection("beneficiaries").doc(docId);
    await db.runTransaction(function(transaction) {
        return  transaction.get(benRef).then(function(sfDoc) {
             if (!sfDoc.exists) {
                 throw "Document does not exist!";
             }
             transaction.update(benRef, {userType: "Rejected",updatedAt:timeStamp });
          }); 
    }).then(function (res) {
        console.log(res);
         //setModalDetails({...modalDetails,'data':'PledgeSuccess','state':false})
     }).catch(function(err) {
         console.log(err);
         //setModalDetails({...modalDetails,'data':'PledgeFailed','state':false})
     });
    await db.runTransaction(function(transaction) {
     return transaction.get(docRef).then(function(sfDoc) {
            if (!sfDoc.exists) {
                throw "Document does not exist!";
            }
            transaction.update(docRef, { userType: "Rejected",updatedAt:timeStamp });
      });
    }).then(function (res) {
        console.log(res)
        userRejectedState = true
        //setModalDetails({...modalDetails,'data':'PledgeSuccess','state':false})
    }).catch(function(err) {
        console.log(err)
        userRejectedState = false
        //setModalDetails({...modalDetails,'data':'PledgeFailed','state':false})
    });

    return userRejectedState
}

const getUnverifiedUsersList =async()=>{
    
    let UnverifiedUserList = []
    
    await db.collection("users").where("emailVerified", "==", false)
    .get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            UnverifiedUserList.push(doc.data())
        })
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
    
    return UnverifiedUserList
    
}

export const useFetchUnverifiedUsersList = (event) => {
    
    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{

        setState(state=>({data:state.data,loading:true}))

        async function fetchUnverifiedUsersList(){
            const unverifiedUsersList = await getUnverifiedUsersList()
            setState({data:unverifiedUsersList,loading:false})
        }
        
        fetchUnverifiedUsersList()
        
    },[event])

    return state
};


