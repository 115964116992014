import React,{useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/ContactInfoPrompt.css'


const ContactInfoPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    return(
        <React.Fragment>
            <div id="ContactInformation">
                <div className="ContactInformation-Header">
                    <div className="ContactInformation-PreHeaderTitle">Requestor</div>
                    <div className="ContactInformation-HeaderTitle">Contact Information</div>
                </div>

                <div className="ContactInformation-Body">
                    <div className="ContactInformation-InputLayout">
                        <div className="ContactInformation-InputLayout-Left">
                            <div className="ContactInformation-InputLeft">
                                <label className="ContactInformation-InputLabelSmall">First name</label>
                                <div className="ContactInformation-InputValue">{modalDetails.data.firstName}</div>
                            </div>
                        </div>
                        <div className="ContactInformation-InputLayout-Right">
                            <div className="ContactInformation-InputRight">
                                <label className="ContactInformation-InputLabelSmall">Last name</label>
                                <div className="ContactInformation-InputValue">{modalDetails.data.lastName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="ContactInformation-InputLayout">
                        <div className="ContactInformation-InputLayout-Left">
                            <div className="ContactInformation-InputLeft">
                                <label className="ContactInformation-InputLabelSmall">Mobile Number</label>
                                <div className="ContactInformation-InputValueBlue">{modalDetails.data.mobileNumber}</div>
                            </div>
                        </div>
                        <div className="ContactInformation-InputLayout-Right">
                            <div className="ContactInformation-InputRight">
                                <label className="ContactInformation-InputLabelSmall">Email address</label>
                                <div className="ContactInformation-InputValueBlue">{modalDetails.data.emailAddress}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ContactInformation-Footer">
                    <div className="ContactInformation-CloseButton" onClick={()=>setModalDetails({...modalDetails,'state':false})}>Close</div>
                </div>
                
            </div>
        </React.Fragment>
    )
}
export default ContactInfoPrompt