import React from 'react'
import FAQRenderer from '../components/FAQRenderer.jsx'
import '../styles/FAQPage.css'
import Footer from '../../../../global/components/Footer.jsx'

const FAQPage =()=> {

    return(
        <React.Fragment>
            <div id="FAQPage">
                <FAQRenderer />
            </div>
            <Footer />
        </React.Fragment>
    )
}
export default FAQPage