import React, { useState, useContext } from "react";
import { ModalState } from "../services/Context.jsx";
import AlertIcon from "../../assets/ic_alert.png";
import SuccessIcon from "../../assets/ic_success.png";
import "../styles/GenericPrompt.css";
import { saveAndSubmit } from "../../pages/main/screens/ui/AccountSetupPageViewModel.jsx";

const GenericPrompt = () => {
  const { modalDetails, setModalDetails } = useContext(ModalState);
  return (
    <React.Fragment>
      <div id="GenericPrompt">
        <div className="GenericPrompt-Body">
          {modalDetails.data.icon === "Success" ? (
            <img src={SuccessIcon} />
          ) : (
            <img src={AlertIcon} />
          )}
          <div className="GenericPrompt-Title">{modalDetails.data.title}</div>
          <div className="GenericPrompt-Description">
            {modalDetails.data.message}
          </div>
        </div>
        <div className="GenericPrompt-Footer">
          {modalDetails.data.isSuccessCommunity ? (
            <div
              className="GenericPrompt-SubmitButton-Proceed"
              onClick={() => {
                setModalDetails({ ...modalDetails, state: false });
                return saveAndSubmit(modalDetails.data.userInfo);
              }}
            >
              Proceed to community requests
            </div>
          ) : modalDetails.data.isSuccessSubmitCity ? (
            <div
              className="GenericPrompt-GotItButton"
              onClick={() => setModalDetails({ ...modalDetails, state: false })}
            >
              Got it
            </div>
          ) : modalDetails.data.isSuccessRegistration ? (
            <div
              className="GenericPrompt-SubmitButton-Proceed"
              onClick={() => {
                setModalDetails({ ...modalDetails, state: false });
                return (window.location.href = "/account-setup");
              }}
            >
              Ok
            </div>
          ) : modalDetails.data.isFailLogin ||
            modalDetails.data.isSuccessReset ? (
            <div
              className="GenericPrompt-SubmitButton-Proceed"
              onClick={() => {
                setModalDetails({ ...modalDetails, state: false });
              }}
            >
              Ok
            </div>
          ) : modalDetails.data.isSuccessResetPassword ? (
            <div
              className="GenericPrompt-SubmitButton-Proceed"
              onClick={() => {
                setModalDetails({ ...modalDetails, state: false });
                return (window.location.href = "/login/email");
              }}
            >
              Ok
            </div>
          ) : (
            <div
              className="GenericPrompt-SubmitButton"
              onClick={() => setModalDetails({ ...modalDetails, state: false })}
            >
              Close
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default GenericPrompt;
