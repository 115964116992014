import React from 'react'
import '../styles/VendorBanner.css'
const VendorBanner =()=> {

    return(
        <React.Fragment>
            <div id="VendorBanner">
                <div id="VendorBannerLeft">
                    <div id="VendorBannerTitle">We're making it easier for you to source your contributions!</div>
                    <div id="VendorBannerDescription">You can find vendors for your pledges below, connect with them and help support our fight against Covid-19!</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default VendorBanner