import React, { useState, useContext, useEffect } from "react";
import { AccountSetupDetails } from "../../../../global/services/Context.jsx";
import { ModalState } from "../../../../global/services/Context.jsx";
import "../styles/UserInformationForm.css";
import Modal from "../../../../global/components/Modal";
import {
  isValidFirstNameInput,
  isValidLastNameInput,
  isValidMobilNumberInput,
  isValidEmailAddressInput,
  getUserFacebookProfile,
  saveDonateCash,
} from "../ui/AccountSetupPageViewModel.jsx";
import { timeStamp } from "../../../../global/services/moment";
import { db } from "../../../../global/services/firebase";

const DonateCashUserInformation = () => {
  const { accountSetupDetails, setAccountSetupDetails } = useContext(
    AccountSetupDetails
  );
  const [modalDetails, setModalDetails] = useState(useContext(ModalState));

  const [user, setUser] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidMobileNumber, setIsValidMobileNumber] = useState(true);
  const [isValidEmailAddress, setIsValidEmailAddress] = useState(true);

  function handleChangeFirstName(e) {
    setFirstName(e.target.value);
  }

  function handleChangeLastName(e) {
    setLastName(e.target.value);
  }

  function handleChangeMobileNumber(e) {
    setMobileNumber(e.target.value);
  }

  function handleChangeEmailAddress(e) {
    setEmailAddress(e.target.value);
  }

  function handleBackEvent() {
    if (accountSetupDetails.userType === "NeedsHelp") {
      setAccountSetupDetails({ ...accountSetupDetails, step: "2" });
    } else {
      setAccountSetupDetails({
        ...accountSetupDetails,
        step: "4",
      });
    }
  }

  async function getUserProfile() {
    const userProfile = await getUserFacebookProfile();
    setUser(userProfile);

    var name = userProfile.displayName.split(" ");
    setFirstName(name[0]);
    setLastName(name[name.length - 1]);
    setMobileNumber(userProfile.phoneNumber || mobileNumber);
    setEmailAddress(userProfile.email);
  }

  async function submitUserInfo() {
    const isValidFirstName = await isValidFirstNameInput(firstName);
    const isValidLastName = await isValidLastNameInput(lastName);
    const isValidMobileNumber = await isValidMobilNumberInput(mobileNumber);
    const isValidEmailAddress = await isValidEmailAddressInput(emailAddress);

    let { userType, partner } = accountSetupDetails;
    const userInfo = {
      userType,
      firstName: firstName,
      lastName: lastName,
      mobileNumber: mobileNumber,
      emailAddress: emailAddress,
      locationName: "",
      locationAddress: "",
      city: "",
      profileAccount: "",
      companyId: "",
      emailVerified: false,
      uid: user.uid,
      createdAt: timeStamp,
      updatedAt: timeStamp,
      partner,
    };

    if (
      isValidFirstName &&
      isValidLastName &&
      isValidMobileNumber &&
      isValidEmailAddress
    ) {
      setLoading(true);
      const isComplete = await saveDonateCash(userInfo);
      if (isComplete) {
        await sendEmailToCashDonor();
        const data = {
          title: "Account setup Complete",
          message:
            "The volunteers of WeCare will get in touch with you within 24 hours. Thank you for your kind heart.",
          icon: "Success",
        };

        setModalDetails({
          type: "CashDonationComplete",
          state: true,
          data: data,
        });
      } else {
        const data = {
          title: "Unable to setup an account",
          message: "Please try to sign in again",
          icon: "Error",
        };
        setModalDetails({
          type: "CashDonationComplete",
          state: true,
          data: data,
        });
      }
    } else {
      setIsValidFirstName(isValidFirstName);
      setIsValidLastName(isValidLastName);
      setIsValidMobileNumber(isValidMobileNumber);
      setIsValidEmailAddress(isValidEmailAddress);
    }
  }

  useEffect(() => {
    //populateCity();
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function closeModal(e) {
    if (e.target.className === "modalOpen") {
      setModalDetails({ ...modalDetails, state: false });
    }
  }

  async function sendEmailToCashDonor() {
    await db.collection("mail").add({
      from: "support@wecare.ph",
      to: emailAddress,
      message: {
        subject: "WeCare Cash Donation Instructions",
        html: `${firstName} ${lastName}, WeCARE partnered with ABS-CBN
        Foundations to allow your cash donations to be fulfilled. You may now donate thru the
        following:
        <br/>
        <br/>
        <span>
          <b>Bank Name:</b> BPI
        </span>
        <br/>
        <span>
          <b>Bank Branch:</b> Sct Limbaga
        </span>
        <br/>
        <span>
          <b>Bank Complete Address:</b> GF Scout Center, Scout Limbaga cor Sct
          Tobias St Brgy Laging Handa Quezon City
        </span>
        <br/>
        <span>
          <b>Account Name:</b> ABS-CBN Lingkod Kapamilya Foundation, Inc.
        </span>
        <br/>
        <span>
          <b>Account Number:</b> 4221-0000-27
        </span>
        <br/>
        <span>
          <b>Swift Code:</b> BOPIPHMM
        </span>
        <br/>
        <br/>
        <span>
          <b>Bank Name:</b> BDO
        </span>
        <br/>
        <span>
          <b>Bank Branch:</b> Sct Albano
        </span>
        <br/>
        <span>
          <b>Bank Complete Address:</b> Georgetown Cyber Mall, Rodolfo N. Pelaez Blvd. Kaswagan, Cagayan de Oro, Misamis Oriental
        </span>
        <br/>
        <span>
          <b>Account Name:</b> ABS-CBN Lingkod Kapamilya Foundation, Inc.
        </span>
        <br/>
        <span>
          <b>Account Number:</b> 0066000-71981
        </span>
        <br/>
        <span>
          <b>Swift Code:</b> BNORPHMM
        </span>
        <br/>
        <br/>
        <span>
          Also, Cash donations received by the ABS-CBN Foundation will be duly receipted.
        </span>
        <br/>
        <span>
          To receive a copy of your Official Receipt please send a scanned copy of your deposit
          slip or for online transfers, a confirmation notice to lalara@abs-cbnfoundation.com with the following information:
        </span>
        <br/>
        <br/>
        <span>
          Donor’s registered name
        </span>
        <br/>
        <span>
          Donor’s registered address
        </span>
        <br/>
        <span>
          Donor’s TIN
        </span>
        <br/>
        <br/>
        <br/>
        <span>
          Maraming Salamat!
        </span>
        <br/>
        <br/>
        <span>
          - WeCare Support -
        </span>
        `,
      },
    });
  }

  return (
    <React.Fragment>
      <div
        className={modalDetails.state ? "modalOpen" : "modalClose"}
        onClick={(e) => closeModal(e)}
      >
        <ModalState.Provider value={{ modalDetails, setModalDetails }}>
          <Modal />
        </ModalState.Provider>
      </div>
      <div id="UserInformationFormContainer">
        <div id="UserInformationForm">
          <div className="DonationCash-Header">
            <img src=""></img>
          </div>
          <div className="UserInformationForm-Contents">
            <label id="UserInformationForm-InputLabelLarge">
              User Information
            </label>
            <label id="UserInformationForm-Description">
              This information are from Facebook. Please provide the missing
              ones.
            </label>

            <div className="UserInformationForm-InputRow">
              <div className="UserInformationForm-LeftColumn">
                <div className="UserInformation-InputContainerLeft">
                  <label id="UserInformationForm-InputLabelSmall">
                    First Name:
                  </label>
                  <input
                    type="text"
                    value={firstName}
                    id={`${
                      isValidFirstName
                        ? "UserInformationForm-TextInput"
                        : "UserInformationForm-TextInputError"
                    }`}
                    onChange={handleChangeFirstName}
                  />
                  <div
                    id={`${
                      isValidFirstName
                        ? "Hidden"
                        : "UserInformationForm-TextError"
                    }`}
                  >
                    First name is required
                  </div>
                </div>
              </div>
              <div className="UserInformationForm-RightColumn">
                <div className="UserInformation-InputContainerRight">
                  <label id="UserInformationForm-InputLabelSmall">
                    Last Name:
                  </label>
                  <input
                    type="text"
                    value={lastName}
                    id={`${
                      isValidLastName
                        ? "UserInformationForm-TextInput"
                        : "UserInformationForm-TextInputError"
                    }`}
                    onChange={handleChangeLastName}
                  />
                  <div
                    id={`${
                      isValidLastName
                        ? "Hidden"
                        : "UserInformationForm-TextError"
                    }`}
                  >
                    Last name is required
                  </div>
                </div>
              </div>
            </div>

            <div className="UserInformationForm-InputRow">
              <div className="UserInformationForm-LeftColumn">
                <div className="UserInformation-InputContainerLeft">
                  <label id="UserInformationForm-InputLabelSmall">
                    Mobile:
                  </label>
                  <input
                    type="number"
                    value={mobileNumber}
                    id={`${
                      isValidMobileNumber
                        ? "UserInformationForm-NumberInput"
                        : "UserInformationForm-NumberInputError"
                    }`}
                    onKeyDown={(e) =>
                      (e.key === "e" ||
                        e.key === "." ||
                        e.key === "+" ||
                        e.key === "-") &&
                      e.preventDefault()
                    }
                    onChange={handleChangeMobileNumber}
                  />
                  <div
                    id={`${
                      isValidMobileNumber
                        ? "Hidden"
                        : "UserInformationForm-TextError"
                    }`}
                  >
                    Mobile number is required
                  </div>
                </div>
              </div>
              <div className="UserInformationForm-RightColumn">
                <div className="UserInformation-InputContainerRight">
                  <label id="UserInformationForm-InputLabelSmall">Email:</label>
                  <input
                    type="text"
                    value={emailAddress}
                    id={`${
                      isValidEmailAddress
                        ? "UserInformationForm-TextInput"
                        : "UserInformationForm-TextInputError"
                    }`}
                    onChange={handleChangeEmailAddress}
                  />
                  <div
                    id={`${
                      isValidEmailAddress
                        ? "Hidden"
                        : "UserInformationForm-TextError"
                    }`}
                  >
                    Email address is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="UserInformationForm-ButtonsLayout">
            <div
              id={
                loading
                  ? "UserInformationForm-BackButtonDisabled"
                  : "UserInformationForm-BackButton"
              }
              onClick={() => handleBackEvent()}
            >
              Back
            </div>
            <div
              id={
                loading
                  ? "UserInformationForm-SaveButtonDisabled"
                  : "UserInformationForm-SaveButton"
              }
              onClick={() => submitUserInfo()}
            >
              Submit
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default DonateCashUserInformation;
