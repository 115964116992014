import React from 'react'
import StatisticsRenderer from '../components/Statistics.jsx'
import '../styles/Statistics.css'
import Footer from '../../../../global/components/Footer.jsx'

const StatisticsPage =()=> {

    return(
        <React.Fragment>
                <StatisticsRenderer />
            <Footer />
        </React.Fragment>
    )
}
export default StatisticsPage