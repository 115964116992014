import React from 'react'
import DonorHomeDetails from '../components/DonorHomePageForm.jsx'
import Footer from '../../../../global/components/Footer.jsx'
import '../styles/DonorHomePage.css'

const DonorHomePage =()=> {

    return(
        <React.Fragment>
            <div id="DonorHomePage">
               <DonorHomeDetails />
            </div>
            <Footer />
        </React.Fragment>
    )
}
export default DonorHomePage