import React,{useState,useContext,useEffect} from 'react'
import firebase from "firebase/app";
import {db} from "../services/firebase";
import {ModalState} from '../services/Context.jsx'
import '../styles/EditPledgedItemQuantityForm.css'
import { timeStamp } from '../services/moment/index.js';

const EditPledgedItemQuantityForm =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)
    const [quantity,setQuantity] = useState('')
    const [loading,setLoading] = useState(false)
    const [errorVisible,setErrorVisible] = useState(false)
    const [errorQty,setErrorQty] = useState(false)
    const data = modalDetails.data
    const requestItem = modalDetails.requestItemDetails

    function handleChangeQuantity(e){
        setQuantity(e.target.value)
    }

    function closeModal(){
        clearInputs()
        setModalDetails({...modalDetails,'state':false})
    }

    async function clearInputs(){
        setQuantity('')
        setErrorVisible(false)
        setErrorQty(false)
    }

    async function updateQuantity(){
        setLoading(true)
        
        var isValidInputs = true
        const blankQuantity = quantity.replace(/^\s+/, '').replace(/\s+$/, '')

        if(blankQuantity === ""){
            isValidInputs = false
            setErrorVisible(true)
        } else {
            var requestItemProgress = (parseInt(requestItem.progressCount) - parseInt(data.pledgeQuantity)) + parseInt(quantity)
            
            if (requestItemProgress > data.quantity) {
                isValidInputs = false
                setErrorQty(true)
            }
        }

        if(isValidInputs){
            setErrorVisible(false)
            
            var docRef = db.collection("pledgelist").doc(data.itemPledgedId);
            db.runTransaction(function(transaction) {
                return transaction.get(docRef).then(function(sfDoc) {
                    if (!sfDoc.exists) {
                        throw "Document does not exist!";
                    }
                    transaction.update(docRef, { pledgeQuantity:quantity,updatedAt:timeStamp });
                });
            }).then(function() {
                var itemDocId = db.collection("requestlist").doc(requestItem.itemId);
                db.runTransaction(function(itemTransactions) {
                    return itemTransactions.get(itemDocId).then(function(itemDocs) {
                        if (!itemDocs.exists) {
                            throw "Document does not exist!";
                        }
                        itemTransactions.update(itemDocId, { progress:requestItemProgress,updatedAt:timeStamp });
                    }); 
                }).then(function() {
                    return window.location.href="/community-requests"
                }).catch(function(e) {
                    setModalDetails({...modalDetails,'data':'SubmitRequestFailed','state':false})
                });
            }).catch(function() {
                setModalDetails({...modalDetails,'data':'SubmitRequestFailed','state':false})
            });

            setLoading(false)
            clearInputs()
        } else {
            setLoading(false)
        }
    }

    return(
        <React.Fragment>
            <div id="EditPledgedQtyForm">
                <div className="EditPledgedQtyForm-Header">
                    <div className="EditPledgedQtyForm-PreHeaderTitle">We want to help</div>
                    <div className="EditPledgedQtyForm-HeaderTitle">Update quantity</div>
                </div>
                
                <div className="EditPledgedQtyForm-Body">
                    <div className="EditPledgedQtyForm-AutoComplete">
                        <label className="EditPledgedQtyForm-InputLabelSmall">Quantity</label>
                        <input type="number" className="EditPledgedQtyForm-NumberInput" value={quantity}
                                onKeyDown={(e)=>(e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' 
                                || quantity.length>5 && e.keyCode !== 8) && e.preventDefault()} 
                                onChange={handleChangeQuantity} />
                    </div>
                    {errorVisible?<span className="EditPledgedQtyForm-InputLabelSmall-Error">Quantity is required</span>:"" }
                    {errorQty?<span className="EditPledgedQtyForm-InputLabelSmall-Error">Quantity exceeds the quantity requested</span>:"" }
                </div>
                {
                    loading
                    ?
                    <div className="EditPledgedQtyForm-Footer">
                        <div className="EditPledgedQtyForm-SkipButtonDisabled">Cancel</div>
                        <div className="EditPledgedQtyForm-SubmitButtonDisabled">Submit</div>
                    </div>
                    :
                    <div className="EditPledgedQtyForm-Footer">
                        <div className="EditPledgedQtyForm-SkipButton" onClick={()=>closeModal()} >Cancel</div>
                        <div className="EditPledgedQtyForm-SubmitButton" onClick={()=>updateQuantity()}>Submit</div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}
export default EditPledgedItemQuantityForm