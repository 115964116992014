import React,{useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/RejectRemarksPrompt.css'

const RejectRemarksPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    return(
        <React.Fragment>
            <div id="RejectRemarksForm">
                <div className="RejectRemarks-Header">
                    <div className="RejectRemarks-PreHeaderTitle">Account Verification</div>
                    <div className="RejectRemarks-HeaderTitle">Remarks</div>
                </div>
                
                <div className="RejectRemarks-Body">
                    {modalDetails.data.remarks} 
                </div>

                <div className="RejectRemarks-Footer">
                    <div className="RejectRemarks-CloseButton" onClick={()=>setModalDetails({...modalDetails,'state':false})} >Close</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default RejectRemarksPrompt