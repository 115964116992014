import React,{useState,useEffect} from 'react'
import {Switch, BrowserRouter,Redirect,Route} from 'react-router-dom'
import '../styles/Main.css'
import MainMenu from '../screens/components/MainMenu.jsx'
import Footer from '../../../global/components/Footer'
import MyRequestsPage from '../screens/ui/MyRequestsPage.jsx'
import MyPledgesPage from '../screens/ui/MyPledgesPage.jsx'
import CommunityRequestsPage from '../screens/ui/CommunityRequestsPage.jsx'
import FAQPage from '../screens/ui/FAQPage.jsx'
import MyAccountPage from '../screens/ui/MyAccountPage.jsx'
import AccountSetupPage from '../screens//ui/AccountSetupPage.jsx'
import AdminVerificationListPage from '../screens/ui/AdminVerificationListPage.jsx'
import AdminUserListPage from '../screens/ui/AdminUserListPage.jsx'
import {useFetchMyAccount} from '../screens/ui/MyAccountPageViewModel.jsx'
import StatisticsPage from '../screens/ui/StatisticsPage.jsx'
import AdminDatabaseRequestPage from '../screens/ui/AdminDatabaseRequestPage.jsx'
import AdminDatabaseUserPage from '../screens/ui/AdminDatabaseUserPage.jsx'
import AdminDatabasePledgePage from '../screens/ui/AdminDatabasePledgePage.jsx'
import AdminDatabaseBeneficiariesPage from '../screens/ui/AdminDatabaseBeneficiariesPage.jsx'
import AdminDatabasePledgerPage from '../screens/ui/AdminDatabasePledgerPage.jsx'
import TermsAndConditionPage from '../screens/ui/TermsAndConditionPage.jsx'
import MyListingsPage from '../screens/ui/MyListingsPage.jsx'
import VendorDirectoryPage from '../screens/ui/VendorDirectoryPage.jsx'
import AccreditorVerificationListPage from '../screens/ui/AccreditorVerificationListPage.jsx'
import MessengerCustomerChat from 'react-messenger-customer-chat';
import EdmMailPage from '../screens/ui/EdmMailPage.jsx'
import DonorHomePage from '../screens/ui/DonorHomePage.jsx'
import MyAccountPageDonor from '../screens/ui/MyAccountPageDonor.jsx'



const MainPage =()=> {

    let auth = localStorage.getItem("session");

    const {data} = useFetchMyAccount()

    const [userType,setUserType] = useState("NeedsHelp")

    const ValidUserAction=()=>{
        return <Route render={()=>{
            if(auth){
                return <Redirect to={{pathname:'/'}} />
            }
        }}/>
    }

    useEffect(()=>{
        if(data){
            setUserType(data.userType)
            //setUserType("Admin")
        }
    },[data])

 /* div=fbChat
 added by: jabercasio
 added date: 04012020
 */
    
    return(
        <React.Fragment>
            <BrowserRouter>
                <div id="MainPage">
                    {
                        window.location.pathname === "/account-setup" || window.location.pathname === "/terms-and-conditions" || window.location.pathname === "/edm-mail"
                        ? null
                        : <MainMenu user={data} />
                    }
                    {
                        userType === "Admin"
                        ?
                        <Switch>
                            <Route exact path="/" render={(props)=>{return <AdminVerificationListPage />}} />
                            <Route path="/user-list" render={(props)=>{return <AdminUserListPage />}} />
                            <Route path="/my-account" render={(props)=>{return <MyAccountPage />}} />
                            <Route path="/databaserequests" render={(props)=>{return <AdminDatabaseRequestPage />}} />
                            <Route path="/databaseusers" render={(props)=>{return <AdminDatabaseUserPage />}} />
                            <Route path="/databasebeneficiaries" render={(props)=>{return <AdminDatabaseBeneficiariesPage />}} />
                            <Route path="/databasepledger" render={(props)=>{return <AdminDatabasePledgerPage />}} />
                            <Route path="/databasepledges" render={(props)=>{return <AdminDatabasePledgePage />}} />
                            <ValidUserAction />
                        </Switch>
                        :
                        userType === "Super Admin"
                        ?
                        <Switch>
                            <Route exact path="/" render={(props)=>{return <AdminVerificationListPage />}} />
                            <Route path="/user-list" render={(props)=>{return <AdminUserListPage />}} />
                            <Route path="/my-account" render={(props)=>{return <MyAccountPage />}} />
                            <Route path="/statistics" render={(props)=>{return <StatisticsPage />}} />
                            <Route path="/databaserequests" render={(props)=>{return <AdminDatabaseRequestPage />}} />
                            <Route path="/databaseusers" render={(props)=>{return <AdminDatabaseUserPage />}} />
                            <Route path="/databasebeneficiaries" render={(props)=>{return <AdminDatabaseBeneficiariesPage />}} />
                            <Route path="/databasepledger" render={(props)=>{return <AdminDatabasePledgerPage />}} />
                            <Route path="/databasepledges" render={(props)=>{return <AdminDatabasePledgePage />}} />
                            <Route path="/edm-mail" render={(props)=>{return <EdmMailPage />}} />
                            <ValidUserAction />
                        </Switch>
                        : 
                        userType === "Accreditor"
                        ?
                        <Switch>
                            <Route exact path="/" render={(props)=>{return <AccreditorVerificationListPage />}} />
                            <Route path="/my-account" render={(props)=>{return <MyAccountPage />}} />
                            <ValidUserAction />
                        </Switch>
                        :
                        userType === "Stats Viewer"
                        ?
                        <Switch>
                            <Route exact path="/" render={(props)=>{return <StatisticsPage />}} />
                            <Route path="/my-account" render={(props)=>{return <MyAccountPage />}} />
                            <ValidUserAction />
                        </Switch>
                        :
                        userType === "DonateCash"
                        ?
                        <Switch>
                            <Route exact path="/" render={(props)=>{return <DonorHomePage />}} />
                            <Route path="/faqs" render={(props)=>{return <FAQPage />}} />
                            <Route path="/my-account" render={(props)=>{return <MyAccountPageDonor />}} />
                            <Route path="/terms-and-conditions" render={(props)=>{return <TermsAndConditionPage user={data} />}} />
                            <ValidUserAction />
                        </Switch>
                        :
                        <Switch>
                            {
                                userType === "NeedsHelp"
                                ? <Route exact path="/" render={(props)=>{return <MyRequestsPage user={data} />}} />
                                : userType === "WantsToHelp"
                                    ? <Route exact path="/" render={(props)=>{return <MyPledgesPage />}} />
                                    : <Route exact path="/" render={(props)=>{return <MyListingsPage user={data} />}} />
                            }
                            {
                                userType === "WantsToHelp"
                                ? <Route exact path="/vendor-directory" render={(props)=>{return <VendorDirectoryPage user={data} />}} />
                                : null
                            }
                            <Route path="/community-requests" render={(props)=>{return <CommunityRequestsPage user={data} />}} />
                            <Route path="/statistics" render={(props)=>{return <StatisticsPage />}} />
                            <Route path="/faqs" render={(props)=>{return <FAQPage />}} />
                            <Route path="/my-account" render={(props)=>{return <MyAccountPage />}} />
                            <Route path="/account-setup" render={(props)=>{return <AccountSetupPage user={data} />}} />
                            <Route path="/terms-and-conditions" render={(props)=>{return <TermsAndConditionPage user={data} />}} />
                            <ValidUserAction />
                        </Switch>
                    }
                    
                    <div id="fbChat">
                        <MessengerCustomerChat
                            pageId="102460311404995"
                            appId="571667447040965"
                        />
                    </div>
                </div>
               
            </BrowserRouter>
        </React.Fragment>
    )
}
export default MainPage