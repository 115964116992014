import {useState,useEffect} from 'react'
import {db} from "../../../../global/services/firebase";

export const getCommunityRequests =async(uid)=> {

    let CommunityRequests = []

    await db.collection("requestlist").orderBy("location")
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                if(doc.data().uid !== uid){
                    const item = {...doc.data(), docId:doc.id}
                    CommunityRequests.push(item)
                }
            })
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });

    return CommunityRequests
}

export const useFetchCommunityRequests = (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchCommunityRequests(){
            const requestList = await getCommunityRequests()

            setState({data:requestList,loading:false})
        }
        
        fetchCommunityRequests()
    },[event])

    return state
};