import React, { useState, useContext, useEffect } from "react";
import "../styles/UserTypeForm.css";
import CortexLogo from "../../../../assets/Logo.png";
import { AccountSetupDetails } from "../../../../global/services/Context.jsx";
import ReactGa from "react-ga";
import InitializeReactGa from "../../../../global/services/googleanalytics/index.jsx";
import firebase from "firebase/app";

const UserTypeForm = () => {
  const { accountSetupDetails, setAccountSetupDetails } = useContext(
    AccountSetupDetails
  );
  const [showNotice, setShowNotice] = useState(true);

  InitializeReactGa();

  function proceedToNextStep(accountType) {
    if (accountType === "NeedsHelp") {
      ReactGa.event({
        category: "We need things",
        action: "Click need help button",
        label: "We need things",
      });

      return setAccountSetupDetails({
        ...accountSetupDetails,
        step: "2",
        userType: accountType,
      });
    } else if (accountType === "WantsToSupply") {
      ReactGa.event({
        category: "We want to supply things",
        action: "Click wants to supply button",
        label: "We want to supply things",
      });

      return setAccountSetupDetails({
        ...accountSetupDetails,
        step: "2",
        userType: accountType,
      });
    } else if (accountType === "WantsToHelp") {
      ReactGa.event({
        category: "We want to help",
        action: "Click wants help button",
        label: "We want to help",
      });

      return setAccountSetupDetails({
        ...accountSetupDetails,
        step: "3",
        userType: accountType,
      });
    } else {
      ReactGa.event({
        category: "Donate cash",
        action: "Click donate cash button",
        label: "I want to donate cash",
      });

      return setAccountSetupDetails({
        ...accountSetupDetails,
        step: "4",
        userType: accountType,
      });
    }
  }

  return (
    <React.Fragment>
      <div id="UserTypeContainer">
        <div id={showNotice ? "UserTypeNotice" : "UserTypeForm-Hidden"}>
          <div id="UserTypeNotice-Left">
            <div id="UserTypeNotice-Title">
              Important notice for Pledgees (We need things)
            </div>
            <div id="UserTypeNotice-Description">
              To avoid duplication of needed resources we are currently
              implementing a 1 person per hospital restriction for new
              registrations. We suggest that hospitals centralise their needs
              through one person and have that person be the one to register.
            </div>
          </div>
          <div id="UserTypeNotice-Right">
            <div
              id="UserTypeNotice-Button"
              onClick={() => setShowNotice(false)}
            >
              Got It
            </div>
          </div>
        </div>
        <div id="UserTypeForm">
          <div id="UserTypeForm-Header">
            <label id="UserTypeForm-InputLabel">Account Setup</label>
          </div>
          <div className="UserTypeForm-Contents">
            <label id="UserTypeForm-InputLabelSmall">User Type:</label>
            <label id="UserTypeForm-Description">
              Your account will be bound to the selection you make.
            </label>

            <div id="UserTypeForm-InputLayout">
              <div id="UserTypeForm-InputLayoutLeft">
                <div id="UserTypeForm-InputContainerLeft">
                  <label for="NeedsHelp" id="UserTypeForm-RadioInputLabelLeft">
                    <input
                      type="radio"
                      id="NeedsHelp"
                      name="purpose"
                      value="NeedsHelp"
                      onClick={() => proceedToNextStep("NeedsHelp")}
                    />
                    We need things
                  </label>
                </div>
              </div>
              <div id="UserTypeForm-InputLayoutRight">
                <div id="UserTypeForm-InputContainerRight">
                  <label
                    for="WantsToHelp"
                    id="UserTypeForm-RadioInputLabelRight"
                  >
                    <input
                      type="radio"
                      id="WantsToHelp"
                      name="purpose"
                      value="WantsToHelp"
                      onClick={() => proceedToNextStep("WantsToHelp")}
                    />
                    We want to help
                  </label>
                </div>
              </div>
            </div>
            <div id="UserTypeForm-InputLayout">
              <div id="UserTypeForm-InputLayoutLeft">
                <div id="UserTypeForm-InputContainerLeft">
                  <label
                    for="WantsToSupply"
                    id="UserTypeForm-RadioInputLabelLeft"
                  >
                    <input
                      type="radio"
                      id="WantsToSupply"
                      name="purpose"
                      value="WantsToSupply"
                      onClick={() => proceedToNextStep("WantsToSupply")}
                    />
                    We want to supply things
                  </label>
                </div>
              </div>
              <div id="UserTypeForm-InputLayoutRight">
                <div id="UserTypeForm-InputContainerRight">
                  <label
                    for="DonateCash"
                    id="UserTypeForm-RadioInputLabelRight"
                  >
                    <input
                      type="radio"
                      id="DonateCash"
                      name="purpose"
                      value="DonateCash"
                      onClick={() => proceedToNextStep("DonateCash")}
                    />
                    I want to donate cash
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default UserTypeForm;
