import React,{useState,useEffect,useContext} from 'react'
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import '../styles/UserProfile.css'
import {useFetchMyAccount} from '../ui/MyAccountPageViewModel.jsx'

const UserProfile =()=>{

    const {data, loading} = useFetchMyAccount()

    const [modalDetails,setModalDetails] = useState(useContext(ModalState))

    const [firstName,setFirstName] = useState('')
    const [lastName,setLastName] = useState('')
    const [mobileNumber,setMobileNumber] = useState('')
    const [emailAddress,setEmailAddress] = useState('')
    const [intention,setIntention] = useState('')

    const [locationName,setLocationName] = useState('')
    const [locationAddress,setLocationAddress] = useState('')
    const [city,setCity] = useState('')
    const [website,setWebsite] = useState('')
    const [paymentMethods,setPaymentMethods] = useState('')
    const [subscribe,setSubscribe] = useState(false)

    const [fetchTrigger,setFetchTrigger] = useState(0)
    const [userType,setUserType] = useState('NeedsHelp')

    useEffect(()=>{
        if(data){
            setFirstName(data.firstName)
            setLastName(data.lastName)
            setMobileNumber(data.mobileNumber)
            setEmailAddress(data.emailAddress)
            setLocationName(data.locationName)
            setIntention(data.intention);
            setLocationAddress(data.locationAddress)
            setCity(data.city)
            setWebsite(data.website)
            setPaymentMethods(data.paymentMethods)
            setUserType(data.userType)
            setSubscribe(data.subscribe)

            if (data.subscribe === undefined)
            {
                setSubscribe("1")
            }
        }
    },[data])

    useEffect(()=>{
        if(modalDetails.data === "SubmitRequestSuccess"){
            setFetchTrigger(fetchTrigger+1)
        }
        if(modalDetails.data === "CancelRequestSuccess"){
            setFetchTrigger(fetchTrigger+1)
        }
    },[modalDetails.state])

    function closeModal(e){
        if (e.target.className === "modalOpen") {
            setModalDetails({...modalDetails,'state':false}) 
        }
    }

    return(
        <React.Fragment>
            <div className={modalDetails.state ? "modalOpen":"modalClose"} onClick={(e)=>closeModal(e)}>
                <ModalState.Provider value={{modalDetails,setModalDetails}}>
                    <Modal />
                </ModalState.Provider>
            </div>
            <div id="UserProfile">
                <div id="UserProfileContainer">
                    <div className="UserProfile-Header">
                        <div className="UserProfile-InputLayout">
                            <div className="UserProfile-InputLayout-Left">
                                <div className="UserProfile-PreHeaderTitle">Account Details</div>
                                <div className="UserProfile-HeaderTitle">Personal Information</div>
                            </div>
                            <div className="UserProfile-InputLayout-Right">
                                <div className="UserProfile-ButtonsLayout">
                                    <div id="UserProfile-SaveButton" onClick={()=>setModalDetails({'type':'UpdateAccountDetails','state':true, 'data':data})}>Update</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        loading
                        ? <div className="UserProfile-Loading">Loading...</div>
                        :
                        <div className="UserProfile-Body">
                            <div className="UserProfile-InputLayout">
                                <div className="UserProfile-InputLayout-Left">
                                    <div className="UserProfile-InputLeft">
                                        <label className="UserProfile-InputLabelSmall">First name</label>
                                        <div className="UserProfile-InputValue">{firstName}</div>
                                    </div>
                                </div>
                                <div className="UserProfile-InputLayout-Right">
                                    <div className="UserProfile-InputRight">
                                        <label className="UserProfile-InputLabelSmall">Last name</label>
                                        <div className="UserProfile-InputValue">{lastName}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="UserProfile-InputLayout">
                                <div className="UserProfile-InputLayout-Left">
                                    <div className="UserProfile-InputLeft">
                                        <label className="UserProfile-InputLabelSmall">Mobile number</label>
                                        <div className="UserProfile-InputValue">{mobileNumber}</div>
                                    </div>
                                </div>
                                <div className="UserProfile-InputLayout-Right">
                                    <div className="UserProfile-InputRight">
                                        <label className="UserProfile-InputLabelSmall">Email address</label>
                                        <div className="UserProfile-InputValue">{emailAddress}</div>
                                    </div>
                                </div>
                            </div>
                            {
                                userType === "WantsToHelp"
                                ?
                                <div className="UserProfile-InputLayout">
                                    <div className="UserProfile-InputLayout-Left">
                                        <div className="UserProfile-InputLeft">
                                            <label className="UserProfile-InputLabelSmall">City</label>
                                            <div className="UserProfile-InputValue">{city}</div>
                                        </div>
                                    </div>
                                    <div className="UserProfile-InputLayout-Right">
                                        <div className="UserProfile-InputRight">
                                            <label className="UserProfile-InputLabelSmall">Subscribe to newsletter?</label>
                                            <div className="UserProfile-InputValue">{subscribe === "1" ? "Yes" : "No"}</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {
                                userType !== "WantsToHelp" && city !== "" && city !== undefined && city !== null
                                ?
                                <div className="UserProfile-InputLayout">
                                    <div className="UserProfile-InputLayout-Left">
                                        <div className="UserProfile-InputLeft">
                                            <label className="UserProfile-InputLabelSmall">City</label>
                                            <div className="UserProfile-InputValue">{city}</div>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            {
                                userType === "DonateCash"
                                ?
                                <div className="UserProfile-InputLayout-Bottom">
                                            <label className="UserProfile-InputLayout-Bottom-Label" >Please indicate your intentions in donating funds</label>
                                            <div className="UserProfile-InputLayout-BottomValue">{intention}</div>
                                </div>
                                : null
                            }

                            <div className={userType === "NeedsHelp" ? "UserProfile-Institution":"Gone"}>
                                    <label className="UserProfile-InputLabelSmall">Institution/Hospital</label>
                                    <div className="UserProfile-InputValue">{locationName}</div>

                                    <label className="UserProfile-InputLabelSmall">Institution/Hospital Address</label>
                                    <div className="UserProfile-InputValue">{locationAddress}</div>
                            </div>

                            <div className={userType === "WantsToSupply" || userType === "WantsToSupplyRejected" ? "UserProfile-Institution":"Gone"}>
                                    <label className="UserProfile-InputLabelSmall">Business name</label>
                                    <div className="UserProfile-InputValue">{locationName}</div>

                                    <label className="UserProfile-InputLabelSmall">Business address</label>
                                    <div className="UserProfile-InputValue">{locationAddress}</div>

                                    <label className="UserProfile-InputLabelSmall">City</label>
                                    <div className="UserProfile-InputValue">{city}</div>

                                    <div className="UserProfile-InputLayout">
                                        <div className="UserProfile-InputLayout-Left">
                                            <div className="UserProfile-InputLeft">
                                                <label className="UserProfile-InputLabelSmall">Website</label>
                                                {
                                                    website && website.includes("http")
                                                    ?<div className="UserProfile-InputValueBlue"><a href={`${website}`} target="_blank">{website}</a></div>
                                                    :<div className="UserProfile-InputValueBlue"><a href={`https://${website}`} target="_blank">{website}</a></div>
                                                }
                                                
                                            </div>
                                        </div>
                                        <div className="UserProfile-InputLayout-Right">
                                            <div className="UserProfile-InputRight">
                                                <label className="UserProfile-InputLabelSmall">Payment methods</label>
                                                <div className="UserProfile-InputValue">{paymentMethods}</div>
                                            </div>
                                        </div>
                                    </div>    
                            </div>
                        </div> 
                    }   
                </div>    
            </div>
        </React.Fragment>
    )
}
export default UserProfile