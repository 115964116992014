import React from 'react';
import PropTypes from 'prop-types'

const FilteredVerificationList = ({ data, value, onClickViewId, onClickContactInfo , onClickVerify, onClickReject}) => {
    if (!data) {
        return
    }
    let result = data.filter((data) => {
        if (value == null)
            return data
        else if (data.firstName.toLowerCase().includes(value.toLowerCase()) 
        || data.locationName.toLowerCase().includes(value.toLowerCase())) {
            return data
        }
    }).map((item, index) => {
        console.log(item);
        if(item.userType !== "NeedsHelp")
        {
            return null
        }
        else{
            return (
                <tr>
                    <td><div>{item.firstName}</div></td>
                    <td><div>{item.lastName}</div></td>
                    <td><div>{item.locationName}</div></td>
                    <td><div>{item.profileAccount}</div></td>
                    <td>
                        <div className="AdminVerificationList-ActionButtonsLayout">
                            <div className="AdminVerificationList-ActionButtonsLayout-Right">
                                <div className="AdminVerificationList-ActionButton-SecondaryButton" onClick={()=>onClickViewId(item)}>View ID</div>
                            </div>
                            <div className="AdminVerificationList-ActionButtonsLayout-Right">
                                <div className="AdminVerificationList-ActionButton-SecondaryButton" onClick={()=>onClickContactInfo(item)}>Contact Info</div>
                            </div>
                            <div className="AdminVerificationList-ActionButtonsLayout-Left">
                                <div className="AdminVerificationList-ActionButton-PrimaryButton" onClick={()=>onClickVerify(item)}>Verify</div>
                            </div>
                            <div className="AdminVerificationList-ActionButtonsLayout-Left">
                                <div className="AdminVerificationList-ActionButton-SecondaryButtonSmall" onClick={()=>onClickReject(item)}>X</div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        }
    });
    return (
        result
    )
}


FilteredVerificationList.propTypes = {
    data: PropTypes.array,
    value: PropTypes.string,
    children: PropTypes.any,
    onClickViewId: PropTypes.func,
    onClickContactInfo: PropTypes.func,
    onClickVerify: PropTypes.func,
    onClickReject: PropTypes.func
}
export default FilteredVerificationList;