import React,{useState,useEffect,useContext} from 'react'
import '../styles/ListingsTracker.css'
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import BlueBackButton from '../../../../assets/blue_back_button.png'
import PledgersListTable from '../components/PledgersListTable.jsx'
import {useFetchMyListings} from '../ui/MyListingsPageViewModel.jsx'
import LogisticsImage from '../../../../assets/ic_logistics.svg'
import Footer from '../../../../global/components/Footer'

const ListingsTracker =({user})=> {

    const [modalDetails,setModalDetails] = useState(useContext(ModalState))

    const [isPageVisible,setIsPageVisible] = useState(false)
    const [isPledgerListVisible,setPledgerListVisible] = useState(false)
    const [itemIndex, setItemIndex] = useState(0)
    const [fetchTrigger,setFetchTrigger] = useState(0)
    const [isAccountVerified,setIsAccountVerified] = useState(false)
    const [rejectionDetails,setRejectionDetails] = useState('')
    const [userType,setUserType] = useState("WantsToSupply")

    const {data, loading} = useFetchMyListings(fetchTrigger)

    function updateStocks(item){
        setModalDetails({...modalDetails,'type':'UpdateStocks','data':item,'state':true})  
    }

    function removeListing(item){
        setModalDetails({...modalDetails,'type':'CancelListing','data':item,'state':true})  
    }

    function viewItemImage(item){
        setModalDetails({...modalDetails,'type':'ViewItemImage','data':item,'state':true})
    }

    function showRemarks(rejectDetails){
        setModalDetails({...modalDetails,'type':'ShowRemarks','data':rejectDetails,'state':true})
    }

    function closeModal(e){
        if (e.target.className === "modalOpen") {
            setModalDetails({...modalDetails,'state':false}) 
        }
    }

    useEffect(()=>{
        if(user){
            setIsPageVisible(true)
            setIsAccountVerified(user.emailVerified)
            setUserType(user.userType)
            if(user.userType === "WantsToSupplyRejected"){
                setRejectionDetails(user.rejectionHistory[user.rejectionHistory.length - 1])
            }
        }
    },[user])

    useEffect(()=>{
        if(modalDetails.data === "SubmitListingSuccess"){
            const promptDetails = {title:'Item listing successful',
            message: 'Thank you for your listing. Your item can now be viewed by our pledgers.',
            messageImage: LogisticsImage,
            notes: 'Please note that logistics and other monetary concerns are between the vendor and pledger.',
            icon:'Success'}
            setModalDetails({'type':'GenericImagePrompt','data':promptDetails,'state':true})
            setFetchTrigger(fetchTrigger+1)
        }
        if(modalDetails.data === "CancelListingSuccess"){
            setFetchTrigger(fetchTrigger+1)
        }
        if(modalDetails.data === "UpdateStockSuccess"){
            setFetchTrigger(fetchTrigger+1)
        }
    },[modalDetails.state])

    return(
        <React.Fragment>
            <div className={modalDetails.state ? "modalOpen":"modalClose"} onClick={(e)=>closeModal(e)}>
                <ModalState.Provider value={{modalDetails,setModalDetails}}>
                    <Modal />
                </ModalState.Provider>
            </div>
            <div id={isPageVisible?"ListingsTrackerHolder":"Gone"}>
                <div id={`${isPledgerListVisible ? "PledgersPageContainer" : "None"}`}>
                    <div id="PledgersPage">
                        <div className="PledgersPageButtonLayout" onClick={()=>setPledgerListVisible(false)}>
                            <img src={BlueBackButton} />
                            <div id="PledgersPage-BackButton">Back to request list</div>
                        </div>
                        {isPledgerListVisible ? <PledgersListTable item={data[itemIndex]} /> : null}
                    </div>
                </div>
                <div id={`${isPledgerListVisible ? "None": "ListingsTrackerContainer"}`}>
                    <div className="ListingsTracker-Header">
                        <div className="ListingsTracker-HeaderLeft">
                            <div className="ListingsTracker-HeaderTitle">My item listing</div>
                            <div className="ListingsTracker-HeaderSubtitle">{user && user.locationName}</div>
                        </div>
                        <div className="ListingsTracker-HeaderRight">
                            {
                                isAccountVerified
                                ?<div className="ListingsTracker-AddRequestButton" onClick={()=>setModalDetails({'type':'AddListing','state':true})}>+ Add New Listing</div>
                                :<div className="ListingsTracker-AddRequestButtonDisabled">+ Add New Listing</div>
                            }
                            
                        </div>
        
                    </div>
                    {
                    isAccountVerified && userType !== "WantsToSupplyRejected"
                    ? 
                    <div id="ListingsTracker-MainContents">
                            {
                            loading
                            ? <div className="ListingsTracker-Loading">Loading...</div>
                            :
                            <div>
                                {
                                    data && data.length>0 
                                    ?
                                    <table id="ListingsTracker">
                                        <tbody>
                                            <tr>
                                                <th><div className="thRow">Item Listing</div></th>
                                                <th><div className="thRow">Price per unit</div></th>
                                                <th><div className="thRow">Stock</div></th>
                                                <th><div className="thRow">MOQ</div></th>
                                                <th><div className="thRow">Item Specs</div></th>
                                                <th style={{width:345,whiteSpace:"nowrap"}}><div className="thRowLast">Actions</div></th>
                                            </tr>
                                            {
                                                data.map((item,index) => {
                                                    return <tr key={index}>
                                                                <td>{item.itemName}</td>
                                                                <td>₱ {item.price}</td>
                                                                <td>{item.progress}/{item.stock} {item.unit}</td>
                                                                <td>{item.minQuantity} unit quantity</td>
                                                                <td>{item.itemSpecs}</td>
                                                                <td>
                                                                    <div className="ListingsTracker-ActionButtonsLayout">
                                                                        <div className="ListingsTracker-ActionButton-ViewItemImage" onClick={()=>viewItemImage(item)}>View item image</div>
                                                                        <div className="ListingsTracker-ActionButton-RemoveListing" onClick={()=>removeListing(item)}>Remove listing</div>
                                                                        <div className="ListingsTracker-ActionButton-UpdateStocks" onClick={()=>updateStocks(item)}>Update Stocks</div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    :
                                    <div id="ListingsTracker-RequestsPlaceholder">
                                        <div>Your item list will appear here. Click the “Add New listing button” to add an item now.</div>
                                    </div>
                                }
                            </div>
                            }
                        </div>
                    : 
                    userType === "WantsToSupplyRejected"
                        ?
                        <div id="ListingsTracker-MainContents">
                            {
                                rejectionDetails.rejectionType === 1 
                                ?
                                <div id="ListingsTracker-RequestsPlaceholder">
                                    Our accreditation team may have found some inconsistencies with your documents, please <label id="ListingsTracker-BlueLabel" onClick={()=>showRemarks(rejectionDetails)}>see remarks</label>.<br/>
                                    {/* To undergo accreditation again, You can <label id="ListingsTracker-BlueLabel" onClick={()=>window.location.href="/account-setup"}>click here</label> to edit and resubmit your registration details based on the accreditors remarks.<br/> */}
                                    For inquiries you can get in touch with our Vendor Management Support by sending an email to <label id="ListingsTracker-BlueLabelDisabled"><a href="mailto:supplieraccreditation@wecare.ph?Subject=Registration%20Inquiry" target="_top">supplieraccreditation@wecare.ph</a></label>.<br/>
                                </div>
                                :
                                <div id="ListingsTracker-RequestsPlaceholder">
                                <label id="ListingsTracker-RedLabel">Your account has been permanently rejected.</label><br/>
                                For inquiries you can get in touch with our Vendor Management Support by sending an email to <label id="ListingsTracker-BlueLabelDisabled"><a href="mailto:supplieraccreditation@wecare.ph?Subject=Registration%20Inquiry" target="_top">supplieraccreditation@wecare.ph</a></label>.<br/>
                            </div>
                            }
                           
                        </div>
                        :
                        <div id="ListingsTracker-MainContents">
                            <div id="ListingsTracker-RequestsPlaceholder">
                            Please wait while we process your accreditation.<br/>
                            Our system will send you an e-mail notification after this has been evaluated.<br/>
                            Meanwhile, you can browse all the current items which are currently needed by our Pledgers.
                            </div>
                        </div>
                        
                    }
                    
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}
export default ListingsTracker