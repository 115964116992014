import React from 'react'
import MainMenu from '../components/MainMenu.jsx'
import AdminDatabaseBeneficiaries from '../components/AdminDatabaseBeneficiaries.jsx'
import Footer from '../../../../global/components/Footer.jsx'
import '../styles/AdminDatabasePage.css'

const AdminDatabaseBeneficiariesPage =({})=> {
    return(
        <React.Fragment>
            <div id="AdminDatabaseBeneficiariesPage">
                <AdminDatabaseBeneficiaries/>
            </div>
            <Footer />
        </React.Fragment>
    )
}
export default AdminDatabaseBeneficiariesPage
