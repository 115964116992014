import firebase from "firebase/app";
import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import {db} from "../services/firebase";
import { timeStamp } from '../../global/services/moment'
import '../styles/AddRequestForm.css'

//added by mcaguinaldo 04032020: Adding of image when user didn't add image upon request.
const AddRequestImage = () => {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const [requestImageId, setRequestImageId] = useState('')
    const [requestImageUrl, setRequestImageUrl] = useState('')
    const [loading,setLoading] = useState(false)
    const [errorVisible,setErrorVisible] = useState(false)
    const [isValidRequestImageid, setIsValidRequestImageId] = useState(true);
    const [errorMessage, setImageErrorMessage] = useState('');
    const [inputFileText, setInputFileText] = useState('jpeg or png 5MB or less')

    function closeModal(){
        clearInputs()
        setModalDetails({...modalDetails,'state':false})
    }

    async function handleChangeRequestImageId(e){
        const image = e.target.files[0]
        var imageSize = 0;

        if (image != undefined)
        {
            imageSize = image.size;

            if (imageSize > 5742880)
            {
                e.target.value = null;
                setInputFileText('jpeg or png 5MB or less');
                setIsValidRequestImageId(false);
                setRequestImageId('');
                setImageErrorMessage('Image\'s size must not exceed 5MB.');
            }
            else {
                setInputFileText(image.name);
                setIsValidRequestImageId(true);
                setRequestImageId(imageFile => (image))
            }  
        }
        else {
            setRequestImageId('');
            setInputFileText('jpeg or png 5MB or less');
        }
    }

    async function clearInputs(){
        if (requestImageId != '')
        {
            document.getElementById('AddRequest-FileInput').value = null;
        }
        setRequestImageId('')
        setRequestImageUrl('')
        setInputFileText('jpeg or png 5MB or less');
        setIsValidRequestImageId('true');
    }

    async function addRequest(){
        setLoading(true)
        if (requestImageId == "")
        {
            setLoading(false)
            setIsValidRequestImageId(false);
            setImageErrorMessage('Choose file to add.');
        }
        else {
            const imageUrl = await uploadImage();
            var itemInfo = {'requestImageId': imageUrl, 'updatedAt': timeStamp}
            try {
                db.collection('requestlist')
                    .doc(modalDetails.data.itemId)
                    .set(itemInfo, { merge: true })
                    .then(function() {
                        setModalDetails({...modalDetails,'data':'SubmitRequestSuccess','state':false})
                })
            .catch(function(err) {
                console.log("Error",err)
                setModalDetails({...modalDetails,'data':'SubmitRequestFailed','state':false})
            });
            } catch (e) {
                console.log("Error",e)
                setModalDetails({...modalDetails,'data':'SubmitRequestFailed','state':false})
            }
            setLoading(false)
            clearInputs();
        }
        
    }

    // if upload image upon creation
    async function uploadImage() {
        if (requestImageId != '')
        {
            return new Promise(function(resolve, reject) {
                const storage = firebase.storage();
                const imageName = timeStamp + requestImageId.name;
                const storageRef = storage.ref(`/requestListImages/${imageName}`)
                const uploadTask = storageRef.put(requestImageId)
                uploadTask.on('state_changed', function(snapshot) {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    console.log('Upload is ' + progress + '% done')
                }, function error(err) {
                    console.log('error', err)
                    reject()
                }, function complete() {
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        resolve(downloadURL)
                    })
                })
                })
        }
        else {
            return ''
        }
    }

    return(
        <React.Fragment>
            <div id="AddRequestForm">
                <div className="AddRequest-Header">
                    <div className="AddRequest-PreHeaderTitle">We need things</div>
                    <div className="AddRequest-HeaderTitle">Add Request Image</div>
                </div>
                <div className="AddRequest-Body">
                    <div className="AddRequest-InputLayout">
                        <label className="AddRequest-InputLabelSmall">Request Image (Optional)</label>
                        <div id={`${isValidRequestImageid ? "file-upload-wrapper" : "file-upload-error"}`} data-text={inputFileText}>
                            <input id="AddRequest-FileInput" type="file" readOnly="readonly" class="file-upload-field" onChange={handleChangeRequestImageId} accept="image/png, image/jpeg"/>
                        </div>
                        <div id={`${isValidRequestImageid ? "Hidden" : "AddRequest-ErrorText"}`}>{errorMessage}</div>
                    </div>
                </div>
                    {
                        loading
                        ?
                        <div className="AddRequest-Footer">
                            <div className="AddRequest-CancelButtonDisabled">Cancel</div>
                            <div className="AddRequest-SubmitButtonDisabled">Add</div>
                        </div>
                        :
                        <div className="AddRequest-Footer">
                            <div className="AddRequest-CancelButton" onClick={()=>closeModal()}>Cancel</div>
                            <div className="AddRequest-SubmitButton" onClick={()=>addRequest()}>Add</div>
                        </div>
                    }
            </div>
        </React.Fragment>
    )
}

export default AddRequestImage

