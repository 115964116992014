import React,{useState,useContext, useEffect} from 'react'
import firebase from "firebase/app";
import CortexLogo from '../../../../assets/Logo.png'
import {AccountSetupDetails} from '../../../../global/services/Context.jsx'
import '../styles/UserInformationFormVendor.css'
import {isValidFirstNameInput,isValidLastNameInput,isValidMobilNumberInput,
    isValidEmailAddressInput,isValidVerificationInputVendor,getUserFacebookProfile,saveAndSubmit} from '../ui/AccountSetupPageViewModel.jsx'

const UserInformationFormVendor =()=>{

    const {accountSetupDetails,setAccountSetupDetails} = useContext(AccountSetupDetails)

    const [user,setUser] = useState('')
    const [firstName,setFirstName] = useState('')
    const [lastName,setLastName] = useState('')
    const [mobileNumber,setMobileNumber] = useState('')
    const [emailAddress,setEmailAddress] = useState('')
    const [profileAccount,setProfileAccount] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [loading,setLoading] = useState(false)

    const [isValidFirstName,setIsValidFirstName] = useState(true)
    const [isValidLastName,setIsValidLastName] = useState(true)
    const [isValidMobileNumber,setIsValidMobileNumber] = useState(true)
    const [isValidEmailAddress,setIsValidEmailAddress] = useState(true)
    const [isValidVerification,setIsValidVerification] = useState(true)
    const [verificationError,setVerificationError] = useState('')

    function handleChangeFirstName(e){
        setFirstName(e.target.value)
    }

    function handleChangeLastName(e){
        setLastName(e.target.value)
    }

    function handleChangeMobileNumber(e){
        setMobileNumber(e.target.value)
    }

    function handleChangeEmailAddress(e){
        setEmailAddress(e.target.value)
    }

    function handleChangeUserProfile(e){
        setProfileAccount(e.target.value)
    }

    function handleChangeCompanyId(e){
        const image = e.target.files[0]

        if(image !== undefined){
            if(image.size < 5742880){
                setCompanyId(imageFile => (image))
                setIsValidVerification(true)
                setVerificationError("")
            }
            else{
                setIsValidVerification(false)
                setVerificationError("File size must be less than 5mb or you can provide a profile link as a substitute.")
            }
        }
        else{
            setCompanyId('')
            setIsValidVerification(true)
            setVerificationError("")
        }
        
    }

    async function getUserProfile(){
        const userProfile = await getUserFacebookProfile()
        setUser(userProfile)

        var name = userProfile.displayName.split(" ");
        setFirstName(name[0])
        setLastName(name[name.length -1])
        setMobileNumber(userProfile.phoneNumber)
        setEmailAddress(userProfile.email)
    }

    async function proceedToNextStep(){
        const isValidFirstName = await isValidFirstNameInput(firstName)
        const isValidLastName = await isValidLastNameInput(lastName)
        const isValidMobileNumber = await isValidMobilNumberInput(mobileNumber)
        const isValidEmailAddress = await isValidEmailAddressInput(emailAddress)
        const isValidVerification = await isValidVerificationInputVendor(profileAccount,companyId)

        if (isValidFirstName && isValidLastName && isValidMobileNumber && isValidEmailAddress && isValidVerification) {
            return setAccountSetupDetails({...accountSetupDetails,
                'step':'4',
                'firstName':firstName,
                'lastName':lastName,
                'mobileNumber':mobileNumber,
                'emailAddress':emailAddress,
                'profileAccount':profileAccount,
                'companyId':companyId})
        }
        else {
            setIsValidFirstName(isValidFirstName)
            setIsValidLastName(isValidLastName)
            setIsValidMobileNumber(isValidMobileNumber)
            setIsValidEmailAddress(isValidEmailAddress)
            setIsValidVerification(isValidVerification)

            if(!isValidVerification){
                setVerificationError('You must provide a link to your professional profile and a picture of your company ID.')
            }
            else{
                setVerificationError('')
            }
        }
    }

    useEffect(()=>{
        getUserProfile()
    },[])

    return(
        <React.Fragment>
            <div id="UserInformationFormVendorContainer">
                <div id="UserInformationFormVendor">
                    <div className="UserInformationFormVendor-Header">
                        <div id="UserInformationFormVendor-Pretitle">We want to supply things</div>
                        <div id="UserInformationFormVendor-Title">Account Setup</div>
                        <div id="UserInformationFormVendor-Subtitle">Step 2 of 4</div>
                    </div>
                    <div className="UserInformationFormVendor-Contents">
                        <label id="UserInformationFormVendor-InputLabel">User Information</label>
                        <label id="UserInformationFormVendor-Description">These information are from Facebook. 
                        Please provide the missing details if there are any.</label>

                        <div className="UserInformationFormVendor-InputRow">
                            <div className="UserInformationFormVendor-LeftColumn">
                                <div className="UserInformation-InputContainerLeft">
                                    <label id="UserInformationFormVendor-InputLabelSmall">First name:</label>
                                    <input type="text"  value={firstName}
                                    id={`${isValidFirstName ? "UserInformationFormVendor-TextInput" : "UserInformationFormVendor-TextInputError"}`} 
                                    onChange={handleChangeFirstName} />
                                    <div id={`${isValidFirstName ? "Hidden" : "UserInformationFormVendor-TextError"}`}>First name is required</div>
                                </div>
                            </div>
                            <div className="UserInformationFormVendor-RightColumn">
                                <div className="UserInformation-InputContainerRight">
                                    <label id="UserInformationFormVendor-InputLabelSmall">Last name:</label>
                                    <input type="text" value={lastName}
                                    id={`${isValidLastName ? "UserInformationFormVendor-TextInput" : "UserInformationFormVendor-TextInputError"}`} 
                                    onChange={handleChangeLastName} />
                                    <div id={`${isValidLastName ? "Hidden" : "UserInformationFormVendor-TextError"}`}>Last name is required</div>
                                </div>
                            </div>
                        </div>

                        <div className="UserInformationFormVendor-InputRow">
                            <div className="UserInformationFormVendor-LeftColumn">
                                <div className="UserInformation-InputContainerLeft">
                                    <label id="UserInformationFormVendor-InputLabelSmall">Mobile:</label>
                                    <input type="number" value={mobileNumber}
                                    id={`${isValidMobileNumber ? "UserInformationFormVendor-NumberInput" : "UserInformationFormVendor-NumberInputError"}`} 
                                    onKeyDown={(e)=>(e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-') && e.preventDefault()}
                                    onChange={handleChangeMobileNumber} />
                                    <div id={`${isValidMobileNumber ? "Hidden" : "UserInformationFormVendor-TextError"}`}>Mobile number is required</div>
                                </div>
                            </div>
                            <div className="UserInformationFormVendor-RightColumn">
                                <div className="UserInformation-InputContainerRight">
                                    <label id="UserInformationFormVendor-InputLabelSmall">Email:</label>
                                    <input type="text" value={emailAddress}
                                    id={`${isValidEmailAddress ? "UserInformationFormVendor-TextInput" : "UserInformationFormVendor-TextInputError"}`} 
                                    onChange={handleChangeEmailAddress} />
                                    <div id={`${isValidEmailAddress ? "Hidden" : "UserInformationFormVendor-TextError"}`}>Email address is required</div>
                                </div>
                            </div>
                        </div>
                        
                        <div id="UserInformationFormVendor-VerificationSection">
                            <label id="UserInformationFormVendor-InputLabel">Verification Requirements</label>
                            <label id="UserInformationFormVendor-Description">We want to ensure the users of WeCare that we are listing verified vendor representatives. 
                            Please provide a professional profile and a company ID.</label>

                            <div className="UserInformationFormVendor-InputRow">
                                <div className="UserInformationFormVendor-LeftColumn">
                                    <div className="UserInformation-InputContainerLeft">
                                        <label id="UserInformationFormVendor-InputLabelSmall">Professional Profile: <div id="UserInformationFormVendor-Hint">(LinkedIn, Employee Profile, etc.)</div></label>
                                        <input type="text" 
                                        id={`${isValidVerification ? "UserInformationFormVendor-TextInput" : "UserInformationFormVendor-TextInputError"}`} 
                                        onChange={handleChangeUserProfile} />
                                    </div>
                                </div>
                                <div className="UserInformationFormVendor-RightColumn">
                                    <div className="UserInformation-InputContainerRight">
                                        <label id="UserInformationFormVendor-InputLabelSmall">Company ID: <div id="UserInformationFormVendor-Hint">(JPG or PNG less than 5mb)</div></label>
                                        <input type="file" accept='image/jpeg,image/png'
                                        id={`${isValidVerification ? "UserInformationFormVendor-FileInput" : "UserInformationFormVendor-FileInputError"}`} 
                                        onChange={handleChangeCompanyId} />
                                    </div>
                                </div>
                                <div id={`${isValidVerification ? "Hidden" : "UserInformationFormVendor-TextError"}`}>
                                {verificationError}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="UserInformationFormVendor-ButtonsLayout">
                        <div id={loading ? "UserInformationFormVendor-BackButtonDisabled":"UserInformationFormVendor-BackButton"} onClick={()=>setAccountSetupDetails({...accountSetupDetails,'step':'2'})}>Back</div>
                        <div id={loading ? "UserInformationFormVendor-SaveButtonDisabled":"UserInformationFormVendor-SaveButton"} onClick={()=>proceedToNextStep()}>Next</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default UserInformationFormVendor