import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase/app";
import "../styles/AccountSetupPage.css";
import UserTypeForm from "../components/UserTypeForm.jsx";
import LocationForm from "../components/LocationForm.jsx";
import LocationFormVendor from "../components/LocationFormVendor.jsx";
import UserInformationForm from "../components/UserInformationForm.jsx";
import UserInformationFormVendor from "../components/UserInformationFormVendor.jsx";
import BusinessInformationFormVendor from "../components/BusinessInformationFormVendor.jsx";
import BusinessDocumentsFormVendor from "../components/BusinessDocumentsFormVendor.jsx";
import DonateCashUserInformation from "../components/DonateCashUserInformation";
import PledgerDonateCashPrompt from "../../../../global/components/PledgerDonateCashPrompt.jsx";
import { AccountSetupDetails } from "../../../../global/services/Context.jsx";
import Footer from "../../../../global/components/Footer";

const AccountSetupPage = ({ user }) => {
  const [accountSetupDetails, setAccountSetupDetails] = useState(
    useContext(AccountSetupDetails)
  );

  const [currentUser, setCurrentUser] = useState(null);

  async function getCurrentUser() {
    return setCurrentUser(firebase.auth().currentUser);
  }

  useEffect(() => {
    getCurrentUser();
  }, [user]);

  useEffect(() => {
    if (currentUser && currentUser !== undefined) {
      if (currentUser.providerData[0].providerId === "password") {
        setAccountSetupDetails({
          ...accountSetupDetails,
          step: "2",
          userType: "NeedsHelp",
          partner: "ABS-CBN",
        });
      } else {
        if (user) {
          if (
            user.userType === "NeedsHelp" ||
            user.userType === "WantsToHelp" ||
            user.userType === "WantsToSupply"
          ) {
            return (window.location.href = "/");
          } else if (user.userType === "WantsToSupplyRejected") {
            setAccountSetupDetails({
              ...accountSetupDetails,
              step: "2",
              userType: "WantsToSupply",
            });
          } else {
            setAccountSetupDetails({
              ...accountSetupDetails,
              step: "2",
              userType: "NeedsHelp",
            });
          }
        } else {
          setAccountSetupDetails({ ...accountSetupDetails, step: "1" });
        }
      }
    }
  }, [currentUser]);

  switch (accountSetupDetails.step) {
    case "1":
      return (
        <React.Fragment>
          <div id="AccountSetupPage">
            <AccountSetupDetails.Provider
              value={{ accountSetupDetails, setAccountSetupDetails }}
            >
              <UserTypeForm />
            </AccountSetupDetails.Provider>
          </div>
          <Footer />
        </React.Fragment>
      );
    case "2":
      if (accountSetupDetails.userType === "WantsToSupply") {
        return (
          <React.Fragment>
            <div id="AccountSetupPage">
              <AccountSetupDetails.Provider
                value={{ accountSetupDetails, setAccountSetupDetails }}
              >
                <LocationFormVendor />
              </AccountSetupDetails.Provider>
            </div>
            <Footer />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <div id="AccountSetupPage">
              <AccountSetupDetails.Provider
                value={{ accountSetupDetails, setAccountSetupDetails }}
              >
                <LocationForm />
              </AccountSetupDetails.Provider>
            </div>
            <Footer />
          </React.Fragment>
        );
      }
    case "3":
      if (accountSetupDetails.userType === "WantsToSupply") {
        return (
          <React.Fragment>
            <div id="AccountSetupPage">
              <AccountSetupDetails.Provider
                value={{ accountSetupDetails, setAccountSetupDetails }}
              >
                <UserInformationFormVendor />
              </AccountSetupDetails.Provider>
            </div>
            <Footer />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <div id="AccountSetupPage">
              <AccountSetupDetails.Provider
                value={{ accountSetupDetails, setAccountSetupDetails }}
              >
                <UserInformationForm />
              </AccountSetupDetails.Provider>
            </div>
            <Footer />
          </React.Fragment>
        );
      }
    case "4":
      if (accountSetupDetails.userType === "WantsToSupply") {
        return (
          <React.Fragment>
            <div id="AccountSetupPage">
              <AccountSetupDetails.Provider
                value={{ accountSetupDetails, setAccountSetupDetails }}
              >
                <BusinessInformationFormVendor />
              </AccountSetupDetails.Provider>
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <div id="AccountSetupPage">
              <AccountSetupDetails.Provider
                value={{ accountSetupDetails, setAccountSetupDetails }}
              >
                <div id="ModalContainer">
                  <PledgerDonateCashPrompt />
                </div>
              </AccountSetupDetails.Provider>
            </div>
          </React.Fragment>
        );
      }
    case "5":
      return (
        <React.Fragment>
          <div id="AccountSetupPage">
            <AccountSetupDetails.Provider
              value={{ accountSetupDetails, setAccountSetupDetails }}
            >
              <BusinessDocumentsFormVendor />
            </AccountSetupDetails.Provider>
          </div>
          <Footer />
        </React.Fragment>
      );
    case "6":
      return (
        <React.Fragment>
          <div id="AccountSetupPage">
            <AccountSetupDetails.Provider
              value={{ accountSetupDetails, setAccountSetupDetails }}
            >
              <DonateCashUserInformation />
            </AccountSetupDetails.Provider>
          </div>
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <div id="AccountSetupPage"></div>
          <Footer />
        </React.Fragment>
      );
  }
};
export default AccountSetupPage;
