import React from 'react';
import PropTypes from 'prop-types'
import Moment from 'moment'

const FilteredVerificationList = ({ data, value, onClickViewId, onClickContactInfo , onClickVerify, onClickReject}) => {
    console.log("data",data)
    if (!data) {
        return
    }
    let result = data.map((item, index) => {
        if(item.userType !== "WantsToSupply" && item.userType !== "WantsToSupplyRejected")
        {
            return null
        }
        else{
            return (
                <tr>
                    <td><div>{item.firstName}</div></td>
                    <td><div>{item.locationName}</div></td>
                    <td><div>{item.createdAt !== undefined ? Moment(item.createdAt).format('MMM D, YYYY') : null}</div></td>
                    <td>
                        <div>{
                            item.emailVerified
                            ? "Verified"
                            : item.userType === "WantsToSupplyRejected" && item.rejectionHistory[item.rejectionHistory.length-1].rejectionType === 1
                                ? "Returned to sender"
                                : item.userType === "WantsToSupplyRejected" && item.rejectionHistory[item.rejectionHistory.length-1].rejectionType === 2
                                    ? "Permanently rejected"
                                    : "Pending verification"
                        }</div>
                    </td>
                    <td>
                        {
                            item.userType === "WantsToSupply" && !item.emailVerified
                            ?
                            <div className="AccreditorVerificationList-ActionButtonsLayout">
                                <div className="AccreditorVerificationList-ActionButton-VerificationInfoButton" onClick={()=>onClickContactInfo(item)}>Verification Info</div>
                                <div className="AccreditorVerificationList-ActionButton-RejectButton" onClick={()=>onClickReject(item)}>Reject</div>
                                <div className="AccreditorVerificationList-ActionButton-VerifyButton" onClick={()=>onClickVerify(item)}>Verify</div>
                            </div>
                            :
                            <div className="AccreditorVerificationList-ActionButtonsLayout">
                                <div className="AccreditorVerificationList-ActionButton-VerificationInfoButton" onClick={()=>onClickContactInfo(item)}>Verification Info</div>
                            </div>
                        }
                    </td>
                </tr>
            )
        }
    });
    return (
        result
    )
}


FilteredVerificationList.propTypes = {
    data: PropTypes.array,
    value: PropTypes.string,
    children: PropTypes.any,
    onClickViewId: PropTypes.func,
    onClickContactInfo: PropTypes.func,
    onClickVerify: PropTypes.func,
    onClickReject: PropTypes.func
}
export default FilteredVerificationList;