import React,{useState,useEffect,useContext} from 'react'
import { Select } from 'antd';
import '../styles/BusinessInformationFormVendor.css'
import {AccountSetupDetails} from '../../../../global/services/Context.jsx'
import 'antd/dist/antd.css';
import Modal from '../../../../global/components/Modal'
import {ModalState} from '../../../../global/services/Context.jsx'
import {isValidWebsiteInput,isValidPaymentMethodsInput} from '../ui/AccountSetupPageViewModel.jsx'
import Footer from '../../../../global/components/Footer';

const BusinessInformationFormVendor =()=> {
    const { Option } = Select;

    const [modalDetails,setModalDetails] = useState(useContext(ModalState))
    const {accountSetupDetails,setAccountSetupDetails} = useContext(AccountSetupDetails)

    const [website, setWebsite] = useState('')
    const [paymentMethods, setPaymentMethods] = useState('')
    const [logistics,setLogistics] = useState(true)
    const [loading,setLoading] = useState(false)

    const [isValidWebsite,setIsValidWebsite] = useState(true)
    const [isValidPaymentMethods,setIsValidPaymentMethods] = useState(true)


    function handleChangeWebsite(e) {
        setWebsite(e.target.value);
    }

    function handleChangePaymentMethods(e){
        setPaymentMethods(e.target.value)
    }

    async function proceedToNextStep(){
        setLoading(true)

        const validWebsite = await isValidWebsiteInput(website)
        const validPaymentMethods = await isValidPaymentMethodsInput(paymentMethods)

        if(validWebsite && validPaymentMethods){
            setLoading(false)
            return setAccountSetupDetails({...accountSetupDetails,
                'step':'5',
                'website':website,
                'paymentMethods':paymentMethods,
                'logistics':logistics})
        }
        else{
            setIsValidWebsite(validWebsite)
            setIsValidPaymentMethods(validPaymentMethods)
            setLoading(false)
        }
        
    }

    useEffect(()=>{
        if(accountSetupDetails.website !== "" && accountSetupDetails.website !== null && accountSetupDetails.website !== undefined){
            setWebsite(accountSetupDetails.website)
        }
        if(accountSetupDetails.paymentMethods !== "" && accountSetupDetails.paymentMethods !== null && accountSetupDetails.paymentMethods !== undefined){
            setPaymentMethods(accountSetupDetails.paymentMethods)
        }
        if(accountSetupDetails.logistics !== "" && accountSetupDetails.logistics !== null && accountSetupDetails.logistics !== undefined){
            setLogistics(accountSetupDetails.logistics)
        }
    },[])

    function closeModal(e){
        if (e.target.className === "modalOpen") {
            setModalDetails({...modalDetails,'state':false})
        }
    }

    return(
        <React.Fragment>
            <div className={modalDetails.state ? "modalOpen":"modalClose"} onClick={(e)=>closeModal(e)}>
                <ModalState.Provider value={{modalDetails,setModalDetails}}>
                    <Modal />
                </ModalState.Provider>
            </div>
           <div id="BusinessInformationFormVendorContainer">
            <div id="BusinessInformationFormVendor">
                    <div className="BusinessInformationFormVendor-Header">
                        <div id="BusinessInformationFormVendor-Pretitle">We want to supply things</div>
                        <div id="BusinessInformationFormVendor-Title">Account Setup</div>
                        <div id="BusinessInformationFormVendor-Subtitle">Step 3 of 4</div>
                    </div>
                    <div className="BusinessInformationFormVendor-Contents">
                        <label id="BusinessInformationFormVendor-InputLabel">Business Information</label>

                        <label id="BusinessInformationFormVendor-InputLabelSmall">Website:</label>
                        <div className="BusinessInformationFormVendor-AutoComplete">
                            <input type="text" id={isValidWebsite ? "BusinessInformationFormVendor-TextInput" : "BusinessInformationFormVendor-TextInputError"} value={website} onChange={handleChangeWebsite} />
                        </div>
                        <div id={isValidWebsite ? "Hidden" : "BusinessInformationFormVendor-TextError"}>This field is required</div>
                        
                        <label id="BusinessInformationFormVendor-InputLabelSmall">Payment methods:</label>
                        <label id="BusinessInformationFormVendor-InputSubLabel">Please note that WeCare does not handle payments. Transactions is between vendor and pledger.</label>
                        <input type="text" id={isValidPaymentMethods ? "BusinessInformationFormVendor-PaymentMethods" : "BusinessInformationFormVendor-PaymentMethodsError"} value={paymentMethods} onChange={handleChangePaymentMethods} />
                        <div id={isValidPaymentMethods ? "Hidden" : "BusinessInformationFormVendor-TextError"}>At least 1 payment method is required.</div>
                   
                        <label id="BusinessInformationFormVendor-InputLabelSmall">Logistics:</label>
                        <label for="BusinessInformationFormVendor-HasLogistics" id="BusinessInformationFormVendor-RadioInputLabelLeft">
                        <input type="radio" id="BusinessInformationFormVendor-HasLogistics" name="logistics" checked={logistics === true ? true : false}
                        value={true} onClick={()=>setLogistics(true)}/>
                        We handle logistics</label>

                        <label for="BusinessInformationFormVendor-HasNoLogistics" id="BusinessInformationFormVendor-RadioInputLabelLeft">
                        <input type="radio" id="BusinessInformationFormVendor-HasNoLogistics" name="logistics" checked={logistics === false ? true : false}
                        value={false} onClick={()=>setLogistics(false)}/>
                        We don't handle logistics</label>

                        <div id="BusinessInformationFormVendor-TierDescription">
                        Logistics will determine your tier upon verification. Tier 1 signifies that you are able to supply and deliver. Tier 2 will categorize you as a supply only vendor.
                        </div>
                    </div>

                    <div className="BusinessInformationFormVendor-ButtonsLayout">
                        <div id={loading ? "BusinessInformationFormVendor-BackButtonDisabled":"BusinessInformationFormVendor-BackButton"} onClick={()=>setAccountSetupDetails({...accountSetupDetails,'step':'3'})}>Back</div>
                        <div id={loading ? "BusinessInformationFormVendor-SaveButtonDisabled":"BusinessInformationFormVendor-SaveButton"} onClick={()=>proceedToNextStep()}>Next</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default BusinessInformationFormVendor