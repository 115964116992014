import firebase from "firebase/app";
import {db} from "../../../../global/services/firebase";
import {useState, useEffect} from 'react'

export const getDonorProfile =async(uid)=> {

    let donorProfile = {}

    await db.collection("cashDonor").doc(uid)
            .get()
            .then(function(doc) {
                const docData = doc.data()
                donorProfile = {...docData,uid:doc.id}
            })
            .catch(function(error) {
                return window.location.href="/account-setup"
            });

    return donorProfile
}

export const useFetchMyAccount = () => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchMyProfile(){
            firebase.auth().onAuthStateChanged(async function(user) {
                if(user){
                    const requestList = await getDonorProfile(user.uid)
                    setState({data:requestList,loading:false})
                }
                else{
                    localStorage.clear()
                    return window.location.href="/login"
                }
            })
        }
        
        fetchMyProfile()

    },[])

    return state
};