import React,{useState,useEffect,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/VendorContactInfoPrompt.css'
import Moment from 'moment'

const ContactInfoPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const [rejectionHistory,setRejectionHistory] = useState([])

    const [activeTab,setActiveTab] = useState(1)

    async function closePrompt(){
        setActiveTab(1)
        setModalDetails({...modalDetails,'state':false})
    }

    useEffect(()=>{
        
        if(modalDetails.data.rejectionHistory !== undefined){
            const sortedRejectionHistory = modalDetails.data.rejectionHistory.sort((a,b)=>new Date(b.dateRejected) - new Date(a.dateRejected))
            setRejectionHistory(sortedRejectionHistory)
        }
    
        
    },[modalDetails.state])

    return(
        <React.Fragment>
            <div id="VendorContactInformation">
                <div className="VendorContactInformation-Header">
                    <div className="VendorContactInformation-PreHeaderTitle">Vendor Information</div>
                    <div className="VendorContactInformation-HeaderTitle">{modalDetails.data.firstName} {modalDetails.data.lastName}</div>
                </div>

                {
                    modalDetails.data.rejectionHistory !== undefined && modalDetails.data.rejectionHistory.length > 0
                    ?
                    <div className="VendorContactInformation-TabLayout">
                        <div className="VendorContactInformation-TabLayoutClassic">
                            <div className={activeTab === 1 ? "VendorContactInformation-ActiveTab":"VendorContactInformation-InactiveTab"}
                            onClick={()=>setActiveTab(1)}>Vendor Details</div>
                        </div>
                        <div className="VendorContactInformation-TabLayoutClassic">
                            <div className={activeTab === 2 ? "VendorContactInformation-ActiveTab":"VendorContactInformation-InactiveTab"}
                            onClick={()=>setActiveTab(2)}>Verification Requirements</div>
                        </div>
                        <div className="VendorContactInformation-TabLayoutClassic">
                            <div className={activeTab === 3 ? "VendorContactInformation-ActiveTab":"VendorContactInformation-InactiveTab"}
                            onClick={()=>setActiveTab(3)}>Accreditor Remarks</div>
                        </div>
                    </div>
                    :
                    <div className="VendorContactInformation-TabLayout">
                        <div className="VendorContactInformation-TabLayoutLeft">
                            <div className={activeTab === 1 ? "VendorContactInformation-LeftActiveTab":"VendorContactInformation-LeftInactiveTab"}
                            onClick={()=>setActiveTab(1)}>Vendor Details</div>
                        </div>
                        <div className="VendorContactInformation-TabLayoutRight">
                            <div className={activeTab === 2 ? "VendorContactInformation-RightActiveTab":"VendorContactInformation-RightInactiveTab"}
                            onClick={()=>setActiveTab(2)}>Verification Requirements</div>
                        </div>
                    </div>
                }

                <div className={activeTab === 1 ? "VendorContactInformation-Body":"VendorContactInformation-Hidden"}>
                    <div className="VendorContactInformation-SectionTitle">User Information</div>
                    <div className="VendorContactInformation-InputLayout">
                        <div className="VendorContactInformation-InputLayout-Left">
                            <div className="VendorContactInformation-ValueLayout">
                                <label className="VendorContactInformation-ValueLabel">First name</label>
                                <div className="VendorContactInformation-Value">{modalDetails.data.firstName}</div>
                            </div>
                        </div>
                        <div className="VendorContactInformation-InputLayout-Right">
                            <div className="VendorContactInformation-ValueLayout">
                                <label className="VendorContactInformation-ValueLabel">Last name</label>
                                <div className="VendorContactInformation-Value">{modalDetails.data.lastName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="VendorContactInformation-InputLayout">
                        <div className="VendorContactInformation-InputLayout-Left">
                            <div className="VendorContactInformation-ValueLayout">
                                <label className="VendorContactInformation-ValueLabel">Mobile Number</label>
                                <div className="VendorContactInformation-ValueBlue">{modalDetails.data.mobileNumber}</div>
                            </div>
                        </div>
                        <div className="VendorContactInformation-InputLayout-Right">
                            <div className="VendorContactInformation-ValueLayout">
                                <label className="VendorContactInformation-ValueLabel">Email address</label>
                                <div className="VendorContactInformation-Value">{modalDetails.data.emailAddress}</div>
                            </div>
                        </div>
                    </div>

                    <div className="VendorContactInformation-BodySection">
                        <div className="VendorContactInformation-SectionTitle">Business Information</div>

                        <div className="VendorContactInformation-ValueLayout">
                            <label className="VendorContactInformation-ValueLabel">Business name</label>
                            <div className="VendorContactInformation-Value">{modalDetails.data.locationName}</div>
                        </div>
                        <div className="VendorContactInformation-ValueLayout">
                            <label className="VendorContactInformation-ValueLabel">Business address</label>
                            <div className="VendorContactInformation-Value">{modalDetails.data.locationAddress}</div>
                        </div>
                        <div className="VendorContactInformation-ValueLayout">
                            <label className="VendorContactInformation-ValueLabel">City</label>
                            <div className="VendorContactInformation-Value">{modalDetails.data.city}</div>
                        </div>
                        <div className="VendorContactInformation-ValueLayout">
                            <label className="VendorContactInformation-ValueLabel">Website</label>
                            {
                                modalDetails.data.website.includes("http")
                                ?<div className="VendorContactInformation-ValueBlue"><a href={`${modalDetails.data.website}`} target="_blank">{modalDetails.data.website}</a></div>
                                :<div className="VendorContactInformation-ValueBlue"><a href={`https://${modalDetails.data.website}`} target="_blank">{modalDetails.data.website}</a></div>
                            }
                        </div>
                        <div className="VendorContactInformation-ValueLayout">
                            <label className="VendorContactInformation-ValueLabel">Payment methods</label>
                            <div className="VendorContactInformation-Value">{modalDetails.data.paymentMethods}</div>
                        </div>
                        <div className="VendorContactInformation-ValueLayout">
                            <label className="VendorContactInformation-ValueLabel">Handles logistics?</label>
                            <div className="VendorContactInformation-Value">{modalDetails.data.logistics ? "Yes":"No"}</div>
                            <div id="VendorContactInformation-TierDescription">
                                Tier 1: Vendor is able to supply and deliver<br/>Tier 2: Vendor is able to supply only
                            </div>
                        </div>
                    </div>
                </div>

                <div className={activeTab === 2 ? "VendorContactInformation-Body":"VendorContactInformation-Hidden"}>
                    <div className="VendorContactInformation-SectionTitle">User verification documents</div>
                    <div className="VendorContactInformation-ValueLayout">
                        <div className="VendorContactInformation-ValueLabel">Professional Profile</div>
                        {
                            modalDetails.data.profileAccount.includes("http")
                            ?<div className="VendorContactInformation-ValueBlue"><a href={`${modalDetails.data.profileAccount}`} target="_blank">View Profile</a></div>
                            :<div className="VendorContactInformation-ValueBlue"><a href={`https://${modalDetails.data.profileAccount}`} target="_blank">{modalDetails.data.profileAccount}</a></div>
                        }
                    </div>
                    <div className="VendorContactInformation-ValueLayout">
                        <div className="VendorContactInformation-ValueLabel">CompanyId</div>
                        <div className="VendorContactInformation-ValueBlue"><a href={modalDetails.data.companyId} target="_blank">View ID</a></div>
                    </div>

                    <div className="VendorContactInformation-BodySection">
                        <div className="VendorContactInformation-SectionTitle">Business verification documents</div>

                        <div className="VendorContactInformation-ValueLayout">
                            <label className="VendorContactInformation-ValueLabel">Business documents</label>
                        </div>
                        <div className="VendorContactInformation-DocumentsLayout">
                                <div className="VendorContactInformation-DocumentRow">
                                    <div className="VendorContactInformation-DocumentItem">BIR Form 2303</div>
                                    {
                                        modalDetails.data.businessDocuments.form2303 !== "" && modalDetails.data.businessDocuments.form2303 !== undefined 
                                        ? <a href={modalDetails.data.businessDocuments.form2303} target="_blank"><div className="VendorContactInformation-DocumentAction">View</div></a>
                                        : null
                                    }
                                </div>
                                <div className="VendorContactInformation-DocumentRow">
                                    <div className="VendorContactInformation-DocumentItem">SEC Registration Permit</div>
                                    {
                                        modalDetails.data.businessDocuments.registrationPermit !== "" && modalDetails.data.businessDocuments.registrationPermit !== undefined 
                                        ? <a href={modalDetails.data.businessDocuments.registrationPermit} target="_blank"><div className="VendorContactInformation-DocumentAction">View</div></a>
                                        : null
                                    }
                                </div>
                                <div className="VendorContactInformation-DocumentRow">
                                    <div className="VendorContactInformation-DocumentItem">DTI</div>
                                    {
                                        modalDetails.data.businessDocuments.dti !== "" && modalDetails.data.businessDocuments.dti !== undefined 
                                        ? <a href={modalDetails.data.businessDocuments.dti} target="_blank"><div className="VendorContactInformation-DocumentAction">View</div></a>
                                        : null
                                    }
                                </div>
                                <div className="VendorContactInformation-DocumentRow">
                                    <div className="VendorContactInformation-DocumentItem">Business Permit</div>
                                    {
                                        modalDetails.data.businessDocuments.businessPermit !== "" && modalDetails.data.businessDocuments.businessPermit !== undefined 
                                        ? <a href={modalDetails.data.businessDocuments.businessPermit} target="_blank"><div className="VendorContactInformation-DocumentAction">View</div></a>
                                        : null
                                    }
                                </div>
                                <div className="VendorContactInformation-DocumentRow">
                                    <div className="VendorContactInformation-DocumentItem">General Information Sheet</div>
                                    {
                                        modalDetails.data.businessDocuments.generalInformationSheet !== "" && modalDetails.data.businessDocuments.generalInformationSheet !== undefined 
                                        ? <a href={modalDetails.data.businessDocuments.generalInformationSheet} target="_blank"><div className="VendorContactInformation-DocumentAction">View</div></a>
                                        : null
                                    }
                                </div>
                                <div className="VendorContactInformation-DocumentRow">
                                    <div className="VendorContactInformation-DocumentItem">Company Profile</div>
                                    {
                                        modalDetails.data.businessDocuments.companyProfile !== "" && modalDetails.data.businessDocuments.companyProfile !== undefined 
                                        ? <a href={modalDetails.data.businessDocuments.companyProfile} target="_blank"><div className="VendorContactInformation-DocumentAction">View</div></a>
                                        : null
                                    }
                                </div>
                                <div className="VendorContactInformation-DocumentRow">
                                    <div className="VendorContactInformation-DocumentItem">Formal Quotation</div>
                                    {
                                        modalDetails.data.businessDocuments.formalQuotation !== "" && modalDetails.data.businessDocuments.formalQuotation !== undefined 
                                        ? <a href={modalDetails.data.businessDocuments.formalQuotation} target="_blank"><div className="VendorContactInformation-DocumentAction">View</div></a>
                                        : null
                                    }
                                </div>
                        </div>
                       
                    </div>
                </div>

                <div className={activeTab === 3 ? "VendorContactInformation-Body":"VendorContactInformation-Hidden"}>
                    <div className="VendorContactInformation-SectionTitle">Remarks</div>
                    {
                        rejectionHistory && rejectionHistory.map((data,index) => {
                         return <div className={index + 1 === rejectionHistory.length ? "VendorContactInformation-RejectDataLast":"VendorContactInformation-RejectData"}>
                                    <div className="VendorContactInformation-ValueLayout">
                                    <label className="VendorContactInformation-ValueLabel">{Moment(data.dateRejected).format('MMM D, YYYY @ hh:mm A')}</label>
                                            <div className="VendorContactInformation-Value">
                                                <b>{data.rejectionType === 1 ? "Returned to sender - ":"Permanently Rejected"}</b>{data.remarks}
                                            </div>
                                    </div>
                                </div>
                        })
                    }
                    
                </div>

                <div className="VendorContactInformation-Footer">
                    <div className="VendorContactInformation-CloseButton" onClick={()=>closePrompt()}>Close</div>
                </div>
                
            </div>
        </React.Fragment>
    )
}
export default ContactInfoPrompt