import React, { useState, useContext, useEffect,useRef } from "react";
import { useHistory,useLocation } from 'react-router-dom';
import { confirmPasswordWithCode ,isNewUser,checkAuth} from "../ui/LandingPageViewModel.jsx";
import WeCareLogo from "../../../../assets/logo_wecare.png";
import "../styles/ResetLinkPage.css";
import Modal from "../../../../global/components/Modal.jsx";
import { ModalState } from "../../../../global/services/Context.jsx";
import ReactGa from 'react-ga';
import InitializeReactGa from '../../../../global/services/googleanalytics/index.jsx';
import {reset} from '../../../../global/services/Constants.js';
import BackIcon from '../../../../assets/blue_back_button.png';
import ShowPassword from './../../../../assets/open_password.svg';
import HidePassword from './../../../../assets/close_password.svg';
import queryString from 'query-string';

export  default () => {
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const [error, setError] = useState();
  const history = useHistory();
  const location = useLocation();
  const values = queryString.parse(location.search)
  const [modalDetails, setModalDetails] = useState(useContext(ModalState));
  const [passwordOption, setPasswordOption] = useState(false);
  const [confirmPasswordOption, setConfirmPasswordOption] = useState(false);
  // ref
  const passwordInput = useRef("");
  const confirmPasswordInput = useRef("");

  InitializeReactGa();  

  function handlePasswordOption(){
    setPasswordOption(!passwordOption);
  }
  function handleConfirmPasswordOption(){
   setConfirmPasswordOption(!confirmPasswordOption);
  }
  async function checkUser(){
    const user = await checkAuth()
    if(user){
      console.log("user",user)
      setIsLoggingIn(true);
      isNewUser(user.uid);
    }
    else{
      setIsLoggingIn(false)
    }
  }

  async function doEmailReset() {
    setIsLoggingIn(true);
    if (!passwordInput.current.value && !confirmPasswordInput.current.value ) {
      setIsLoggingIn(false);
      return setError({ message: {password: reset.passwordRequired} });
    } else if (!passwordInput.current.value ){
    setIsLoggingIn(false);
      return setError({ message: {password: reset.passwordRequired} });
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(passwordInput.current.value) && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(passwordInput.current.value)) {
      setIsLoggingIn(false);  
      return setError({ message: { password: reset.passwordRegexExpression} });
    } else if (!confirmPasswordInput.current.value ){
      setIsLoggingIn(false);
      return setError({ message: {confirmPassword: reset.confirmPasswordRequired} });
    } else if (passwordInput.current.value !== confirmPasswordInput.current.value) {
      setIsLoggingIn(false);
      return setError({ message: {confirmPassword: reset.passwordConfirmDoNotMatch }});
    }
    else 
    {
     setIsLoggingIn(true);
     if (passwordInput.current.value && confirmPasswordInput.current.value){
     let data = await confirmPasswordWithCode(values.oobCode, passwordInput.current.value)
     if (passwordInput.current.value === confirmPasswordInput.current.value) {
        setError("");
     }
     if (data===undefined && !data ){
      setIsLoggingIn(false);
      const promptDetails = {title:'Reset password successful!',icon:'Success',message:"Upon clicking Ok below you will be redirected to the email login page.",'isSuccessResetPassword':true}
      setModalDetails({ 'type': 'GenericPrompt', 'data': promptDetails,'state':true})
      } else if (data.code === "auth/invalid-action-code"){
        setIsLoggingIn(false);
        return setError({ message: { resetPassword: reset.resetPasswordAlreadyBeenUsed }});
      } else if (data.code === "auth/expired-action-code"){
          setIsLoggingIn(false);
          return setError({ message: { resetPassword: reset.resetPasswordAlreadyBeenUsed }});
      } 
    }
   }
  }
  
  useEffect(() => {
    checkUser()
  },[]);

  function closeModal(e) {
    if (e.target.className === "modalOpen") {
      setModalDetails({ ...modalDetails, state: false });
    }
  }

  return (
      <React.Fragment>
    <div id="ResetLinkPage-Body">
      <div
        className={modalDetails.state ? "modalOpen" : "modalClose"}
        onClick={e => closeModal(e)}
      >
        <ModalState.Provider value={{ modalDetails, setModalDetails }}>
          <Modal />
        </ModalState.Provider>
          </div>
     <header id="ResetLinkPage-Header"/>
     <div id="ResetLinkPage">
        <div id="ResetLinkPageContents1">
          <img src={WeCareLogo} id="ResetLinkPage-Logo" />
          <div id="ResetLinkPage-Title">
           Reset password
          </div>
          </div>
          <div id="ResetLinkPageContents-Description">
             <div id="ResetLinkPage-PasswordInformation-Description">Passwords must be at least 8-16 characters in length, must have one upper case letter, one lower case letter, one number, and an optional special character</div>
          </div>
          <div id="ResetLinkPageContents2">
            <div className="ResetLinkPage-InputContainer">
                 <label className="ResetLinkPage-InputLabel">New Password</label>
                 <img src={ passwordOption? HidePassword : ShowPassword } className="RegisterPage-PasswordOption" alt="showPassword" onClick={handlePasswordOption}/>
                 <input ref={passwordInput} type={passwordOption?"text":"password"} className={error && error.message.password?"ResetLinkPage-InputErrorPass":"ResetLinkPage-InputPass"} />   
                 <span className="ResetLinkPage-InputErrorMessage">{error && error.message.password}</span>
            </div>
            <div className="ResetLinkPage-InputContainer">
                 <label className="ResetLinkPage-InputLabel">Confirm Password</label>
                 <img src={ confirmPasswordOption ? HidePassword : ShowPassword } className="RegisterPage-PasswordOption" alt="showConfirmPassword" onClick={handleConfirmPasswordOption}/>
                 <input ref={confirmPasswordInput} type={confirmPasswordOption?"text":"password"} className={error && error.message.confirmPassword?"ResetLinkPage-InputErrorPass":"ResetLinkPage-InputPass"} />   
                 <span className="ResetLinkPage-InputErrorMessage">{error && error.message.confirmPassword || error && error.message.resetPassword}</span>
            </div>
          {isLoggingIn ? (
            <div id="ResetLinkPageContents-SaveButtonDisabled">
              <div id="ResetLinkPageContents-SaveButtonLoading" />
            </div>
          ) : (
            <div
              id="ResetLinkPage-SaveButton"
              onClick={() => doEmailReset()}
            >
              Save 
            </div>
              )}
        </div>
        </div>
    </div>
    </React.Fragment>
  );
};

