import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import AlertIcon from '../../assets/ic_alert.png'
import SuccessIcon from '../../assets/ic_success.png'
import '../styles/GenericPrompt.css'

const AccountUpdateComplete = () => {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    function handleClose() {
        setModalDetails({ ...modalDetails, 'state': false })
        if (modalDetails.data.isPledgerDonate) {
            return window.location.href="/my-account"
        }
        return window.location.href="/"
    }

    function handleCloseError() {
        setModalDetails({...modalDetails,'state':false})
        return window.location.href="/my-account"
    }

    return(
        <React.Fragment>
            <div id="GenericPrompt">
                <div className="AccountUpdate-Body">
                    <div className="AccountUpdate-Body">
                        {modalDetails.data.icon === "Success"?<img src={SuccessIcon} />:<img src={AlertIcon} />}
                        <div className="AccountUpdate-Title">{modalDetails.data.title}</div>
                    </div>
                </div>
                <div className="DonationCash-Footer">
                    {
                        modalDetails.data.icon === "Success"
                        ?
                        <div className="DonationCash-CloseButton" onClick={handleClose}>Got it</div>
                        :
                        <div className="DonationCash-CloseButton" onClick={handleCloseError}>Got it</div>
                    }
                    
                </div>
            </div>
        </React.Fragment>
    )
}
export default AccountUpdateComplete