import React,{useState,useEffect,useContext} from 'react'
import '../styles/LandingFAQRenderer.css'
import {useFetchAllFaq} from "../ui/LandingFAQPageViewModel.jsx";

const LandingFAQRenderer =()=>{
    const {data} = useFetchAllFaq()
    const [loading,setLoading] = useState(true)
    useEffect(()=>{
        if(data){
            setLoading(false)
        }
    },[data])
    return(
        <React.Fragment>
            <div id="LandingFAQsHolder">
                <div id="LandingFAQsContainer">
                    <div id="LandingFAQsContainer-HeaderRow">
                        <div className="LandingFAQsContainer-HeaderTitle">Frequenty Asked Questions</div>
                    </div>
                    <div id="LandingFAQsContainer-Body">
     
                        {  data && data.map((item, index) => {
                              
                              return (
                                 
                                  <div dangerouslySetInnerHTML={{ __html: item.content}} />
                                
                                  )
                              })
                          }

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default LandingFAQRenderer