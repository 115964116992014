import React,{useState,useEffect,useContext} from 'react'
import '../styles/AdminDatabase.css'
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import {useFetchAllRequest} from '../ui/AdminDatabasePageViewModel.jsx'
import { Select } from 'antd';
import 'antd/dist/antd.css';
import { CSVLink, CSVDownload } from "react-csv";

const AdminDatabase = ({}) => {

    const {data} = useFetchAllRequest()
    const [loading,setLoading] = useState(true)
   
    useEffect(()=>{
        if(data){
            setLoading(false)
        }
    },[data])

    const Requestist = []
    data && data.map((item)=> {
        const requestdata = {'Request Id': item.itemId,'Item Request' : item.itemName, 
        'Quantity' : item.quantity,'Unit': item.unit,'Item Specs':item.itemSpecs,'Institution/Hospital':item.location,
        'Progress':item.progress,'User Id': item.uid,
        'DateTime Created': item.createdAt,
        'DateTime Updated': item.updatedAt}
        Requestist.push(requestdata)
    })

    return(
        <React.Fragment>
            <div id="AdminDatabaseContainer">
                <div id="AdminDatabaseContainer-HeaderRow">
                    <div className="AdminDatabaseContainer-HeaderTitle">Request List</div>
                    <CSVLink data ={Requestist} filename="Wecare_RequestList"> DOWNLOAD</CSVLink>
                </div>
                
                <div id="AdminDatabaseContainer-Body">
                {
                    loading
                    ?<div className="RequestsTable-Loading">Loading...</div>
                    :
                    <table id="RequestsTable">
                        <tbody>
                            <tr>
                            <th><div className="thRow" >Request Id</div></th>
                                <th><div className="thRow">Item Request</div></th>
                                <th><div className="thRow">Quantity</div></th>
                                <th><div className="thRow">Unit</div></th>
                                <th><div className="thRow">Item Specs</div></th>
                                <th><div className="thRow">Institution/Hospital</div></th>
                                <th><div className="thRow" >Progress</div></th>
                                <th><div className="thRow" >User Id</div></th>
                                <th><div className="thRow" >DateTime Created</div></th>
                                <th><div className="thRow" >DateTime Updated</div></th>
                            </tr>
                            {  data && data.map((item, index) => {
                            return (
                                <tr key={index}>
                                     <td>{item.itemId}</td>
                                        <td>{item.itemName}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.unit}</td>
                                        <td>{item.itemSpecs}</td>
                                        <td>{item.location}</td>
                                        <td>{item.progress}</td>
                                        <td>{item.uid}</td>
                                        <td>{item.createdAt}</td>
                                        <td>{item.updatedAt}</td>
                                    </tr>
                                    )
                                })
                                }
                        </tbody>
                    </table>
                }
                </div>
            </div>
        </React.Fragment>
    )
}
export default AdminDatabase