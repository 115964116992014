import React from 'react'
import EdmMailRenderer from '../components/EdmMailRenderer.jsx'
import '../styles/EdmMail.css'

const EdmMailPage =()=> {

    return(
        <React.Fragment>
            <div id="EdmMailPage">
                <EdmMailRenderer />
            </div>
        </React.Fragment>
    )
}

export default EdmMailPage