import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import AlertIcon from '../../assets/ic_alert.png'
import '../styles/WhatToExpect.css'

const WhatToExpect =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    return(
        <React.Fragment>
            <div id="WhatToExpectPrompt">
                <div className="WhatToExpectPrompt-Header">
                    <div className="WhatToExpectPrompt-HeaderTitle">What’s Next</div>
                </div>
                <div className="WhatToExpectPrompt-Body">
                    <div className="WhatToExpectPrompt-Description">
                        <ul>
                            <li>Other Login Options</li>
                            <li>Better Mobile responsiveness</li>
                            <li>Better handling of requests and pledges</li>
                            <li>Share requested items on social media</li>
                            <li>Allow multiple beneficiary in one hospital</li>
                            <li>Ability to modify user information</li>
                        </ul>
                        <div className="WhatToExpectPrompt-ManyMore">And many more!</div>
                    </div>
                </div>
                <div className="WhatToExpectPrompt-Footer">
                    <div className="WhatToExpectPrompt-SubmitButton" onClick={()=>setModalDetails({...modalDetails,'state':false})}>Close</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default WhatToExpect