import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import AlertIcon from '../../assets/ic_alert.png'
import '../styles/CompletePledgePrompt.css'
import {db} from "../services/firebase";
import { timeStamp } from '../services/moment/index.js';

const CompletePledgePrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const [loading,setLoading] = useState(false)

    function completePledge(){

        setLoading(true)

        const data = modalDetails.data

        var docRef = db.collection("pledgelist").doc(data.pledgeId);
        db.runTransaction(function(transaction) {
            return transaction.get(docRef).then(function(sfDoc) {
                if (!sfDoc.exists) {
                    throw "Document does not exist!";
                }
                var newStatus = "Received";
                transaction.update(docRef, { status: newStatus,updatedAt:timeStamp });
            });
        }).then(function() {
            setLoading(false)
            setModalDetails({...modalDetails,'data':'CompletePledgeSuccess','pledger':modalDetails.data,'state':false})
        }).catch(function() {
            setLoading(false)
            setModalDetails({...modalDetails,'data':'CompletePledgeFailed','state':false})
        });

    }

    return(
        <React.Fragment>
            <div id="CompletePledgePrompt">
                <div className="CompletePledge-Body">
                    <img src={AlertIcon} />
                    <div className="CompletePledge-Title">Mark pledge as complete?</div>
                    <div className="CompletePledge-Description">Make sure that you have Received the items before marking this as complete.</div>
                </div>

                {
                    loading
                    ?
                    <div className="CompletePledge-Footer">
                        <div className="CompletePledge-CancelButtonDisabled">No</div>
                        <div className="CompletePledge-SubmitButtonDisabled">Yes</div>
                    </div>
                    :
                    <div className="CompletePledge-Footer">
                        <div className="CompletePledge-CancelButton" onClick={()=>setModalDetails({...modalDetails,'state':false})}>No</div>
                        <div className="CompletePledge-SubmitButton" onClick={()=>completePledge()}>Yes</div>
                    </div>
                }
                
            </div>
        </React.Fragment>
    )
}
export default CompletePledgePrompt