import React, { useState, useContext, useEffect,useRef } from "react";
import { useHistory } from 'react-router-dom';
import { checkSignInMethod,resetEmailPassword, checkAuth ,isNewUser} from "../ui/LandingPageViewModel.jsx";
import WeCareLogo from "../../../../assets/logo_wecare.png";
import "../styles/ResetEmailPasswordPage.css";
import Modal from "../../../../global/components/Modal.jsx";
import { ModalState } from "../../../../global/services/Context.jsx";
import ReactGa from 'react-ga';
import InitializeReactGa from '../../../../global/services/googleanalytics/index.jsx';
import {reset} from '../../../../global/services/Constants.js';
import BackIcon from '../../../../assets/blue_back_button.png';

export  default () => {
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const [error, setError] = useState();
  const history = useHistory();
  const [modalDetails, setModalDetails] = useState(useContext(ModalState));
  const emailInput = useRef("");
  const [timeLeft,setTimeLeft] = useState();

  async function checkUser(){
    const user = await checkAuth()
    if(user){
      setIsLoggingIn(true);
      isNewUser(user.uid);
    }
    else{
      setIsLoggingIn(false)
    }
  }

  InitializeReactGa();  

  async function doEmailReset() {
    setIsLoggingIn(true);
    if (!emailInput.current.value) {
       setIsLoggingIn(false);
      setError({ message: { email: reset.emailRequired } })
    } else 
    {
     setIsLoggingIn(true);
     if (emailInput.current.value){
        let userSignInMethod = await checkSignInMethod(emailInput.current.value);

        if(userSignInMethod && userSignInMethod.length > 0 && userSignInMethod[0] === 'password' )
        {
          let data = await resetEmailPassword(emailInput.current.value);
        
          if(data && data !== undefined){
            if (data.code === "auth/invalid-email") {
              setIsLoggingIn(false);
              setError({ message: { emailFormat : reset.emailFormatRequired } })
              } else if (data.code === "auth/user-not-found") {
              setIsLoggingIn(false);
              setError({ message: { emailRegister : reset.emailNotRegistered } })
              } else{
                setIsLoggingIn(false);
                setError({ message: { email : reset.emailReachedLimit } })
              }
          }
          else{
            setIsLoggingIn(false);
            setTimeLeft(60);
            setError({ message: { emailSent:true } })
            const promptDetails = {title:'Reset password link sent!',icon:'Success',message:"We just sent you an email containing a link where you can reset your password.",'isSuccessReset':true}
            setModalDetails({ 'type': 'GenericPrompt', 'data': promptDetails,'state':true})
          }
        }
        else{
          setIsLoggingIn(false);
          setError({ message: { emailRegister : reset.emailNotRegistered } })
        }
        
      }
    }
  }
  
  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  },[timeLeft]);

  useEffect(() => {
    checkUser()
  },[]);

  function closeModal(e) {
    if (e.target.className === "modalOpen") {
      setModalDetails({ ...modalDetails, state: false });
    }
  }
  return (
      <React.Fragment>
    <div id="ResetEmailPasswordPage-Body">
      <div
        className={modalDetails.state ? "modalOpen" : "modalClose"}
        onClick={e => closeModal(e)}
      >
        <ModalState.Provider value={{ modalDetails, setModalDetails }}>
          <Modal />
        </ModalState.Provider>
          </div>
     <header id="ResetEmailPasswordPage-Header">
        <div onClick={()=>history.push("/login/email")}>
                <span id="ResetEmailPasswordPage-HeaderTitle"> <img src={BackIcon} id="ResetEmailPasswordPage-HeaderIcon" alt="backButton"/> Back to Email Login</span>     
        </div>
    </header>
     <div id="ResetEmailPasswordPage">
        <div id="ResetEmailPasswordPageContents1">
          <img src={WeCareLogo} id="ResetEmailPasswordPage-Logo" />
          <div id="ResetEmailPasswordPage-Title">
           Reset password
          </div>
          <label id="LoginPageContents-Description">
          Please enter the email address you provided us upon registration. If the email address you provided is valid we will send an email containing a link where you can reset your password.
          </label>
            <div className="ResetEmailPasswordPage-InputContainer">
                 <label className="ResetEmailPasswordPage-InputLabel">Email</label>
                 <input ref={emailInput} className={error && error.message.email ?"ResetEmailPasswordPage-InputError": error && error.message.emailFormat?"ResetEmailPasswordPage-InputError":error && error.message.emailRegister?"ResetEmailPasswordPage-InputError":"ResetEmailPasswordPage-Input"} />   
              <span className="ResetEmailPasswordPage-InputErrorMessage">{error && error.message.email}</span>
              <span className="ResetEmailPasswordPage-InputErrorMessage">{error && error.message.emailFormat}</span>
              <span className="ResetEmailPasswordPage-InputErrorMessage">{error && error.message.emailRegister}</span>
            </div>
            { error && error.message.emailSent?<div id="ResetEmailPasswordPage-Reset"> Didn't receive email? {timeLeft === 0 ?<span id="ResetEmailPasswordPage-ResetLabel" onClick={() => doEmailReset()} >Resend</span> : <span> You can resend code in {timeLeft}s</span>} </div> : "" }
          {isLoggingIn ? (
            <div id="ResetEmailPasswordPageContents-SendButtonDisabled">
              <div id="ResetEmailPasswordPageContents-SendButtonLoading" />
            </div>
          ) : (
            timeLeft === 0 || timeLeft==="" || timeLeft===undefined?
            <div
              id="ResetEmailPasswordPage-SendButton"
              onClick={() => doEmailReset()}
            >
              Send 
            </div>
            : 
            <div
            id="ResetEmailPasswordPage-SendButtonDisabled"
            >
            Send 
          </div>
              )}
            <div id="ResetEmailPasswordPage-DataPrivacy">
            Having problems? Email
            <a href="mailto:support@wecare.ph" target="_top">
             &nbsp;support@wecare.ph
            </a>
          </div>
        </div>
        </div>
    </div>
    </React.Fragment>
  );
};

