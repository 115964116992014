import {useState,useEffect}  from 'react'
import Footer from '../../../../global/components/Footer.jsx'
import {db} from "../../../../global/services/firebase";

const getAllUsers = async()=> {
    let UserList = []
    await db.collection("users").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const userdata = {...docData,itemId:doc.id}
            UserList.push(userdata)
        })
    })
    .catch(function(error) {
        console.log("Error getting users: ", error);
    });

    return UserList
}

export const useFetchAllUsers= (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchAllUsers(){
            const userList = await getAllUsers()
            console.log("fetchUser",userList)
            setState({data:userList,loading:false})
        }

        fetchAllUsers()

    },[event])

    return state
};


const getAllPledgee = async()=> {
    let UserList = []
    await db.collection("users").where("userType", "==", "NeedsHelp").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const userdata = {...docData,itemId:doc.id}
            UserList.push(userdata)
        })
    })
    .catch(function(error) {
        console.log("Error getting pledgee: ", error);
    });
    return UserList
}

export const useFetchAllPledgee= (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchAllPledgee(){
            const userList = await getAllPledgee()
            console.log("fetchUserPledgee",userList)
            setState({data:userList,loading:false})
        }
        
        fetchAllPledgee()

    },[event])

    return state
};

const getAllPledger = async()=> {
    let UserList = []
    await db.collection("users").where("userType", "==", "WantsToHelp").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const userdata = {...docData,itemId:doc.id}
            UserList.push(userdata)
        })
    })
    .catch(function(error) {
        console.log("Error getting pledger: ", error);
    });
    return UserList
}

export const useFetchAllPledger= (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchAllPledger(){
            const userList = await getAllPledger()
            console.log("fetchUserPledger",userList)
            setState({data:userList,loading:false})
        }
        
        fetchAllPledger()

    },[event])

    return state
};

const getAllRequest = async()=> {

    let RequestList = []
    
    await db.collection("requestlist").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const requestitem = {...docData,itemId:doc.id}
            RequestList.push(requestitem)
            console.log("RequestList",RequestList)
        })
    })
    .catch(function(error) {
        console.log("Error getting request: ", error);
    });

    return RequestList
}


export const useFetchAllRequest= (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchAllRequest(){
            const requestList = await getAllRequest()
            console.log("fetchrequest",requestList)
            setState({data:requestList,loading:false})
        }
        
        fetchAllRequest()

    },[event])

    return state
};


const getAllPledges = async()=> {

    let PledgeList = []
    
    await db.collection("pledgelist").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const pledgeitem = {...docData,itemId:doc.id}
            PledgeList.push(pledgeitem)
        })
    })
    .catch(function(error) {
        console.log("Error getting pledges: ", error);
    });

    return PledgeList
}


export const useFetchAllPledge= (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchAllPledge(){
            const pledgeList = await getAllPledges()
            console.log("fetchpledges",pledgeList)
            setState({data:pledgeList,loading:false})
        }
        
        fetchAllPledge()

    },[event])

    return state
};

const getAllBenificiaries= async()=> {

    let BeneficiariesList = []
    
    await db.collection("beneficiaries").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const requestitem = {...docData,itemId:doc.id}
            BeneficiariesList.push(requestitem)
            console.log("BeneficiariesList",BeneficiariesList)
        })
    })
    .catch(function(error) {
        console.log("Error getting beneficiaries: ", error);
    });

    return BeneficiariesList
}


export const useFetchAllBeneficiaries= (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchAllBene(){
            const beneList = await getAllBenificiaries()
            console.log("fetchbeneList",beneList)
            setState({data:beneList,loading:false})
        }
        
        fetchAllBene()

    },[event])

    return state
};