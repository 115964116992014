import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase/app";
import { db } from "../services/firebase";
import { ModalState } from "../services/Context.jsx";
import "../styles/AddCityForm.css";

const AddCityForm = () => {
  const { modalDetails, setModalDetails } = useContext(ModalState);

  const [city, setCity] = useState("");
  const [value, setValue] = useState([]);
  const [isCityValid, setIsCityValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(true);
  const [resultValue, setResultValue] = useState();
  const { hospitalCityMunicipality } = !resultValue
    ? ""
    : JSON.parse(resultValue);

  const [province, setProvince] = useState("");
  const [provinceValue, setProvinceValue] = useState([]);
  const [isProvinceValid, setIsProvinceValid] = useState(false);
  const [hideProvince, setHideProvince] = useState(true);
  const [provinceResultValue, setProvinceResultValue] = useState();
  const { hospitalProvince } = !provinceResultValue
    ? ""
    : JSON.parse(provinceResultValue);

  function handleChangeCity(e) {
    let hospital_list = [];
    setHide(false);
    setCity(e.target.value);
    if (e.target.value.length === 0) {
      setHide(true);
      return setValue(null);
    } else {
      db.collection("philippineshospitals")
        .where("city_municipality", ">=", e.target.value.toUpperCase())
        .where(
          "city_municipality",
          "<=",
          e.target.value.toUpperCase() + "\uf8ff"
        )
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            hospital_list.push({
              hospitalCityMunicipality: doc.data().city_municipality,
            });
          });
          setValue(hospital_list);
        });
    }
  }

  function handleChangeProvince(e) {
    let hospital_list = [];
    setHideProvince(false);
    setProvince(e.target.value);
    if (e.target.value.length === 0) {
      setHideProvince(true);
      return setProvinceValue(null);
    } else {
      db.collection("philippineshospitals")
        .where("province", ">=", e.target.value.toUpperCase())
        .where("province", "<=", e.target.value.toUpperCase() + "\uf8ff")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            hospital_list.push({
              hospitalProvince: doc.data().province,
            });
          });
          setProvinceValue(hospital_list);
        });
    }
  }
  function handleClicklAutoComplete(e) {
    setHide(true);
    return removeDuplicates.filter(function (item) {
      setResultValue(e.currentTarget.dataset.league);
    });
  }

  function handleClickProvinceAutoComplete(e) {
    setHideProvince(true);
    return removeDuplicates.filter(function (item) {
      setProvinceResultValue(e.currentTarget.dataset.league);
    });
  }
  function showAutoComplete() {
    return hide ? (
      ""
    ) : (
      <ul>
        {!removeDuplicates
          ? ""
          : removeDuplicates.map((item, i) => {
              return (
                <li
                  key={i}
                  data-league={JSON.stringify(item)}
                  onClick={(e) => handleClicklAutoComplete(e)}
                >
                  {item.hospitalCityMunicipality}
                </li>
              );
            })}
      </ul>
    );
  }

  function showProvinceAutoComplete() {
    return hideProvince ? (
      ""
    ) : (
      <ul>
        {!removeDuplicatesProvince
          ? ""
          : removeDuplicatesProvince.map((item, i) => {
              return (
                <li
                  key={i}
                  data-league={JSON.stringify(item)}
                  onClick={(e) => handleClickProvinceAutoComplete(e)}
                >
                  {item.hospitalProvince}
                </li>
              );
            })}
      </ul>
    );
  }
  async function submitCity() {
    setLoading(true);
    if (!city || !province) {
      setLoading(false);
      setIsCityValid(!city);
      setIsProvinceValid(!province);
    } else {
      setIsCityValid(false);
      setIsProvinceValid(false);
      firebase.auth().onAuthStateChanged(async function (user) {
        if (user) {
          var docRef = db.collection("users").doc(user.uid);
          await db
            .runTransaction(function (transaction) {
              return transaction.get(docRef).then(function (sfDoc) {
                if (!sfDoc.exists) {
                  throw "Document does not exist!";
                }
                transaction.update(docRef, { city: city, province: province });
              });
            })
            .then(function (res) {
              setLoading(false);
              setModalDetails({
                ...modalDetails,
                data: "SubmitCitySuccess",
                state: false,
              });
            })
            .catch(function (err) {
              setLoading(false);
              setModalDetails({
                ...modalDetails,
                data: "SubmitCityFailed",
                state: false,
              });
            });
        } else {
          setLoading(false);
          localStorage.clear();
          return (window.location.href = "/login");
        }
      });
    }
  }
  async function populateCity() {
    setCity(hospitalCityMunicipality);
    setProvince(hospitalProvince);
  }
  useEffect(() => {
    populateCity();
  }, [hospitalCityMunicipality, hospitalProvince]);

  const removeDuplicates = !value
    ? ""
    : value.filter(
        (ele, ind) =>
          ind ===
            value.findIndex(
              (elem) =>
                elem.hospitalCityMunicipality === ele.hospitalCityMunicipality
            ) &&
          ele.hospitalCityMunicipality !== null &&
          ele.hospitalCityMunicipality !== ""
      );

  const removeDuplicatesProvince = !value
    ? ""
    : provinceValue.filter(
        (ele, ind) =>
          ind ===
            provinceValue.findIndex(
              (elem) => elem.hospitalProvince === ele.hospitalProvince
            ) &&
          ele.hospitalProvince !== null &&
          ele.hospitalProvince !== ""
      );

  return (
    <React.Fragment>
      <div id="AddCityForm">
        <div className="AddCityForm-Header">
          <div className="AddCityForm-PreHeaderTitle">We want to help</div>
          <div className="AddCityForm-HeaderTitle">
            Update city/town & province
          </div>
        </div>

        <div className="AddCityForm-Body">
          <div className="AddCityForm-Notice">
            We want to improve your experience in the platform. By entering your
            CITY or TOWN, we can recommend institutions near you and hopefully
            allow better pledge fulfillment with your chosen hospitals. Maraming
            Salamat!
          </div>
          <div className="AddCityForm-FieldsContainer">
            <div className="AddCityForm-FieldsInputContainerCity">
              <div className="AddCityForm-AutoComplete">
                <label className="AddCityForm-InputLabelSmall">
                  City or town
                </label>
                <input
                  type="text"
                  className={
                    isCityValid
                      ? "AddCityForm-TextInput-Error"
                      : "AddRequest-TextInput"
                  }
                  value={city}
                  maxLength={50}
                  onChange={handleChangeCity}
                />
                {showAutoComplete()}
              </div>
              {isCityValid ? (
                <span className="AddCityForm-InputLabelSmall-Error">
                  City is required
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="AddCityForm-FieldsInputContainerProvince">
              <div className="AddCityForm-AutoComplete">
                <label className="AddCityForm-InputLabelSmall">Province</label>
                <input
                  type="text"
                  className={
                    isCityValid
                      ? "AddCityForm-TextInput-Error"
                      : "AddRequest-TextInput"
                  }
                  value={province}
                  maxLength={50}
                  onChange={handleChangeProvince}
                />
                {showProvinceAutoComplete()}
              </div>
              {isProvinceValid ? (
                <span className="AddCityForm-InputLabelSmall-Error">
                  Province is required
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <div className="AddCityForm-Footer">
            <div className="AddCityForm-SkipButtonDisabled">Skip</div>
            <div className="AddCityForm-SubmitButtonDisabled">Submit</div>
          </div>
        ) : (
          <div className="AddCityForm-Footer">
            <div
              className="AddCityForm-SkipButton"
              onClick={() => setModalDetails({ ...modalDetails, state: false })}
            >
              Skip
            </div>
            <div
              className="AddCityForm-SubmitButton"
              onClick={() => submitCity()}
            >
              Submit
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default AddCityForm;
