import React,{useState,useEffect,useContext} from 'react'
import '../styles/TermsAndCondition.css'
import {useFetchAllTc} from '../ui/FAQAndTCPageViewModel.jsx'

const TermsAndConditionRenderer =()=>{
    const {data} = useFetchAllTc()
    const [loading,setLoading] = useState(true)
    useEffect(()=>{
        if(data){
            setLoading(false)
        }
    },[data])
    return(
        <React.Fragment>
            <div id="TermsAndConditionHolder">
                <div id="TermsAndConditionContainer">
                {  data && data.map((item, index) => {
                              
                              return (
                                 
                                  <div dangerouslySetInnerHTML={{ __html: item.content}} />
                                
                                  )
                              })
                          }
                </div>
            </div>
        </React.Fragment>
    )
}

export default TermsAndConditionRenderer