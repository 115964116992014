import React,{useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import AlertIcon from '../../assets/ic_error.png'
import '../styles/AlreadyRegisteredPrompt.css'

const AlreadyRegisteredPrompt =()=> {
    const {modalDetails,setModalDetails} = useContext(ModalState)
    return(
        <React.Fragment>
            <div id="AlreadyRegisteredPrompt">
                <div className="AlreadyRegisteredPrompt-Body">
                    <img src={AlertIcon} />
                    <div className="AlreadyRegisteredPrompt-Title">Institution already registered</div>
                    <div className="AlreadyRegisteredPrompt-Description">For better logistics handling a single authorized representative per institution/hospital is being implemented for now.</div>
                    <div className="AlreadyRegisteredPrompt-Description">Please contact <a href="mailto:support@wecare.ph" target="_top">support@wecare.ph</a></div>
                </div>
                <div className="AlreadyRegisteredPrompt-Footer">
                    <div className="AlreadyRegisteredPrompt-SubmitButton" onClick={()=>setModalDetails({...modalDetails,'state':false})}>Ok</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default AlreadyRegisteredPrompt;