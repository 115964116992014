import {useState,useEffect}  from 'react'
import Footer from '../../../../global/components/Footer.jsx'
import {db} from "../../../../global/services/firebase";

const getAllImages = async()=> {
    let ImageList = []
    await db.collection("imagesbase64").where('imagename', 'in',['image1', 'image2','image3','image4','image5','image6']).get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const imagedata = {...docData,itemId:doc.id}
            ImageList.push(imagedata)
        })
    })
    .catch(function(error) {
        console.log("Error getting Images: ", error);
    });

    return ImageList
}

export const useFetchAllImages= (event) => {

    const [state, setState] = useState({image:null,loading:true})

    useEffect(()=>{
        setState(state=>({image:state.image,loading:true}))

        async function fetchAllImages(){
            const imageList = await getAllImages()
            console.log("fetchImages",imageList)
            setState({image:imageList,loading:false})
        }

        fetchAllImages()

    },[event])

    return state
};

const getHeader = async()=> {
    let HeaderList = []
    await db.collection("imagesbase64").where('imagename', '==', 'header').get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const headerdata = {...docData,itemId:doc.id}
            HeaderList.push(headerdata)
        })
    })
    .catch(function(error) {
        console.log("Error getting Header: ", error);
    });

    return HeaderList
}

export const useFetchHeader= (event) => {

    const [state, setState] = useState({imageheader:null,loading:true})

    useEffect(()=>{
        setState(state=>({imageheader:state.image,loading:true}))

        async function fetchHeader(){
            const headerList = await getHeader()
            console.log("fetchHeader",headerList)
            setState({imageheader:headerList,loading:false})
        }

        fetchHeader()

    },[event])

    return state
};

const getFooter = async()=> {
    let footerList = []
    await db.collection("imagesbase64").where('imagename', '==', 'footer').get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const footerdata = {...docData,itemId:doc.id}
            footerList.push(footerdata)
        })
    })
    .catch(function(error) {
        console.log("Error getting Footer: ", error);
    });

    return footerList
}

export const useFetchFooter= (event) => {

    const [state, setState] = useState({imagefooter:null,loading:true})

    useEffect(()=>{
        setState(state=>({imagefooter:state.image,loading:true}))

        async function fetchFooter(){
            const footerList = await getFooter()
            console.log("fetchFooter",footerList)
            setState({imagefooter:footerList,loading:false})
        }

        fetchFooter()

    },[event])

    return state
};

const getPledgeListCountRequest = async()=> {  
    return (await db.collection("pledgelist")
        .get()).size/*.toLocaleString('en', {useGrouping: true })*/
}

const getWantToHelpRequest = async()=> {
    return (await db.collection("users").where("userType", "==", "WantsToHelp")
        .get()).size/*.toLocaleString('en', {useGrouping: true })*/
}


const getRequestCountRequest = async()=> {
    return (await db.collection("requestlist")
        .get()).size/*.toLocaleString('en', {useGrouping: true })*/
}

const getHospitalCountRequest = async()=> {
    var hospitals = []
    await db.collection("users").where("userType", "==", "NeedsHelp").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            const dataList = doc.data()
            const item = {...dataList, itemName: String(doc.locationName)}
            if (!hospitals.includes(item.locationName)) {
                hospitals.push(item.locationName)
            }

        })
    })
    return hospitals.length
}

const getRequestQuantityTotalRequest = async() => {
    var totalRequest = 0

    await db.collection("requestlist").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            const dataList = doc.data()
            const item = {...dataList,itemName:doc.itemName}
            totalRequest +=  parseInt(item.quantity);
        })
    })
    return totalRequest/*.toLocaleString('en', {useGrouping: true })*/
}

const getPledgeTotalRequest = async() => {
    var totalPledge = 0

    await db.collection("pledgelist").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            const dataList = doc.data()
            const item = {...dataList,itemName:doc.itemName}
            totalPledge +=  parseInt(item.pledgeQuantity);
        })
    })
    return totalPledge/*.toLocaleString('en', {useGrouping: true })*/
}

const getCurrentCompletedPledge = async() => {
    var numberOfCompletedPledge = 0
    await db.collection("requestlist").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            const dataList = doc.data()
            const item = {...dataList,itemName:doc.itemName}
            const progress = parseInt(item.progress);
            const quantity = parseInt(item.quantity);
            if (progress >= quantity) {
                numberOfCompletedPledge++;
            }
        })
    })

    return numberOfCompletedPledge
}

export const useFetchStatisticsRequests = (event) => {

    const [state, setState] = useState({dataStats:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchUserCountRequests(){
            const pledgelistCount = await getPledgeListCountRequest()
            const wantsToHelpCount = await getWantToHelpRequest()
            const requestTotal = await getRequestQuantityTotalRequest()
            const pledgeTotal = await getPledgeTotalRequest()
            const hospitalCount = await getHospitalCountRequest()
            const requestCount = await getRequestCountRequest()
            const completedPledgesCount = await getCurrentCompletedPledge()
            const userCountList = { "pledgelistCount": pledgelistCount, 
                                    "wantsToHelpCount": wantsToHelpCount,
                                    "requestTotal": requestTotal,
                                    "pledgeTotal": pledgeTotal,
                                    "hospitalCount": hospitalCount,
                                    "requestCount": requestCount,
                                    "completedPledgeCount": completedPledgesCount }
            setState({dataStats:userCountList,loading:false})
        }
        
        fetchUserCountRequests()
    },[event])

    return state
};

const getAllPledger = async()=> {
    let UserList = []
    await db.collection("users").where("userType", "==", "WantsToHelp").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const userdata = {...docData,itemId:doc.id}
            UserList.push(userdata)
        })
    })
    .catch(function(error) {
        console.log("Error getting pledger: ", error);
    });
    return UserList
}

export const useFetchAllPledger= (event) => {

    const [state, setState] = useState({pledgerdata:null,loading:true})

    useEffect(()=>{
        setState(state=>({pledgerdata:state.pledgerdata,loading:true}))

        async function fetchAllPledger(){
            const userList = await getAllPledger()
            console.log("fetchUserPledger",userList)
            setState({pledgerdata:userList,loading:false})
        }
        
        fetchAllPledger()

    },[event])

    return state
};

const getAllPledgee = async()=> {
    let UserList = []
    await db.collection("pledgelist").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const userdata = {...docData,itemName: String(doc.location)}
            if (!UserList.includes(userdata.location)) {
                UserList.push(userdata.location)
            }
        })
    })
    .catch(function(error) {
        console.log("Error getting pledgee: ", error);
    });
    return UserList
}

export const useFetchAllPledgee= (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchAllPledgee(){
            const userList = await getAllPledgee()
            console.log("fetchUserPledgee",userList)
            setState({data:userList,loading:false})
        }
        
        fetchAllPledgee()

    },[event])

    return state
};