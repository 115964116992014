import React from 'react'
import ExclamationIcon from "../../../../assets/exclamation.svg";
import WeCareLogo from '../../../../assets/logo_wecare_header.png'
import { Button, Header, Icon, Image, Modal } from 'semantic-ui-react';
import {redirectTo,signOut} from '../components/MainMenuViewModel.jsx'
import 'semantic-ui-css/semantic.min.css'
const ModalConfirmSignout =()=> {

    const [open, setOpen] = React.useState(false)
    return (
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                size="tiny"
                closeOnDimmerClick = {false}
                trigger = {<img src={WeCareLogo} />}
                >
                <Modal.Content >
                    <div className="center-align mrg-bt-10">
                        <img src={ExclamationIcon} alt="signout"/>
                    </div>
                    <h3 class="bold center-align">
                        Return to Landing Page?
                    </h3>
                    <div class="center-align mrg-top-10">
                        <p>
                            You are about to leave the page.
                        </p>
                        <p>
                            You will be Signed out. Proceed?
                        </p>
                    </div>
                
                    <div className="flex mrg-top-20 action-modal">
                        <button id="SignoutCancelModalButton" onClick={() => setOpen(false)}>Cancel</button>
                        <button id="SignoutModalButton" onClick={()=>signOut()}>Sign Out</button>
                    </div>
                </Modal.Content>
            </Modal>
    )
}
export default ModalConfirmSignout