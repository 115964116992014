import firebase from "firebase/app";
import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import {db} from "../services/firebase";
import { timeStamp } from '../../global/services/moment'
import '../styles/AddRequestForm.css'

//added by mcaguinaldo 04032020: Viewing of image when existing on created items.
const AddRequestImage = () => {

    const {modalDetails,setModalDetails} = useContext(ModalState)
    const [loading,setLoading] = useState(false)

    function closeModal(){
        //clearInputs()
        setModalDetails({...modalDetails,'state':false})
    }

    return(
        <React.Fragment>
            <div id="AddRequestForm">
                <div className="AddRequest-Header">
                    <div className="AddRequest-PreHeaderTitle">We need things</div>
                <div className="AddRequest-HeaderTitle">{modalDetails.data.itemName}</div>
                </div>
                <div className="AddRequest-Body">
                    <img className="AddRequest-Img" src={modalDetails.data.requestImageId}></img>
                </div>
                <div className="AddRequest-Footer">
                    <div className="AddRequest-CancelButton" onClick={()=>closeModal()}>Close</div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddRequestImage