import React,{useState,useEffect,useContext,Component} from 'react'
import {db} from "../../../../global/services/firebase";
import '../styles/EdmMail.css'
import {useFetchAllPledgee,useFetchAllImages,useFetchStatisticsRequests,useFetchFooter,useFetchHeader,useFetchAllPledger} from '../ui/EdmMailDatabasePageVIewModel.jsx'
import moment from 'moment'
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'




const EdmMailRenderer =()=>{

  const [modalDetails,setModalDetails] = useState(useContext(ModalState))
    const {data} = useFetchAllPledgee()
    const RequestList = []
    const {image,loading} = useFetchAllImages()

    const {imageheader} = useFetchHeader()
    const {imagefooter} = useFetchFooter()
    const {pledgerdata} = useFetchAllPledger()

    
    const ImageHeader = []
    const ImageFooter = []
    const ImageList = []
    const PledgerList = []
    
    const [fetchTrigger,setFetchTrigger] = useState(0)
    const {dataStats} = useFetchStatisticsRequests(fetchTrigger)
    const [pledgelistCount,setPledgelistCount] = useState('')
    const [wantsToHelpCount,setWantsToHelpCount] = useState('')
    const [requestTotal,setQuantityTotal] = useState('')
    const [pledgeTotal, setPledgeTotal] = useState('')
    const [hospitalCount, setHospitalCount] = useState('')
    const [requestCount, setRequestCount] = useState('')
    const [completedPledgeCount, setCompletedPledgeCount] = useState('')   

    const [announcementText,setAnnouncement] = useState('')
    const [testEmail,setTestEmail] = useState('')
    const [converted,setImageId] = useState('')

    const [editorText,setEditor] = useState('')

   /* useEffect(()=>{
      
      console.log("useffect")
      
      if(image){
        setImageId(image[0].converted)
      }
  },[image])  */ 

  data && data.map((item)=> {
    const requestdata = {'Institution/Hospital':item}
    RequestList.push(requestdata)
   // console.log(RequestList)
})

 /*  image && image.map((imagesitem)=> {
      const imagedata = {'converted':imagesitem.converted}
      ImageList.push(imagedata)
    })

  imageheader && imageheader.map((imageshead)=> {
    const headerdata = {'converted':imageshead.converted}
    ImageHeader.push(headerdata)
    })

  imagefooter && imagefooter.map((imagesfoot)=> {
    const footerdata = {'converted':imagesfoot.converted}
    ImageFooter.push(footerdata)
    }) */

    pledgerdata && pledgerdata.map((pledgeremail)=> {
      //console.log('subscribe:',pledgeremail.subscribe)
      const pledgerdata = {'subscribe':pledgeremail.subscribe,'Email Address':pledgeremail.emailAddress}
      PledgerList.push(pledgerdata)
      console.log(PledgerList)
      })

    useEffect(()=>{
        if(dataStats){
            setPledgelistCount(dataStats.pledgelistCount)
            setWantsToHelpCount(dataStats.wantsToHelpCount)
            setQuantityTotal(dataStats.requestTotal)
            setPledgeTotal(dataStats.pledgeTotal)
            setHospitalCount(dataStats.hospitalCount)
            setRequestCount(dataStats.requestCount)
            setCompletedPledgeCount(dataStats.completedPledgeCount)
        }
    },[dataStats]) 

    function handleAnnouncement(e){
      setAnnouncement(e.target.value)
  }
    function handleTestEmail(e)
    {
      
      setTestEmail(e.target.value)
    }

    function handleEditor(e){
      setEditor(e.target.value)
  }


    

    async function sendEmail(e){

        

          /*const image1 = await getBase64ImageFromUrl("https://images.sftcdn.net/images/t_app-cover-l,f_auto/p/ce2ece60-9b32-11e6-95ab-00163ed833e7/260663710/the-test-fun-for-friends-screenshot.jpg");
          alert(image1)
          console.log(image1) */

         /* const fileUrl = 'https://drive.google.com/file/d/1B-4j9z42n6hGd5Wo1RaNEvtI1-L9mXi6/view?usp=sharing' // provide file location 
          fetch(fileUrl) 
            .then( r => r.text() ) 
            .then( t => console.log(t) )*/
          
          //  console.log("imageid:",converted)  
          
          var CompletedCount = pledgeTotal.toLocaleString('en', {useGrouping: true })
          var wantsToCount = wantsToHelpCount.toLocaleString('en', {useGrouping: true })
          var pledgeCount = pledgelistCount.toLocaleString('en', {useGrouping: true })

          var hospitalCount = RequestList.length
          var locationList = ""

          var forAnnouncement = ""
          var fortestEmail = ""

          forAnnouncement = '<p style="font-size: 14px; line-height: 140%;"><span style="color: #565554; font-size: 14px; line-height: 19.6px;"><span style="font-family:arial,helvetica,sans-serif; font-size:18px; line-height:23.4px;"> '+announcementText.toString()+'</span></span></p>'
          fortestEmail = testEmail.toString()
           
          data && data.map((item, index) => { 
              return ( 
                locationList += '<p style="font-size: 14px; line-height: 140%;"><span style="color: #565554; font-size: 14px; line-height: 19.6px;"><strong><span style="font-family: arial, monospace; font-size: 14px; text-align: start; line-height: 19.6px;"> '+ item +'</span></strong></span></p>'
                )
          })

      var xsubscribe =""
      var emaillist =""
      pledgerdata && pledgerdata.map((pledgeremail, index) => { 
        xsubscribe = pledgeremail.subscribe
        if(xsubscribe == undefined || xsubscribe == "1")
          {
            return ( 
              emaillist += pledgeremail.emailAddress + ","
            )
            
          }
        })
        //console.log(emaillist)

     /*   var image1 = image.find((a,i)=> i === 0)
        var image2 = image.find((a,i)=> i === 1)
        var image3 = image.find((a,i)=> i === 2)
        var image4 = image.find((a,i)=> i === 3)
        var image5 = image.find((a,i)=> i === 4)
        var image6 = image.find((a,i)=> i === 5) */
       // alert(image1.converted)

        var imgList1 ='<img align="center" border="0" src="https://storage.googleapis.com/we_care_edm_images/image1.jpg" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 180px;" width="180"/>'

        var imgList2 ='<img align="center" border="0" src="https://storage.googleapis.com/we_care_edm_images/image2.jpg" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 180px;" width="180"/>'

        var imgList3 ='<img align="center" border="0" src="https://storage.googleapis.com/we_care_edm_images/image3.jpg" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 180px;" width="180"/>'

        var imgList4 ='<img align="center" border="0" src="https://storage.googleapis.com/we_care_edm_images/image4.jpg" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 180px;" width="180"/>'

        var imgList5 ='<img align="center" border="0" src="https://storage.googleapis.com/we_care_edm_images/image5.jpg" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 180px;" width="180"/>'

        var imgList6 ='<img align="center" border="0" src="https://storage.googleapis.com/we_care_edm_images/image6.jpg" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 180px;" width="180"/>'
       
        //console.log(imgList)

        var htmlBod =  '<head>'+
        '<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">'+
        '<meta name="viewport" content="width=device-width, initial-scale=1.0">'+
        '<meta name="x-apple-disable-message-reformatting">'+
      '  <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge">'+
      '  <title></title>'+
        
      '<style type="text/css">'+
            'a { color: #545757; text-decoration: underline; }'+
      'body {'+
        'margin: 0;'+
        'padding: 0;'+
      '}'+
      
      'table, tr, td {'+
        'vertical-align: top;'+
      '  border-collapse: collapse;'+
      ' background: #FFFFFF' +
      '}'+
      
      'p, ul {'+
        'margin: 0;'+
      '}'+
      
      '.ie-container table, .mso-container table {'+
        'table-layout: fixed;'+
      '}'+
      
      '* {'+
        'line-height: inherit;'+
      '}'+
      
      'a[x-apple-data-detectors=true] {'+
        'color: inherit !important;'+
      '  text-decoration: none !important;'+
      '}'+
      
      '.ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div {'+
        'line-height: 100%;'+
      '}'+
      
      '[owa] .email-row .email-col {'+
        'display: table-cell;'+
      '  float: none !important;'+
        'vertical-align: top;'+
      '}'+
      
      '.ie-container .email-col-100, .ie-container .email-row, [owa] .email-col-100, [owa] .email-row { width: 600px !important; }'+
      '.ie-container .email-col-17, [owa] .email-col-17 { width: 102.00000000000001px !important; }'+
      '.ie-container .email-col-25, [owa] .email-col-25 { width: 150px !important; }'+
      '.ie-container .email-col-33, [owa] .email-col-33 { width: 198px !important; }'+
      '.ie-container .email-col-50, [owa] .email-col-50 { width: 300px !important; }'+
      '.ie-container .email-col-67, [owa] .email-col-67 { width: 402px !important; }'+
      
      '@media only screen and (min-width: 620px) {'+
        '.email-row { width: 600px !important; }'+
      '  .email-row .email-col { vertical-align: top; }'+
        '.email-row .email-col-100 { width: 600px !important; }'+
      '  .email-row .email-col-67 { width: 402px !important; }'+
        '.email-row .email-col-50 { width: 300px !important; }'+
      '  .email-row .email-col-33 { width: 198px !important; }'+
        '.email-row .email-col-25 { width: 150px !important; }'+
      '  .email-row .email-col-17 { width: 102.00000000000001px !important; }'+
      '}'+
      
      '@media (max-width: 620px) {'+
        '.email-row-container {'+
          'padding-left: 0px !important;'+
      '    padding-right: 0px !important;'+
        '}'+
      '  .email-row .email-col {'+
          'min-width: 320px !important;'+
      '    max-width: 100% !important;'+
          'display: block !important;'+
        '}'+
      '  .email-row { width: calc(100% - 40px) !important; }'+
        '.email-col { width: 100% !important; }'+
      '  .email-col > div { margin: 0 auto; }'+
        '.no-stack .email-col { min-width: 0 !important; display: table-cell !important; }'+
      '  .no-stack .email-col-50 { width: 50% !important; }'+
        '.no-stack .email-col-33 { width: 33% !important; }'+
      '  .no-stack .email-col-67 { width: 67% !important; }'+
        '.no-stack .email-col-25 { width: 25% !important; }'+
      '  .no-stack .email-col-17 { width: 17% !important; }'+
      '}'+
      
      '@media (max-width: 480px) {'+
        '.hide-mobile {'+
          'display: none !important;'+
      '    max-height: 0px;'+
          'overflow: hidden;'+
        '}'+
      '}'+
      
      '@media (min-width: 980px) {'+
        '.hide-desktop {'+
          'display: none !important;'+
      '    max-height: none !important;'+
        '}'+
      '}'+
      
          '</style>'+
      '  '+
      '</head>'+ 
      
      '<body class="clean-body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #ffffff">'+
      
        '<div><table class="nl-container" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: white;width:100%" cellpadding="0" cellspacing="0">'+
      '  <tbody>'+
        '<tr style="vertical-align: top">'+
          '<td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">'+
      '    '+
      
      '<div class="email-row-container" style="padding: 10px;background-color: rgba(255,255,255,0)">'+
        '<div style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: white;" class="email-row">'+
          '<div style="border-collapse: collapse;display: table;width: 100%;background-color: white;">'+
            
      '<div class="email-col email-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">'+
        '<div style="width: 100% !important;">'+
      '<div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">'+
        
      '<table id="u_content_image_1" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
      '<table width="100%" cellpadding="0" cellspacing="0" border="0">'+
        '<tr>'+
          '<td style="padding-right: 0px;padding-left: 0px;" align="center">'+
            
      '      <img align="center" border="0" src="https://storage.googleapis.com/we_care_edm_images/header.jpg" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 580px;" width="580"/>'+
            
          '</td>'+
        '</tr>'+
      '</table>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
      '<table id="u_content_text_1" class="u_content_text" style="font-family:arial,helvetica,sans-serif;background-color:#FFFFFF;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:45px 10px 10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
        '<div style="color: #fb7305; line-height: 130%; text-align: center; word-wrap: break-word;background-color:#FFFFFF;">'+
          '<p style="font-size: 14px; line-height: 130%;"><strong><span style="font-size: 22px; line-height: 28.6px;">Maraming salamat sa pag-register sa WeCARE <span style="line-height: 28.6px; font-size: 22px;">Emergency</span> Response platform.</span></strong></p>'+
        '</div>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
      '<table id="u_content_text_2" class="u_content_text" style="font-family:arial,helvetica,sans-serif;background-color:#FFFFFF;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:25px 25px 10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
        '<div style="color: #545757; line-height: 130%; text-align: center; word-wrap: break-word;">'+
          '<p style="font-size: 14px; line-height: 130%;"><span style="font-family: arial, helvetica, sans-serif; font-size: 18px; line-height: 23.4px;">Masaya kaming ipahatid sa inyo na as of '+moment().format('MMMM Do YYYY, h:mm:ss a')+',ang WeCARE ay nakalikom na ng <span style="color: #fb7305; font-size: 18px; line-height: 23.4px;"><strong>'+ pledgeCount +' pledges</strong></span> na katumbas ng <span style="color: #fb7305; font-size: 18px; line-height: 23.4px;"><strong>'+ CompletedCount +' items</strong></span> para sa ating mga medical frontline teams. Karamihan sa mga donasyong ito ay PPEs, surgical masks, alcohol, pagkain, face shields at iba pang kagamitang pang-ospital. </span></p>'+
      '<p style="font-size: 14px; line-height: 130%;">&nbsp;</p>'+
      '<p style="font-size: 14px; line-height: 130%;"><span style="font-family: arial, helvetica, sans-serif; font-size: 18px; line-height: 23.4px;">Ang mga donasyong ito ay mula sa <span style="color: #fb7305; font-size: 18px; line-height: 23.4px;"><strong>'+ wantsToCount + ' pledgers</strong></span> na nakapag-register na sa ating platform</span></p>'+
        '</div>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
      '<table id="u_content_button_1" class="u_content_button" style="font-family:arial,helvetica,sans-serif;background-color:#FFFFFF;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:30px 10px 45px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
      '<div align="center">'+
          '<a href="" target="_blank" style="display: inline-block;font-family:arial,helvetica,sans-serif;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #FFFFFF; background-color: #fb7305; border-radius: 20px; -webkit-border-radius: 20px; -moz-border-radius: 20px; width: auto; padding: 10px 50px; mso-border-alt: none;">'+
            '<span style="line-height:120%;"><span style="font-size: 24px; line-height: 28.8px;">'+CompletedCount+' fulfilled items</span><br /></span>'+
          '</a>'+
      '</div>'+
       
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
       '</div>'+
        '</div>'+
      '</div>'+
          '</div>'+
        '</div>'+
      '</div>'+
      
      
      
      '<div class="email-row-container" style="padding: 0px 27px;background-color: #ffffff">'+
        '<div style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: white;" class="email-row">'+
          '<div style="border-collapse: collapse;display: table;width: 100%;background-color: white;">'+
            
      '<div class="email-col email-col-33" style="max-width: 320px;min-width: 200px;display: table-cell;vertical-align: top;">'+
        '<div style="width: 100% !important;">'+
       '<div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">'+
        
      '<table id="u_content_image_2" class="u_content_image" style="font-family:arial,helvetica,sans-serif;background-color:#FFFFFF;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
      '<table width="100%" cellpadding="0" cellspacing="0" border="0">'+
        '<tr>'+
          '<td style="padding-right: 0px;padding-left: 0px;" align="center">'+
            
    /*  '      <img align="center" border="0" src="data:image/png;base64,' + image1.converted.toString() + '" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 180px;" width="180"/>'+*/
     imgList1 +
            
          '</td>'+
        '</tr>'+
      '</table>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
      '</div>'+
        '</div>'+
      '</div>'+
      '<div class="email-col email-col-33" style="max-width: 320px;min-width: 200px;display: table-cell;vertical-align: top;">'+
        '<div style="width: 100% !important;">'+
       '<div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">'+
        
      '<table id="u_content_image_3" class="u_content_image" style="font-family:arial,helvetica,sans-serif;background-color:#FFFFFF;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
      '<table width="100%" cellpadding="0" cellspacing="0" border="0">'+
        '<tr>'+
          '<td style="padding-right: 0px;padding-left: 0px;" align="center">'+
            
      imgList2 +
            
          '</td>'+
        '</tr>'+
      '</table>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
      '</div>'+
        '</div>'+
      '</div>'+
      '<div class="email-col email-col-33" style="max-width: 320px;min-width: 200px;display: table-cell;vertical-align: top;">'+
        '<div style="width: 100% !important;">'+
      '<div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">'+
        
      '<table id="u_content_image_4" class="u_content_image" style="font-family:arial,helvetica,sans-serif;background-color:#FFFFFF;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
      '<table width="100%" cellpadding="0" cellspacing="0" border="0">'+
        '<tr>'+
          '<td style="padding-right: 0px;padding-left: 0px;" align="center">'+
            
          imgList3 +
            
          '</td>'+
        '</tr>'+
      '</table>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
      '</div>'+
        '</div>'+
      '</div>'+
          '</div>'+
        '</div>'+
      '</div>'+
      
      
      
      '<div class="email-row-container" style="padding: 0px 27px;background-color: #ffffff">'+
        '<div style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: white;" class="email-row">'+
          '<div style="border-collapse: collapse;display: table;width: 100%;background-color: white;">'+
            
      '<div class="email-col email-col-33" style="max-width: 320px;min-width: 200px;display: table-cell;vertical-align: top;">'+
        '<div style="width: 100% !important;">'+
      '  <div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">'+
        
      '<table id="u_content_image_5" class="u_content_image" style="font-family:arial,helvetica,sans-serif;background-color:#FFFFFF;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
      '<table width="100%" cellpadding="0" cellspacing="0" border="0">'+
        '<tr>'+
          '<td style="padding-right: 0px;padding-left: 0px;" align="center">'+
            
          imgList4 +
            
          '</td>'+
        '</tr>'+
      '</table>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
      '</div>'+
        '</div>'+
      '</div>'+
      '<div class="email-col email-col-33" style="max-width: 320px;min-width: 200px;display: table-cell;vertical-align: top;">'+
        '<div style="width: 100% !important;">'+
      '<div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">'+
        
      '<table id="u_content_image_6" class="u_content_image" style="font-family:arial,helvetica,sans-serif;background-color:#FFFFFF;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
      '<table width="100%" cellpadding="0" cellspacing="0" border="0">'+
        '<tr>'+
          '<td style="padding-right: 0px;padding-left: 0px;" align="center">'+
            
          imgList5 +
            
          '</td>'+
        '</tr>'+
      '</table>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
        '</div>'+
      '  </div>'+
      '</div>'+
      
      '<div class="email-col email-col-33" style="max-width: 320px;min-width: 200px;display: table-cell;vertical-align: top;">'+
        '<div style="width: 100% !important;">'+
      '  <div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">'+
        
      '<table id="u_content_image_7" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
      '<table width="100%" cellpadding="0" cellspacing="0" border="0">'+
        '<tr>'+
          '<td style="padding-right: 0px;padding-left: 0px;" align="center">'+
            
          imgList6 +
            
          '</td>'+
        '</tr>'+
      '</table>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
        '</div>'+
      '  </div>'+
      '</div>'+
      
          '</div>'+
        '</div>'+
      '</div>'+
      
      
      
      '<div class="email-row-container" style="padding: 0px;background-color: #ffffff">'+
        '<div style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: white;" class="email-row">'+
          '<div style="border-collapse: collapse;display: table;width: 100%;background-color: white;">'+
            
      '<div class="email-col email-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">'+
        '<div style="width: 100% !important;">'+
      '  <div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">'+
        
      '<table id="u_content_text_5" class="u_content_text" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:33px 25px 10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
        '<div style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">'+
          '<p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="font-family: arial, helvetica, sans-serif; font-size: 18px; line-height: 25.2px; color: #545757;">Sa ngayon ay mayroon tayong <span style="color: #fb7305; font-size: 18px; line-height: 23.4px;"><strong>'+hospitalCount+'</strong></span> na hospitals na kabilang na sa WeCARE platform at ang kanilang pangangailangan ay makikita sa Community Requests section ng ating platform.</span></p>'+
        '</div>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
      '<table id="u_content_text_6" class="u_content_text" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:45px 10px 15px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
        '<div style="color: #545757; line-height: 140%; text-align: left; word-wrap: break-word;">'+
          '<p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="font-family: arial, helvetica, sans-serif; font-size: 18px; line-height: 25.2px; color: #545757;">Ilan ang mga ito sa mga ospital na </span></p>'+
      '<p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="font-family: arial, helvetica, sans-serif; font-size: 18px; line-height: 25.2px; color: #545757;">natugunan ang pangangailangan:</span></p>'+
        '</div>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
        '</div>'+
      '  </div>'+
      '</div>'+
      
          '</div>'+
        '</div>'+
      '</div>'+
      
      
      
      '<div class="email-row-container" style="padding: 0px;background-color: #ffffff">'+
        '<div style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: white;" class="email-row">'+
          '<div style="border-collapse: collapse;display: table;width: 100%;background-color: white;">'+
            
      '<div class="email-col email-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">'+
        '<div style="background-color: #effaff;width: 100% !important;">'+
      '  <div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">'+
        
      '<table id="u_content_text_8" class="u_content_text" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
        '<div style="color: #000000; line-height: 140%; text-align: center; word-wrap: break-word; background-color:#EFFAFF;">' +
        
        locationList 

      +  '</div>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
        '</div>'+
      '  </div>'+
      '</div>'+
      
          '</div>'+
        '</div>'+
      '</div>'+
      
      
      
      '<div class="email-row-container" style="padding: 0px;background-color: #ffffff">'+
        '<div style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: white;" class="email-row">'+
          '<div style="border-collapse: collapse;display: table;width: 100%;background-color: white;">'+
            
      '<div class="email-col email-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">'+
        '<div style="background-color: white;width: 100% !important;">'+
      '  <div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">'+
        
      '<table id="u_content_text_11" class="u_content_text" style="font-family:arial,helvetica,sans-serif;background-color:#FFFFFF;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:45px 30px 10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
        '<div style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">'+
          '<p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="color: #575553; font-family: arial, helvetica, sans-serif; font-size: 20px; line-height: 28px;">Hangad naming agarang matugunan at maihatid ang mga hiling ng ating frontline medical teams, kaya&rsquo;t patuloy kaming nananawagan sa inyong suporta.</span></p>'+
      '<p style="font-size: 14px; line-height: 140%; text-align: center;">&nbsp;</p>'+
      '<p style="font-size: 14px; line-height: 140%; text-align: center;">&nbsp;</p>'+
        '</div>'+
      
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+      
      /*'<table id="u_content_text_12" class="u_content_text" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:30px 30px 10px;font-family:arial,helvetica,sans-serif;" align="left">'+
        '<div style="position: absolute; width: 540px; height: auto; left: 30px; top: 1814px; background: #FFFFFF; border: 1px solid #D8D8D8; box-sizing: border-box;">' +      
        '<div style="position: absolute; width: 540px; height: 250px; left: 30px; top: 1814px;">'+
        ' <img align="center" border="0" src="https://storage.googleapis.com/we_care_edm_images/announcement.jpg" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: 250px;float: none;width: 100%;max-width: 540px;" width="540"/>' +
          '</div>' +
          '<div position: absolute; width: 540px; height: 28px; left: 30px; top: 2094px; font-family: Arial;font-style: normal;font-weight: bold;font-size: 24px;line-height: 28px;>'+
          '<p style="font-style: normal; font-weight: bold; text-align: center;"><span style="color: #FB7305; font-family: arial, helvetica, sans-serif; font-size: 24px; line-height: 28px;">Announcement<br></br></span></p>'+
          '</div>'+
        '<div style="color: #565554;; line-height: 140%; text-align: center; word-wrap: break-word;">'+
        forAnnouncement +

        '</div>'+
        '</div>'+
            '</td>'+
          '</tr>'+
        '</tbody>'+
      '</table>'+  */
      //FOR EDM
      '<table id="u_content_image_9" class="u_content_image" style="font-family:arial,helvetica,sans-serif;background-color:#FFFFFF;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">'+  
      '<table width="100%" cellpadding="0" cellspacing="0" style="border: 2px solid #D8D8D8; background-color:white;">'+
        '<tr>'+
          '<td style="padding-right: 0px;padding-left: 0px;" align="center">'+
      ' <img align="center" border="0" src="https://storage.googleapis.com/we_care_edm_images/announcement.jpg" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 580px;" width="580"/>      ' +
            '</a>'+
          '</td>'+
        '</tr>'+
     // '</table>'+
      //      '</td>'+
    //      '</tr>'+
     //     '<tr>'+
     //     '<td>' +
     // '<table width="100%" cellpadding="0" cellspacing="0" border="2" border-color="#D8D8D8">'+
          '<tr>'+
          '<td>' +
          '<p style="font-style: normal; font-weight: bold; text-align: center;"><span style="color: #FB7305; font-family: arial, helvetica, sans-serif; font-size: 24px; line-height: 28px;">Announcement<br></br></span></p>'+
         // '<br></br>'+
          forAnnouncement +
          '<br></br>'+
          '</td>'+
        '</tr>'+
      '</table>'+
          '</td>' +
          '</tr>'+
        '</tbody>'+
      '</table>'+  
      //FOOTER
      '<table id="u_content_image_9" class="u_content_image" style="font-family:arial,helvetica,sans-serif;background-color:#FFFFFF;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">'+
        '<tbody>'+
          '<tr>'+
            '<td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">'+
              
      '<table width="100%" cellpadding="0" cellspacing="0" border="0">'+
        '<tr>'+
          '<td style="padding-right: 0px;padding-left: 0px;" align="center">'+
            '<a href="https://www.facebook.com/WeCarePlatform/" target="_blank">'+
      ' <img align="center" border="0" src="https://storage.googleapis.com/we_care_edm_images/footer.jpg" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 580px;" width="580"/>      ' +
            '</a>'+
          '</td>'+
        '</tr>'+
      '</table>'+
      
            '</td>'+
          '</tr>'+
          '<tr>'+
          '<td>' +
          '<p style="font-size: 14px; line-height: 140%; text-align: center;"> To unsubscribe, click <a href="https://wecare.ph/my-account" target="_blank">here</a></p>' +
          '</td>' +
          '</tr>'+
        '</tbody>'+
      '</table>'+
      
        '</div>'+
      '  </div>'+
      '</div>'+
          '</div>'+
        '</div>'+
      '</div>'+
      
      
          '</td>'+
        '</tr>'+
      '  </tbody>'+
        '</table></div>'+
      '</body>'

     // console.log(htmlBod)
     if (e.target.className === "PledgersListTable-ActionButton-EmailBlast") {

      try{
          db.collection('mail').add({
                from:'support@wecare.ph',
                bcc: emaillist,
                message: {
                  subject: 'WeCare Philippines',
                html: htmlBod
              ,         
            },
          });
          alert("Email Blast Successfully Sent!")
        }
        catch(error){
          console.log(error);
          alert("Something when wrong, please try again.")
        }
          
      }
      else
      {
        try{
        db.collection('mail').add({
          from:'support@wecare.ph',
          to: fortestEmail,
          message: {
            subject: 'Test Email: WeCare Philippines',
           html: htmlBod
            ,         
          },
        });
        alert("Test Email Successfully Sent!")
      }
        catch(error){
          console.log(error);
          alert("Something when wrong, please try again.")
        }
      }
    }

    return(
      
        <React.Fragment>
          
          <div id="EdmMailContainer">
            <div id="EdmAnnouncementLabel">
              <h1>Announcement</h1>
              <h2> You can use HTML codes to style your announcement</h2>
              <div>
              <textarea
                id="test-mailing"
                name="test-mailing"
                onChange={handleAnnouncement}
                placeholder="Post some lorem ipsum here"
                style={{width: '50%', height: '150px'}}
              />

              
              </div>
              <div className="PledgersListTable-ActionButtonsLayout-Left">
                 <div className="PledgersListTable-ActionButton-EmailBlast" onClick={(e) => { if (window.confirm('Are you sure you want to send out this email?')) sendEmail(e)} }>Send Email Blast</div> 
              </div>
              <div><br></br></div>
              <div className="PledgersListTable-ActionButtonsLayout-Left">
              <label>Test Email(Separated by comma(,)):</label>
              <br></br>
              <input type="text"  onChange={handleTestEmail} style={{width: '500px'}}/>
              <div className="PledgersListTable-ActionButton-TestEmail" onClick={(e)=>sendEmail(e)}>Send Test Email</div>
              </div>

              <div>
                
              </div>
              </div> 
          </div>
              
        </React.Fragment>
    )
}
export default EdmMailRenderer