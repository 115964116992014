import React,{useState,useEffect,useContext} from 'react'
import '../styles/FAQRenderer.css'
import {useFetchAllFaq} from '../ui/FAQAndTCPageViewModel.jsx'


const FAQRenderer =()=>{
    const {data} = useFetchAllFaq()
    const [loading,setLoading] = useState(true)
    useEffect(()=>{
        if(data){
            setLoading(false)
        }
    },[data])
    return(
        <React.Fragment>
            <div id="FAQsHolder">
                <div id="FAQsContainer">
                    <div id="FAQsContainer-HeaderRow">
                        <div className="FAQsContainer-HeaderTitle">Frequenty Asked Questions</div>
                    </div>
                    <div id="FAQsContainer-Body">
                        
                    {  data && data.map((item, index) => {
                              
                        return (
                           
                            <div dangerouslySetInnerHTML={{ __html: item.content}} />
                          
                            )
                        })
                    }

                

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default FAQRenderer