import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import WarningIcon from '../../assets/ic_error.png'
import '../styles/CancelRequestPrompt.css'
import {db} from "../services/firebase";

const CancelRequestPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const [loading,setLoading] = useState(false)

    function cancelRequest(){

        setLoading(true)

        const data = modalDetails.data

        db.collection('pledgelist').where('docId','==',data.itemId).get()
        .then(function(querySnapshot) {
            
              var batch = db.batch();
      
              querySnapshot.forEach(function(doc) {
                  batch.delete(doc.ref);
              });
      
              // Commit the batch
              return batch.commit();
        }).then(function() {
            console.log("Delete Success!")
                db.collection("requestlist").doc(data.itemId).delete().then(function() {
                setLoading(false)
                setModalDetails({...modalDetails,'data':'CancelRequestSuccess','state':false})
            }).catch(function(error) {
                setLoading(false)
                setModalDetails({...modalDetails,'data':'CancelRequestFail','state':false})
            });
        }).catch(function(err) {
            console.log("Delete Failed!",err)
            setLoading(false)
            setModalDetails({...modalDetails,'data':'CancelRequestFail','state':false})
        }) 

    }

    return(
        <React.Fragment>
            <div id="CancelRequestPrompt">
                <div className="CancelRequest-Body">
                    <img src={WarningIcon} />
                    <div className="CancelRequest-Title">Request Cancellation</div>
                    <div className="CancelRequest-Description">Your request doesn't have a pledger yet. Are you sure you want to cancel your request?</div>
                </div>
                {
                    loading
                    ?
                    <div className="CancelRequest-Footer">
                        <div className="CancelRequest-CancelButtonDisabled">No</div>
                        <div className="CancelRequest-SubmitButtonDisabled">Yes</div>
                    </div>
                    :
                    <div className="CancelRequest-Footer">
                        <div className="CancelRequest-CancelButton" onClick={()=>setModalDetails({...modalDetails,'state':false})}>No</div>
                        <div className="CancelRequest-SubmitButton" onClick={()=>cancelRequest()}>Yes</div>
                    </div>
                }
                
            </div>
        </React.Fragment>
    )
}
export default CancelRequestPrompt