import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import AlertIcon from '../../assets/ic_alert.png'
import '../styles/ChangeStatusPrompt.css'
import {db} from "../services/firebase";
import { timeStamp } from '../services/moment/index.js';

const ChangeStatusPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const [loading,setLoading] = useState(false)

    function changeStatus(){

        setLoading(true)

        const data = modalDetails.data

        var docRef = db.collection("pledgelist").doc(data.pledgeId);
        db.runTransaction    (function(transaction) {
            return transaction.get(docRef).then(function(sfDoc) {
                if (!sfDoc.exists) {
                    throw "Document does not exist!";
                }
                var newStatus = data.newStatus;
                transaction.update(docRef, { status: newStatus,updatedAt:timeStamp });
            });
        }).then(function() {
            setLoading(false)
            setModalDetails({...modalDetails,'data':'ChangeStatusSuccess','state':false})
        }).catch(function() {
            setLoading(false)
            setModalDetails({...modalDetails,'data':'ChangeStatusFailed','state':false})
        });

    }

    return(
        <React.Fragment>
            <div id="ChangeStatusPrompt">
                <div className="ChangeStatus-Body">
                    <img src={AlertIcon} />
                    <div className="ChangeStatus-Title">Changing the status to {modalDetails.data.newStatus}</div>
                    {
                        modalDetails.data.newStatus === "Shipping"
                        ?<div className="ChangeStatus-Description">Please make sure that item is on delivery before changing the status to "Shipping"</div>
                        :<div className="ChangeStatus-Description">Are you sure you want to change the status back to "Packing"?</div>
                    }
                </div>

                {
                    loading
                    ?
                    <div className="ChangeStatus-Footer">
                        <div className="ChangeStatus-CancelButtonDisabled">Cancel</div>
                        <div className="ChangeStatus-SubmitButtonDisabled">Confirm</div>
                    </div>
                    :
                    <div className="ChangeStatus-Footer">
                        <div className="ChangeStatus-CancelButton" onClick={()=>setModalDetails({...modalDetails,'state':false})}>Cancel</div>
                        <div className="ChangeStatus-SubmitButton" onClick={()=>changeStatus()}>Confirm</div>
                    </div>
                }
                
            </div>
        </React.Fragment>
    )
}
export default ChangeStatusPrompt