import React from 'react'
import VerificationList from '../components/AdminVerificationList.jsx'

const AdminVerificationListPage =()=>{

    return(
        <React.Fragment>
            <div>
                <VerificationList />
            </div>
        </React.Fragment>
    )
}
export default AdminVerificationListPage