import firebase from "firebase/app";
import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import { Select } from 'antd';
import '../styles/AddRequestForm.css'
import 'antd/dist/antd.css';
import {db} from "../services/firebase";
import {getUserProfile} from '../../pages/main/screens/ui/MyAccountPageViewModel.jsx'
import {ItemList,UnitTypes} from '../../global/services/Server.jsx'
import ReactGa from 'react-ga'
import InitializeReactGa from '../services/googleanalytics/index.jsx'
import { timeStamp } from '../../global/services/moment'

const AddRequestForm =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const { Option } = Select;
    const [itemType,setItemType] = useState('')
    const [itemName,setItemName] = useState('')
    const [itemSpecifications,setItemSpecification] = useState('')
    const [quantity,setQuantity] = useState('')
    const [unit,setUnit] = useState('Pieces')
    const [loading,setLoading] = useState(false)
    const [errorVisible,setErrorVisible] = useState(false)
    const [requestImageId, setRequestImageId] = useState('')
    const [progressId, setProgress] = useState(false)
    const [requestImageUrl, setRequestImageUrl] = useState('')
    const [isValidRequestImageid, setIsValidRequestImageId] = useState(true);
    const [errorMessage, setImageErrorMessage] = useState('');
    const [inputFileText, setInputFileText] = useState('jpeg or png 5MB or less')
    
    InitializeReactGa();

    function handleChangeItemName(e){
        setItemName(e.target.value)
    }

    function handleChangeItemSpecification(e){
        setItemSpecification(e.target.value)
    }

    function handleChangeQuantity(e){
        setQuantity(e.target.value)
    }

    function setItem(value){
        if(value === '70% Ethyl Alcohol'){
            setItemName(value)
            setUnit('Gallons')
        }
        else if(value === 'Transportation'){
            setItemName(value)
            setUnit('Vehicles')
        }
        else if(value === 'Others'){

        }
        else{
            setItemName(value)
            setUnit('Pieces')
        }

        return setItemType(value)
    }

    //added by mcaguinaldo 04032020: If user choose file by clicking upload button set requestImageId
    async function handleChangeRequestImageId(e){
        const image = e.target.files[0]
        var imageSize = 0;

        if (image != undefined)
        {
            imageSize = image.size;

            if (imageSize > 5742880)
            {
                e.target.value = null;
                setInputFileText('jpeg or png 5MB or less');
                setIsValidRequestImageId(false);
                setRequestImageId('');
                setImageErrorMessage('Image\'s size must not exceed 5MB.');
            }
            else {
                setInputFileText(image.name);
                setIsValidRequestImageId(true);
                setRequestImageId(imageFile => (image))
            }  
        }
        else {
            setRequestImageId('');
            setInputFileText('jpeg or png 5MB or less');
        }
    }

    //added by mcaguinaldo 04032020: If upload first before create
    function handleUpload() {
        const storage = firebase.storage();
        const imageFile = timeStamp + requestImageUrl.name
        const storageRef = storage.ref(`/requestListImages/${imageFile}`)
        const uploadTask = storageRef.put(requestImageId)
        uploadTask.on(
            "state_changed", snapshot => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(progress);
            }, error => {
                console.log(error);
            }, () => {
              storage
                .ref("requestListImages")
                .child(imageFile)
                .getDownloadURL()
                .then(url => {
                  setRequestImageUrl(url);
                });
            }
          );
    };

    function closeModal(){
        clearInputs()
        setModalDetails({...modalDetails,'state':false})
    }

    async function clearInputs(){
        if (requestImageId != '')
        {
            document.getElementById('AddRequest-FileInput').value = null;
        }
        setItemType('')
        setItemName('')
        setItemSpecification('')
        setQuantity('')
        setUnit('Pieces')
        setRequestImageId('')
        setRequestImageUrl('')
        setInputFileText('jpeg or png 5MB or less');
        setIsValidRequestImageId('true');
    }

    async function verifyInputs(){
        const blankItemName = itemName.replace(/^\s+/, '').replace(/\s+$/, '')
        const blankItemSpecifications = itemSpecifications.replace(/^\s+/, '').replace(/\s+$/, '')
        const blankQuantity = quantity.replace(/^\s+/, '').replace(/\s+$/, '')

        if(itemName === "" || itemSpecifications === "" || quantity === "" || unit === "" || Number(quantity) === 0 
        || blankItemName === "" || blankItemSpecifications === "" || blankQuantity === "")
        {
            return false
        }
        else{
            return true
        }
    }

    async function requestItem(requestDetails){
        try {
            db.collection('requestlist')
                .add(requestDetails)
                .then(function() {
                    setModalDetails({...modalDetails,'data':'SubmitRequestSuccess','state':false})
            })
            .catch(function(err) {
                console.log("Error",err)
                setModalDetails({...modalDetails,'data':'SubmitRequestFailed','state':false})
            });
        } catch (e) {
            console.log("Error",e)
            setModalDetails({...modalDetails,'data':'SubmitRequestFailed','state':false})
        }
    }

    //added by mcaguinaldo 04032020: If upload image upon create
    async function uploadImage() {
        if (requestImageId != '')
        {
            return new Promise(function(resolve, reject) {
                const storage = firebase.storage();
                const imageName = timeStamp + requestImageId.name;
                const storageRef = storage.ref(`/requestListImages/${imageName}`)
                const uploadTask = storageRef.put(requestImageId)
                uploadTask.on('state_changed', function(snapshot) {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    console.log('Upload is ' + progress + '% done')
                }, function error(err) {
                    console.log('error', err)
                    reject()
                }, function complete() {
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        resolve(downloadURL)
                    })
                })
                })
        }
        else {
            return ''
        }
    }

    async function addRequest(){
        setLoading(true)
        const isValidInputs = await verifyInputs()
        const imageUrl = await uploadImage();
        
        ReactGa.event({category: 'Create Request', action: 'Click create button on My Request modal', label: 'Create'})
        if(isValidInputs){
            setErrorVisible(false)
            firebase.auth().onAuthStateChanged(async function(user) {
                if (user) {
                    const userProfile = await getUserProfile(user.uid)
                    let requestDetails = {}
                    console.log("userProfile",userProfile)
                    if(userProfile.city !== "" && userProfile.city !== null && userProfile.city !== undefined){
                        requestDetails = {
                            'itemName':itemName,
                            'quantity':quantity,
                            'unit':unit,
                            'itemSpecs':itemSpecifications,
                            'location':userProfile.locationName,
                            'city':userProfile.city,
                            'progress':0,
                            'uid':user.uid,
                            'createdAt':timeStamp,
                            'updatedAt':timeStamp,
                            'requestImageId':imageUrl
                        }
                    }
                    else{
                        requestDetails = {
                            'itemName':itemName,
                            'quantity':quantity,
                            'unit':unit,
                            'itemSpecs':itemSpecifications,
                            'location':userProfile.locationName,
                            'progress':0,
                            'uid':user.uid,
                            'createdAt':timeStamp,
                            'updatedAt':timeStamp,
                            'requestImageId': imageUrl
                        }
                    }

                    await requestItem(requestDetails)
                    setLoading(false)
                    clearInputs()
                } else {
                    setLoading(false)
                    setModalDetails({...modalDetails,'data':'NoUserFound','state':false})
                }
            })
        }
        else{
            setErrorVisible(true)
            setLoading(false)
        }
    
    }

    return(
        <React.Fragment>
            <div id="AddRequestForm">
                <div className="AddRequest-Header">
                    <div className="AddRequest-PreHeaderTitle">We need things</div>
                    <div className="AddRequest-HeaderTitle">Create request</div>
                </div>
                
                <div className="AddRequest-Body">
                    <label className="AddRequest-InputLabelSmall">Item:</label>
                    <Select value={itemType} size="large" style={{width:'100%'}} onChange={(v) => setItem(v)}>
                        {ItemList.map(item => (
                        <Option key={item}>{item}</Option>)
                        )}
                    </Select>
                    <div className={itemType === "Others" ? "AddRequest-InputLayout" : "Gone"}>
                        <label className="AddRequest-InputLabelSmall">Please Specify:</label>
                        <input type="text" className="AddRequest-TextInput" value={itemName} maxLength={50} onChange={handleChangeItemName} />
                    </div>

                    <div className="AddRequest-InputLayout">
                        <label className="AddRequest-InputLabelSmall">Item Specifications:</label>
                        <input type="text" className="AddRequest-TextInput" value={itemSpecifications} maxLength={100} onChange={handleChangeItemSpecification} />
                    </div>

                    <div className="AddRequest-InputLayout">
                        <div className="AddRequest-InputLayout-Left">
                            <div className="AddRequest-InputLeft">
                                <label className="AddRequest-InputLabelSmall">Quantity:</label>
                                <input type="number" className="AddRequest-NumberInput" value={quantity}
                                onKeyDown={(e)=>(e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' 
                                || quantity.length>5 && e.keyCode !== 8) && e.preventDefault()} 
                                onChange={handleChangeQuantity} />
                            </div>
                        </div>
                        <div className="AddRequest-InputLayout-Right">
                            <div className="AddRequest-InputRight">
                                <label className="AddRequest-InputLabelSmall">Unit:</label>
                                <Select value={unit} size="large" style={{width:'100%'}} onChange={(v) => setUnit(v)}>
                                    {UnitTypes.map(item => (
                                    <Option key={item}>{item}</Option>)
                                    )}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="AddRequest-InputLayout">
                        <label className="AddRequest-InputLabelSmall">Request Image (Optional)</label>
                        <div id={`${isValidRequestImageid ? "file-upload-wrapper" : "file-upload-error"}`} data-text={inputFileText}>
                            <input id="AddRequest-FileInput" type="file" readOnly="readonly" class="file-upload-field" onChange={handleChangeRequestImageId} accept="image/png, image/jpeg"/>
                        </div>
                        <label id={`${isValidRequestImageid ? "Hidden" : "AddRequest-ErrorText"}`}>{errorMessage}</label>
                    </div>
                    <div className={errorVisible ? "AddRequest-ErrorText" : "Gone"}>* Invalid input(s). Please try again. *</div>
                </div>
                {
                    loading
                    ?
                    <div className="AddRequest-Footer">
                        <div className="AddRequest-CancelButtonDisabled">Cancel</div>
                        <div className="AddRequest-SubmitButtonDisabled">Create</div>
                    </div>
                    :
                    <div className="AddRequest-Footer">
                        <div className="AddRequest-CancelButton" onClick={()=>closeModal()}>Cancel</div>
                        <div className="AddRequest-SubmitButton" onClick={()=>addRequest()}>Create</div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}
export default AddRequestForm