import React from 'react';
import PropTypes from 'prop-types'
import Moment from 'moment'
import { db } from '../../../../global/services/firebase';

const FilteredData = ({ data, currentPage, onClick, onView, user, onEditPledged, data2 }) => {

    if (!data) {
        return
    }

    let result = data.map((item, index) => {

        var idx = -1;
        data2.find(function(value, key){
            if(value.docId === item.docId){
                idx = key;
              return key;
            }
          });

        if(user.uid === item.uid)
        {
            return null
        }
        else {
            return (
                <tr key={index}>
                        <td>{item.itemName}</td>
                        <td>{item.itemSpecs}</td>
                        <td>{item.createdAt !== undefined ? Moment(item.createdAt).format('MMM D, YYYY') : null}</td>
                        <td>{item.city}</td>
                        <td>{item.location}</td>
                        <td>
                            <div className="RequestsTable-ProgressValue">{item.progress}/{item.quantity} {item.unit}</div>
                            <progress
                                id={`${Number(item.progress) === Number(item.quantity) ? "RequestsTable-ProgressBarComplete" : "RequestsTable-ProgressBar"}`}
                                value={item.progress} max={item.quantity} />
                        </td>
                        {
                            user && (user.userType === 'WantsToSupply' || user.userType === 'WantsToSupplyRejected')
                            ? null
                            :
                            <td>
                                <div className="RequestsTable-ActionButtonsLayout-Left">
                                    {
                                        (item.requestImageId) === null || (item.requestImageId) === '' || (item.requestImageId) === undefined
                                        ?
                                        <div className="RequestsTable-ActionButton-ViewRequestImage Hidden">View Request Image</div>
                                        :
                                        <div className="RequestsTable-ActionButton-ViewRequestImage" onClick={()=>{return onView(item)}}>View Request Image</div>
                                    }
                                </div>
                                {
                                    Number(item.progress) === Number(item.quantity)
                                        ? 
                                            <div className="RequestsTable-ActionButtonsLayout-Left">
                                                <div className="RequestsTable-EditDisabledButton">Edit Pledged</div>
                                                <div className="RequestsTable-ActionButtonCompleted">Completed</div>
                                            </div>
                                        : 
                                            <div className="RequestsTable-ActionButtonsLayout-Left">
                                                <div id={item.docId} className={data2[idx][item.docId] > 0 ? "RequestsTable-EditActionButton" : "RequestsTable-EditDisabledButton"} onClick={()=>{return onEditPledged(item, user, item.docId)}}>Edit Pledged</div>
                                                <div className="RequestsTable-ActionButton" onClick={() => {
                                                    db.collection("users").doc(item.uid)
                                                    .get()
                                                    .then(function(doc) {
                                                        if(!doc.exists){
                                                            throw "Document does not exist"
                                                        }
                                                        return onClick(item,user,doc.data());
                                                    })
                                                    }}>Pledge
                                                </div>
                                            </div>
                                }
                            </td>
                        }
                    </tr>
            )
        }
    });
    return (
        result
    )
}


FilteredData.propTypes = {
    data: PropTypes.array,
    currentPage: PropTypes.int,
    dateFormatter: PropTypes.func,
    user: PropTypes.string,
    onClick: PropTypes.func,
    onEditPledged: PropTypes.func
}
export default FilteredData;