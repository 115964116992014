export const login = {
	emailEmpty                    : ' ',
	emailFormatRequired           : 'Invalid email.',
	emailRequired                 : 'Email is required.',
	passwordRequired              : 'Password is required.',
	bothEmailandPasswordIncorrect : 'Your password or email is incorrect.',
};

export const register = {
	emailFormatRequired       : 'Invalid email.',
	emailRequired             : 'Email is required.',
	passwordRequired          : 'Password is required.',
	// confirmRequired           : 'Confirm Password is required',
	confirmPasswordRequired   : 'Please confirm password.',
	passwordRegexExpression   : 'Password does not meet character requirements.',
	passwordConfirmDoNotMatch : 'Password do not match.',
};

export const reset = {
	emailFormatRequired 	  : 'Invalid email.',
	emailRequired      		  : 'Email is required.',
	emailNotRegistered  	  : 'Email has not been registered to WeCare.',
	emailReachedLimit  	  	  : 'Email account has already exceeded its limit. Please try again later.',
	passwordRequired          : 'Password is required.',
	confirmPasswordRequired   : 'Please confirm password.',
	passwordRegexExpression   : 'Password does not meet character requirements.',
	passwordConfirmDoNotMatch : 'Password do not match.',
	resetPasswordAlreadyBeenUsed: 'Reset Password is expired or already been used.',
};
