import React,{useState,useEffect,useContext} from 'react'
import '../styles/AdminDatabase.css'
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import {useFetchAllPledger} from '../ui/AdminDatabasePageViewModel.jsx'
import { Select } from 'antd';
import 'antd/dist/antd.css';
import { CSVLink, CSVDownload } from "react-csv";

const AdminDatabase = ({}) => {

    const {data} = useFetchAllPledger()
    const [loading,setLoading] = useState(true)
   
    useEffect(()=>{
        if(data){
            setLoading(false)
        }
    },[data])

    const UserList = []
    data && data.map((item)=> {
        const userdata = {'User Id': item.uid,
        'firstname' : item.firstName, 'lastname' : item.lastName,
        'Location Name': item.locationName,'User Type':item.userType,
        'Is Verified':item.emailVerified,'Email Address':item.emailAddress,'Linkedin Profile': item.profileAccount,
        'DateTime Created': item.createdAt,
        'DateTime Updated': item.updatedAt}
        UserList.push(userdata)
    })

    return(
        <React.Fragment>
            <div id="AdminDatabaseContainer">
                <div id="AdminDatabaseContainer-HeaderRow">
                    <div className="AdminDatabaseContainer-HeaderTitle">Pledger List</div>
                    <CSVLink data ={UserList} filename="Wecare_Pledgers"> DOWNLOAD</CSVLink>
                </div>
                
                <div id="AdminDatabaseContainer-Body">
                {
                    loading
                    ?<div className="RequestsTable-Loading">Loading...</div>
                    :
                    <table id="RequestsTable">
                        <tbody>
                            <tr>
                                <th><div className="thRow">User Id</div></th>
                                <th><div className="thRow">First Name</div></th>
                                <th><div className="thRow">Last Name</div></th>
                                <th><div className="thRow">Location Name</div></th>
                                <th><div className="thRow">User Type</div></th>
                                <th><div className="thRow" >Is Verified</div></th>
                                <th><div className="thRow" >Email Address</div></th>
                                <th><div className="thRow" >LinkedIn Profile</div></th>
                                <th><div className="thRow" >DateTime Created</div></th>
                                <th><div className="thRow" >DateTime Updated</div></th>
                            </tr>
                            {  data && data.map((item, index) => {
                            return (
                                <tr key={index}>
                                        <td>{item.uid}</td>
                                        <td>{item.firstName}</td>
                                        <td>{item.lastName}</td>
                                        <td>{item.locationName}</td>
                                        <td>{item.userType}</td>
                                        <td>{String(item.emailVerified)}</td>
                                        <td>{item.emailAddress}</td>
                                        <td>{item.profileAccount}</td>
                                        <td>{item.createdAt}</td>
                                        <td>{item.updatedAt}</td>
                                    </tr>
                                    )
                                })
                                }
                        </tbody>
                    </table>
                }
                </div>
            </div>
        </React.Fragment>
    )
}
export default AdminDatabase