import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import '../styles/Footer.css'
import {redirectTo,signOut} from '../../pages/main/screens/components/MainMenuViewModel'

const Footer =()=> {

    return(
        <React.Fragment>
            <div id="Footer">
                <div className="FooterLeft">
                   <div className="FooterLeft-Copyright"> © Copyright Cortex Technologies Corporation. All rights reserved</div>
                </div>
                <div className="FooterRight">
                    <div className="FooterRight-TermsAndConditions"><a href="/terms-and-conditions" target="_blank">Terms and conditions</a></div>
                    <div className="FooterRight-PrivacyNotice"><a href="https://cortex.ph/privacypolicy" target="_blank">Privacy notice</a></div>   
                </div>
            </div>
        </React.Fragment>
    )
}
export default Footer