import firebase from "firebase/app";
import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import { Select } from 'antd';
import '../styles/AddListingForm.css'
import 'antd/dist/antd.css';
import {db} from "../services/firebase";
import {getUserProfile} from '../../pages/main/screens/ui/MyAccountPageViewModel.jsx'
import moment from 'moment-timezone';
import {ItemList,UnitTypes} from '../../global/services/Server.jsx'

const AddListingForm =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const { Option } = Select;

    const [itemType,setItemType] = useState('')
    const [itemName,setItemName] = useState('')
    const [itemSpecifications,setItemSpecification] = useState('')
    const [stock,setStock] = useState('')
    const [unit,setUnit] = useState('Pieces')
    const [price, setPrice] = useState('')
    const [minQuantity,setMinQuantity] = useState('')
    const [itemImage,setItemImage] = useState('')
    const [loading,setLoading] = useState(false)
    const [errorVisible,setErrorVisible] = useState(false)
    const [errorMessage,setErrorMessage] = useState('')

    function handleChangeItemName(e){
        setItemName(e.target.value)
    }

    function handleChangeItemSpecification(e){
        setItemSpecification(e.target.value)
    }

    function handleChangeStock(e){
        setStock(e.target.value)
    }

    function handleChangePrice(e){
        setPrice(e.target.value)
    }

    function handleChangeMinQuantity(e){
        setMinQuantity(e.target.value)
    }

    function handleChangeItemImage(e){
        const image = e.target.files[0]

        if(image !== undefined){
            if(image.size < 5742880){
                setItemImage(imageFile => (image))
                setErrorVisible(false)
                setErrorMessage('')
            }
            else{
                setErrorVisible(true)
                setErrorMessage('* File size must be less than 5mb *')
            }
        }
        else{
            setErrorVisible(false)
            setErrorMessage('')
        }
    }

    function closeModal(){
        clearInputs()
        setModalDetails({...modalDetails,'state':false})
    }

    async function clearInputs(){
        setItemType('')
        setItemName('')
        setItemSpecification('')
        setStock('')
        setUnit('Pieces')
        setPrice('')
        setMinQuantity('')
        setItemImage('')
    }

    async function verifyInputs(){
        const blankItemName = itemName.replace(/^\s+/, '').replace(/\s+$/, '')
        const blankItemSpecifications = itemSpecifications.replace(/^\s+/, '').replace(/\s+$/, '')
        const blankStock = stock.replace(/^\s+/, '').replace(/\s+$/, '')
        const blankPrice = price.replace(/^\s+/, '').replace(/\s+$/, '')

        if(itemName === "" || itemSpecifications === "" || stock === "" || Number(stock) === 0 
        || unit === "" || price === "" || Number(price) === 0 || blankPrice === ""
        || blankItemName === "" || blankItemSpecifications === "" || blankStock === "")
        {
            return false
        }
        else{
            return true
        }
    }

    async function listItem(listingDetails){
        try {
            db.collection('listingslist')
                .add(listingDetails)
                .then(function() {
                    setLoading(false)
                    clearInputs()
                    setModalDetails({...modalDetails,'data':'SubmitListingSuccess','state':false})
            })
            .catch(function(err) {
                setLoading(false)
                clearInputs()
                setModalDetails({...modalDetails,'data':'SubmitListingFailed','state':false})
            });
        } catch (e) {
            setLoading(false)
            clearInputs()
            setModalDetails({...modalDetails,'data':'SubmitListingFailed','state':false})
        }
    }

    async function addListing(){
        setLoading(true)
        const isValidInputs = await verifyInputs()
        if(isValidInputs){
            setErrorVisible(false)
            firebase.auth().onAuthStateChanged(async function(user) {
                if (user) {
                    const userProfile = await getUserProfile(user.uid)
                    var date = (moment().tz("Asia/Singapore").format()).replace(/T/, ' ').replace("+08:00", '')

                    if(itemImage !== ""){
                        const fbstorage = firebase.storage()

                        fbstorage.ref(`/listingImages/${itemImage.name}`).put(itemImage)
                        .then((snapshot) =>{
                            snapshot.ref.getDownloadURL()
                            .then(async (imgUrl)=>{
                                const listingDetails = {
                                    'itemName':itemName,
                                    'itemSpecs':itemSpecifications,
                                    'stock':stock,
                                    'progress':stock,
                                    'unit':unit,
                                    'price':price,
                                    'minQuantity':minQuantity,
                                    'itemImage':imgUrl,
                                    'location':userProfile.locationName,
                                    'tier':userProfile.tier,
                                    'city':userProfile.city,
                                    'uid':user.uid,
                                    'createdAt':date,
                                    'updatedAt':date
                                }
                                await listItem(listingDetails)
                            })
                        })
                        
                    }
                    else{
                        const listingDetails = {
                            'itemName':itemName,
                            'itemSpecs':itemSpecifications,
                            'stock':stock,
                            'progress':stock,
                            'unit':unit,
                            'price':price,
                            'minQuantity':minQuantity,
                            'itemImage':itemImage,
                            'location':userProfile.locationName,
                            'tier':userProfile.tier,
                            'city':userProfile.city,
                            'uid':user.uid,
                            'createdAt':date,
                            'updatedAt':date
                        }
                        await listItem(listingDetails)
                    }
                    
                } else {
                    setLoading(false)
                    setModalDetails({...modalDetails,'data':'NoUserFound','state':false})
                }
            })
        }
        else{
            setErrorVisible(true)
            setErrorMessage('* Invalid input(s). Please try again. *')
            setLoading(false)
        }
    
    }

    return(
        <React.Fragment>
            <div id="AddListingForm">
                <div className="AddListing-Header">
                    <div className="AddListing-PreHeaderTitle">We want to supply things</div>
                    <div className="AddListing-HeaderTitle">Create item listing</div>
                </div>
                
                <div className="AddListing-Body">
                    <div className="AddListing-InputLayout">
                        <label className="AddListing-InputLabelSmall">Item:</label>
                        <input type="text" className="AddListing-TextInput" value={itemName} maxLength={50} onChange={handleChangeItemName} />
                    </div>

                    <div className="AddListing-InputLayout">
                        <label className="AddListing-InputLabelSmall">Item Specifications:</label>
                        <input type="text" className="AddListing-TextInput" value={itemSpecifications} maxLength={100} onChange={handleChangeItemSpecification} />
                    </div>

                    <div className="AddListing-InputLayout">
                        <div className="AddListing-InputLayout-Left">
                            <div className="AddListing-InputLeft">
                                <label className="AddListing-InputLabelSmall">Stock:</label>
                                <input type="number" className="AddListing-NumberInput" value={stock}
                                onKeyDown={(e)=>(e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' 
                                || stock.length>5 && e.keyCode !== 8) && e.preventDefault()} 
                                onChange={handleChangeStock} />
                            </div>
                        </div>
                        <div className="AddListing-InputLayout-Right">
                            <div className="AddListing-InputRight">
                                <label className="AddListing-InputLabelSmall">Unit:</label>
                                <Select value={unit} size="large" style={{width:'100%'}} onChange={(v) => setUnit(v)}>
                                    {UnitTypes.map(item => (
                                    <Option key={item}>{item}</Option>)
                                    )}
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div className="AddListing-InputLayout">
                        <div className="AddListing-InputLayout-Left">
                            <div className="AddListing-InputLeft">
                                <label className="AddListing-InputLabelSmall">Price per unit in Philippine peso:</label>
                                <input type="number" className="AddListing-NumberInput" value={price}
                                onKeyDown={(e)=>(e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' 
                                || price.length>6 && e.keyCode !== 8) && e.preventDefault()} 
                                onChange={handleChangePrice} />
                            </div>
                        </div>
                        <div className="AddListing-InputLayout-Right">
                            <div className="AddListing-InputRight">
                                <label className="AddListing-InputLabelSmall">Minimum order unit quantity:</label>
                                <input type="number" className="AddListing-NumberInput" value={minQuantity}
                                onKeyDown={(e)=>(e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' 
                                || minQuantity.length>5 && e.keyCode !== 8) && e.preventDefault()} 
                                onChange={handleChangeMinQuantity} />
                            </div>
                        </div>
                    </div>

                    <div className="AddListing-InputLayout">
                        <label className="AddListing-InputLabelSmall">Product Image: <div id="AddListing-Hint">(JPG or PNG less than 5mb)</div></label>
                        <input type="file" accept='image/jpeg,image/png'
                            id="UserInformationForm-FileInput"
                            onChange={handleChangeItemImage} />
                    </div>
                    <div className={errorVisible ? "AddListing-ErrorText" : "Gone"}>{errorMessage}</div>
                </div>
                {
                    loading
                    ?
                    <div className="AddListing-Footer">
                        <div className="AddListing-CancelButtonDisabled">Cancel</div>
                        <div className="AddListing-SubmitButtonDisabled">Create</div>
                    </div>
                    :
                    <div className="AddListing-Footer">
                        <div className="AddListing-CancelButton" onClick={()=>closeModal()}>Cancel</div>
                        <div className="AddListing-SubmitButton" onClick={()=>addListing()}>Create</div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}
export default AddListingForm