import firebase from "firebase/app";
import React,{useState,useContext, useEffect} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/AddRequestForm.css'
import {db} from "../services/firebase";
import { timeStamp } from '../../global/services/moment'
import ReactGa from 'react-ga'
import InitializeReactGa from '../services/googleanalytics/index.jsx'

const UpdateRequestForm =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)
    const [loading,setLoading] = useState(false)

    const [itemName,setItemName] = useState('')
    const [itemSpecifications,setItemSpecification] = useState('')
    const [quantity,setQuantity] = useState('')
    const [requestImageId, setRequestImageId] = useState('')
    const [requestImageUrl, setRequestImageUrl] = useState('')
    const [isValidRequestImageid, setIsValidRequestImageId] = useState(true);
    const [isValidQuantity, setIsValidQuantity] = useState(true);
    const [errorMessage, setImageErrorMessage] = useState('');
    const [errorQuantityMessage, setQuantityErrorMessage] = useState('');
    const [inputFileText, setInputFileText] = useState('jpeg or png 5MB or less')
    const [errorVisible,setErrorVisible] = useState(false)

    function handleChangeItemSpecification(e){
        setItemSpecification(e.target.value)
    }

    function handleChangeQuantity(e){
        if (e.target.value < modalDetails.data.progress)
        {
            setIsValidQuantity(false);
            setQuantityErrorMessage("Quantity cannot be less than the pledge quantity.");
            setQuantity(e.target.value);
        }
        else {
            setIsValidQuantity(true);
            setQuantityErrorMessage("");
            setQuantity(e.target.value);
        }
    }

    async function handleChangeRequestImageId(e){
        const image = e.target.files[0]
        var imageSize = 0;

        if (image != undefined)
        {
            imageSize = image.size;

            if (imageSize > 5742880)
            {
                e.target.value = null;
                setInputFileText('jpeg or png 5MB or less');
                setIsValidRequestImageId(false);
                setRequestImageId('');
                setImageErrorMessage('Image\'s size must not exceed 5MB.');
            }
            else {
                setInputFileText(image.name);
                setIsValidRequestImageId(true);
                setRequestImageId(imageFile => (image))
            }  
        }
        else {
            setRequestImageId('');
            setInputFileText('jpeg or png 5MB or less');
        }
    }

    async function verifyInputs(){
        const blankItemSpecifications = itemSpecifications.replace(/^\s+/, '').replace(/\s+$/, '')
        const blankQuantity = quantity.replace(/^\s+/, '').replace(/\s+$/, '')

        if(itemSpecifications === "" || quantity === "" || Number(quantity) === 0 
        || blankItemSpecifications === "" || blankQuantity === "" || isValidQuantity === false)
        {
            return false
        }
        else{
            return true
        }
    }

    async function uploadImage() {
        if (requestImageId != '')
        {
            return new Promise(function(resolve, reject) {
                const storage = firebase.storage();
                const imageName = timeStamp + requestImageId.name;
                const storageRef = storage.ref(`/requestListImages/${imageName}`)
                const uploadTask = storageRef.put(requestImageId)
                uploadTask.on('state_changed', function(snapshot) {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    console.log('Upload is ' + progress + '% done')
                }, function error(err) {
                    console.log('error', err)
                    reject()
                }, function complete() {
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        resolve(downloadURL)
                    })
                })
                })
        }
        else if (requestImageUrl != null || requestImageUrl != undefined || requestImageUrl != ""){
            return requestImageUrl
        }
        else {
            return ""
        }
    }

    async function clearInputs(){
        if (requestImageId != '')
        {
            document.getElementById('AddRequest-FileInput').value = null;
        }
        setRequestImageId('')    
        setInputFileText('jpeg or png 5MB or less');
        setIsValidRequestImageId('true');
        setErrorVisible(false);
    }

    async function clearInputsUponCancel() {
        setQuantity(modalDetails.data.quantity);
        setRequestImageUrl(modalDetails.data.requestImageId);
        setItemSpecification(modalDetails.data.itemSpecs);
    }

    async function updateRequest() {
        setLoading(true);
        const isValidInputs = await verifyInputs()
        const imageUrl = await uploadImage();

        if (isValidInputs)
        {
            setErrorVisible(false);
            var itemInfo = {'requestImageId': imageUrl, "quantity": quantity, "itemSpecs": itemSpecifications, 'updatedAt': timeStamp}
            try {
                db.collection('requestlist')
                    .doc(modalDetails.data.itemId)
                    .set(itemInfo, { merge: true })
                    .then(function() {
                        setModalDetails({...modalDetails,'data':'SubmitRequestSuccess','state':false})
                })
            .catch(function(err) {
                console.log("Error",err)
                setModalDetails({...modalDetails,'data':'SubmitRequestFailed','state':false})
            });
            } catch (e) {
                console.log("Error",e)
                setModalDetails({...modalDetails,'data':'SubmitRequestFailed','state':false})
            }

            setLoading(false)
            clearInputs();
        }
        else{
            setErrorVisible(true)
            setLoading(false)
        }
    }

    function closeModal(){
        clearInputsUponCancel();
        setModalDetails({...modalDetails,'state':false})
    }

    useEffect(()=>{
        if(modalDetails.data){
            setQuantity(modalDetails.data.quantity)
            setItemSpecification(modalDetails.data.itemSpecs)
            setRequestImageUrl(modalDetails.data.requestImageId)
            setItemName(modalDetails.data.itemName)
        }
    },[modalDetails.data])

    return(
        <React.Fragment>
            <div id="AddRequestForm">
                <div className="AddRequest-Header">
                    <div className="AddRequest-PreHeaderTitle">Update Request</div>
                    <div className="AddRequest-HeaderTitle">{itemName}</div>
                </div>
                <div className="AddRequest-Body">
                    <div className="AddRequest-InputLayout">
                        <label className="AddRequest-InputLabelSmall">Item Specifications:</label>
                        <input type="text" className="AddRequest-TextInput" value={itemSpecifications} maxLength={100} onChange={handleChangeItemSpecification} />
                    </div>

                    <div className="AddRequest-InputLayout">
                        <label className="AddRequest-InputLabelSmall">Quantity:</label>
                        <input type="number" className="AddRequest-NumberInput" value={quantity}
                        onKeyDown={(e)=>(e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' 
                        || quantity.length>5 && e.keyCode !== 8) && e.preventDefault()} 
                        onChange={handleChangeQuantity} />
                        <label id={`${isValidQuantity ? "Hidden" : "AddRequest-ErrorText"}`}>{errorQuantityMessage}</label>
                    </div>

                    <div className="AddRequest-InputLayout">
                        <label className="AddRequest-InputLabelSmall">Request Image (Optional)</label>
                        <div id={`${isValidRequestImageid ? "file-upload-wrapper" : "file-upload-error"}`} data-text={inputFileText}>
                            <input id="AddRequest-FileInput" type="file" readOnly="readonly" class="file-upload-field" onChange={handleChangeRequestImageId} accept="image/png, image/jpeg"/>
                        </div>
                        <label id={`${isValidRequestImageid ? "Hidden" : "AddRequest-ErrorText"}`}>{errorMessage}</label>
                    </div>
                    <div className={errorVisible ? "AddRequest-ErrorText" : "Gone"}>* Invalid input(s). Please try again. *</div>
                </div>
                {
                    loading
                    ?
                    <div className="AddRequest-Footer">
                        <div className="AddRequest-CancelButtonDisabled">Cancel</div>
                        <div className="AddRequest-SubmitButtonDisabled">Update</div>
                    </div>
                    :
                    <div className="AddRequest-Footer">
                        <div className="AddRequest-CancelButton" onClick={()=>closeModal()}>Cancel</div>
                        <div className="AddRequest-SubmitButton" onClick={()=>updateRequest()}>Update</div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default UpdateRequestForm
