import {useState,useEffect} from 'react'
import {db} from "../../../../global/services/firebase";

const getPledgeListCountRequest = async()=> {  
    return (await db.collection("pledgelist")
        .get()).size/*.toLocaleString('en', {useGrouping: true })*/
}

const getWantToHelpRequest = async()=> {
    return (await db.collection("users").where("userType", "==", "WantsToHelp")
        .get()).size/*.toLocaleString('en', {useGrouping: true })*/
}


const getRequestCountRequest = async()=> {
    return (await db.collection("requestlist")
        .get()).size/*.toLocaleString('en', {useGrouping: true })*/
}

const getHospitalCountRequest = async()=> {
    var hospitals = []
    await db.collection("users").where("userType", "==", "NeedsHelp").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            const dataList = doc.data()
            const item = {...dataList, itemName: String(doc.locationName)}
            if (!hospitals.includes(item.locationName)) {
                hospitals.push(item.locationName)
            }

        })
    })
    return hospitals.length
}

const getRequestQuantityTotalRequest = async() => {
    var totalRequest = 0

    await db.collection("requestlist").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            const dataList = doc.data()
            const item = {...dataList,itemName:doc.itemName}
            totalRequest +=  parseInt(item.quantity);
        })
    })
    return totalRequest/*.toLocaleString('en', {useGrouping: true })*/
}

const getPledgeTotalRequest = async() => {
    var totalPledge = 0

    await db.collection("pledgelist").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            const dataList = doc.data()
            const item = {...dataList,itemName:doc.itemName}
            totalPledge +=  parseInt(item.pledgeQuantity);
        })
    })
    return totalPledge/*.toLocaleString('en', {useGrouping: true })*/
}

const getCurrentCompletedPledge = async() => {
    var numberOfCompletedPledge = 0
    await db.collection("requestlist").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            const dataList = doc.data()
            const item = {...dataList,itemName:doc.itemName}
            const progress = parseInt(item.progress);
            const quantity = parseInt(item.quantity);
            if (progress >= quantity) {
                numberOfCompletedPledge++;
            }
        })
    })

    return numberOfCompletedPledge
}

export const useFetchStatisticsRequests = (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchUserCountRequests(){
            const pledgelistCount = await getPledgeListCountRequest()
            const wantsToHelpCount = await getWantToHelpRequest()
            const requestTotal = await getRequestQuantityTotalRequest()
            const pledgeTotal = await getPledgeTotalRequest()
            const hospitalCount = await getHospitalCountRequest()
            const requestCount = await getRequestCountRequest()
            const completedPledgesCount = await getCurrentCompletedPledge()
            const userCountList = { "pledgelistCount": pledgelistCount, 
                                    "wantsToHelpCount": wantsToHelpCount,
                                    "requestTotal": requestTotal,
                                    "pledgeTotal": pledgeTotal,
                                    "hospitalCount": hospitalCount,
                                    "requestCount": requestCount,
                                    "completedPledgeCount": completedPledgesCount }
            setState({data:userCountList,loading:false})
        }
        
        fetchUserCountRequests()
    },[event])

    return state
};