import React from 'react';
import PropTypes from 'prop-types'
import VerifiedIcon from '../../../../assets/ic_verified.png' 
import { db } from '../../../../global/services/firebase';

const FilteredSuppliesData = ({ data, onMoreDetails, onContactVendor }) => {

    if (!data) {
        return
    }

    let result = data.map((item, index) => {
        return (
            <tr key={index}>
                    <td>{item.itemName}</td>
                    <td>₱ {item.price}</td>
                    <td>{item.progress} {item.unit}</td>
                    <td>{item.itemSpecs}</td>
                    <td>
                        {item.location} 
                        {
                            item.tier && item.tier === 1 
                            ? 
                            <div className="SuppliesTable-VerifiedIconHolder">
                                <img className="SuppliesTable-VerifiedIcon" src={VerifiedIcon} />
                                <span className='SuppliesTable-Tooltip'>
                                    <div className='SuppliesTable-TooltipContent'>
                                        This check signifies that the vendor handles the logistics of the products.
                                    </div>
                                </span>
                            </div>
                            : null
                        }
                    </td>
                    <td>{item.city}</td>
                    <td>
                        <div className="SuppliesTable-MoreDetails" onClick={()=>onMoreDetails(item)}>More details</div>
                        <div className="SuppliesTable-ContactVendor" onClick={()=>onContactVendor(item)}>Contact vendor</div>
                    </td>
                </tr>
        )
    });
    return (
        result
    )
}


FilteredSuppliesData.propTypes = {
    data: PropTypes.array,
    onMoreDetails: PropTypes.func,
    onContactVendor: PropTypes.func
}
export default FilteredSuppliesData;