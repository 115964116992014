import React from 'react'
import PledgerBanner from '../components/PledgerBanner.jsx'
import RequestsTable from '../components/RequestsTable.jsx'
import Footer from '../../../../global/components/Footer.jsx'
import '../styles/CommunityRequestsPage.css'

const CommunityRequestsPage =()=> {
    return(
        <React.Fragment>
            <PledgerBanner />
            <div id="CommunityRequestsPage">
                <RequestsTable />
            </div>
        </React.Fragment>
    )
}
export default CommunityRequestsPage