import * as _ from "lodash";
import React, { useState, useContext, useEffect } from "react";
import Modal from "../../../../global/components/Modal";
import {
  AccountSetupDetails,
  ModalState,
} from "../../../../global/services/Context.jsx";
import "../styles/UserInformationForm.css";
import {
  isValidFirstNameInput,
  isValidLastNameInput,
  isValidMobilNumberInput,
  isValidEmailAddressInput,
  isValidVerificationInput,
  isValidCityInput,
  isValidProvinceInput,
  getUserFacebookProfile,
} from "../ui/AccountSetupPageViewModel.jsx";
import { timeStamp } from "../../../../global/services/moment";
import { db } from "../../../../global/services/firebase";

const UserInformationForm = () => {
  const { accountSetupDetails, setAccountSetupDetails } = useContext(
    AccountSetupDetails
  );
  const [modalDetails, setModalDetails] = useState(useContext(ModalState));

  const [user, setUser] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [profileAccount, setProfileAccount] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [
    cityOrProvinceAutoCompleteOptions,
    setCityOrProvinceAutoCompleteOptions,
  ] = useState([]);
  const [cityValue, setCityValue] = useState("");
  const [provinceValue, setProvinceValue] = useState("");
  const [
    autoCompleteCityOptionsHidden,
    setAutoCompleteCityOptionsHidden,
  ] = useState(true);

  const [
    autoCompleteProvinceOptionsHidden,
    setAutoCompleteProvinceOptionsHidden,
  ] = useState(true);

  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidMobileNumber, setIsValidMobileNumber] = useState(true);
  const [isValidEmailAddress, setIsValidEmailAddress] = useState(true);
  const [isValidVerification, setIsValidVerification] = useState(true);
  const [isValidCity, setIsValidCity] = useState(true);
  const [isValidProvince, setIsValidProvince] = useState(true);

  function handleChangeFirstName(e) {
    setFirstName(e.target.value);
  }

  function handleChangeLastName(e) {
    setLastName(e.target.value);
  }

  function handleChangeMobileNumber(e) {
    setMobileNumber(e.target.value);
  }

  function handleChangeEmailAddress(e) {
    setEmailAddress(e.target.value);
  }

  function handleChangeUserProfile(e) {
    setProfileAccount(e.target.value);
  }

  function handleChangeCity(e) {
    let city_list = [];
    setAutoCompleteCityOptionsHidden(false);
    setCityValue(e.target.value);
    if (e.target.value.length === 0) {
      setAutoCompleteCityOptionsHidden(true);
      return setCityOrProvinceAutoCompleteOptions(null);
    } else {
      db.collection("philippineshospitals")
        .where("city_municipality", ">=", e.target.value.toUpperCase())
        .where(
          "city_municipality",
          "<=",
          e.target.value.toUpperCase() + "\uf8ff"
        )
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            city_list.push(doc.data().city_municipality);
          });
          setCityOrProvinceAutoCompleteOptions(city_list);
        });
    }
  }

  function handleChangeProvince(e) {
    let province_list = [];
    setAutoCompleteProvinceOptionsHidden(false);
    setProvinceValue(e.target.value);
    if (e.target.value.length === 0) {
      setAutoCompleteProvinceOptionsHidden(true);
      return setCityOrProvinceAutoCompleteOptions(null);
    } else {
      db.collection("philippineshospitals")
        .where("province", ">=", e.target.value.toUpperCase())
        .where("province", "<=", e.target.value.toUpperCase() + "\uf8ff")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            province_list.push(doc.data().province);
          });
          setCityOrProvinceAutoCompleteOptions(province_list);
        });
    }
  }

  function showAutoCompleteCity() {
    return autoCompleteCityOptionsHidden ? (
      ""
    ) : (
      <ul>
        {!removeDuplicatesCityOrProvinceOptions
          ? ""
          : removeDuplicatesCityOrProvinceOptions.map((cityValue, i) => {
              return (
                <li
                  key={i}
                  data-league={cityValue}
                  onClick={(e) => {
                    setCityValue(e.currentTarget.dataset.league);
                    setAutoCompleteCityOptionsHidden(true);
                  }}
                >
                  {cityValue}
                </li>
              );
            })}
      </ul>
    );
  }

  function showAutoCompleteProvince() {
    return autoCompleteProvinceOptionsHidden ? (
      ""
    ) : (
      <ul>
        {!removeDuplicatesCityOrProvinceOptions
          ? ""
          : removeDuplicatesCityOrProvinceOptions.map((provinceValue, i) => {
              return (
                <li
                  key={i}
                  data-league={provinceValue}
                  onClick={(e) => {
                    setProvinceValue(e.currentTarget.dataset.league);
                    setAutoCompleteProvinceOptionsHidden(true);
                  }}
                >
                  {provinceValue}
                </li>
              );
            })}
      </ul>
    );
  }

  function handleChangeCompanyId(e) {
    const image = e.target.files[0];
    setCompanyId((imageFile) => image);
  }

  function handleBackEvent() {
    if (accountSetupDetails.userType === "NeedsHelp") {
      setAccountSetupDetails({ ...accountSetupDetails, step: "2" });
    } else {
      setAccountSetupDetails({ ...accountSetupDetails, step: "1" });
    }
  }

  async function getUserProfile() {
    const userProfile = await getUserFacebookProfile();
    setUser(userProfile);

    var name =
      userProfile.displayName === null
        ? ""
        : userProfile.displayName.split(" ");
    setFirstName(name[0]);
    setLastName(name[name.length - 1]);
    setMobileNumber(userProfile.phoneNumber || mobileNumber);
    setEmailAddress(userProfile.email);
  }

  async function submitUserInfo() {
    const isValidFirstName = await isValidFirstNameInput(firstName);
    const isValidLastName = await isValidLastNameInput(lastName);
    const isValidMobileNumber = await isValidMobilNumberInput(mobileNumber);
    const isValidEmailAddress = await isValidEmailAddressInput(emailAddress);
    const isValidVerification = await isValidVerificationInput(
      profileAccount,
      companyId
    );
    const isValidCity = await isValidCityInput(cityValue);
    const isValidProvince = await isValidProvinceInput(provinceValue);

    let {
      userType,
      locationName,
      locationAddress,
      city,
      province,
      partner,
    } = accountSetupDetails;

    const userInfo = {
      userType: userType,
      firstName: firstName,
      lastName: lastName,
      mobileNumber: mobileNumber,
      emailAddress: emailAddress,
      locationName: locationName,
      locationAddress: locationAddress,
      city: accountSetupDetails.userType === "NeedsHelp" ? city : cityValue,
      province:
        accountSetupDetails.userType === "WantsToHelp"
          ? provinceValue
          : province,
      profileAccount: profileAccount,
      companyId: companyId,
      website: "",
      paymentMethods: "",
      logistics: "",
      pdf: "",
      businessDocuments: {
        form2303: "",
        registrationPermit: "",
        byLaws: "",
        productCertifications: "",
      },
      emailVerified: false,
      uid: user.uid,
      createdAt: timeStamp,
      updatedAt: timeStamp,
      partner: partner,
    };
    if (accountSetupDetails.userType === "WantsToHelp") {
      if (
        isValidFirstName &&
        isValidLastName &&
        isValidMobileNumber &&
        isValidEmailAddress &&
        isValidCity &&
        isValidProvince
      ) {
        setLoading(true);
        const promptDetails = {
          title: "Account Setup Complete",
          icon: "Success",
          isSuccessCommunity: true,
          userInfo: userInfo,
        };
        setModalDetails({
          type: "GenericPrompt",
          data: promptDetails,
          state: true,
        });
      } else {
        setIsValidFirstName(isValidFirstName);
        setIsValidLastName(isValidLastName);
        setIsValidMobileNumber(isValidMobileNumber);
        setIsValidEmailAddress(isValidEmailAddress);
        setIsValidCity(isValidCity);
        setIsValidProvince(isValidProvince);
      }
    } else {
      if (
        isValidFirstName &&
        isValidLastName &&
        isValidMobileNumber &&
        isValidEmailAddress &&
        isValidVerification
      ) {
        setLoading(true);
        if (
          accountSetupDetails.partner &&
          accountSetupDetails.partner === "ABS-CBN"
        ) {
          const promptDetails = {
            title: "Account Setup Complete",
            icon: "Success",
            message: "Your account setup is complete.",
            isSuccessCommunity: true,
            userInfo: userInfo,
          };
          setModalDetails({
            type: "GenericPrompt",
            data: promptDetails,
            state: true,
          });
        } else {
          const promptDetails = {
            title: "Account Setup Complete",
            icon: "Success",
            message:
              "Your account setup is complete. Please wait for at least 5 minutes for us to verify your account.",
            isSuccessCommunity: true,
            userInfo: userInfo,
          };
          setModalDetails({
            type: "GenericPrompt",
            data: promptDetails,
            state: true,
          });
        }
      } else {
        setIsValidFirstName(isValidFirstName);
        setIsValidLastName(isValidLastName);
        setIsValidMobileNumber(isValidMobileNumber);
        setIsValidEmailAddress(isValidEmailAddress);
        setIsValidVerification(isValidVerification);
      }
    }
  }

  useEffect(() => {
    getUserProfile();
  }, [modalDetails]);

  const removeDuplicatesCityOrProvinceOptions = !_.isEmpty(
    cityOrProvinceAutoCompleteOptions
  )
    ? _.uniq(cityOrProvinceAutoCompleteOptions)
    : [];

  return (
    <React.Fragment>
      <div className={modalDetails.state ? "modalOpen" : "modalClose"}>
        <ModalState.Provider value={{ modalDetails, setModalDetails }}>
          <Modal />
        </ModalState.Provider>
      </div>
      <div id="UserInformationFormContainer">
        <div id="UserInformationForm">
          <div className="UserInformationForm-Header">
            <div id="UserInformationForm-Pretitle">
              {accountSetupDetails.userType === "NeedsHelp"
                ? "We need things"
                : "We want to help"}
            </div>
            <div id="UserInformationForm-Title">Account Setup</div>
            <div
              id={`${
                accountSetupDetails.userType === "NeedsHelp"
                  ? "UserInformationForm-Subtitle"
                  : "Gone"
              }`}
            >
              Step 2 of 2
            </div>
          </div>
          <div className="UserInformationForm-Contents">
            <label id="UserInformationForm-InputLabel">User Information</label>
            <label id="UserInformationForm-Description">
              User information have been gathered from Facebook. You will have
              to provide the missing details if there are any.
            </label>

            <div className="UserInformationForm-InputRow">
              <div className="UserInformationForm-LeftColumn">
                <div className="UserInformation-InputContainerLeft">
                  <label id="UserInformationForm-InputLabelSmall">
                    First Name:
                  </label>
                  <input
                    type="text"
                    value={firstName}
                    id={`${
                      isValidFirstName
                        ? "UserInformationForm-TextInput"
                        : "UserInformationForm-TextInputError"
                    }`}
                    onChange={handleChangeFirstName}
                  />
                  <div
                    id={`${
                      isValidFirstName
                        ? "Hidden"
                        : "UserInformationForm-TextError"
                    }`}
                  >
                    First name is required
                  </div>
                </div>
              </div>
              <div className="UserInformationForm-RightColumn">
                <div className="UserInformation-InputContainerRight">
                  <label id="UserInformationForm-InputLabelSmall">
                    Last Name:
                  </label>
                  <input
                    type="text"
                    value={lastName}
                    id={`${
                      isValidLastName
                        ? "UserInformationForm-TextInput"
                        : "UserInformationForm-TextInputError"
                    }`}
                    onChange={handleChangeLastName}
                  />
                  <div
                    id={`${
                      isValidLastName
                        ? "Hidden"
                        : "UserInformationForm-TextError"
                    }`}
                  >
                    Last name is required
                  </div>
                </div>
              </div>
            </div>

            <div className="UserInformationForm-InputRow">
              <div className="UserInformationForm-LeftColumn">
                <div className="UserInformation-InputContainerLeft">
                  <label id="UserInformationForm-InputLabelSmall">
                    Mobile:
                  </label>
                  <input
                    type="number"
                    value={mobileNumber}
                    id={`${
                      isValidMobileNumber
                        ? "UserInformationForm-NumberInput"
                        : "UserInformationForm-NumberInputError"
                    }`}
                    onKeyDown={(e) =>
                      (e.key === "e" ||
                        e.key === "." ||
                        e.key === "+" ||
                        e.key === "-") &&
                      e.preventDefault()
                    }
                    onChange={handleChangeMobileNumber}
                  />
                  <div
                    id={`${
                      isValidMobileNumber
                        ? "Hidden"
                        : "UserInformationForm-TextError"
                    }`}
                  >
                    Mobile number is required
                  </div>
                </div>
              </div>
              <div className="UserInformationForm-RightColumn">
                <div className="UserInformation-InputContainerRight">
                  <label id="UserInformationForm-InputLabelSmall">Email:</label>
                  <input
                    type="text"
                    value={emailAddress}
                    disabled={
                      accountSetupDetails.partner &&
                      accountSetupDetails.partner === "ABS-CBN"
                        ? true
                        : false
                    }
                    id={`${
                      isValidEmailAddress
                        ? "UserInformationForm-TextInput"
                        : "UserInformationForm-TextInputError"
                    }`}
                    onChange={handleChangeEmailAddress}
                  />
                  <div
                    id={`${
                      isValidEmailAddress
                        ? "Hidden"
                        : "UserInformationForm-TextError"
                    }`}
                  >
                    Email address is required
                  </div>
                </div>
              </div>
            </div>
            {accountSetupDetails.userType === "NeedsHelp" ? (
              ""
            ) : accountSetupDetails.userType === "WantsToHelp" ? (
              <div className="UserInformationForm-InputRow">
                <div className="UserInformationForm-LeftColumn">
                  <div className="UserInformation-InputContainerLeft">
                    <label id="UserInformationForm-InputLabelSmall">
                      City:
                    </label>
                    <div className="UserInformationForm-AutoComplete">
                      <input
                        type="text"
                        id={
                          isValidCity
                            ? "LocationForm-AddressInput"
                            : "UserInformationForm-TextInputError"
                        }
                        value={cityValue}
                        onChange={(e) => handleChangeCity(e)}
                      />
                      {showAutoCompleteCity()}
                    </div>
                    <div
                      id={`${
                        isValidCity ? "Hidden" : "UserInformationForm-TextError"
                      }`}
                    >
                      City is required
                    </div>
                  </div>
                </div>
                <div className="UserInformationForm-RightColumn">
                  <div className="UserInformation-InputContainerRight">
                    <label id="UserInformationForm-InputLabelSmall">
                      Province:
                    </label>
                    <div className="UserInformationForm-AutoComplete">
                      <input
                        type="text"
                        id={
                          isValidCity
                            ? "LocationForm-AddressInput"
                            : "UserInformationForm-TextInputError"
                        }
                        value={provinceValue}
                        onChange={(e) => handleChangeProvince(e)}
                      />
                      {showAutoCompleteProvince()}
                    </div>
                    <div
                      id={`${
                        isValidProvince
                          ? "Hidden"
                          : "UserInformationForm-TextError"
                      }`}
                    >
                      Province is required
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <label id="UserInformationForm-InputLabelSmall">City:</label>
                <div className="UserInformationForm-AutoComplete">
                  <input
                    type="text"
                    id={
                      isValidCity
                        ? "LocationForm-AddressInput"
                        : "UserInformationForm-TextInputError"
                    }
                    value={cityValue}
                    onChange={(e) => handleChangeCity(e)}
                  />
                  {showAutoCompleteCity()}
                </div>
                <div
                  id={`${
                    isValidCity ? "Hidden" : "UserInformationForm-TextError"
                  }`}
                >
                  City is required
                </div>
              </>
            )}
            <div
              id={`${
                accountSetupDetails.userType === "NeedsHelp"
                  ? "UserInformationForm-VerificationSection"
                  : "Gone"
              }`}
            >
              <label id="UserInformationForm-InputLabel">
                Verification Requirements
              </label>
              <label id="UserInformationForm-Description">
                For the protection of all parties we would require one(1) of the
                following: A link to your LinkedIn profile or a picture of your
                Hospital ID/Government ID.
              </label>

              <div className="UserInformationForm-InputRow">
                <div className="UserInformationForm-LeftColumn">
                  <div className="UserInformation-InputContainerLeft">
                    <label id="UserInformationForm-InputLabelSmall">
                      Professional Profile:{" "}
                      <div id="UserInformationForm-Hint">
                        (LinkedIn, Employee Profile, etc.)
                      </div>
                    </label>
                    <input
                      type="text"
                      id={`${
                        isValidVerification
                          ? "UserInformationForm-TextInput"
                          : "UserInformationForm-TextInputError"
                      }`}
                      onChange={handleChangeUserProfile}
                    />
                  </div>
                </div>
                <div className="UserInformationForm-RightColumn">
                  <div className="UserInformation-InputContainerRight">
                    <label id="UserInformationForm-InputLabelSmall">
                      Hospital ID or Government ID:{" "}
                      <div id="UserInformationForm-Hint">
                        (JPG or PNG less than 5mb)
                      </div>
                    </label>
                    <input
                      type="file"
                      accept="image/jpeg,image/png"
                      id={`${
                        isValidVerification
                          ? "UserInformationForm-FileInput"
                          : "UserInformationForm-FileInputError"
                      }`}
                      onChange={handleChangeCompanyId}
                    />
                  </div>
                </div>
                <div
                  id={`${
                    isValidVerification
                      ? "Hidden"
                      : "UserInformationForm-TextError"
                  }`}
                >
                  You must provide at least one of the following: A link to your
                  LinkedIn profile or a picture of your company ID.
                </div>
              </div>
            </div>
          </div>
          <div className="UserInformationForm-ButtonsLayout">
            <div
              id={
                loading
                  ? "UserInformationForm-BackButtonDisabled"
                  : "UserInformationForm-BackButton"
              }
              onClick={() => handleBackEvent()}
            >
              Back
            </div>
            <div
              id={
                loading
                  ? "UserInformationForm-SaveButtonDisabled"
                  : "UserInformationForm-SaveButton"
              }
              onClick={() => submitUserInfo()}
            >
              Finish
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default UserInformationForm;
