import React from 'react'
import {Link} from 'react-router-dom'
import WeCareLogo from '../../../../assets/logo_wecare_header.png'
import FAQPage from '../../../main/screens/ui/FAQPage.jsx'
import '../styles/LandingMainMenu.css'

const LandingMainMenu =()=> {

    return(
        <React.Fragment>
            <div id="LandingPageHeader">
                <div className="LandingPageHeader-Left">
                <Link to="/"><img src={WeCareLogo} /></Link>
                </div>
                <div className="LandingPageHeader-Right">
                <a href="https://www.cortex.ph/contacts/" target="_blank"><div className="LandingMenuItem">Contact us</div></a>
                <a href="/faq-page" target="_blank"><div className="LandingMenuItem">FAQ</div></a>
                </div>
            </div>
        </React.Fragment>
    )
}
export default LandingMainMenu