import React,{useState} from 'react'
import {loginWithFacebook} from '../ui/LandingPageViewModel.jsx'
import CortexLogo from '../../../../assets/Logo.png'
import '../styles/AdminLoginPage.css'

const AdminLoginPage =()=> {

    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')

    function handleChangeUsername(e){
        setUsername(e.target.value)
    }

    function handleChangePassword(e){
        setPassword(e.target.value)
    }


    return(
        <React.Fragment>
            <div id="AdminLoginPage">
                <img src={CortexLogo} id="AdminLoginPage-Logo" />
                <div id="AdminLoginPage-Title">Emergency Donation Platform</div>

                <div id="AdminLoginPageContents">
                    <div id="AdminLoginPage-Title">Admin Login</div>

                    <label id="AdminLoginPage-InputLabelSmall">Username:</label>
                    <input type="text" id="AdminLoginPage-TextInput" onChange={handleChangeUsername} />

                    <label id="AdminLoginPage-InputLabelSmall">First Name:</label>
                    <input type="password" id="AdminLoginPage-TextInput" onChange={handleChangePassword} />

                    <div id="AdminLoginPageContents-LoginButton" onClick={()=>loginWithFacebook()}>Login</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default AdminLoginPage