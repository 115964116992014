import React from 'react'

const AdminUserListPage =()=> {

    return(
        <React.Fragment>
            - This it the Admin User List page-
        </React.Fragment>
    )
}
export default AdminUserListPage