import React, { useState, useContext, useEffect,useRef } from "react";
import { useHistory } from 'react-router-dom';
import { checkAuth ,isNewUser, loginWithEmail} from "../ui/LandingPageViewModel.jsx";
import WeCareLogo from "../../../../assets/logo_wecare.png";
import ShowPassword from './../../../../assets/open_password.svg';
import HidePassword from './../../../../assets/close_password.svg';
import "../styles/LoginUsingEmailPage.css";
import Modal from "../../../../global/components/Modal.jsx";
import { ModalState } from "../../../../global/services/Context.jsx";
import InitializeReactGa from '../../../../global/services/googleanalytics/index.jsx';
import BackIcon from '../../../../assets/blue_back_button.png';
import { login } from '../../../../global/services/Constants.js';

export default () => {
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const history = useHistory();
  const [modalDetails, setModalDetails] = useState(useContext(ModalState));
  const [passwordOption, setPasswordOption] = useState(false);
  const [error, setError] = useState();
  // ref
  const emailInput = useRef("");
  const passwordInput = useRef("");

  async function checkUser(){
  const user = await checkAuth();
    console.log(user);
    if(user){
      setIsLoggingIn(true);
      isNewUser(user.uid);
    }
    else{
      setIsLoggingIn(false)
    }
  }

  InitializeReactGa();  
  
  function handlePasswordOption() {
    setPasswordOption(!passwordOption);
  }

async function doEmailLogin() {
    setIsLoggingIn(true);
    if (!emailInput.current.value && !passwordInput.current.value) {
      setIsLoggingIn(false);
      return setError({ message: { email: login.emailRequired, password: login.passwordRequired } });
    } else if (!emailInput.current.value) {
      setIsLoggingIn(false);
      return setError({ message: { email: login.emailRequired } });
    } else if (!passwordInput.current.value) {
      setIsLoggingIn(false);
      return setError({message:{password:login.passwordRequired } });
    } 
    else {
    setIsLoggingIn(true);
     if (emailInput.current.value || passwordInput.current.value) {
        setError("");
     } 
      let data = await loginWithEmail(emailInput.current.value, passwordInput.current.value)
      console.log(data);
      if (data === undefined){
        return
      }
      else if (data.code === "auth/invalid-email") {
          setIsLoggingIn(false);
          return setError({ message: { emailFormat: login.emailFormatRequired } });
      }
      else if (data.code === "auth/user-not-found" || data.code === "auth/wrong-password") {
          setIsLoggingIn(false);
          return setError({ message: { email: login.emailEmpty, password: login.bothEmailandPasswordIncorrect } });
      } else if (!data && !data.code ){
        const promptDetails = {title:'Login failed.',icon:'',message:"There seems to be a problem connecting to WeCare’s server please try again later.",'isFailLogin':true}
        setModalDetails({ 'type': 'GenericPrompt', 'data': promptDetails,'state':true})
      }
  }   
}

  useEffect(() => {
    checkUser()
  },[]);

  function closeModal(e) {
    if (e.target.className === "modalOpen") {
      setModalDetails({ ...modalDetails, state: false });
    }
  }
  return (
      <React.Fragment>
    <div id="LoginUsingEmailPage-Body">
      <div
        className={modalDetails.state ? "modalOpen" : "modalClose"}
        onClick={e => closeModal(e)}
      >
        <ModalState.Provider value={{ modalDetails, setModalDetails }}>
          <Modal />
        </ModalState.Provider>
          </div>
     <header id="LoginUsingEmailPage-Header">
        <div onClick={()=>history.push("/login")}>
                <span id="LoginUsingEmailPage-HeaderTitle"> <img src={BackIcon} id="LoginUsingEmailPage-HeaderIcon" alt="backButton"/> Back to WeCARE Home</span>     
        </div>
    </header>
     <div id="LoginUsingEmailPage">
        <div id="LoginUsingEmailPageContents">
          <img src={WeCareLogo} id="LoginUsingEmailPage-Logo" />
          <div id="LoginUsingEmailPage-Title">
            Email login
          </div>
            <div className="LoginUsingEmailPage-InputContainer">
                <label className="LoginUsingEmailPage-InputLabel">Email</label>
                <input ref={emailInput} className={error && error.message.email?"LoginUsingEmailPage-InputErrorGen": error && error.message.emailFormat?"LoginUsingEmailPage-InputErrorGen":"LoginUsingEmailPage-InputGen"}/>
                <span className="LoginUsingEmailPage-InputErrorMessage">{error && error.message.email}</span>   
                <span className="LoginUsingEmailPage-InputErrorMessage">{error && error.message.emailFormat}</span>   
            </div>
            <div className="LoginUsingEmailPage-InputContainer">
                <label className="LoginUsingEmailPage-InputLabel">Password
                  <img src={ passwordOption? HidePassword : ShowPassword } className="LoginUsingEmailPage-PasswordOption" alt="showPassword" onClick={handlePasswordOption}/>
                  <input ref={passwordInput} type={passwordOption?"text":"password"} className={error && error.message.password? "LoginUsingEmailPage-InputErrorPass": "LoginUsingEmailPage-InputPass"} />  
                </label>
                <span className="LoginUsingEmailPage-InputErrorMessage">{error && error.message.password }</span>   
            </div>
            <div id="LoginUsingEmailPageContents-ForgotPassword" >
               <span onClick={()=>history.push("/login/reset-email-password")}> Forgot your password? </span>
          </div>
          {isLoggingIn ? (
            <div id="LoginUsingEmailPageContents-NewLoginButtonDisabled">
              <div id="LoginUsingEmailPageContents-NewLoginButtonLoading" />
            </div>
          ) : (
            <div
              id="LoginUsingEmailPageContents-LoginNewButton"
              onClick={() => doEmailLogin()}
            >
              Login 
            </div>
              )}
          <div id="LoginUsingEmailPage-DataPrivacy">
            By clicking Login I hereby agree and consent to the {""}
            <a href="/terms-and-conditions" target="_blank">
              User Agreement
            </a>
            , its policies, and the{" "}
            <a href="https://cortex.ph/privacypolicy" target="_blank">
              Privacy Policy.
            </a>
          </div>
          </div>
        <div id="LoginUsingEmailPageContentsFooter">
          <div
            id="LoginUsingEmailPageContentsFooter-NewToWecare"
          >
            New To WeCARE?
          </div>
            <div
              id="LoginUsingEmailPageContents-RegisterButton"
              onClick={() =>history.push('/login/register')}
            >
              Register
            </div>
        </div>
        </div>
    </div>
    </React.Fragment>
  );
};

