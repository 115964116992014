import * as _ from "lodash";
import { db } from "../services/firebase";
import React, { useState, useContext, useEffect } from "react";

import "../styles/PledgerDonateCashPrompt.css";
import WeCareLogo from "../../assets/logo_wecare_small.png";
import ABSCBNLogo from "../../assets/abs-found.png";
import { getUserFacebookProfile } from "../../pages/main/screens/ui/AccountSetupPageViewModel";
import { timeStamp } from "../../global/services/moment";
import { AccountSetupDetails } from "../../global/services/Context";
import { ModalState } from "../../global/services/Context";

export default () => {
  const { accountSetupDetails, setAccountSetupDetails } = useContext(
    AccountSetupDetails
  );

  const { modalDetails, setModalDetails } = useContext(ModalState);
  const [userProfile, setUserProfile] = useState("");
  const [loading, setLoading] = useState(false);

  async function onSubmitDonationCash() {
    if (
      !_.isNull(userProfile) &&
      userProfile !== undefined &&
      userProfile.userType === "WantsToHelp"
    ) {
      try {
        const isExistingDonor =
          (
            await db
              .collection("cashDonor")
              .where("emailAddress", "==", userProfile.emailAddress)
              .get()
          ).docs.length > 0;
        if (!isExistingDonor) {
          await saveCashDonor();
          await sendEmailToCashDonor();
        }

        const successObj = {
          title: "Thank You!",
          message: "",
          isPledgerDonate: true,
          icon: "Success",
        };
        setModalDetails({
          type: "CashDonationComplete",
          state: true,
          data: successObj,
        });
      } catch (error) {
        const errorObj = {
          title: "Unable to setup an account",
          message: "Please try to sign in again",
          icon: "Error",
        };
        setModalDetails({
          type: "CashDonationComplete",
          state: true,
          data: errorObj,
        });
      }
    } else {
      setAccountSetupDetails({
        ...accountSetupDetails,
        step: "6",
        userType: "DonateCash",
      });
    }
  }
  async function populateUserProfile(e) {
    setLoading(true);
    const data = await getUserFacebookProfile();
    let result = await db
      .collection("users")
      .doc(data.uid)
      .get()
      .then(function (doc) {
        return doc.data();
      })
      .catch(function (error) {
        return;
      });
    setLoading(false);
    await setUserProfile(result);
  }

  async function saveCashDonor() {
    try {
      await db.collection("cashDonor").doc(userProfile.uid).set(
        {
          userType: userProfile.userType,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          mobileNumber: userProfile.mobileNumber,
          emailAddress: userProfile.emailAddress,
          createdAt: timeStamp,
          updatedAt: timeStamp,
        },
        { merge: false }
      );

      return true;
    } catch (error) {
      return null;
    }
  }

  async function sendEmailToCashDonor() {
    await db.collection("mail").add({
      from: "support@wecare.ph",
      to: userProfile.emailAddress,
      message: {
        subject: "WeCare Cash Donation Instructions",
        html: `${userProfile.firstName} ${userProfile.lastName}, WeCARE partnered with ABS-CBN
        Foundations to allow your cash donations to be fulfilled. You may now donate thru the
        following:
        <br/>
        <br/>
        <span>
          <b>Bank Name:</b> BPI
        </span>
        <br/>
        <span>
          <b>Bank Branch:</b> Sct Limbaga
        </span>
        <br/>
        <span>
          <b>Bank Complete Address:</b> GF Scout Center, Scout Limbaga cor Sct
          Tobias St Brgy Laging Handa Quezon City
        </span>
        <br/>
        <span>
          <b>Account Name:</b> ABS-CBN Lingkod Kapamilya Foundation, Inc.
        </span>
        <br/>
        <span>
          <b>Account Number:</b> 4221-0000-27
        </span>
        <br/>
        <span>
          <b>Swift Code:</b> BOPIPHMM
        </span>
        <br/>
        <br/>
        <span>
          <b>Bank Name:</b> BDO
        </span>
        <br/>
        <span>
          <b>Bank Branch:</b> Sct Albano
        </span>
        <br/>
        <span>
          <b>Bank Complete Address:</b> Georgetown Cyber Mall, Rodolfo N. Pelaez Blvd. Kaswagan, Cagayan de Oro, Misamis Oriental
        </span>
        <br/>
        <span>
          <b>Account Name:</b> ABS-CBN Lingkod Kapamilya Foundation, Inc.
        </span>
        <br/>
        <span>
          <b>Account Number:</b> 0066000-71981
        </span>
        <br/>
        <span>
          <b>Swift Code:</b> BNORPHMM
        </span>
        <br/>
        <br/>
        <span>
          Also, Cash donations received by the ABS-CBN Foundation will be duly receipted.
        </span>
        <br/>
        <span>
          To receive a copy of your Official Receipt please send a scanned copy of your deposit
          slip or for online transfers, a confirmation notice to lalara@abs-cbnfoundation.com with the following information:
        </span>
        <br/>
        <br/>
        <span>
          Donor’s registered name
        </span>
        <br/>
        <span>
          Donor’s registered address
        </span>
        <br/>
        <span>
          Donor’s TIN
        </span>
        <br/>
        <br/>
        <br/>
        <span>
          Maraming Salamat!
        </span>
        <br/>
        <br/>
        <span>
          - WeCare Support -
        </span>
        `,
      },
    });
  }

  function onCloseModal(e) {
    if (
      !_.isNull(userProfile) &&
      userProfile !== undefined &&
      userProfile.userType === "WantsToHelp"
    ) {
      setModalDetails({ ...modalDetails, state: false });
    } else {
      setAccountSetupDetails({ ...accountSetupDetails, step: "1" });
    }
  }
  useEffect(() => {
    populateUserProfile();
  }, []);
  return (
    <React.Fragment>
      <header className="PledgerDonateCash-Header" />
      <div className="PledgerDonateCash-Body">
        <div className="PledgerDonateCash-Title">
          Please read before proceeding
        </div>
        <div className="PledgerDonateCash-Content">
          WeCare is a non-payment platform that relies on pledgers to donate
          items that are needed to beneficiaries directly. We cannot accept cash
          donations.
        </div>
        <div className="PledgerDonateCash-Content">
          WeCARE partnered with ABS-CBN Foundations to allow your cash donations
          to be fulfilled. You may donate thru:
        </div>
        <div className="PledgerDonateCash-Content border-bottom">
          <p>
            <b>Bank Name:</b> BPI
          </p>
          <p>
            <b>Bank Branch:</b> Sct Limbaga
          </p>
          <p>
            <b>Bank Complete Address:</b> GF Scout Center, Scout Limbaga cor Sct
            Tobias St Brgy Laging Handa Quezon City
          </p>
          <p>
            <b>Account Name:</b> ABS-CBN Lingkod Kapamilya Foundation, Inc.
          </p>
          <p>
            <b>Account Number:</b> 4221-0000-27
          </p>
          <p>
            <b>Swift Code:</b> BOPIPHMM
          </p>
        </div>

        <div className="PledgerDonateCash-Content">
          <p>
            <b>Bank Name:</b> BDO
          </p>
          <p>
            <b>Bank Branch:</b> Sct Albano
          </p>
          <p>
            <b>Bank Complete Address:</b> Georgetown Cyber Mall, Rodolfo N.
            Pelaez Blvd. Kaswagan, Cagayan de Oro, Misamis Oriental
          </p>
          <p>
            <b>Account Name:</b> ABS-CBN Lingkod Kapamilya Foundation, Inc.
          </p>
          <p>
            <b>Account Number:</b> 0066000-71981
          </p>
          <p>
            <b>Swift Code:</b> BNORPHMM
          </p>
        </div>

        <div className="PledgerDonateCash-ContentBr">
          We will send these details as well to your email address when you
          register on the next page.
          <br />
          <br />
          Maraming salamat!
        </div>

        <div>
          <img src={WeCareLogo} alt="wecarelogo" />
          <img src={ABSCBNLogo} alt="abscbnlogo" />
        </div>
      </div>
      <div className="PledgerDonateCash-Footer">
        <div
          className="PledgerDonateCash-Footer-Cancel"
          onClick={() => onCloseModal()}
        >
          Back
        </div>
        <div
          className={
            loading
              ? "PledgerDonateCash-Footer-Submit-Disable"
              : "PledgerDonateCash-Footer-Submit"
          }
          onClick={() => onSubmitDonationCash()}
        >
          Proceed
        </div>
      </div>
    </React.Fragment>
  );
};
