import firebase, { database } from "firebase/app";
import React,{useState,useContext, useEffect} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/UpdateAccount.css'
import {db} from "../services/firebase";
import {isValidMobilNumberInput,isValidEmailAddressInput, isValidMobileFormatInput, isValidEmailFormatInput} from '../../pages/main/screens/ui/AccountSetupPageViewModel.jsx'
import { timeStamp } from '../../global/services/moment'

const UpdateAccount =()=> {
    const {modalDetails,setModalDetails} = useContext(ModalState)
    const [loading,setLoading] = useState(false)

    const [mobileNumber,setMobileNumber] = useState('')
    const [emailAddress,setEmailAddress] = useState('')
    const [subscribe,setSubscribe] = useState('0')
    const [userType,setUserType] = useState('NeedsHelp')
    const [isValidMobileNumber,setIsValidMobileNumber] = useState(true)
    const [isValidEmailAddress,setIsValidEmailAddress] = useState(true)
    const [mobileNumberTextError,setMobileNumberTextError] = useState('Mobile Number Error Text')
    const [emailAddressTextError,setEmailAddressTextError] = useState('Email Address Error Text')

    function handleChangeMobileNumber(e) {
        setMobileNumber(e.target.value)
    }

    function handleChangeEmailAddress(e){
        setEmailAddress(e.target.value)
    }

    function handleChangeSubscribe(e){
        setSubscribe(e.target.value);
    }

    function closeModal(){
        setModalDetails({...modalDetails,'state':false})
    }

    async function updateRequest(){
        setLoading(true);
        const isValidMobileNumber = await isValidMobilNumberInput(mobileNumber)
        const isValidEmailAddress = await isValidEmailAddressInput(emailAddress)
        const isValidMobileFormat = await isValidMobileFormatInput(mobileNumber)
        const isValidEmailFormat = await isValidEmailFormatInput(emailAddress)

        const itemInfo = {'emailAddress': emailAddress, 'mobileNumber': mobileNumber, 'updatedAt': timeStamp}
        const pledgerItemInfo = {'emailAddress': emailAddress, 'mobileNumber': mobileNumber, 'updatedAt': timeStamp, 'subscribe': subscribe}
        if (isValidMobileNumber && isValidEmailAddress && isValidMobileFormat && isValidEmailFormat)
        {
            const dataSuccess = {
                'title': 'Account update complete!',
                'icon': 'Success'
            }

            const dataError = {
                'title': 'Unable to update account!',
                'icon': 'Error'
            }

            try {
                if (userType === "WantsToHelp")
                {
                    db.collection('users')
                    .doc(modalDetails.data.uid)
                    .set(pledgerItemInfo, { merge: true })
                    .then(function() {
                        db.collection('pledgers')
                        .doc(modalDetails.data.uid)
                        .set(pledgerItemInfo, { merge: true })
                        .then(function() {
                            setModalDetails({...modalDetails,'data':'SubmitRequestSuccess','state':false});
                            setModalDetails({'type':'AccountUpdateComplete','state':true, 'data': dataSuccess});
                        })   
                        //return window.location.href="/my-account"
                    })
                    .catch(function(err) {
                        console.log("Error",err)
                        setModalDetails({...modalDetails,'state':false})
                        setModalDetails({'type':'AccountUpdateComplete','state':true, 'data': dataError});
                    });
                }
                else {
                    db.collection('users')
                    .doc(modalDetails.data.uid)
                    .set(itemInfo, { merge: true })
                    .then(function() {
                        if (userType === "NeedsHelp")
                        {
                            db.collection('beneficiaries')
                            .doc(modalDetails.data.uid)
                            .set(itemInfo, { merge: true })
                            .then(function() {
                                setModalDetails({...modalDetails,'data':'SubmitRequestSuccess','state':false});
                                setModalDetails({'type':'AccountUpdateComplete','state':true, 'data': dataSuccess});
                            })
                        }
                        else if (userType === "WantsToHelp")
                        {
                            db.collection('pledgers')
                            .doc(modalDetails.data.uid)
                            .set(itemInfo, { merge: true })
                            .then(function() {
                                setModalDetails({...modalDetails,'data':'SubmitRequestSuccess','state':false});
                                setModalDetails({'type':'AccountUpdateComplete','state':true, 'data': dataSuccess});
                            })
                        }
                        else if (userType === "DonateCash")
                        {
                            db.collection('cashDonor')
                            .doc(modalDetails.data.uid)
                            .set(itemInfo, { merge: true })
                            .then(function() {
                                setModalDetails({...modalDetails,'data':'SubmitRequestSuccess','state':false});
                                setModalDetails({'type':'AccountUpdateComplete','state':true, 'data': dataSuccess});
                            })
                        }
                        else if (userType === "WantsToSupply")
                        {
                            db.collection('suppliers')
                            .doc(modalDetails.data.uid)
                            .set(itemInfo, { merge: true })
                            .then(function() {
                                setModalDetails({...modalDetails,'data':'SubmitRequestSuccess','state':false});
                                setModalDetails({'type':'AccountUpdateComplete','state':true, 'data': dataSuccess});
                            })
                        }
                        })
                    .catch(function(err) {
                        console.log("Error",err)
                        setModalDetails({...modalDetails,'state':false})
                        setModalDetails({'type':'AccountUpdateComplete','state':true, 'data': dataError});
                    });
                }
                
            } catch (e) {
                console.log("Error",e)
                setModalDetails({...modalDetails,'state':false})
                setModalDetails({'type':'AccountUpdateComplete','state':true, 'data': dataError});
            }
            setLoading(false);
        } 
        else {
            setLoading(false)
            setIsValidMobileNumber(isValidMobileNumber);
            setIsValidEmailAddress(isValidEmailAddress);

            if (!isValidMobileFormat)
            {
                setMobileNumberTextError("Invalid mobile number");
                setIsValidMobileNumber(isValidMobileFormat);
            }

            if (!isValidEmailFormat)
            {
                setEmailAddressTextError("Invalid email address");
                setIsValidEmailAddress(isValidEmailFormat);
            }

            if (!isValidMobileNumber)
            {
                setMobileNumberTextError("Mobile number is required.")
            }

            if (!isValidEmailAddress)
            {
                setEmailAddressTextError("Email address is required.")
            }
        }        
    }

    useEffect(()=>{
        if(modalDetails.data){
            setMobileNumber(modalDetails.data.mobileNumber)
            setEmailAddress(modalDetails.data.emailAddress)
            setUserType(modalDetails.data.userType)
            setSubscribe(modalDetails.data.subscribe)

            if (modalDetails.data.subscribe === undefined)
            {
                setSubscribe("1");
            }
        }

        if(modalDetails.data.subscribe === undefined)
        {
            setSubscribe("1")
        }
    },[modalDetails.data])

    return(
        <React.Fragment>
            <div id="UpdateAccountForm">
                <div className="UpdateAccount-Header">
                    <div className="UpdateAccount-PreHeaderTitle">Personal Information</div>
                    <div className="UpdateAccount-HeaderTitle">Update account details</div>
                </div>

                <div className="UpdateAccount-Body">
                    
                        <div className="UpdateAccount-InputLayout-Left">
                            <div className="UpdateAccount-InputLeft">
                                <label className="UpdateAccount-InputLabelSmall">Mobile:</label>
                                <input type="number" value={mobileNumber}
                                id={`${isValidMobileNumber ? "UpdateAccount-NumberInput" : "UpdateAccount-NumberInputError"}`} 
                                onKeyDown={(e)=>(e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-') && e.preventDefault()}
                                onChange={handleChangeMobileNumber} />
                                <div id={`${isValidMobileNumber ? "Hidden" : "UpdateAccount-TextError"}`}>{mobileNumberTextError}</div>
                            </div>
                        </div>
                        <div className="UpdateAccount-InputLayout-Right">
                            <div className="UpdateAccount-InputRight">
                                <label className="UpdateAccount-InputLabelSmall">Email:</label>
                                <input type="text" value={emailAddress}
                                id={`${isValidEmailAddress ? "UpdateAccount-TextInput" : "UpdateAccount-TextInputError"}`} 
                                onChange={handleChangeEmailAddress} />
                                <div id={`${isValidEmailAddress ? "Hidden" : "UpdateAccount-TextError"}`}>{emailAddressTextError}</div>
                            </div>
                        </div>
                        <div className="UpdateAccount-InputLayout">
                        {
                            userType === "WantsToHelp"
                            ?
                            <label className="UpdateAccount-InputLayout-Bottom-Label" >I want to subsribe to the newsletter</label>
                            :
                            null
                        }
                        </div>
                            {
                                userType === "WantsToHelp"
                                ?
                                <div className="UserProfile-Input-Layout">
                                    <label className="container">Yes
                                        <input type="radio" name="rbtnSubscribe" value="1" checked={subscribe === "1"} onChange={handleChangeSubscribe}/>
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="container">No
                                        <input type="radio" name="rbtnSubscribe" value="0" checked={subscribe === "0"} onChange={handleChangeSubscribe}/>
                                        <span className="checkmark"></span>
                                    </label>             
                                </div>
                                :
                                null
                            }
                </div>
                {
                    loading
                    ?
                    <div className="UpdateAccount-Footer">
                        <div className="UpdateAccount-CancelButtonDisabled">Cancel</div>
                        <div className="UpdateAccount-SubmitButtonDisabled">Update</div>
                    </div>
                    :
                    <div className="UpdateAccount-Footer">
                        <div className="UpdateAccount-CancelButton" onClick={()=>closeModal()}>Cancel</div>
                        <div className="UpdateAccount-SubmitButton" onClick={()=>updateRequest()}>Update</div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default UpdateAccount