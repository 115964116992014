import React,{useState,useEffect,useContext} from 'react'
import '../styles/SuppliesTable.css'
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import {getItemListings} from '../ui/VendorDirectoryPageViewModel.jsx'
import { Select } from 'antd';
import 'antd/dist/antd.css';
import FilteredSuppliesData from './FilteredSuppliesData.jsx';
import Footer from '../../../../global/components/Footer'
import {db} from "../../../../global/services/firebase";

const SuppliesTable = ({ user }) => {
    const [modalDetails,setModalDetails] = useState(useContext(ModalState))
    const [fetchTrigger,setFetchTrigger] = useState(0)
    const [myUser,setMyUser] = useState(null)
    const [search, setSearch] = useState(null);
    const [loading,setLoading] = useState(true)

    const [rawData,setRawData] = useState([])
    const [dataToDisplay,setDataToDisplay] = useState([])

    const [firstPage,setFirstPage] = useState(0)
    const [lastPage,setLastPage] = useState(5)
    const [currentPage,setCurrentPage] = useState(1)

    const indexOfLastData = currentPage * 10
    const indexOfFirstData = indexOfLastData - 10
    const tableData = dataToDisplay.slice(indexOfFirstData,indexOfLastData)
    const dataToDisplayCount = dataToDisplay.length
    const tableDataCount = tableData.length

    const pages = []
    for(let x = 1; x <= Math.ceil(dataToDisplay.length/10); x++){
        pages.push(x)
    }
    const numberOfPages = pages.length
    
    const pagesToBeRendered = pages.slice(firstPage,lastPage)

    async function fetchTableData(){
        const tableData = await getItemListings()

        if(tableData && tableData.length > 0){

            let itemListingWithProfile = [] 

            await tableData.map(async (item) =>{
                await db.collection("users").doc(item.uid)
                .get()
                .then(function(doc) {
                    if(doc.exists){
                        const itemWithProfile = {...item,vendorProfile:doc.data()}
                        itemListingWithProfile.push(itemWithProfile)
                        if(itemListingWithProfile.length === tableData.length){
                            setRawData(itemListingWithProfile)
                            setDataToDisplay(itemListingWithProfile)
                            setLoading(false)
                        }
                    }
                    else{
                        setLoading(false)
                        throw "Document does not exist";
                    }
                })
                .catch(function(error) {
                    setLoading(false)
                    return itemListingWithProfile
                });
            })
        }
        else{
            setLoading(false)
        }

    }

    async function searchSpace(event) {
       let keyword = event.target.value;
       return await setSearch(keyword);
    }

    function handlePreviousPage(){
        if(currentPage > 1)
        {
            const newPage = currentPage - 1
            setCurrentPage(Number(newPage))
            if(firstPage === 0)
            {

            }
            else{
                if(newPage === firstPage+2){
                    setFirstPage(newPage-3)
                    setLastPage(newPage+2)
                }
            }
        }
        else{

        }
        
    }

    function handleNextPage(e){
        const newPage = currentPage + 1
        if(newPage <= numberOfPages)
        {
            setCurrentPage(Number(newPage))
            if(lastPage === numberOfPages)
            {

            }
            else{
                if(newPage === lastPage-1){
                    setFirstPage(newPage-3)
                    setLastPage(newPage+2)
                }
            }
        }
        else{

        }
    }

    function handlePageChange(e){
        const newPage = Number(e.target.id)
        setCurrentPage(newPage)

        if(numberOfPages > 5){
            const nFirstPage = newPage-3
            const nLastPage = newPage+2
            if(nFirstPage < 0)
            {
                setFirstPage(0)
                setLastPage(5)
            }
            else if(nLastPage > numberOfPages)
            {
                setFirstPage(numberOfPages-5)
                setLastPage(numberOfPages)
            }
            else{
                setFirstPage(nFirstPage)
                setLastPage(nLastPage)
            }

        }
    }

    async function showMoreDetails(item){
        setModalDetails({'type':'ItemListingMoreDetails','state':true,'data':item})
    }

    async function showVendorContactInfo(item){
        setModalDetails({'type':'VendorInformation','state':true,'data':item.vendorProfile})
    }

    function closeModal(e){
        if (e.target.className === "modalOpen") {
            setModalDetails({...modalDetails,'state':false})
        }
    }

    useEffect(()=> {
        const filteredData = rawData.filter((data)=>{
            if (search === null || search.length <= 0 || loading)
                return data
            else if (data.itemName.toLowerCase().includes(search.toLowerCase()) 
            || data.location.toLowerCase().indexOf(search.toLowerCase())!== -1 
            || (data.city !== undefined && data.city.toLowerCase().includes(search.toLowerCase()))) {
                setCurrentPage(1)
                return data
            }
        })
        setDataToDisplay(filteredData)
    },[search])

    useEffect(() => {
        if(user){
            setMyUser(user)
            fetchTableData()
        }
    },[user])

    useEffect(()=>{
        if(modalDetails.data === "PledgeSuccess"){
            const promptDetails = {title:'Pledge Successful',
            message: 'The institution will reach out to you soon. Please allow 24-48 hours for the institution to contact you.',
            icon:'Success'}
            setModalDetails({'type':'GenericPrompt','data':promptDetails,'state':true})
            setFetchTrigger(fetchTrigger+1)
        }
        else if(modalDetails.data === "InvalidPledge"){
            setModalDetails({'type':'InvalidPledge','state':true})
        }
    },[modalDetails.state])
    return(
        <React.Fragment>
            <div className={modalDetails.state ? "modalOpen":"modalClose"} onClick={(e)=>closeModal(e)}>
                <ModalState.Provider value={{modalDetails,setModalDetails}}>
                    <Modal />
                </ModalState.Provider>
            </div>
            <div id="SuppliesTableContainer">
                <div id="SuppliesTableContainer-HeaderRow">
                    <div className="SuppliesTableContainer-HeaderTitle">Vendor Directory</div>
                    <div className="SuppliesTableContainer-InputHolderLarge">
                        <input type="text" id="SuppliesTableSearch-SearchTextInput" placeholder="Search for an item, city, or vendor" onChange={(event)=>searchSpace(event)}  />
                    </div>
                     <div className="SuppliesTableContainer-ItemCountLabel">Showing {tableDataCount} vendors of {dataToDisplayCount}</div>
                </div>
                
                <div id="SuppliesTableContainer-Body">
                    {
                    loading
                    ?<div className="SuppliesTable-Loading">Loading...</div>
                    :
                    <div>
                        <table id="SuppliesTable">
                            <tbody>
                                <tr>
                                    <th><div className="thRow">Item listing</div></th>
                                    <th><div className="thRow">Price per unit</div></th>
                                    <th><div className="thRow">Stock</div></th>
                                    <th><div className="thRow">Item Specs</div></th>
                                    <th><div className="thRow">Vendor</div></th>
                                    <th><div className="thRow" >City</div></th>
                                    <th style={{width:300}}><div className="thRowLast">Actions</div></th>
                                </tr>
                                {tableData && tableData.length > 0
                                ? <FilteredSuppliesData data={tableData} onMoreDetails={showMoreDetails} onContactVendor={showVendorContactInfo} />
                                : null}
                            </tbody>
                        </table>
        
                    </div>
                    }
                </div>
                {
                    pagesToBeRendered && pagesToBeRendered.length > 0
                    ?
                    <div id='SuppliesTable-Pagination'>
                        <ul>
                            <li onClick={handlePreviousPage} className="SuppliesTable-InactiveLi"><b>{"<"}</b></li>
                            {pagesToBeRendered.map(page => {
                                return(<li key={page} id={page} className={currentPage === page? "SuppliesTable-ActiveLi":"SuppliesTable-InactiveLi"} onClick={(e)=>handlePageChange(e)}>{page}</li>)
                            })}
                            <li onClick={handleNextPage} className="SuppliesTable-InactiveLi"><b>{">"}</b></li>
                        </ul>
                    </div>
                    : null
                }
            </div>
            <Footer />
        </React.Fragment>
    )
}
export default SuppliesTable