import ReactGa from 'react-ga';
import InitializeReactGa from '../../../../global/services/googleanalytics/index.jsx';

export async function redirectTo(route){

    return window.location.href=`/${route}`
}
export async function signOut(){
    InitializeReactGa();
    ReactGa.event({category: 'Sign Out', action: 'Click sign out on Menu', label: 'Sign Out'})
    localStorage.clear()
    return window.location.href="/"
}