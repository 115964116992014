import React from 'react'
import MainMenu from '../components/MainMenu.jsx'
import AdminDatabasePledger from '../components/AdminDatabasePledger.jsx'
import Footer from '../../../../global/components/Footer.jsx'
import '../styles/AdminDatabasePage.css'

const AdminDatabasePledgerPage =({})=> {
    return(
        <React.Fragment>
            <div id="AdminDatabasePledgerPage">
                <AdminDatabasePledger/>
            </div>
            <Footer />
        </React.Fragment>
    )
}
export default AdminDatabasePledgerPage
