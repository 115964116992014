import React,{useState,useContext, useEffect} from 'react'
import { Select } from 'antd';
import '../styles/LocationFormVendor.css'
import {AccountSetupDetails} from '../../../../global/services/Context.jsx'
import {checkLocationInput} from '../ui/AccountSetupPageViewModel.jsx'
import 'antd/dist/antd.css';
import Modal from '../../../../global/components/Modal'
import {ModalState} from '../../../../global/services/Context.jsx'
import {CitiesList} from '../../../../global/services/Server.jsx'
import firebase from "../../../../global/services/firebase"
import { db } from '../../../../global/services/firebase'

const LocationFormVendor =()=> {
    const { Option } = Select;

    const [modalDetails,setModalDetails] = useState(useContext(ModalState))
    const {accountSetupDetails,setAccountSetupDetails} = useContext(AccountSetupDetails)

    const [locationName, setLocationName] = useState('')
    const [locationAddress, setLocationAddress] = useState('')
    const [cityValue,setCityValue] = useState('')
    const [loading,setLoading] = useState(false)


    const [city,setCity] = useState('');
    const [value, setValue] = useState([]);
    const [isCityValid,setIsCityValid] = useState(true)
    const [hide, setHide] = useState(true);
    const [resultValue, setResultValue] = useState();
    const { hospitalCityMunicipality } = !resultValue ? "" : JSON.parse(resultValue);

    const [errorCode,setErrorCode] = useState(0)

    function handleChangeLocationName(e) {
        setLocationName(e.target.value);
    }
    function handleChangeLocationAddress(e){
        setLocationAddress(e.target.value)
    }

    function handleChangeCity(e) {
        let hospital_list = [];
        setHide(false);
        setCity(e.target.value)
        if (e.target.value.length === 0) {
            setHide(true);
           return setValue(null);
       } 
       else 
       {
           setCityValue(e.target.value)
           db.collection("philippineshospitals")
               .where('city_municipality', '>=', e.target.value.toUpperCase()).where('city_municipality', '<=', e.target.value.toUpperCase()+ '\uf8ff')
               .get().then((querySnapshot)=>{
                   querySnapshot.forEach((doc)=>{
                       hospital_list.push({
                           hospitalCityMunicipality: doc.data().city_municipality,
                       })
   
                   })
                 setValue(hospital_list)
               })
       }
    }

    function handleClicklAutoComplete(e) {
        setHide(true);
        return removeDuplicates.filter(function(item) {
           setResultValue(e.currentTarget.dataset.league);
           setCityValue(JSON.parse(e.currentTarget.dataset.league).hospitalCityMunicipality)
        });
    }

    function showAutoComplete() {
                return hide?"":
                <ul>
                    {
                            !removeDuplicates ? "" : removeDuplicates.map((item, i) => {
                        return <li key={i}  data-league={JSON.stringify(item)} onClick={(e)=>handleClicklAutoComplete(e)}>{item.hospitalCityMunicipality}</li>
                    })
                } 
            </ul>
    }

    async function populateCity() {
        setCity(hospitalCityMunicipality);
    }

    async function proceedToNextStep(){
        const isValidInput = await checkLocationInput(locationName,locationAddress)

        const cityValueIsWhiteSpace = cityValue.replace(/^\s+/, '').replace(/\s+$/, '')

        if (isValidInput === 0) {
            if(cityValue === "" || cityValueIsWhiteSpace === "" 
            || cityValue === null || cityValue === undefined){
                setIsCityValid(false)
            }
            else{
                setIsCityValid(true)
                return setAccountSetupDetails({...accountSetupDetails,
                    'step':'3',
                    'locationName':locationName,
                    'locationAddress':locationAddress,
                    'city':cityValue})
            }
        }
        else {
            setErrorCode(isValidInput)
            if(cityValue === "" || cityValueIsWhiteSpace === "" 
            || cityValue === null || cityValue === undefined){
                setIsCityValid(false)
            }
            else{
                setIsCityValid(true)
            }
        }
    }

    useEffect(()=>{
        
        if(accountSetupDetails.locationName !== "" && accountSetupDetails.locationName !== undefined
        && accountSetupDetails.locationAddress !== "" && accountSetupDetails.locationAddress !== undefined){
            setLocationName(accountSetupDetails.locationName)
            setLocationAddress(accountSetupDetails.locationAddress)
            setCityValue(accountSetupDetails.city)
            setAccountSetupDetails({...accountSetupDetails,
                'locationName':'',
                'locationAddress':'',
                'city':''})
        }
    },[])

    useEffect(() => {
        populateCity();
    }, [hospitalCityMunicipality])

    const removeDuplicates = !value?"":value.filter(
        (ele, ind) =>
          ind === value.findIndex(elem => elem.hospitalCityMunicipality === ele.hospitalCityMunicipality) &&
          ele.hospitalCityMunicipality !== null &&
          ele.hospitalCityMunicipality !== ""
      );

    function closeModal(e){
        if (e.target.className === "modalOpen") {
            setModalDetails({...modalDetails,'state':false})
        }
    }

    return(
        <React.Fragment>
            <div className={modalDetails.state ? "modalOpen":"modalClose"} onClick={(e)=>closeModal(e)}>
                <ModalState.Provider value={{modalDetails,setModalDetails}}>
                    <Modal />
                </ModalState.Provider>
            </div>
           <div id="LocationFormVendorContainer">
            <div id="LocationFormVendor">
                    <div className="LocationFormVendor-Header">
                        <div id="LocationFormVendor-Pretitle">We want to supply things</div>
                        <div id="LocationFormVendor-Title">Account Setup</div>
                        <div id="LocationFormVendor-Subtitle">Step 1 of 4</div>
                    </div>
                    <div className="LocationFormVendor-Contents">
                        <label id="LocationFormVendor-InputLabel">Location</label>
                        <label id="LocationFormVendor-Description">For better tracking of things we will need the location of your business.</label>

                        <label id="LocationFormVendor-InputLabelSmall">Business name:</label>
                        <input type="text" id={`${errorCode === 1 || errorCode === 3 ? "LocationFormVendor-TextInputError" : "LocationFormVendor-TextInput"}`} value={locationName} onChange={handleChangeLocationName} />
                        <div id={`${errorCode === 1 || errorCode === 3 ? "LocationFormVendor-TextError" : "Hidden"}`}>Business name is required</div>
                        
                        <label id="LocationFormVendor-InputLabelSmall">Business address:</label>
                        <input type="text" id={`${errorCode === 2 || errorCode === 3 ? "LocationFormVendor-AddressInputError" : "LocationFormVendor-AddressInput"}`} value={locationAddress} onChange={handleChangeLocationAddress} />
                        <div id={`${errorCode === 2 || errorCode === 3 ? "LocationFormVendor-TextError" : "Hidden"}`}>Business address is required.</div>
                        
                        <div className="LocationFormVendor-AutoComplete">
                            <label id="LocationFormVendor-InputLabelSmall">City:</label>
                            <input type="text" id={isCityValid ? "LocationFormVendor-TextInput":"LocationFormVendor-TextInputError"} value={city} maxLength={50} onChange={handleChangeCity} />
                            {showAutoComplete()}
                        </div>
                        <div id={isCityValid ? "Hidden" : "LocationFormVendor-TextError"}>City is required</div>
                    </div>
                    <div className="LocationFormVendor-ButtonsLayout">
                        <div id="LocationFormVendor-BackButton" onClick={()=>setAccountSetupDetails({...accountSetupDetails,'step':'1'})}>Back</div>
                        <div id="LocationFormVendor-SaveButton" onClick={()=>proceedToNextStep()}>Next</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default LocationFormVendor