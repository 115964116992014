import React,{useState,useEffect,useContext} from 'react'
import '../styles/AdminDatabase.css'
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import {useFetchAllPledge} from '../ui/AdminDatabasePageViewModel.jsx'
import { Select } from 'antd';
import 'antd/dist/antd.css';
import { CSVLink } from "react-csv";

const AdminDatabase = ({}) => {

    const {data} = useFetchAllPledge()
    const [loading,setLoading] = useState(true)
   
    useEffect(()=>{
        if(data){
            setLoading(false)
        }
    },[data])

    const Requestist = []
    data && data.map((item)=> {
        const requestdata = {'Pledge Id': item.itemId,
        'Pledge Quantity' : item.pledgeQuantity, 
        'Pledge Status' : item.status,
        'Pledger Id': item.pledgerId,
        'Request Id':item.docId,
        'Request Name':item.itemName,
        'Request Specs':item.itemSpecs,
        'Request Quantity': item.quantity,
        'Request Unit':item.unit,
        'DateTime Created': item.createdAt,
        'DateTime Updated': item.updatedAt}
        Requestist.push(requestdata)
    })

    return(
        <React.Fragment>
            <div id="AdminDatabaseContainer">
                <div id="AdminDatabaseContainer-HeaderRow">
                    <div className="AdminDatabaseContainer-HeaderTitle">Pledge List</div>
                    <CSVLink data ={Requestist} filename="Wecare_PledgeList"> DOWNLOAD</CSVLink>
                </div>
                
                <div id="AdminDatabaseContainer-Body">
                {
                    loading
                    ?<div className="RequestsTable-Loading">Loading...</div>
                    :
                    <table id="RequestsTable">
                        <tbody>
                            <tr>
                                <th><div className="thRow" >Pledge Id</div></th>
                                <th><div className="thRow" >Pledge Quantity</div></th>
                                <th><div className="thRow" >Pledge Status</div></th>
                                <th><div className="thRow" >Pledger Id</div></th>
                                <th><div className="thRow">Request Id</div></th>
                                <th><div className="thRow">Request Name</div></th>
                                <th><div className="thRow">Request Specs</div></th>
                                <th><div className="thRow" >Request Quantity</div></th>
                                <th><div className="thRow">Request Unit</div></th>
                                <th><div className="thRow" >DateTime Created</div></th>
                                <th><div className="thRow" >DateTime Updated</div></th>
                            </tr>
                            {  data && data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    
                                        <td>{item.itemId}</td>
                                        <td>{item.pledgeQuantity}</td>
                                        <td>{item.status}</td>
                                        <td>{item.pledgerId}</td>
                                        <td>{item.docId}</td>
                                        <td>{item.itemName}</td>
                                        <td>{item.itemSpecs}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.unit}</td>
                                        <td>{item.createdAt}</td>
                                        <td>{item.updatedAt}</td>
                                    </tr>
                                    )
                                })
                                }
                        </tbody>
                    </table>
                }
                </div>
            </div>
        </React.Fragment>
    )
}
export default AdminDatabase