import React from 'react'
import MainMenu from '../components/MainMenu.jsx'
import AdminDatabaseUser from '../components/AdminDatabaseUser.jsx'
import Footer from '../../../../global/components/Footer.jsx'
import '../styles/AdminDatabasePage.css'

const AdminDatabaseUserPage =({})=> {
    return(
        <React.Fragment>
            <div id="AdminDatabaseUserPage">
                <AdminDatabaseUser/>
            </div>
            <Footer />
        </React.Fragment>
    )
}
export default AdminDatabaseUserPage
