import { semaphore_url } from '../ServiceUrl';

export async function sendMessageNotification(data){
	const options = {
		method : 'POST',
		body   : JSON.stringify({
			number  : data.number,
			message : data.message,
		}),
	};
	const response = await fetch(
		`${semaphore_url}?number=${data.number}&message=${data.message}`,
		options,
	)
		.then((item) => console.log(item, 'sendNotificationSuccess'))
		.catch((e) => console.log(e, 'sendNotificationError'));
	return response;
}
