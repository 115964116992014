import React from 'react'
import {withRouter} from 'react-router-dom'
import LoginPage from '../components/LoginPage.jsx'
import LandingMainMenu from '../components/LandingMainMenu.jsx'
import Footer from '../../../../global/components/Footer.jsx'
import '../styles/LandingPage.css'
import ReactGa from 'react-ga';
import InitializeReactGa from '../../../../global/services/googleanalytics/index.jsx';

const LandingPage =()=> {
    InitializeReactGa();
    ReactGa.pageview(window.location.pathname + window.location.search);

    return (
        <React.Fragment>
           
            <div id="LandingPage">
                <LandingMainMenu />
                <LoginPage />    
            </div>
            <div id="LandingPage-Footer"><Footer /></div>
        </React.Fragment>
    )
}
export default withRouter(LandingPage)