import React from 'react'
import UserProfile from '../components/UserProfile.jsx'
import Footer from '../../../../global/components/Footer.jsx'
import '../styles/MyAccountPage.css'

const MyAccountPage =()=> {

    return(
        <React.Fragment>
            <div id="MyAccountPage">
               <UserProfile />
            </div>
            <Footer />
        </React.Fragment>
    )
}
export default MyAccountPage