import React from 'react'
import Bpi from "../../../../assets/images/column/bpi.png";
import Bdo from "../../../../assets/images/column/bdo.png";
import { Button, Header, Icon, Image, Modal } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css'
const ModalDonateCash =()=> {

    const [open, setOpen] = React.useState(false)

    const imageDimension = {
      width: "100px",
      height: "auto"
    };  
    return (
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                size="tiny"
                closeOnDimmerClick = {false}
                trigger = {<button className="button basic-orange flex-fixed-bottom">Donate Cash</button>}
                >
                <Modal.Header>
                    <div class="right-align">
                    <Icon name="close" link onClick={() => setOpen(false)}></Icon>
                    </div>
                </Modal.Header>
                <Modal.Content >
                    <h4 class="center-align">
                        WeCARE partnered with ABS-CBN Foundations to allow your cash donations to be fulfilled. 
                    </h4>
                    <p class="italic mrg-bt-10 mrg-top-20">
                        You may now donate thru the following:
                    </p>
                    <img src={Bpi} style={imageDimension} class="mrg-bt-20"/>
                    <p className="mrg-bt-15">
                        Bank Branch: 
                        <p className="bold">Sct Limbaga</p>
                    </p>
                    <p className="mrg-bt-15">
                    Bank Complete Address: 
                    <p className="bold">GF Scout Center, Scout Limbaga cor Sct Tobias St Brgy Laging Handa Quezon City</p>
                    </p>
                    <p className="mrg-bt-15">
                    Account Name: 
                    <p className="bold">ABS-CBN Lingkod Kapamilya Foundation, Inc.</p>
                    </p>
                    <p className="mrg-bt-15">
                    Account Number:
                    <p className="bold">4221-0000-27</p>
                    </p>
                    <p className="mrg-bt-15">
                    Swift Code:
                    <p className="bold">OPIPHMM</p>
                    </p>
                    <br/>
                    <img src={Bdo} style={imageDimension} class="mrg-bt-20"/>
                    <p className="mrg-bt-15">
                        Bank Branch: 
                        <p className="bold">Sct Albano </p>
                    </p>
                    <p className="mrg-bt-15">
                    Bank Complete Address: 
                    <p className="bold">Georgetown Cyber Mall, Rodolfo N. Pelaez Blvd.Kaswagan, Cagayan de Oro, Misamis Oriental</p>
                    </p>
                    <p className="mrg-bt-15">
                    Account Name: 
                    <p className="bold">ABS-CBN Lingkod Kapamilya Foundation, Inc.</p>
                    </p>
                    <p className="mrg-bt-15">
                    Account Number:
                    <p className="bold">0066000-71981</p>
                    </p>
                    <p className="mrg-bt-15">
                    Swift Code:
                    <p className="bold">BNORPHMM</p>
                    </p>
                    <p className="mrg-bt-15">
                    Also, <span className="bold">Cash donations</span>  received by the <span className="bold">ABS-CBN Foundation</span>  will be duly receipted. To receive a copy of your Official Receipt please send a scanned copy of your deposit slip or for online transfers, a confirmation notice to <span className="bold">lalara@abs-cbnfoundation.com</span>  with the following information
                    </p>
                    <p className="mrg-bt-15">
                        <ul class="mrg-left-15">
                            <li>Donor’s Registered Name</li>
                            <li>Donor’s Registered Address</li>
                            <li>Donor’s TIN</li>
                        </ul>
                    </p>
                    <p>
                        <span className="bold">Maraming Salamat!</span>
                    </p>
                    <p className="italic">
                        -WeCare Team
                    </p>
                    <div className="mrg-top-15 center-align">
                        <p>
                        Please follow us on facebook    <br/>  
                        <a href="https://www.facebook.com/WeCAREPlatform/" className="orange-color">https://www.facebook.com/WeCAREPlatform/</a>
                        </p>
                    </div>
                </Modal.Content>
            </Modal>
    )
}
export default ModalDonateCash