import firebase from "firebase/app";
import React,{useState,useEffect,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/PledgeForm.css'
import {db} from "../services/firebase";
import { getUserFacebookProfile } from "../../pages/main/screens/ui/AccountSetupPageViewModel";
import { useFetchMyAccount } from '../../pages/main/screens/ui/MyAccountPageViewModel.jsx'
import { sendMessageNotification } from '../../global/services/sephamore'
import ReactGa from 'react-ga'
import InitializeReactGa from '../services/googleanalytics/index.jsx'
import { timeStamp } from '../services/moment'

const PledgeForm =()=>{
    const {modalDetails,setModalDetails} = useContext(ModalState)

    const [quantity,setQuantity] = useState('')
    const [loading,setLoading] = useState(false)
    const { data } = useFetchMyAccount();
    InitializeReactGa();

    const [city, setCity] = useState('')
    const [value, setValue] = useState([]);
    const [isCityValid,setIsCityValid] = useState(false)
    const [hide, setHide] = useState(true);
    const [resultValue, setResultValue] = useState();
    const { hospitalCityMunicipality } = !resultValue ? "" : JSON.parse(resultValue);

    function handleChangeQuantity(e){
        setQuantity(e.target.value)
    }

    function sendEmail(item, unit) {
        const smsData = {
            number: modalDetails.pledgee.mobileNumber,
            message:`Someone shows his/her care and just posted a pledge of ${quantity} ${unit} of ${item} to you and your hospital.You can now connect with ${data.firstName} to arrange the transport of the package.`,
        }
        sendMessageNotification(smsData);
        return db.collection('mail').add({
            from:'support@wecare.ph',
            to: modalDetails.pledgee.emailAddress,
            bcc: "support@wecare.ph",
            message: {
              subject: 'WeCare Philippines',
              html: '<b>Someone shows his/her care!</b> <br/><br/>'+` ${data.firstName}`+" "+`${data.lastName} `+ "just posted a pledge of"+` ${quantity} `+`${unit} `+`of ${item} `+ "to you and your hospital.<br/> You can now connect with"+` ${data.firstName}`+` ${data.lastName} `+"to arrange the transport of the package.<br/><br/><i>- WeCare Team -</i><br/>Please follow us on facebook<br/> https://www.facebook.com/WeCAREPlatform/",
            },
          });
    }

    function handleChangeCity(e) {
        let hospital_list = [];
        setHide(false);
        setCity(e.target.value)
        if (e.target.value.length === 0) {
            setHide(true);
           return setValue(null);
       } 
       else 
       {
           db.collection("philippineshospitals")
               .where('city_municipality', '>=', e.target.value.toUpperCase()).where('city_municipality', '<=', e.target.value.toUpperCase()+ '\uf8ff')
               .get().then((querySnapshot)=>{
                   querySnapshot.forEach((doc)=>{
                       hospital_list.push({
                           hospitalCityMunicipality: doc.data().city_municipality,
                       })
   
                   })
                 setValue(hospital_list)
               })
       }
    }
    function handleClicklAutoComplete(e) {
        setHide(true);
        return removeDuplicates.filter(function(item) {
           setResultValue(e.currentTarget.dataset.league);
        });
    }
    function showAutoComplete() {
                return hide?"":
                <ul>
                    {
                        !removeDuplicates ? "" : removeDuplicates.map((item, i) => {
                            return <li key={i}  data-league={JSON.stringify(item)} onClick={(e)=>handleClicklAutoComplete(e)}>{item.hospitalCityMunicipality}</li>
                        })
                    } 
                </ul>
    }

    async function populateCity() {
        setCity(hospitalCityMunicipality);
    }

    function closeForm(){
        setQuantity('')
        setModalDetails({...modalDetails,'state':false})
    }

    async function pledgeItem(data){
        setLoading(true)
        const progress = Number(modalDetails.data.progress) + Number(quantity)
        const newData = {...data, pledgeQuantity: quantity}
        ReactGa.event({category: 'Submit Pledge', action: 'Click submit button on My Pledgee Modal', label: 'Submit'})
        if(quantity === "" || Number(quantity) === 0){
            setLoading(false)
            setQuantity('')
            setModalDetails({...modalDetails,'data':'InvalidPledge','state':false})
        }
        else if(progress > modalDetails.data.quantity){
            setLoading(false)
            setQuantity('')
            setModalDetails({...modalDetails,'data':'InvalidPledge','state':false})
        }
        else{
            sendEmail(newData.itemName,newData.unit);
            var docRef = db.collection("requestlist").doc(data.docId);
            db.runTransaction(function(transaction) {
                return transaction.get(docRef).then(function(sfDoc) {
                    if (!sfDoc.exists) {
                        throw "Document does not exist!";
                    }
                    saveToPledgeList(newData) 
                    var newProgress = progress;
                    transaction.update(docRef, { progress: newProgress,updatedAt:timeStamp });
                });
            }).then(async function() {
                if(city !== "" && city !== undefined && city !== null){
                    var docRef = db.collection("users").doc(modalDetails.pledger.uid);
                    await db.runTransaction(function(transaction) {
                        return  transaction.get(docRef).then(function(sfDoc) {
                             if (!sfDoc.exists) {
                                 throw "Document does not exist!";
                             }
                             transaction.update(docRef, { city: city});
                          }); 
                 
                    }).then(function (res) {
                        setLoading(false)
                        setQuantity('')
                        setModalDetails({...modalDetails,'data':'PledgeSuccess','state':false})
                     }).catch(function(err) {
                        setLoading(false)
                        setQuantity('')
                        setModalDetails({...modalDetails,'data':'PledgeFailed','state':false})
                     });
                }
                else{
                    setLoading(false)
                    setQuantity('')
                    setModalDetails({...modalDetails,'data':'PledgeSuccess','state':false})
                }
            }).catch(function() {
                if(city !== "" && city !== undefined && city !== null){

                }
                else{
                    setLoading(false)
                    setQuantity('')
                    setModalDetails({...modalDetails,'data':'PledgeFailed','state':false})
                }
            });
        }

}

    function saveToPledgeList(data) {

        firebase.auth().onAuthStateChanged(async function(user) {
            if (user) {
                console.log(user)
                const uid = user.uid
                data.pledgerId =  uid
                data.createdAt = timeStamp
                data.updatedAt = timeStamp
                data.status = "Packing"
                db.collection("pledgelist")
                    .add(data)
                    .then(function() {
                    })
                .catch(function(error) {
                    console.log("Failed to login",error)
                });
            } else {
                console.log("No User Found!")
            }
        })
    }
    
    useEffect(() => {
        populateCity();
    }, [hospitalCityMunicipality])

    const removeDuplicates = !value?"":value.filter(
        (ele, ind) =>
          ind === value.findIndex(elem => elem.hospitalCityMunicipality === ele.hospitalCityMunicipality) &&
          ele.hospitalCityMunicipality !== null &&
          ele.hospitalCityMunicipality !== ""
      );

    return(
        <React.Fragment>
            <div id="PledgeForm">
                <div className="PledgeForm-Header">
                    <div className="PledgeForm-PreHeaderTitle">We want to help</div>
                    <div className="PledgeForm-HeaderTitle">Request information</div>
                </div>
                
                <div className="PledgeForm-Body">
                    <div className="PledgeForm-BodySection">
                        <div className="PledgeForm-InputLayout">
                            <label className="PledgeForm-InputLabelSmall">Item</label>
                            <div className="PledgeForm-InputValue">{modalDetails.data.itemName}</div>
                        </div>

                        <label className="PledgeForm-InputLabelSmall">Item Specification</label>
                        <div className="PledgeForm-InputValue">{modalDetails.data.itemSpecs}</div>
                    </div>

                    <div className="PledgeForm-BodySection">
                        <label className="PledgeForm-InputLabelSmall">Pledge quantity in {modalDetails.data.unit}:</label>
                        <input type="number" className="PledgeForm-NumberInput" value={quantity}
                        onKeyDown={(e)=>(e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-') && e.preventDefault()} 
                        onChange={handleChangeQuantity} />
                    </div>

                    {
                        modalDetails.pledger.city === "" || modalDetails.pledger.city === undefined || modalDetails.pledger.city === null
                        ?
                        <div className="PledgeForm-BodySection">
                            <div className="PledgeForm-Notice">
                                We want to improve your experience in the platform. By entering your CITY or TOWN, 
                                we can recommend institutions near you and hopefully allow better pledge fulfillment 
                                with your chosen hospitals. Maraming Salamat!
                            </div>
                            <div className="PledgeForm-AutoComplete">
                                <label className="PledgeForm-InputLabelSmall">City or town</label>
                                <input type="text" className="PledgeForm-TextInput" value={city} maxLength={50} onChange={handleChangeCity} />
                                {showAutoComplete()}
                            </div>
                        </div>
                        : null
                    }
                </div>

                {
                    loading
                    ?
                    <div className="AddRequest-Footer">
                        <div className="PledgeForm-CancelButtonDisabled">Cancel</div>
                        <div className="PledgeForm-SubmitButtonDisabled">Submit</div>
                    </div>
                    :
                    <div className="AddRequest-Footer">
                        <div className="PledgeForm-CancelButton" onClick={()=>closeForm()}>Cancel</div>
                        <div className="PledgeForm-SubmitButton" onClick={()=>pledgeItem(modalDetails.data)}>Submit</div>
                    </div>
                }
                
            </div>
        </React.Fragment>
    )
}
export default PledgeForm