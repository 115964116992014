import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import WarningIcon from '../../assets/ic_error.png'
import '../styles/CancelListingPrompt.css'
import {db} from "../services/firebase";

const CancelListingPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const [loading,setLoading] = useState(false)

    function cancelListing(){

        setLoading(true)

        const data = modalDetails.data

        db.collection("listingslist").doc(data.listingId).delete().then(function() {
            setLoading(false)
            setModalDetails({...modalDetails,'data':'CancelListingSuccess','state':false})
        }).catch(function(error) {
            setLoading(false)
            setModalDetails({...modalDetails,'data':'CancelListingFail','state':false})
        });

    }

    return(
        <React.Fragment>
            <div id="CancelListingPrompt">
                <div className="CancelListing-Body">
                    <img src={WarningIcon} />
                    <div className="CancelListing-Title">Remove Listing</div>
                    <div className="CancelListing-Description">Are you sure you want to remove this item listing?</div>
                </div>
                {
                    loading
                    ?
                    <div className="CancelListing-Footer">
                        <div className="CancelListing-CancelButtonDisabled">No</div>
                        <div className="CancelListing-SubmitButtonDisabled">Yes</div>
                    </div>
                    :
                    <div className="CancelListing-Footer">
                        <div className="CancelListing-CancelButton" onClick={()=>setModalDetails({...modalDetails,'state':false})}>No</div>
                        <div className="CancelListing-SubmitButton" onClick={()=>cancelListing()}>Yes</div>
                    </div>
                }
                
            </div>
        </React.Fragment>
    )
}
export default CancelListingPrompt