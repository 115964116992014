import React from 'react'
import HeroAbsHotline2 from "../../../../assets/images/column/abs-hotline2.png";
import HeroAbsHotline3 from "../../../../assets/images/column/abs-hotline3.png";
import { Button, Header, Icon, Image, Modal } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css'
const ModalAnnouncement =()=> {

    const [open, setOpen] = React.useState(false)

    return (
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                closeOnDimmerClick = {false}
                trigger = {<button className="button orange mrg-top-10">Donate Now</button>}
                >
                <Modal.Header>
                    <div class="right-align">
                    <Icon name="close" link onClick={() => setOpen(false)}></Icon>
                    </div>
                </Modal.Header>
                <Modal.Content image className="img-uly">
                    <div>
                        <img src={HeroAbsHotline2} class="img-full-width"/>
                    </div>
                    <br/>
                    <div>
                    <img src={HeroAbsHotline3} class="img-full-width"/>
                    </div>
                </Modal.Content>
            </Modal>
    )
}
export default ModalAnnouncement