import React from 'react';
import { useParams } from 'react-router-dom';
import LoginUsingEmailPage from '../components/LoginUsingEmailPage';
import RegisterPage from '../components/RegisterPage';
import ResetEmailPasswordPage from '../components/ResetEmailPasswordPage';

export default () => {
    let { landingPageId } = useParams();
    switch (landingPageId) {
        case 'email':
            return <LoginUsingEmailPage />;
        case 'register':
            return <RegisterPage />;
        case 'reset-email-password':
            return <ResetEmailPasswordPage /> ;
        default:
            return window.location.href= "/"; 
    }
}
