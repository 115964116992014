import React from 'react'
import '../styles/PledgerBanner.css'
const PledgerBanner =()=> {

    return(
        <React.Fragment>
            <div id="PledgerBanner">
                <div id="PledgerBannerLeft">
                    <div id="PledgerBannerTitle">Even the smallest contribution can make an impact.</div>
                    <div id="PledgerBannerDescription">Pledge your support now and be one with our frontline teams in their fight against COVID-19!</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default PledgerBanner