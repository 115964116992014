import React from 'react'
import SuppliesTable from '../components/SuppliesTable.jsx'
import VendorBanner from '../components/VendorBanner.jsx'

const VendorDirectoryPage =({user})=> {

    return(
        <React.Fragment>
            <VendorBanner />
            <SuppliesTable user={user} />
        </React.Fragment>
    )
}
export default VendorDirectoryPage