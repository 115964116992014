import React,{useState,useEffect} from 'react'
import '../styles/DonorHomePageForm.css'
import {useFetchStatisticsRequests} from '../ui/StatisticsPageViewModel.jsx'
import { dateFormatOnly } from '../../../../global/services/moment'

const DonorHomePageForm =()=>{

    const [fetchTrigger,setFetchTrigger] = useState(0)
    const {data, loading} = useFetchStatisticsRequests(fetchTrigger)
    const [pledgelistCount,setPledgelistCount] = useState('')
    const [wantsToHelpCount,setWantsToHelpCount] = useState('')
    const [requestTotal,setQuantityTotal] = useState('')
    const [hospitalCount, setHospitalCount] = useState('')

    useEffect(()=>{
        if(data){
            setPledgelistCount(new Intl.NumberFormat('EN-US').format(data.pledgelistCount))
            setWantsToHelpCount(new Intl.NumberFormat('EN-US').format(data.wantsToHelpCount))
            setQuantityTotal(new Intl.NumberFormat('EN-US').format(data.requestTotal))
            setHospitalCount(new Intl.NumberFormat('EN-US').format(data.hospitalCount))
        }
    },[data])

    return(
        <React.Fragment>
            <div id="DonorHome">
                <div id="DonorHomeContainer">
                    <div className="DonorHome-Header">
                    </div>
                    {
                        loading
                        ? <div className="DonorHome-Loading">Loading...</div>
                        :
                        <div className="DonorHome-Body">
                            <div className="DonorHome-InputLayout">
                                <div className="DonorHome-InputLeft">
                                    <label className="DonorHome-HeaderTitle">Your donations will go a long way in helping the nation fight COVID-19. Maraming Salamat!</label>
                                </div>
                            </div>

                            <div className="DonorHome-InputLayout">
                                <p className="DonorHome-ParagraphStyle">
                                    We are happy to share that as of <span className="DoneHome-HighLightInfo">{dateFormatOnly}</span>, 
                                    WeCare has received <span className="DoneHome-HighLightInfo">{pledgelistCount} pledges</span>, 
                                    equivalent to <span className="DoneHome-HighLightInfo">{requestTotal} pledged items</span> for our medical frontlines 
                                    teams. Many of these consists of PPEs, surgical masks, alcohol, food, face shields
                                    and many more.
                                </p>
                                <p className="DonorHome-ParagraphStyle">
                                    Kabilang ka sa <span className="DoneHome-HighLightInfo">{wantsToHelpCount} pledgers</span> na tumulong sa
                                    <span className="DoneHome-HighLightInfo"> {hospitalCount} Hospitals</span> na 
                                    naka-register sa WeCare. Muli, Maraming Salamat!
                                </p>
                            </div>
                        </div>
                    }
                </div>    
            </div>
        </React.Fragment>
    )
}
export default DonorHomePageForm