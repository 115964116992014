import {useState,useEffect}  from 'react'
import Footer from '../../../../global/components/Footer.jsx'
import {db} from "../../../../global/services/firebase";

const getFAQ= async()=> {

    let faq = []
    
    await db.collection("faq").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const item = {...docData,itemId:doc.id}
            faq.push(item)
            console.log("faq",faq)
        })
    })
    .catch(function(error) {
        console.log("Error getting beneficiaries: ", error);
    });

    return faq
}


export const useFetchAllFaq= (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchAllfaq(){
            const faqList = await getFAQ()
            console.log("fetchfaq",faqList)
            setState({data:faqList,loading:false})
        }
        
        fetchAllfaq()

    },[event])

    return state
};


const getTermsAndCondition= async()=> {

    let tc = []
    
    await db.collection("termsandconditions").get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const item = {...docData,itemId:doc.id}
            tc.push(item)
            console.log("tc",tc)
        })
    })
    .catch(function(error) {
        console.log("Error getting beneficiaries: ", error);
    });

    return tc
}


export const useFetchAllTc= (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchAllTc(){
            const tcList = await getTermsAndCondition()
            console.log("fetchftc",tcList)
            setState({data:tcList,loading:false})
        }
        
        fetchAllTc()

    },[event])

    return state
};