import React,{useState, useContext, useEffect} from 'react'
import {db} from "../../../../global/services/firebase";
import { Select } from 'antd';
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import '../styles/PledgersListTable.css'
import {useFetchItemPledgersList,getPledgerProfiles} from '../ui/MyRequestsPageViewModel.jsx'
import { sendMessageNotification } from './../../../../global/services/sephamore'

const PledgersListTable =({item})=> {

    const [modalDetails,setModalDetails] = useState(useContext(ModalState))
    const [loading,setLoading] = useState(true)
    const [itemPledgersList,setItemPledgersList] = useState(null)
    const [fetchTrigger,setFetchTrigger] = useState(0)

    const {data} = useFetchItemPledgersList(fetchTrigger,item.itemId)

    async function populateItemPledgersList(pledgerList){
        let itemPledgers = []     
        await pledgerList.map(async (pledger) =>{
            await db.collection("users").doc(pledger.pledgerId)
            .get()
            .then(function(doc) {
                if(doc.exists){
                    const newPledger = {...pledger,pledgerProfile:doc.data()}
                    itemPledgers.push(newPledger)
                    if(itemPledgers.length === pledgerList.length){
                    
                        setItemPledgersList(itemPledgers)
                        setLoading(false)
                    }
                }
                else{
                    throw "Document does not exist";
                }
            })
            .catch(function(error) {
                return itemPledgers
            });

            
        })
    }
    function closeModal(e){
        if (e.target.className === "modalOpen") {
            setModalDetails({...modalDetails,'state':false})
        }
    }

    useEffect(()=>{
    
        if(data){
            if(data.length > 0)
            {
                setLoading(true)
                populateItemPledgersList(data)
            }
            else{
                setLoading(false)
            }
        } 
    },[data])
    console.log(modalDetails)
    useEffect( ()=>{
        if(modalDetails.data === "CompletePledgeSuccess"){
            const smsData = {
                number: modalDetails.pledger.pledgerProfile.mobileNumber,
                message: `Your WeCARE package was received by the ${modalDetails.pledger.location}! We thank you for your care and your contribution to our nation's fight against Covid-19. Stay safe at maraming salamat.`,
            }
             sendMessageNotification(smsData);
             db.collection('mail').add({
                from:'support@wecare.ph',
                to: modalDetails.pledger.pledgerProfile.emailAddress,
                bcc: "support@wecare.ph",
                message: {
                  subject: 'WeCare Philippines',
                  html: "Your WeCARE package was received by"+` ${modalDetails.pledger.location}`+ " <br/> We thank you for your care and your contribution to our nation's fight against Covid-19.<br/><br/> <b>Stay safe at maraming salamat.</b><br/><br/><i>- WeCare Team -</i><br/>Please follow us on facebook<br/> https://www.facebook.com/WeCAREPlatform/",
                },
              });
           setFetchTrigger(fetchTrigger+1)
        }
    },[modalDetails.data])

    return(
        <React.Fragment>
            <div className={modalDetails.state ? "modalOpen":"modalClose"} onClick={(e)=>closeModal(e)}>
                <ModalState.Provider value={{modalDetails,setModalDetails}}>
                    <Modal />
                </ModalState.Provider>
            </div>
            <div id="PledgersListTableContainer">
                <div className="PledgersListTable-HeaderTitle">Pledgers List</div>
                <div id="PledgersListTable-ProgressRow">
                    <div className="PledgersListTable-ProgressRowLeft">
                        <div className="PledgersListTable-Pretitle">Request</div>
                        <div className="PledgersListTable-Title">{item.itemName}</div>
                        <div className="PledgersListTable-Subtitle">{item.itemSpecs}</div>
                    </div>
                    <div className="PledgersListTable-ProgressRowRight">
                        <div className="PledgersListTable-ProgressValueLayout">
                            <div className="PledgersListTable-ProgressLabel">Progress</div>
                            <div className="PledgersListTable-ProgressValue">{item.progress}/{item.quantity} {item.unit}</div>
                        </div>
                        <progress 
                            id={`${Number(item.progress) === Number(item.quantity) ? "PledgersListTable-ProgressBarComplete" : "PledgersListTable-ProgressBar"}`}
                            value={item.progress} max={item.quantity} />
                    </div>
                </div>
                <div id="PledgersListTable-Contents">
                    {
                        loading
                        ? <div className="PledgersListTable-Loading">Loading...</div>
                        :
                        <div>
                            {
                                itemPledgersList && itemPledgersList.length > 0 
                                ?
                                <table id="PledgersListTable">
                                    <tbody>
                                        <tr>
                                            <th><div className="thRow">Quantity</div></th>
                                            <th><div className="thRow">Unit</div></th>
                                            <th><div className="thRow">Pledger</div></th>
                                            <th style={{width:150}}><div className="thRow" >Status</div></th>
                                            <th style={{width:260}}><div className="thRowLast">Action</div></th>
                                        </tr>
                                        {
                                        itemPledgersList.map((item,index) => {
                                            return <tr key={index}>
                                                    <td>{item.pledgeQuantity}</td>
                                                    <td>{item.unit}</td>
                                                    <td>{item.pledgerProfile.firstName} {item.pledgerProfile.lastName}</td>
                                                    <td>{item.status}</td>
                                                    <td>
                                                        <div className="PledgersListTable-ActionButtonsLayout">
                                                            <div className="PledgersListTable-ActionButtonsLayout-Left">
                                                                <div className="PledgersListTable-ActionButton-ContactPledger" onClick={()=>setModalDetails({'type':'PledgerInformation','data':item.pledgerProfile,'state':true})}>Contact pledger</div>
                                                            </div>
                                                            <div className="PledgersListTable-ActionButtonsLayout-Right">
                                                                {
                                                                    item.status === "Received"
                                                                    ?<div className="PledgersListTable-ActionButton-MarkCompleteDisabled">Mark as complete</div>
                                                                    :<div className="PledgersListTable-ActionButton-MarkComplete" onClick={()=>setModalDetails({'type':'MarkAsComplete','data':item,'state':true})}>Mark as complete</div>

                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                :
                                <div className="PledgersListTable-RequestsPlaceholder">This item has no pledgers yet. </div>
                            }
                        </div>               
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default PledgersListTable