import * as _ from "lodash";
import React, { useState, useEffect, useContext } from "react";
import "../styles/RequestsTable.css";
import Modal from "../../../../global/components/Modal.jsx";
import { ModalState } from "../../../../global/services/Context.jsx";
import {
  getCommunityRequests,
  useFetchCommunityRequests,
} from "../ui/CommunityRequestsPageViewModel.jsx";
import { Select } from "antd";
import "antd/dist/antd.css";
import FilteredData from "./FilteredData";
import Footer from "../../../../global/components/Footer";
import ReactGa from "react-ga";
import InitializeReactGa from "../../../../global/services/googleanalytics/index.jsx";
import { useFetchMyAccount } from "../ui/MyAccountPageViewModel.jsx";
import { CSVLink } from "react-csv";
import { csvHeaders } from "../../../../global/services/ReactCSV-constant";
import DownloadLink from "../../../../assets/download_file.svg";
import BlueBackButton from "../../../../assets/blue_back_button.png";
import PledgedItemListTable from "../components/PledgedItemList.jsx";
import { db } from "../../../../global/services/firebase";

const RequestsTable = () => {
  const [modalDetails, setModalDetails] = useState(useContext(ModalState));
  const [myUser, setMyUser] = useState(null);
  const [search, setSearch] = useState("");
  const [currentFilter, setCurrentFilter] = useState("MostRecent");
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [loading, setLoading] = useState(true);

  const { data } = useFetchMyAccount(fetchTrigger);

  const [rawData, setRawData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);

  const [firstPage, setFirstPage] = useState(0);
  const [lastPage, setLastPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [csvMostRecentData, setCsvMostRecentData] = useState([]);
  const [csvAlphabeticalData, setCsvAlphabeticalData] = useState([]);

  const indexOfLastData = currentPage * 10;
  const indexOfFirstData = indexOfLastData - 10;
  const tableData = dataToDisplay.slice(indexOfFirstData, indexOfLastData);
  const dataToDisplayCount = dataToDisplay.length;
  const tableDataCount = tableData.length;

  const [pledgedItemListVisible, setPledgedItemListVisible] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [itemRequestList, setRequestlist] = useState(null);

  InitializeReactGa();

  const pages = [];
  for (let x = 1; x <= Math.ceil(dataToDisplay.length / 10); x++) {
    pages.push(x);
  }
  const numberOfPages = pages.length;

  const pagesToBeRendered = pages.slice(firstPage, lastPage);

  async function fetchTableData(user) {
    setLoading(true);
    const tableData = await getCommunityRequests(user.uid);
    await populateItemPledgedList(tableData, user.uid);

    if (tableData && tableData.length > 0) {
      setRawData(tableData);
      setDataToDisplay(tableData);
      searchTableData(tableData);
    }
  }

  async function populateItemPledgedList(requestList, userId) {
    let itemRequestDetails = [];
    await requestList.map(async (pledges) => {
      var pledgedItemListByItem = db
        .collection("pledgelist")
        .where("docId", "==", pledges.docId);
      var pledgedItemListByUser = pledgedItemListByItem.where(
        "pledgerId",
        "==",
        userId
      );
      var hasPledgedItem = (await pledgedItemListByUser.get()).size;

      var pledgedItemId = pledges.docId;
      const itemRequestWithPledgedCtr = {
        docId: pledges.docId,
        [pledgedItemId]: hasPledgedItem,
      };
      itemRequestDetails.push(itemRequestWithPledgedCtr);
      if (itemRequestDetails.length === requestList.length) {
        setLoading(false);
        setRequestlist(itemRequestDetails);
        return itemRequestDetails;
      }
    });
  }

  async function searchTableData(data) {
    const searchedData = await data.filter((data) => {
      if (search === null || search.length <= 0 || loading) return data;
      else if (
        data.itemName.toLowerCase().includes(search.toLowerCase()) ||
        data.location.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        (data.city !== undefined &&
          data.city.toLowerCase().includes(search.toLowerCase())) ||
        (data.province !== undefined &&
          data.province.toLowerCase().includes(search.toLowerCase()))
      ) {
        setCurrentPage(1);
        return data;
      }
    });
    if (currentFilter === "NearMe") {
      filterTableDataNearest(myUser, searchedData);
    } else if (currentFilter === "MostRecent") {
      filterTableDataMostRecent(searchedData);
    } else {
      setCsvAlphabeticalData(searchedData);
      setDataToDisplay(searchedData);
    }
  }

  async function filterTableDataNearest(user, data) {
    const filteredTableData = data.filter((dataItem) => {
      if (
        !_.isEmpty(dataItem.city) &&
        !_.isEmpty(dataItem.province) &&
        !_.isEmpty(user.city) &&
        !_.isEmpty(user.province)
      ) {
        if (
          dataItem.city.toLowerCase().includes(user.city.toLowerCase()) &&
          dataItem.province.toLowerCase().includes(user.province.toLowerCase())
        ) {
          return dataItem;
        }
      }
    });
    setDataToDisplay(filteredTableData);
  }

  async function filterTableDataMostRecent(data) {
    let itemsWithoutTimestamp = [];
    const itemsWithTimestamp = await data.filter((dataItem) => {
      if (dataItem.createdAt === undefined || dataItem.createdAt === null) {
        itemsWithoutTimestamp.push(dataItem);
        return;
      } else {
        return dataItem;
      }
    });
    const filteredTableData = itemsWithTimestamp
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .concat(itemsWithoutTimestamp);
    setCsvMostRecentData(filteredTableData);
    setDataToDisplay(filteredTableData);
  }

  async function searchSpace(event) {
    let keyword = event.target.value;
    return await setSearch(keyword);
  }

  function handlePreviousPage() {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(Number(newPage));
      if (firstPage === 0) {
      } else {
        if (newPage === firstPage + 2) {
          setFirstPage(newPage - 3);
          setLastPage(newPage + 2);
        }
      }
    } else {
    }
  }

  function handleNextPage(e) {
    const newPage = currentPage + 1;
    if (newPage <= numberOfPages) {
      setCurrentPage(Number(newPage));
      if (lastPage === numberOfPages) {
      } else {
        if (newPage === lastPage - 1) {
          setFirstPage(newPage - 3);
          setLastPage(newPage + 2);
        }
      }
    } else {
    }
  }
  function handlePageChange(e) {
    const newPage = Number(e.target.id);
    setCurrentPage(newPage);

    if (numberOfPages > 5) {
      const nFirstPage = newPage - 3;
      const nLastPage = newPage + 2;
      if (nFirstPage < 0) {
        setFirstPage(0);
        setLastPage(5);
      } else if (nLastPage > numberOfPages) {
        setFirstPage(numberOfPages - 5);
        setLastPage(numberOfPages);
      } else {
        setFirstPage(nFirstPage);
        setLastPage(nLastPage);
      }
    }
  }

  async function pledgeItem(item, user, result) {
    setModalDetails({
      type: "Pledge",
      state: true,
      data: item,
      pledger: user,
      pledgee: result,
    });
    ReactGa.event({
      category: "Pledge Item",
      action: "Click pledge on Community Request",
      label: "Pledge",
    });
  }
  async function pledgerDonateCash() {
    setModalDetails({
      type: "PledgerDonationCash",
      state: true,
      data: "",
      pledger: "",
    });
  }
  async function viewRequestImage(item) {
    setModalDetails({ type: "ViewRequestImage", state: true, data: item });
    ReactGa.event({
      category: "View Request Image Item",
      action: "Click view request image on Community Request",
      label: "View Request Image",
    });
  }

  function editPledgedItem(item, user, enableClass) {
    var isEnabled = document.getElementById(enableClass).className;
    if (isEnabled == "RequestsTable-EditActionButton") {
      setPledgedItemListVisible(true);
      setItemDetails(item);
      setUserDetails(user);
    }
  }

  function closeModal(e) {
    if (e.target.className === "modalOpen") {
      setModalDetails({ ...modalDetails, state: false });
    }
  }
  useEffect(() => {
    searchTableData(rawData);
  }, [search, currentFilter]);

  useEffect(() => {
    if (data) {
      setMyUser(data);
      fetchTableData(data);
      if (data.city === undefined || data.city === null || data.city === "") {
        setModalDetails({ type: "AddCity", data: data, state: true });
      }
    }
  }, [data]);

  useEffect(() => {
    if (modalDetails.data === "PledgeSuccess") {
      const promptDetails = {
        title: "Pledge Successful",
        message:
          "The institution will reach out to you soon. Please allow 24-48 hours for the institution to contact you.",
        icon: "Success",
      };
      setModalDetails({
        type: "GenericPrompt",
        data: promptDetails,
        state: true,
      });
      setFetchTrigger(fetchTrigger + 1);
    } else if (modalDetails.data === "InvalidPledge") {
      setModalDetails({ type: "InvalidPledge", state: true });
    } else if (modalDetails.data === "SubmitCitySuccess") {
      const promptDetails = {
        title: "City/Town & Province updated",
        message:
          'Your city/town & province will now reflect in the "My Account" page and will now affect the “Near me” search results. Please refresh your browser as well.',
        isSuccessSubmitCity: true,
        icon: "Success",
      };
      setModalDetails({
        type: "GenericPrompt",
        data: promptDetails,
        state: true,
      });
      setFetchTrigger(fetchTrigger + 1);
    }
  }, [modalDetails.state]);

  return (
    <React.Fragment>
      <div
        className={modalDetails.state ? "modalOpen" : "modalClose"}
        onClick={(e) => closeModal(e)}
      >
        <ModalState.Provider value={{ modalDetails, setModalDetails }}>
          <Modal />
        </ModalState.Provider>
      </div>
      <div id="RequestsTableContainer">
        <div
          id={`${
            pledgedItemListVisible ? "None" : "RequestsTableContainer-HeaderRow"
          }`}
        >
          <div className="RequestsTableContainer-HeaderSectionTop">
            <div className="RequestsTableContainer-HeaderTitle">
              Request List
            </div>
            <input
              type="text"
              id="RequestsTableSearch-SearchTextInput"
              placeholder="Search for an item, province, city, institution/hospital"
              onChange={(event) => searchSpace(event)}
            />
            {!data ? (
              ""
            ) : data.userType === "WantsToHelp" ? (
              <div className="RequestsTableContainer-HeaderSectionTop-Right">
                <div
                  className="RequestsTable-ActionButton-DonateCash"
                  onClick={pledgerDonateCash}
                >
                  I want to donate cash
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="RequestsTableContainer-HeaderSectionBottom">
            <div className="RequestsTableContainer-TableFilters">
              <div
                className={
                  currentFilter === "NearMe"
                    ? "RequestsTableContainer-TableFiltersActiveColumn"
                    : "RequestsTableContainer-TableFiltersInactiveColumn"
                }
                onClick={() => setCurrentFilter("NearMe")}
              >
                Near Me
              </div>
              <div
                className={
                  currentFilter === "MostRecent"
                    ? "RequestsTableContainer-TableFiltersActiveColumn"
                    : "RequestsTableContainer-TableFiltersInactiveColumn"
                }
                onClick={() => setCurrentFilter("MostRecent")}
              >
                Most Recent
              </div>
              <div
                className={
                  currentFilter === "ShowAll"
                    ? "RequestsTableContainer-TableFiltersActiveColumn"
                    : "RequestsTableContainer-TableFiltersInactiveColumn"
                }
                onClick={() => setCurrentFilter("ShowAll")}
              >
                Alphabetical(A-Z)
              </div>
            </div>
          </div>
          <div className="RequestsTableContainer-ItemCountLabel">
            Showing {tableDataCount} requests of {dataToDisplayCount}
          </div>
        </div>
        {currentFilter === "NearMe" ? (
          ""
        ) : (
          <CSVLink
            data={
              currentFilter === "MostRecent"
                ? csvMostRecentData
                : csvAlphabeticalData
            }
            filename={
              currentFilter === "MostRecent"
                ? "mostRecent_CommunityRequest"
                : "alphabetical_CommunityRequest"
            }
            headers={csvHeaders}
          >
            <div className="RequestsTable-ActionButton-Download">
              <img
                src={DownloadLink}
                className="RequestsTable-ActionButton-Icon"
                alt="downloadLinkCsv"
              />{" "}
              Download the community request
            </div>
          </CSVLink>
        )}

        <div
          id={`${pledgedItemListVisible ? "PledgersPageContainer" : "None"}`}
        >
          <div id="PledgersPage">
            <div className="PledgersPageButtonLayout">
              <img src={BlueBackButton} />
              <div
                id="PledgersPage-BackButton"
                onClick={() => setPledgedItemListVisible(false)}
              >
                Back to request list
              </div>
            </div>
            {pledgedItemListVisible ? (
              <PledgedItemListTable
                itemDetails={itemDetails}
                userDetails={userDetails}
              />
            ) : null}
          </div>
        </div>

        <div
          id={`${
            pledgedItemListVisible ? "None" : "RequestsTableContainer-Body"
          }`}
        >
          {loading ? (
            <div className="RequestsTable-Loading">Loading...</div>
          ) : (
            <div>
              <table
                id={
                  myUser.userType !== "WantsToSupply"
                    ? "RequestsTable"
                    : "RequestsTableVendor"
                }
              >
                <tbody>
                  <tr>
                    <th>
                      <div className="thRow">Item Request</div>
                    </th>
                    <th>
                      <div className="thRow">Item Specs</div>
                    </th>
                    <th>
                      <div className="thRow">Date</div>
                    </th>
                    <th>
                      <div className="thRow">City/Town</div>
                    </th>
                    <th>
                      <div className="thRow">Institution/Hospital</div>
                    </th>
                    <th>
                      <div className="thRow">Progress</div>
                    </th>
                    {/* <th style={{width:280,whiteSpace:"nowrap"}}><div className="thRowLast">Action</div></th> */}
                    {myUser &&
                    (myUser.userType === "WantsToSupply" ||
                      myUser.userType === "WantsToSupplyRejected") ? null : (
                      <th style={{ width: 360, whiteSpace: "nowrap" }}>
                        <div className="thRowLast">Action</div>
                      </th>
                    )}
                  </tr>
                  {tableData &&
                  tableData.length > 0 &&
                  itemRequestList &&
                  itemRequestList.length > 0 ? (
                    <FilteredData
                      data={tableData}
                      currentPage={currentPage}
                      onClick={pledgeItem}
                      onView={viewRequestImage}
                      user={myUser}
                      onEditPledged={editPledgedItem}
                      data2={itemRequestList}
                    />
                  ) : currentFilter !== "NearMe" ? null : myUser.city ===
                      undefined ||
                    myUser.city === null ||
                    myUser.city === "" ? (
                    <td colSpan={7} rowSpan={10}>
                      <div id="RequestsTable-PlaceHolder">
                        We cannot show any results for “Near me” since you do
                        not have a city/town registered to your account.
                        <br />
                        <br />
                        Add your city/town to your account{" "}
                        <label
                          id="RequestsTable-BlueLabel"
                          onClick={() =>
                            setModalDetails({
                              type: "AddCity",
                              data: myUser,
                              state: true,
                            })
                          }
                        >
                          here.
                        </label>
                      </div>
                    </td>
                  ) : (
                    <td colSpan={7} rowSpan={10}>
                      <div id="RequestsTable-PlaceHolder">
                        No results found
                        {search.length > 0 ? ` for ${search}` : ``} in Near Me.
                        <br />
                        <br />
                        You can try pressing the{" "}
                        <label
                          id="RequestsTable-BlueLabel"
                          onClick={() => setCurrentFilter("MostRecent")}
                        >
                          Most recent
                        </label>{" "}
                        and{" "}
                        <label
                          id="RequestsTable-BlueLabel"
                          onClick={() => setCurrentFilter("ShowAll")}
                        >
                          Alphabetical A-Z
                        </label>
                        .
                      </div>
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {pagesToBeRendered && pagesToBeRendered.length > 0 ? (
          <div
            id={`${
              pledgedItemListVisible ? "None" : "RequestsTable-Pagination"
            }`}
          >
            <ul>
              <li
                onClick={handlePreviousPage}
                className="RequestsTable-InactiveLi"
              >
                <b>{"<"}</b>
              </li>
              {pagesToBeRendered.map((page) => {
                return (
                  <li
                    key={page}
                    id={page}
                    className={
                      currentPage === page
                        ? "RequestsTable-ActiveLi"
                        : "RequestsTable-InactiveLi"
                    }
                    onClick={(e) => handlePageChange(e)}
                  >
                    {page}
                  </li>
                );
              })}
              <li onClick={handleNextPage} className="RequestsTable-InactiveLi">
                <b>{">"}</b>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
      <Footer />
    </React.Fragment>
  );
};
export default RequestsTable;
