import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import AlertIcon from '../../assets/ic_alert.png'
import SuccessIcon from '../../assets/ic_success.png'
import '../styles/GenericImagePrompt.css'


const GenericImagePrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)
    return(
        <React.Fragment>
            <div id="GenericImagePrompt">
                <div className="GenericImagePrompt-Body">
                    {modalDetails.data.icon === "Success"?<img src={SuccessIcon} />:<img src={AlertIcon} />}
                    <div className="GenericImagePrompt-Title">{modalDetails.data.title}</div>
                    <div className={modalDetails.data.messageImage !== undefined ? "GenericImagePrompt-DescriptionImage" : "GenericImagePrompt-Gone"}>
                        <img src={modalDetails.data.messageImage} />
                    </div>
                    <div className="GenericImagePrompt-Description">{modalDetails.data.message}</div>
                </div>
                <div className={modalDetails.data.notes !== undefined ? "GenericImagePrompt-AdditionalNotes" : "GenericImagePrompt-Gone"}>
                    {modalDetails.data.notes}
                </div>
                <div className="GenericImagePrompt-Footer">
                    <div className="GenericImagePrompt-GotItButton" onClick={() => setModalDetails({ ...modalDetails, 'state': false })}>Got it</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default GenericImagePrompt