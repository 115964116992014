import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import {db} from "../services/firebase";
import '../styles/VerificationPromptVendor.css'
import moment from 'moment-timezone';
import {verifyVendorAccount} from '../../pages/main/screens/ui/AccreditorVerificationListPageViewModel.jsx'

const VerificationPromptVendor =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const [tier,setTier] = useState(1)
    const [isConfirmed,setIsConfirmed] = useState(false)
    const [loading,setLoading] = useState(false)

    function clearInputs(){
        setTier(1)
        setIsConfirmed(false)
    }

    async function closePrompt(){
        clearInputs()
        setModalDetails({...modalDetails,'state':false})
    }

    async function verifyVendor(item){

        setLoading(true)
        const isUserVerified = await verifyVendorAccount(item.uid,tier)

        if(isUserVerified){
            setLoading(false)
            clearInputs()
            setModalDetails({...modalDetails,'data':'VendorVerificationSuccess','vendor':item,'state':false})
        }
        else{
            setLoading(false)
            clearInputs()
            setModalDetails({...modalDetails,'data':'VendorVerificationFailed','state':false})
        }
    }


    return(
        <React.Fragment>
            <div id="VerificationPromptVendor">
                <div className="VerificationPromptVendor-Header">
                    <div className="VerificationPromptVendor-PreHeaderTitle">Verify vendor</div>
                    <div className="VerificationPromptVendor-HeaderTitle">{modalDetails.data.locationName}</div>
                </div>

                <div className="VerificationPromptVendor-Body">
                    <label className="VerificationPromptVendor-InputLabelSmall">Vendor tier</label>
                    <label for="VerificationPromptVendor-Tier1" id="VerificationPromptVendor-RadioInputLabelLeft">
                    <input type="radio" id="VerificationPromptVendor-Tier1" name="vendorTier" checked={tier === 1 ? true : false}
                    value={1} onClick={()=>setTier(1)}/>
                    Tier 1</label>

                    <label for="Tier2" id="VerificationPromptVendor-RadioInputLabelLeft">
                    <input type="radio" id="VerificationPromptVendor-Tier2" name="vendorTier" checked={tier === 2 ? true : false}
                    value={2} onClick={()=>setTier(2)}/>
                    Tier 2</label>

                    <div id="VerificationPromptVendor-TierDescription">
                        Tier 1: Vendor is able to supply and deliver<br/>Tier 2: Vendor is able to supply only
                    </div>
                </div>

                <div className="VerificationPromptVendor-VerificationCheckbox">
                    <label for="VerificationPromptVendor-Confirmation" id="VerificationPromptVendor-ConfirmationLabel">
                    <input type="checkbox" id="VerificationPromptVendor-Confirmation" name="VerificationPromptVendorConfirmation" checked={isConfirmed} onClick={()=>setIsConfirmed(!isConfirmed)} />
                    I have reviewed the documents and credentials of the vendor</label>
                </div> 

                <div className="VerificationPromptVendor-Footer">
                    <div className="VerificationPromptVendor-CancelButton" onClick={()=>closePrompt()}>Cancel</div>
                    {
                        loading || !isConfirmed
                        ? <div className="VerificationPromptVendor-UpdateButtonDisabled">Update</div>
                        : <div className="VerificationPromptVendor-UpdateButton" onClick={()=>verifyVendor(modalDetails.data)}>Update</div>
                    }
                   
                </div>
                
            </div>
        </React.Fragment>
    )
}
export default VerificationPromptVendor