import firebase from "firebase/app";
import {db} from "../../../../global/services/firebase";
import {useState, useEffect} from 'react'
import moment from 'moment-timezone';

export const verifyVendorAccount =async(docId,tier)=>{
    let userVerificationState = false
    var date = (moment().tz("Asia/Singapore").format()).replace(/T/, ' ').replace("+08:00", '')

    var docRef = db.collection("users").doc(docId);
    await db.runTransaction(function(transaction) {
        return transaction.get(docRef).then(function(sfDoc) {
            if (!sfDoc.exists) {
                throw "Document does not exist!";
            }
            transaction.update(docRef, { emailVerified: true , userType:"WantsToSupply", tier:tier, updatedAt:date});
        });
    }).then(async function(res) {
        userVerificationState = true
        var docRef = db.collection("suppliers").doc(docId);
        await db.runTransaction(function(transaction) {
            return transaction.get(docRef).then(function(sfDoc) {
                if (!sfDoc.exists) {
                    throw "Document does not exist!";
                }
                transaction.update(docRef, { emailVerified: true , userType:"WantsToSupply", tier:tier});
            });
        }).then(function(res) {
            userVerificationState = true
        }).catch(function(err) {
            userVerificationState = false
        });
    }).catch(function(err) {
        userVerificationState = false
    });

    return userVerificationState
}

export const rejectVendorAccount =async(docId,rejectionHistory)=>{
    let userRejectedState = false
    var date = (moment().tz("Asia/Singapore").format()).replace(/T/, ' ').replace("+08:00", '')

    var docRef = db.collection("users").doc(docId);
    await db.runTransaction(function(transaction) {
        return transaction.get(docRef).then(function(sfDoc) {
            if (!sfDoc.exists) {
                throw "Document does not exist!";
            }
            
            transaction.update(docRef, { userType: "WantsToSupplyRejected", rejectionHistory: rejectionHistory, updatedAt:date});
        });
    }).then(function(res) {
        userRejectedState = true
    }).catch(function(err) {
        userRejectedState = false
    });

    return userRejectedState
}

export const findInSupplierCollection =async (docId)=>{
    
    const vendor = await db.collection("users").doc(docId)
    .get()
    .then(async function(doc) {
        if(doc.exists){
            return doc.data()
        }
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    return vendor
}

const getUnverifiedVendorsList =async()=>{
    let unverifiedVendorId = []

    await db.collection("suppliers")
    .get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            unverifiedVendorId.push(doc.id)
        })
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
    
    return unverifiedVendorId
}

export const useFetchUnverifiedVendorsList = (event) => {
    
    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{

        setState(state=>({data:state.data,loading:true}))

        async function fetchUnverifiedVendorsList(){
            const vendorIds = await getUnverifiedVendorsList()

            setState({data:vendorIds,loading:false})
        }
        
        fetchUnverifiedVendorsList()
        
    },[event])

    return state
};


