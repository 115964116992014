import React from 'react'
import PledgesTracker from '../components/PledgesTracker.jsx'
import {db} from "../../../../global/services/firebase";
import Footer from '../../../../global/components/Footer.jsx';
import '../styles/MyPledgesPage.css'
import ReactGa from 'react-ga';
import InitializeReactGa from '../../../../global/services/googleanalytics/index.jsx';

const MyPledgesPage =({uid})=> {
    InitializeReactGa();
    ReactGa.pageview(window.location.pathname + window.location.search);

    return(
        <React.Fragment>
            <div id="MyPledgesPage">
                <PledgesTracker />
            </div>
            <Footer />
        </React.Fragment>
    )
    
}
export default MyPledgesPage