import firebase from 'firebase/app';
require('firebase/auth');
require('firebase/firestore');
require('firebase/storage');

const config = {
	apiKey            : process.env.REACT_APP_API_KEY,
	authDomain        : process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL       : process.env.REACT_APP_DATABASE_URL,
	projectId         : process.env.REACT_APP_PROJECT_ID,
	storageBucket     : process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId : process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const app = firebase.initializeApp(config);

export const auth = app.auth();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const db = firebase.firestore();
export const storageRef = firebase.storage().ref('company_id/');

export default app;
