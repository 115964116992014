import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import '../styles/MainMenu.css'
import WeCareLogo from '../../../../assets/logo_wecare_header.png'
import {redirectTo,signOut} from '../components/MainMenuViewModel.jsx'
import ModalConfirmSignout from '../components/ModalConfirmSignout.jsx'
import ReactGa from 'react-ga'
import InitializeReactGa from '../../../../global/services/googleanalytics/index.jsx'

const MainMenu =({user})=>{

    const [userType,setUserType] = useState()
    const [currentPath,setCurrentPath] = useState('/')

    function changePath(){
        setCurrentPath(window.location.pathname)
    }

    useEffect(()=>{
        if(user){
            setUserType(user.userType)
            //setUserType("Admin")
        }
    },[user])

    useEffect(()=>{
        InitializeReactGa();
        setCurrentPath(window.location.pathname)
        ReactGa.pageview(window.location.pathname + window.location.search);
    },[window.location.pathname])


    return(
        <React.Fragment>
            {
                userType === "Admin"
                ?
                <div id="PageHeader">
                    <div className="PageHeader-Left">
                        <ModalConfirmSignout/>
                    </div>
                    <div className="PageHeader-Right">
                        <div className="MenuItem MenuItemInactive" onClick={()=>signOut()}>Sign Out</div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="my-account" className={currentPath === "/my-account" ? "MenuItemActive" : "MenuItemInactive"}>My Account</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="databasebeneficiaries" className={currentPath === "/databasebeneficiaries" ? "MenuItemActive" : "MenuItemInactive"}>Beneficiaries</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="databaseusers" className={currentPath === "/databaseusers" ? "MenuItemActive" : "MenuItemInactive"}>Users List</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="databasepledges" className={currentPath === "/databasepledges" ? "MenuItemActive" : "MenuItemInactive"}>Pledge List</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="databaserequests" className={currentPath === "/databaserequests" ? "MenuItemActive" : "MenuItemInactive"}>Request List</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="/" className={currentPath === "/" ? "MenuItemActive" : "MenuItemInactive"}>Verification List</Link></div>
                    </div>
                </div>
                :
                userType === "Super Admin"
                ?
                <div id="PageHeader">
                    <div className="PageHeader-Left">
                        <ModalConfirmSignout/>
                    </div>
                    <div className="PageHeader-Right">
                        <div className="MenuItem MenuItemInactive" onClick={()=>signOut()}>Sign Out</div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="my-account" className={currentPath === "/my-account" ? "MenuItemActive" : "MenuItemInactive"}>My Account</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="databasebeneficiaries" className={currentPath === "/databasebeneficiaries" ? "MenuItemActive" : "MenuItemInactive"}>Beneficiaries</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="databaseusers" className={currentPath === "/databaseusers" ? "MenuItemActive" : "MenuItemInactive"}>Users List</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="databasepledges" className={currentPath === "/databasepledges" ? "MenuItemActive" : "MenuItemInactive"}>Pledge List</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="databaserequests" className={currentPath === "/databaserequests" ? "MenuItemActive" : "MenuItemInactive"}>Request List</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="statistics" className={currentPath === "/statistics" ? "MenuItemActive" : "MenuItemInactive"}>Statistics</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="/" className={currentPath === "/" ? "MenuItemActive" : "MenuItemInactive"}>Verification List</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="edm-mail" className={currentPath === "/edm-mail" ? "MenuItemActive" : "MenuItemInactive"}>EDM</Link></div>
                    </div>
                </div>
                :
                userType === "Stats Viewer"
                ?
                    <div id="PageHeader">
                    <div className="PageHeader-Left">
                        <ModalConfirmSignout/>
                    </div>
                    <div className="PageHeader-Right">
                        <div className="MenuItem MenuItemInactive" onClick={()=>signOut()}>Sign Out</div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="my-account" className={currentPath === "/my-account" ? "MenuItemActive" : "MenuItemInactive"}>My Account</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="/" className={currentPath === "/" ? "MenuItemActive" : "MenuItemInactive"}>Statistics</Link></div>
                    </div>
                    </div>
                :
                userType === "DonateCash"
                ?
                    <div id="PageHeader">
                    <div className="PageHeader-Left">
                        <ModalConfirmSignout/>
                    </div>
                    <div className="PageHeader-Right">
                        <div className="MenuItem MenuItemInactive" onClick={()=>signOut()}>Sign Out</div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="my-account" className={currentPath === "/my-account" ? "MenuItemActive" : "MenuItemInactive"}>My Account</Link></div>
                        <a href="https://www.cortex.ph/contacts/" target="_blank"><div className="MenuItem MenuItemInactive">Contact Us</div></a>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="faqs" className={currentPath === "/faqs" ? "MenuItemActive" : "MenuItemInactive"}>FAQs</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="/" className={currentPath === "/" ? "MenuItemActive" : "MenuItemInactive"}>Home</Link></div>
                    </div>
                    </div>
                :
                userType === "Accreditor"
                ?
                <div id="PageHeader">
                    <div className="PageHeader-Left">
                        <ModalConfirmSignout/>
                    </div>
                    <div className="PageHeader-Right">
                        <div className="MenuItem MenuItemInactive" onClick={()=>signOut()}>Sign Out</div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="my-account" className={currentPath === "/my-account" ? "MenuItemActive" : "MenuItemInactive"}>My Account</Link></div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="/" className={currentPath === "/" ? "MenuItemActive" : "MenuItemInactive"}>Verification List</Link></div>
                    </div>
                </div>
                :
                <div id="PageHeader">
                    <div className="PageHeader-Left">
                        <ModalConfirmSignout/>
                    </div>
                    <div className="PageHeader-Right">
                        <div className="MenuItem MenuItemInactive" onClick={()=>signOut()}>Sign Out</div>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="my-account" className={currentPath === "/my-account" ? "MenuItemActive" : "MenuItemInactive"}>My Account</Link></div>
                        <a href="https://www.cortex.ph/contacts/" target="_blank"><div className="MenuItem MenuItemInactive">Contact Us</div></a>
                        <div className="MenuItem" onClick={()=>changePath()}><Link to="faqs" className={currentPath === "/faqs" ? "MenuItemActive" : "MenuItemInactive"}>FAQs</Link></div>
                        {
                            userType && userType === "WantsToHelp"
                            ? <div className="MenuItem" onClick={()=>changePath()}><Link to="vendor-directory" className={currentPath === "/vendor-directory" ? "MenuItemActive" : "MenuItemInactive"}>Vendor Directory</Link></div>
                            : null
                        }
                        <div className="MenuItem" onClick={()=>changePath()}> <Link to="community-requests" className={currentPath === "/community-requests" ? "MenuItemActive" : "MenuItemInactive"}>Community Requests</Link></div>
                        {
                            userType && userType
                            ? <div className="MenuItem" onClick={()=>changePath()}><Link to="/" className={currentPath === "/" ? "MenuItemActive" : "MenuItemInactive"}>
                                {
                                    userType && userType === "NeedsHelp" 
                                    ? "My Requests" 
                                    : userType && userType === "WantsToHelp"
                                        ? "My Pledges"
                                        : "My Listings"
                                }
                                </Link></div>
                            : null
                        }
                    </div>
                </div>
            }
        </React.Fragment>
    )
}
export default MainMenu
