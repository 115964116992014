import React, { useState, useContext } from "react";

import "../styles/GenericPrompt.css";
import ABSCBNLogo from "../../assets/abs-found.png";
import AlertIcon from "../../assets/ic_alert.png";
import SuccessIcon from "../../assets/ic_success.png";
import WeCareLogo from "../../assets/logo_wecare_small.png";
import { ModalState } from "../services/Context.jsx";

const CashDonationSetupComplete = () => {
  const { modalDetails, setModalDetails } = useContext(ModalState);

  function handleClose() {
    setModalDetails({ ...modalDetails, state: false });
    if (modalDetails.data.isPledgerDonate) {
      return (window.location.href = "/my-account");
    }
    return (window.location.href = "/");
  }

  function handleCloseError() {
    setModalDetails({ ...modalDetails, state: false });
    return (window.location.href = "/account-setup");
  }

  return (
    <React.Fragment>
      <div id="GenericPrompt">
        <div className="cash-donation-success-body">
          {modalDetails.data.icon === "Success" ? (
            <img className="cash-donation-modal-icons" src={SuccessIcon} />
          ) : (
            <img className="cash-donation-modal-icons" src={AlertIcon} />
          )}
          <div className="GenericPrompt-Title">{modalDetails.data.title}</div>
          <div className="GenericPrompt-Description">
            <p>
              We value your trust. Cash donations received by the ABS-CBN
              Foundation will be duly receipted.
            </p>
            <br />
            <p>
              To receive a copy of your Official Receipt please send a scanned
              copy of your deposit slip or for online transfers, a confirmation
              notice to lalara@abs-cbnfoundation.com with the following
              information:
            </p>
            <br />
            <p>Donor’s registered name</p>
            <p>Donor’s registered address</p>
            <p>Donor’s TIN</p>
            <br />
            Thank you very much.
          </div>
        </div>
        <div className="cash-donation-success-logos-container">
          <img src={WeCareLogo} alt="wecarelogo" />
          <img src={ABSCBNLogo} alt="abscbnlogo" />
        </div>
        <div className="DonationCash-Footer">
          {modalDetails.data.icon === "Success" ? (
            <div className="DonationCash-CloseButton" onClick={handleClose}>
              Got it
            </div>
          ) : (
            <div
              className="DonationCash-CloseButton"
              onClick={handleCloseError}
            >
              Close
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default CashDonationSetupComplete;
