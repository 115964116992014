import React,{useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/ViewItemImagePrompt.css'

const ViewItemImagePrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    return(
        <React.Fragment>
            <div id="ViewItemImageForm">
                <div className="ViewItemImage-Header">
                    <div className="ViewItemImage-PreHeaderTitle">Item Listing</div>
                    <div className="ViewItemImage-HeaderTitle">{modalDetails.data.itemName}</div>
                </div>
                
                <div className="ViewItemImage-Body">
                    {
                        modalDetails.data.itemImage !== ''
                        ?<img src={modalDetails.data.itemImage} />
                        :<div className="ViewItemImage-NoImage">No Image</div>
                    }
                    
                </div>

                <div className="ViewItemImage-Footer">
                    <div className="ViewItemImage-CloseButton" onClick={()=>setModalDetails({...modalDetails,'state':false})} >Close</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ViewItemImagePrompt