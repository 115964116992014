import React from 'react'
import AccreditorVerificationList from '../components/AccreditorVerificationList.jsx'

const AccreditorVerificationListPage =()=>{

    return(
        <React.Fragment>
            <AccreditorVerificationList />
        </React.Fragment>
    )
}
export default AccreditorVerificationListPage