import React,{useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/VendorInformationPrompt.css'

const VendorInformationPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    return(
        <React.Fragment>
            <div id="VendorInformationPrompt">
                <div className="VendorInformation-Header">
                    <div className="VendorInformation-PreHeaderTitle">Vendor</div>
                    <div className="VendorInformation-HeaderTitle">Contact information</div>
                </div>

                <div className="VendorInformation-Body">
                    <div className="VendorInformation-InputLayout">
                        <div className="VendorInformation-InputLayout-Left">
                            <div className="VendorInformation-InputLeft">
                                <label className="VendorInformation-InputLabelSmall">First name</label>
                                <div className="VendorInformation-InputValue">{modalDetails.data.firstName}</div>
                            </div>
                        </div>
                        <div className="VendorInformation-InputLayout-Right">
                            <div className="VendorInformation-InputRight">
                                <label className="VendorInformation-InputLabelSmall">Last name</label>
                                <div className="VendorInformation-InputValue">{modalDetails.data.lastName}</div>
                            </div>
                        </div>
                    </div>

                    <div className="VendorInformation-InputLayout">
                        <div className="VendorInformation-InputLayout-Left">
                            <div className="VendorInformation-InputLeft">
                                <label className="VendorInformation-InputLabelSmall">Mobile number</label>
                                <div className="VendorInformation-InputValueBlue">{modalDetails.data.mobileNumber}</div>
                            </div>
                        </div>
                        <div className="VendorInformation-InputLayout-Right">
                            <div className="VendorInformation-InputRight">
                                <label className="VendorInformation-InputLabelSmall">Email address</label>
                                <div className="VendorInformation-InputValueBlue">{modalDetails.data.emailAddress}</div>
                            </div>
                        </div>
                    </div>

                    <div className="VendorInformation-InputLayout">
                        <div className="VendorInformation-InputLayout-Left">
                            <div className="VendorInformation-InputLeft">
                                <label className="VendorInformation-InputLabelSmall">Payment options</label>
                                <div className="VendorInformation-InputValue">{modalDetails.data.paymentMethods}</div>
                            </div>
                        </div>
                        <div className="VendorInformation-InputLayout-Right">
                            <div className="VendorInformation-InputRight">
                                <label className="VendorInformation-InputLabelSmall">Website</label>
                                <div className="VendorInformation-InputValueBlue">{modalDetails.data.website}</div>
                            </div>
                        </div>
                    </div>

                    <div className="VendorInformation-InputLayout">
                        <div className="VendorInformation-InputLayout-Left">
                            <div className="VendorInformation-InputLeft">
                                <label className="VendorInformation-InputLabelSmall">Handles logistics?</label>
                                <div className="VendorInformation-InputValue">{modalDetails.data.tier === 1 ? "Yes" : "No"}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="VendorInformation-Footer">
                    <div className="VendorInformation-CancelButton" onClick={()=>setModalDetails({...modalDetails,'state':false})}>Close</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default VendorInformationPrompt