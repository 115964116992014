import React from 'react'
import MainMenu from '../components/MainMenu.jsx'
import AdminDatabasePledge from '../components/AdminDatabasePledge.jsx'
import Footer from '../../../../global/components/Footer.jsx'
import '../styles/AdminDatabasePage.css'

const AdminDatabasePledgePage =({})=> {
    return(
        <React.Fragment>
            <div id="AdminDatabasePledgePage">
                <AdminDatabasePledge/>
            </div>
            <Footer />
        </React.Fragment>
    )
}
export default AdminDatabasePledgePage
