import React,{useState,useEffect} from 'react'
import {Switch, BrowserRouter,Redirect,Route} from 'react-router-dom'
import RequestsTracker from '../components/RequestsTracker.jsx'
import '../styles/MyRequestsPage.css'
import Footer from '../../../../global/components/Footer.jsx'


const RequestsPage =({user})=> {

    return(
        <React.Fragment>
            <div id="MyRequestsPage">
                <RequestsTracker  user={user} />
            </div>
            <Footer />
        </React.Fragment>
    )
}
export default RequestsPage