import React from 'react'
import {withRouter} from 'react-router-dom'
import AdminLoginPage from '../components/AdminLoginPage.jsx'
import Footer from '../../../../global/components/Footer.jsx'
import '../styles/AdminLandingPage.css'

const AdminLandingPage =()=> {

    return (
        <React.Fragment>
            <div id="AdminLandingPage">
                <AdminLoginPage />
                <Footer />
            </div>
        </React.Fragment>
    )
}
export default withRouter(AdminLandingPage)