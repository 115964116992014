import React,{useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/Modal.css'
import AddRequestForm from '../components/AddRequestForm.jsx'
import UpdateRequestForm from '../components/UpdateRequestForm.jsx'
import UpdateAccount from '../components/UpdateAccount.jsx'
import AddListingForm from '../components/AddListingForm.jsx'
import AddRequestImage from '../components/AddRequestImage.jsx'
import ViewRequestImage from '../components/ViewRequestImage.jsx'
import PledgeForm from '../components/PledgeForm.jsx'
import InvalidPledgePrompt from '../components/InvalidPledgePrompt.jsx'
import PledgerDonateCashPrompt from '../components/PledgerDonateCashPrompt.jsx'
import CompletePledgePrompt from '../components/CompletePledgePrompt.jsx'
import ChangeStatusPrompt from '../components/ChangeStatusPrompt.jsx'
import CancelPledgePrompt from '../components/CancelPledgePrompt.jsx'
import CancelRequestPrompt from '../components/CancelRequestPrompt.jsx'
import CancelListingPrompt from '../components/CancelListingPrompt.jsx'
import UpdateStocksForm from '../components/UpdateStocksForm.jsx'
import PledgeeInformationPrompt from '../components/PledgeeInformationPrompt.jsx'
import PledgerInformationPrompt from '../components/PledgerInformationPrompt.jsx'
import VendorInformationPrompt from '../components/VendorInformationPrompt.jsx'
import ViewIdentificationPrompt from '../components/ViewIdentificationPrompt.jsx'
import ViewItemImagePrompt from '../components/ViewItemImagePrompt.jsx'
import ItemListingMoreDetailsPrompt from '../components/ItemListingMoreDetailsPrompt.jsx'
import ContactInfoPrompt from '../components/ContactInfoPrompt.jsx'
import VendorContactInfoPrompt from '../components/VendorContactInfoPrompt.jsx'
import RejectUserPrompt from '../components/RejectUserPrompt.jsx'
import VerifyBusinessPrompt from '../components/VerificationPromptVendor.jsx'
import RejectVendorPrompt from '../components/RejectVendorPrompt.jsx'
import RejectRemarksPrompt from '../components/RejectRemarksPrompt.jsx'
import GenericPrompt from '../components/GenericPrompt.jsx'
import GenericImagePrompt from '../components/GenericImagePrompt.jsx'
import CashDonationSetupComplete from '../components/CashDonationSetupComplete.jsx'
import WhatToExpect from '../components/WhatToExpect.jsx'
import AlreadyRegisteredPrompt from './AlreadyRegistedPrompt.jsx';
import AddCityForm from '../components/AddCityForm.jsx'
import PledgedItemQuantity from '../components/EditPledgedItemQuantityForm.jsx'
import ReactGa from 'react-ga'
import InitializeReactGa from '../services/googleanalytics/index.jsx'
import AccountUpdateComplete from './AccountUpdateComplete.jsx'

const Modal =()=>{

    const {modalDetails,setModalDetails} = useContext(ModalState)
    InitializeReactGa();

    switch(modalDetails.type){
        case 'AddRequest':
            ReactGa.event({category: 'Add New Request', action: 'Click Add New Request on My Request', label: 'Add New Request'})
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                       <AddRequestForm />
                    </div>
                </React.Fragment>
            )
        case 'AddListing':
            ReactGa.event({category: 'Add New Listing', action: 'Click Add New Listing on My listing items', label: 'Add New Listing'})
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                       <AddListingForm />
                    </div>
                </React.Fragment>
            )
        case 'AlreadyRegisteredPrompt':
                return(
                    <React.Fragment>
                        <div id="ModalContainer">
                            <AlreadyRegisteredPrompt/>
                        </div>
                    </React.Fragment>
                )
        case 'Pledge':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                       <PledgeForm />
                    </div>
                </React.Fragment>
            )
        case 'InvalidPledge':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                       <InvalidPledgePrompt />
                    </div>
                </React.Fragment>
            )
        case 'MarkAsComplete':
            return(
                <React.Fragment>
                    <div id="ModalContainerSmall">
                        <CompletePledgePrompt />
                    </div>
                </React.Fragment>
            )
        case 'ChangeStatus':
            return(
                <React.Fragment>
                    <div id="ModalContainerSmall">
                        <ChangeStatusPrompt />
                    </div>
                </React.Fragment>
            )
        case 'CancelPledge':
            return(
                <React.Fragment>
                    <div id="ModalContainerSmall">
                        <CancelPledgePrompt />
                    </div>
                </React.Fragment>
            )
        case 'CancelRequest':
            return(
                <React.Fragment>
                    <div id="ModalContainerSmall">
                        <CancelRequestPrompt />
                    </div>
                </React.Fragment>
            )
        case 'CancelListing':
            return(
                <React.Fragment>
                    <div id="ModalContainerSmall">
                        <CancelListingPrompt />
                    </div>
                </React.Fragment>
            )
        case 'UpdateStocks':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <UpdateStocksForm />
                    </div>
                </React.Fragment>
            )
        case 'PledgeeInformation':
            ReactGa.event({category: 'Contact Pledgee', action: 'Click contact pledgee on My Pledges', label: 'Contact Pledgee'})
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <PledgeeInformationPrompt />
                    </div>
                </React.Fragment>
            )
        case 'PledgerInformation':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <PledgerInformationPrompt />
                    </div>
                </React.Fragment>
            )
        case 'VendorInformation':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <VendorInformationPrompt />
                    </div>
                </React.Fragment>
            )
        case 'ViewIdentification':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <ViewIdentificationPrompt />
                    </div>
                </React.Fragment>
            )
        case 'ViewItemImage':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <ViewItemImagePrompt />
                    </div>
                </React.Fragment>
            )
        case 'ItemListingMoreDetails':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <ItemListingMoreDetailsPrompt />
                    </div>
                </React.Fragment>
            )
        case 'ContactInfo':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <ContactInfoPrompt />
                    </div>
                </React.Fragment>
            )
        case 'VendorContactInfo':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <VendorContactInfoPrompt />
                    </div>
                </React.Fragment>
            )
        case 'RejectUser':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <RejectUserPrompt />
                    </div>
                </React.Fragment>
            )   
        case 'VerifyBusiness':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <VerifyBusinessPrompt />
                    </div>
                </React.Fragment>
            )   
        case 'RejectBusiness':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <RejectVendorPrompt />
                    </div>
                </React.Fragment>
            )  
        case 'ShowRemarks':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <RejectRemarksPrompt />
                    </div>
                </React.Fragment>
            )   
        case 'GenericPrompt':
            return(
                <React.Fragment>
                    <div id="ModalContainerSmall">
                        <GenericPrompt />
                    </div>
                </React.Fragment>
            )
        case 'GenericImagePrompt':
            return(
                <React.Fragment>
                    <div id="ModalContainerSmall">
                        <GenericImagePrompt />
                    </div>
                </React.Fragment>
            )
        case 'WhatToExpect':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <WhatToExpect />
                    </div>
                </React.Fragment>
            )
        case 'AddCity':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <AddCityForm />
                    </div>
                </React.Fragment>
            )
        case 'AddRequestImage':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <AddRequestImage />
                    </div>
                </React.Fragment>
            )
        case 'ViewRequestImage':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <ViewRequestImage />
                    </div>
                </React.Fragment>
            )
        case 'CashDonationComplete':
            return(
                <React.Fragment>
                    <div id="ModalContainerSmall">
                        <CashDonationSetupComplete />
                    </div>
                </React.Fragment>
            )
        case 'UpdateRequest':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <UpdateRequestForm />
                      </div>
                </React.Fragment>
            )
        case 'UpdateAccountDetails':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <UpdateAccount />
                       </div>
                </React.Fragment>
            )
        case 'PledgerDonationCash':
            return(
                <React.Fragment>
                    <div id="ModalContainer">
                        <PledgerDonateCashPrompt/>
                    </div>
                </React.Fragment>
            )
        case 'EditPledgedItemQuantity':
                return(
                    <React.Fragment>
                        <div id="ModalContainerSmall">
                            <PledgedItemQuantity/>
                        </div>
                    </React.Fragment>
                )
        case 'AccountUpdateComplete':
            return(
                <React.Fragment>
                    <div id="ModalContainerSmall">
                        <AccountUpdateComplete/>
                    </div>
                </React.Fragment>
            )
        default:
            return(        
                <React.Fragment>
                    
                </React.Fragment>
            );
    }
}

export default Modal