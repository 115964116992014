import React,{useState,useContext} from 'react'
import {db} from "../services/firebase";
import WarningIcon from '../../assets/ic_error.png'
import {ModalState} from '../services/Context.jsx'
import '../styles/RejectUserPrompt.css'
import {rejectUserAccount} from '../../pages/main/screens/ui/AdminVerificationListPageViewModel.jsx'
import {sendMessageNotification} from '../services/sephamore'

const RejectUserPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)
    const [loading,setLoading] = useState(false)

    async function rejectUser(item){
        setLoading(true)
         console.log(item)
        const isUserRejected = await rejectUserAccount(item.uid)
        if(isUserRejected){
            const smsData = {
                number: modalDetails.data.mobileNumber,
                message: `Hi ${modalDetails.data.firstName}, your WeCARE registration is rejected due to insufficient information. We have a strict verification process to provide users with a safe environment for requests. Please send an email to support@wecare.ph for details and our team will get in touch asap. Thanks for understanding.`
            }
            sendMessageNotification(smsData);
            setLoading(false)
            setModalDetails({...modalDetails,'data':'RejectUserSuccess','pledgee':item,'state':false})
        }
        else{
            setLoading(false)
            setModalDetails({...modalDetails,'data':'RejectUserFailed','state':false})
        }
    }
    return(
        <React.Fragment>
            <div id="RejectUserPrompt">
                <div className="RejectUserPrompt-Header">
                    <img src={WarningIcon} />
                    <div className="RejectUserPrompt-HeaderLayout">
                        <div className="RejectUserPrompt-HeaderTitle">Reject Pledgee?</div>
                        <div className="RejectUserPrompt-SubHeaderTitle">Make sure that you already contacted this person before rejecting.</div>
                        
                    </div>
                </div>

                <div className="RejectUserPrompt-Body">
                    <div className="RejectUserPrompt-InputLayout">
                        <div className="RejectUserPrompt-InputLayout-Left">
                            <div className="RejectUserPrompt-InputLeft">
                                <label className="RejectUserPrompt-InputLabelSmall">First name</label>
                                <div className="RejectUserPrompt-InputValue">{modalDetails.data.firstName}</div>
                            </div>
                        </div>
                        <div className="RejectUserPrompt-InputLayout-Right">
                            <div className="RejectUserPrompt-InputRight">
                                <label className="RejectUserPrompt-InputLabelSmall">Last name</label>
                                <div className="RejectUserPrompt-InputValue">{modalDetails.data.lastName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="RejectUserPrompt-InputLayout">
                        <div className="RejectUserPrompt-InputLayout-Left">
                            <div className="RejectUserPrompt-InputLeft">
                                <label className="RejectUserPrompt-InputLabelSmall">Mobile Number</label>
                                <div className="RejectUserPrompt-InputValueBlue">{modalDetails.data.mobileNumber}</div>
                            </div>
                        </div>
                        <div className="RejectUserPrompt-InputLayout-Right">
                            <div className="RejectUserPrompt-InputRight">
                                <label className="RejectUserPrompt-InputLabelSmall">Email address</label>
                                <div className="RejectUserPrompt-InputValueBlue">{modalDetails.data.emailAddress}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="RejectUserPrompt-Footer">
                    <div className={loading ? "RejectUserPrompt-CancelButtonDisabled":"RejectUserPrompt-CancelButton"} onClick={()=>setModalDetails({...modalDetails,'state':false})}>Cancel</div>
                    <div className={loading ? "RejectUserPrompt-RejectButtonDisabled":"RejectUserPrompt-RejectButton"} onClick={()=>rejectUser(modalDetails.data)}>Reject</div>
                </div>
                
            </div>
        </React.Fragment>
    )
}
export default RejectUserPrompt