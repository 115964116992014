import {useState,useEffect} from 'react'
import {db} from "../../../../global/services/firebase";

export const getItemListings =async()=> {

    let ItemListings = []
    
    await db.collection("listingslist").orderBy("location")
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                const item = {...doc.data(), listingId:doc.id}
                ItemListings.push(item)
            })
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
    
    return ItemListings
}

export const useFetchItemListings = (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{
        setState(state=>({data:state.data,loading:true}))

        async function fetchItemListings(){
            const itemListing = await getItemListings()
            setState({data:itemListing,loading:false})
        }
        
        fetchItemListings()
    },[event])

    return state
};