import firebase from "firebase/app";
import { db } from "../../../../global/services/firebase";
import moment from "moment-timezone";

export const checkLocationInput = (locationName, locationAddress) => {
  const locationNameIsWhiteSpace = locationName
    .replace(/^\s+/, "")
    .replace(/\s+$/, "");
  const locationAddressIsWhiteSpace = locationAddress
    .replace(/^\s+/, "")
    .replace(/\s+$/, "");

  if (
    (locationName === "" || locationNameIsWhiteSpace === "") &&
    (locationAddress === "" || locationAddressIsWhiteSpace === "")
  ) {
    return 3;
  } else if (locationAddress === "" || locationAddressIsWhiteSpace === "") {
    return 2;
  } else if (locationName === "" || locationNameIsWhiteSpace === "") {
    return 1;
  } else {
    return 0;
  }
};

export const isValidFirstNameInput = (firstName) => {
  if (firstName !== null) {
    const firstNameIsWhiteSpace = firstName
      .replace(/^\s+/, "")
      .replace(/\s+$/, "");
    if (firstName === "" || firstNameIsWhiteSpace === "") {
      return false;
    } else {
      return true;
    }
  }
};

export const isValidLastNameInput = (lastName) => {
  if (lastName !== null) {
    const lastNameIsWhiteSpace = lastName
      .replace(/^\s+/, "")
      .replace(/\s+$/, "");
    if (lastName === "" || lastNameIsWhiteSpace === "") {
      return false;
    } else {
      return true;
    }
  }
};

export const isValidMobilNumberInput = (mobileNumber) => {
  if (mobileNumber !== null) {
    const mobileNumberIsWhiteSpace = mobileNumber
      .replace(/^\s+/, "")
      .replace(/\s+$/, "");
    if (mobileNumber === "" || mobileNumberIsWhiteSpace === "") {
      return false;
    } else {
      return true;
    }
  }
};

export const isValidMobileFormatInput = (mobileNumber) => {
  if (mobileNumber !== null) {
    const mobileNumberFormat = /(\+?\d{2}?\s?\d{3}\s?\d{3}\s?\d{4})|([0]\d{3}\s?\d{3}\s?\d{4})/g;
    if (mobileNumberFormat.test(mobileNumber)) {
      return true;
    } else {
      return false;
    }
  }
};

export const isValidCityInput = (cityValue) => {
  if (cityValue !== null) {
    const cityValueIsWhiteSpace = !cityValue
      ? ""
      : cityValue.replace(/^\s+/, "").replace(/\s+$/, "");
    if (cityValue === "" || cityValueIsWhiteSpace === "") {
      return false;
    } else {
      return true;
    }
  }
};

export const isValidProvinceInput = (provinceValue) => {
  if (provinceValue !== null) {
    const provinceValueIsWhiteSpace = !provinceValue
      ? ""
      : provinceValue.replace(/^\s+/, "").replace(/\s+$/, "");
    if (provinceValue === "" || provinceValueIsWhiteSpace === "") {
      return false;
    } else {
      return true;
    }
  }
};

export const isValidEmailAddressInput = (emailAddress) => {
  if (emailAddress !== null) {
    const emailAddressIsWhiteSpace = emailAddress
      .replace(/^\s+/, "")
      .replace(/\s+$/, "");
    if (emailAddress === "" || emailAddressIsWhiteSpace === "") {
      return false;
    } else {
      return true;
    }
  }
};

export const isValidEmailFormatInput = (emailAddress) => {
  if (emailAddress !== null) {
    const emailAddressFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailAddressFormat.test(emailAddress)) {
      return true;
    } else {
      return false;
    }
  }
};

export const isValidIntentionInput = (intention) => {
  if (intention !== null) {
    if (intention === "") {
      return false;
    } else {
      return true;
    }
  }
};

export const isValidWebsiteInput = (website) => {
  if (website !== null) {
    const websiteIsWhiteSpace = website.replace(/^\s+/, "").replace(/\s+$/, "");
    if (website === "" || websiteIsWhiteSpace === "") {
      return false;
    } else {
      return true;
    }
  }
};

export const isValidPaymentMethodsInput = (paymentMethods) => {
  if (paymentMethods !== null) {
    const paymentMethodsIsWhiteSpace = paymentMethods
      .replace(/^\s+/, "")
      .replace(/\s+$/, "");
    if (paymentMethods === "" || paymentMethodsIsWhiteSpace === "") {
      return false;
    } else {
      return true;
    }
  }
};

export const isValidFileInput = (file) => {
  if (file !== null) {
    if (file === "") {
      return false;
    } else {
      return true;
    }
  }
};

export const isValidVerificationInput = (profileAccount, companyId) => {
  const profileAccountIsWhiteSpace = profileAccount
    .replace(/^\s+/, "")
    .replace(/\s+$/, "");

  if (
    (profileAccount === "" || profileAccountIsWhiteSpace === "") &&
    companyId === ""
  ) {
    return false;
  } else {
    return true;
  }
};

export const isValidVerificationInputVendor = (profileAccount, companyId) => {
  console.log("profileAccount", profileAccount);
  console.log("companyId", companyId);
  const profileAccountIsWhiteSpace = profileAccount
    .replace(/^\s+/, "")
    .replace(/\s+$/, "");

  if (
    profileAccount === "" ||
    profileAccountIsWhiteSpace === "" ||
    companyId === ""
  ) {
    return false;
  } else {
    return true;
  }
};

export const getUserFacebookProfile = async () => {
  let userProfile = {};
  await firebase.auth().onAuthStateChanged(async function (user) {
    if (user) {
      userProfile = user;
    } else {
      return console.log("No User Found!");
    }
  });

  return userProfile;
};

export const isHospitalExisting = async (locationName) => {
  let hospitalExists = false;

  await db
    .collection("users")
    .where("locationName", "==", locationName)
    .get()
    .then(function (doc) {
      if (!doc.exists) {
        return (hospitalExists = false);
      }
      hospitalExists = true;
    })
    .catch(function (error) {
      return (window.location.href = "/account-setup");
    });

  return hospitalExists;
};

const saveUserDetails = async (userInfo, companyId, businessDocuments) => {
  const {
    userType,
    firstName,
    lastName,
    mobileNumber,
    emailAddress,
    locationName,
    locationAddress,
    city,
    province,
    profileAccount,
    website,
    paymentMethods,
    logistics,
    emailVerified,
    uid,
    partner,
  } = userInfo;
  var date = moment()
    .tz("Asia/Singapore")
    .format()
    .replace(/T/, " ")
    .replace("+08:00", "");

  let isEmailVerified = emailVerified;
  if (partner === "ABS-CBN") {
    isEmailVerified = true;
  }

  //Save user info in users table
  db.collection("users")
    .doc(uid)
    .set(
      {
        userType,
        firstName,
        lastName,
        mobileNumber,
        emailAddress,
        locationName,
        locationAddress,
        city,
        province: province || "",
        profileAccount,
        companyId,
        website,
        paymentMethods,
        logistics,
        businessDocuments: businessDocuments,
        tier: 0,
        emailVerified: isEmailVerified,
        uid,
        createdAt: date,
        updatedAt: date,
        partner,
      },
      { merge: false }
    )
    .then(function () {
      if (userInfo.userType == "NeedsHelp") {
        //Save to beneficiaries table
        try {
          db.collection("beneficiaries")
            .doc(uid)
            .set(
              {
                userType,
                firstName,
                lastName,
                mobileNumber,
                emailAddress,
                locationName,
                locationAddress,
                city,
                profileAccount,
                companyId,
                createdAt: date,
                updatedAt: date,
              },
              { merge: false }
            )
            .then(function (res) {
              return (window.location.href = "/");
            });
        } catch (e) {
          console.log(e);
        }
      } else if (userInfo.userType == "WantsToHelp") {
        //Save to pledgers table
        try {
          db.collection("pledgers")
            .doc(uid)
            .set(
              {
                userType,
                firstName,
                lastName,
                mobileNumber,
                emailAddress,
                createdAt: date,
                updatedAt: date,
              },
              { merge: false }
            )
            .then(function (res) {
              return (window.location.href = "/");
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        } catch (e) {
          console.log(e);
        }
      } else {
        //Save to suppliers table
        try {
          db.collection("suppliers")
            .doc(uid)
            .set(
              {
                userType,
                firstName,
                lastName,
                mobileNumber,
                emailAddress,
                locationName,
                locationAddress,
                city,
                profileAccount,
                companyId,
                website,
                paymentMethods,
                logistics,
                businessDocuments: businessDocuments,
                tier: 0,
                createdAt: date,
                updatedAt: date,
              },
              { merge: false }
            )
            .then(async function (res) {
              //Send email to vendor
              const emailContentsForVendor = {
                title: "WeCare Philippines - Account Registration",
                message: `Hi ${firstName} ${lastName} of ${locationName},<br/><br/> Thank you for your successful registration in WeCare! We sincerely appreciate your support to provide our Pledgers the much-needed items which will better equip our medical front liners against their heroic task of preventing the spread of NCOV-19.<br/><br/> Please give us a little while to process your registration as we do evaluation & accreditation for your account. We will provide an email notification once this is done.<br/><br/>Thank you very much!<br/><br/><i>- WeCare Team -</i><br/>Please follow us on facebook<br/> https://www.facebook.com/WeCAREPlatform/`,
              };
              await sendEmail(emailAddress, emailContentsForVendor);

              //Send email to procurement
              const email = "sendToProcurement";
              const emailContentsForProcurement = {
                title: "WeCare Philippines - Accreditation Request",
                message: `This is to notify you of a new Accreditation Request from ${locationName} with authorized representative ${firstName} ${lastName}. Kindly login to your WeCare Accreditor Account to process this request. Thank you very much!<br/><br/><i>- WeCare Team -</i><br/>Please follow us on facebook<br/> https://www.facebook.com/WeCAREPlatform/`,
              };
              await sendEmail(email, emailContentsForProcurement);

              return (window.location.href = "/");
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        } catch (e) {
          console.log(e);
        }
      }
    })
    .catch(function (error) {
      console.log("Failed to login", error);
    });
};

export const saveDonateCash = async (userInfo) => {
  const {
    userType,
    firstName,
    lastName,
    mobileNumber,
    emailAddress,
    locationName,
    locationAddress,
    city,
    profileAccount,
    emailVerified,
    uid,
    createdAt,
    updatedAt,
    companyId,
  } = userInfo;
  //Save user info in users table
  return new Promise((resolve) => {
    db.collection("users")
      .doc(uid)
      .set(
        {
          userType,
          firstName,
          lastName,
          mobileNumber,
          emailAddress,
          locationName,
          locationAddress,
          city,
          profileAccount,
          companyId,
          emailVerified,
          uid,
          createdAt,
          updatedAt,
        },
        { merge: false }
      )
      .then(function () {
        if (userInfo.userType === "DonateCash") {
          //Save to cashDonor table
          try {
            db.collection("cashDonor")
              .doc(uid)
              .set(
                {
                  userType,
                  firstName,
                  lastName,
                  mobileNumber,
                  emailAddress,
                  createdAt,
                  updatedAt,
                },
                { merge: false }
              )
              .then(function (res) {
                resolve(true);
              })
              .catch(function (error) {
                console.error("Error writing document: ", error);
                resolve(false);
              });
          } catch (e) {
            console.log(e);
            resolve(false);
          }
        } else {
          resolve(false);
        }
      })
      .catch(function (error) {
        console.log("Failed to login", error);
        resolve(false);
      });
  });
};

const uploadBusinessDocuments = async (userInfo, companyIdUrl, documents) => {
  const fbstorage = firebase.storage();
  console.log("documents", documents);
  const {
    form2303,
    registrationPermit,
    dti,
    businessPermit,
    generalInformationSheet,
    companyProfile,
    formalQuotation,
  } = documents;

  //Upload form2303
  fbstorage
    .ref(`/form2303/${form2303.name}`)
    .put(form2303)
    .then((snapshot) => {
      snapshot.ref.getDownloadURL().then((form2303Url) => {
        fbstorage
          .ref(`/registration-permit/${registrationPermit.name}`)
          .put(registrationPermit)
          .then((snapshot2) => {
            snapshot2.ref.getDownloadURL().then((registrationPermitUrl) => {
              fbstorage
                .ref(`/dti/${dti.name}`)
                .put(dti)
                .then((snapshot3) => {
                  snapshot3.ref.getDownloadURL().then((dtiUrl) => {
                    fbstorage
                      .ref(`/business-permit/${businessPermit.name}`)
                      .put(businessPermit)
                      .then((snapshot4) => {
                        snapshot4.ref
                          .getDownloadURL()
                          .then((businessPermitUrl) => {
                            fbstorage
                              .ref(
                                `/general-information-sheet/${generalInformationSheet.name}`
                              )
                              .put(generalInformationSheet)
                              .then((snapshot5) => {
                                snapshot5.ref
                                  .getDownloadURL()
                                  .then((generalInformationSheetUrl) => {
                                    fbstorage
                                      .ref(
                                        `/company-profile/${companyProfile.name}`
                                      )
                                      .put(companyProfile)
                                      .then((snapshot6) => {
                                        snapshot6.ref
                                          .getDownloadURL()
                                          .then((companyProfileUrl) => {
                                            fbstorage
                                              .ref(
                                                `/formal-quotation/${formalQuotation.name}`
                                              )
                                              .put(formalQuotation)
                                              .then((snapshot7) => {
                                                snapshot7.ref
                                                  .getDownloadURL()
                                                  .then(
                                                    (formalQuotationUrl) => {
                                                      const businessDocuments = {
                                                        form2303: form2303Url,
                                                        registrationPermit: registrationPermitUrl,
                                                        dti: dtiUrl,
                                                        businessPermit: businessPermitUrl,
                                                        generalInformationSheet: generalInformationSheetUrl,
                                                        companyProfile: companyProfileUrl,
                                                        formalQuotation: formalQuotationUrl,
                                                      };
                                                      saveUserDetails(
                                                        userInfo,
                                                        companyIdUrl,
                                                        businessDocuments
                                                      );
                                                    }
                                                  );
                                              });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
                });
            });
          });
      });
    });
};

export const saveAndSubmit = async (userInfo) => {
  const { userType, companyId, businessDocuments } = userInfo;

  if (companyId !== "") {
    const fbstorage = firebase.storage();

    fbstorage
      .ref(`/images/${companyId.name}`)
      .put(companyId)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((companyIdUrl) => {
          if (userType === "WantsToSupply") {
            uploadBusinessDocuments(userInfo, companyIdUrl, businessDocuments);
          } else {
            saveUserDetails(userInfo, companyIdUrl, businessDocuments);
          }
        });
      });
  } else {
    if (userType === "WantsToSupply") {
      uploadBusinessDocuments(userInfo, companyId, businessDocuments);
    } else {
      saveUserDetails(userInfo, companyId, businessDocuments);
    }
  }
};

export const isValidPledgerDonateInput = (intention) => {
  if (intention !== null) {
    const intentionIsWhiteSpace = intention
      .replace(/^\s+/, "")
      .replace(/\s+$/, "");
    if (intention === "" || intentionIsWhiteSpace === "") {
      return false;
    } else {
      return true;
    }
  }
};
const sendEmail = async (email, contents) => {
  try {
    if (email === "sendToProcurement") {
      return await db
        .collection("mail")
        .add({
          to: "marygrace.caniza@ccac.com.ph",
          cc:
            "raleigh.yu@ccac.com.ph,sally.danila@condura.com,rosegail.uy@cic.ph",
          bcc: "support@wecare.ph,supplieraccreditation@wecare.ph",
          message: {
            subject: contents.title,
            html: contents.message,
          },
        })
        .then(async () => {
          console.log("Email Sent!");
        })
        .catch((err) => {
          console.log("Email Error!", err);
        });
    } else {
      return await db
        .collection("mail")
        .add({
          to: email,
          //bcc: "support@wecare.ph,supplieraccreditation@wecare.ph",
          message: {
            subject: contents.title,
            html: contents.message,
          },
        })
        .then(() => {
          console.log("Email Sent!");
        })
        .catch((err) => {
          console.log("Email Error!", err);
        });
    }
  } catch (error) {
    console.log(error);
  }
};
