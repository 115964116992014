import firebase from "firebase/app";
import {db} from "../../../../global/services/firebase";
import {useState, useEffect} from "react"; 


const getMyPledgesList =async(uid)=>{
    let PledgesList = []
    await db.collection("pledgelist").where("pledgerId", "==", uid)
    .get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const pledgeItem = {...docData,pledgeId:doc.id}
            PledgesList.push(pledgeItem)
        })
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    return PledgesList
    
}

export const useFetchMyPledges = (event) => {

    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{

        setState(state=>({data:state.data,loading:true}))

        async function fetchMyPledges(){
            firebase.auth().onAuthStateChanged(async function(user) {
                if (user) {
                    const pledgesList = await getMyPledgesList(user.uid)
                    setState({data:pledgesList,loading:false})
                } else {
                    console.log("No User Found!")
                }
            })
        }
        
        fetchMyPledges()
        
    },[event])

    return state
};

