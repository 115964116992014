import { createContext } from "react";

export const ModalState = createContext({
  type: "AddUser",
  class: "modalOpen",
  state: false,
  data: [],
  pledgee: [],
  locations: [],
});

export const AccountSetupDetails = createContext({
  step: "",
  userType: "",
  firstName: "",
  lastName: "",
  mobileNumber: "",
  emailAddress: "",
  locationName: "",
  locationAddress: "",
  city: "",
  province: "",
  website: "",
  paymentMethods: "",
  logistics: true,
  profileAccount: "",
  companyId: "",
  partner: "",
});

export const RequestList = createContext([
  {
    itemName: "Alcohol",
    quantity: "100",
    unit: "Pieces",
    itemSpecs: "70% isopropyl",
    location: "Asian Hospital",
    progress: "69",
  },
  {
    itemName: "Lemon",
    quantity: "200",
    unit: "Pieces",
    itemSpecs: "Fresh Lemon",
    location: "Asian Hospital",
    progress: "108",
  },
  {
    itemName: "Salt",
    quantity: "50",
    unit: "Pieces",
    itemSpecs: "Rock Salt",
    location: "Asian Hospital",
    progress: "23",
  },
]);
