import React from 'react'
import TermsAndConditionRenderer from '../components/TermsAndConditionRenderer.jsx'
import '../styles/TermsAndCondition.css'
import Footer from '../../../../global/components/Footer.jsx'

const TermsAndConditionPage =()=> {

    return(
        <React.Fragment>
            <div id="TermsAndConditionPage">
                <TermsAndConditionRenderer />
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default TermsAndConditionPage