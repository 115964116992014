import React,{useState, useContext, useEffect} from 'react'
import {db} from "../../../../global/services/firebase";
import { Select } from 'antd';
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import '../styles/PledgedItemList.css'
import {useFetchItemPledgedList} from '../ui/MyRequestsPageViewModel.jsx'
import Moment from 'moment'

const PledgedItemList =(details)=> {

    const [loading, setLoading] = useState(true)
    const [ItemPledgedList,setItemPledgedList] = useState(null)
    const [modalDetails,setModalDetails] = useState(useContext(ModalState))
    const [fetchTrigger,setFetchTrigger] = useState(0)
    const requestItemDetails = {'itemId': details["itemDetails"].docId, 'progressCount': details["itemDetails"].progress}
    const {data} = useFetchItemPledgedList(fetchTrigger,details["itemDetails"].docId, details["userDetails"].uid)

    async function populateItemPledgedList(pledgedList){
        let itemPledged = []     
        await pledgedList.map(async (pledger) =>{
            await db.collection("users").doc(pledger.pledgerId)
            .get()
            .then(function(doc) {
                if(doc.exists){
                    const newPledged = {...pledger,pledgerProfile:doc.data()}
                    itemPledged.push(newPledged)
                    if(itemPledged.length === pledgedList.length){
                        setItemPledgedList(itemPledged)
                        setLoading(false)
                    }
                }
                else{
                    throw "Document does not exist";
                }
            })
            .catch(function(error) {
                return itemPledged
            });
        })
    }

    useEffect(()=>{
        if(data){
            if(data.length > 0){
                setLoading(true)
                populateItemPledgedList(data)
            }
            else{
                setLoading(false)
            }
        } 
    },[data])

    function closeModal(e){
        if (e.target.className === "modalOpen") {
            setModalDetails({...modalDetails,'state':false})
        }
    }

    return(
        <React.Fragment>
            <div className={modalDetails.state ? "modalOpen":"modalClose"} onClick={(e)=>closeModal(e)}>
                <ModalState.Provider value={{modalDetails,setModalDetails}}>
                    <Modal />
                </ModalState.Provider>
            </div>

            <div id="PledgedListTableContainer">
                <div className="PledgedListTable-HeaderTitle">Pledged Item</div>
                <div id="PledgedListTable-ProgressRow">
                    <div className="PledgedListTable-ProgressRowLeft">
                        <div className="PledgedListTable-Pretitle">Request</div>
                        <div className="PledgedListTable-Title">{details["itemDetails"].itemName}</div>
                        <div className="PledgedListTable-Subtitle">{details["itemDetails"].itemSpecs}</div>
                    </div>
                </div>
                <div id="PledgedListTable-Contents">
                    {
                        loading
                        ? <div className="PledgedListTable-Loading">Loading...</div>
                        :
                        <div>
                            {
                                ItemPledgedList && ItemPledgedList.length > 0
                                ?
                                    <table id="PledgedListTable">
                                        <tbody>
                                            <tr>
                                                <th><div className="thRow">Location</div></th>
                                                <th><div className="thRow">Pledged Quantity</div></th>
                                                <th><div className="thRow">Status</div></th>
                                                <th><div className="thRow">Pledged Date</div></th>
                                                <th style={{width:150}}><div className="thRowLast">Action</div></th>
                                            </tr>
                                            {
                                                ItemPledgedList.map((item,index) => {
                                                    return <tr key={index}>
                                                                <td>{item.location}</td>
                                                                <td>{item.pledgeQuantity}</td>
                                                                <td>{item.status}</td>
                                                                <td>{item.createdAt !== undefined ? Moment(item.createdAt).format('MMM D, YYYY') : null}</td>
                                                                <td>
                                                                    <div className="PledgedListTable-ActionButtonsLayout">
                                                                        <div className="PledgedListTable-ActionButtonsLayout-Left">
                                                                            <div className="PledgedListTable-ActionButton-EditQuantity" onClick={()=>setModalDetails({'type':'EditPledgedItemQuantity','data':item,'requestItemDetails':requestItemDetails,'state':true})}>Edit Quantity</div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                :
                                <div className="PledgedListTable-RequestsPlaceholder">You don't have pledge on this item yet. </div>
                                }
                        </div>               
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default PledgedItemList