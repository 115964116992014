import React, { useState, useContext, useEffect } from "react";
import { Select } from "antd";
import "../styles/BusinessDocumentsFormVendor.css";
import { AccountSetupDetails } from "../../../../global/services/Context.jsx";
import "antd/dist/antd.css";
import Modal from "../../../../global/components/Modal";
import { ModalState } from "../../../../global/services/Context.jsx";
import {
  getUserFacebookProfile,
  isValidFileInput,
  saveAndSubmit,
} from "../ui/AccountSetupPageViewModel.jsx";

const BusinessDocumentsFormVendor = () => {
  const [modalDetails, setModalDetails] = useState(useContext(ModalState));
  const { accountSetupDetails, setAccountSetupDetails } = useContext(
    AccountSetupDetails
  );

  const [form2303, setForm2303] = useState("");
  const [registrationPermit, setRegistrationPermit] = useState("");
  const [dti, setDti] = useState("");
  const [businessPermit, setBusinessPermit] = useState("");
  const [generalInformationSheet, setGeneralInformationSheet] = useState("");
  const [companyProfile, setCompanyProfile] = useState("");
  const [formalQuotation, setFormalQuotation] = useState("");

  const [loading, setLoading] = useState(false);

  const [isValidForm2303, setIsValidForm2303] = useState(true);
  const [isValidRegistrationPermit, setIsValidRegistrationPermit] = useState(
    true
  );
  const [isValidDti, setIsValidDti] = useState(true);
  const [isValidBusinessPermit, setIsValidBusinessPermit] = useState(true);
  const [
    isValidGeneralInformationSheet,
    setIsValidGeneralInformationSheet,
  ] = useState(true);
  const [isValidCompanyProfile, setIsValidCompanyProfile] = useState(true);
  const [isValidFormalQuotation, setIsValidFormalQuotation] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  function handleChangeForm2303(e) {
    const file = e.target.files[0];

    if (file !== undefined) {
      if (file.size < 15000000) {
        setForm2303(() => file);
        setIsValidForm2303(true);
        setErrorMessage("");
      } else {
        setIsValidForm2303(false);
        setErrorMessage("* File size must be less than 15mb. *");
      }
    } else {
      setIsValidForm2303(true);
      setErrorMessage("");
    }
  }
  function handleChangeRegistrationPermit(e) {
    const file = e.target.files[0];

    if (file !== undefined) {
      if (file.size < 15000000) {
        setRegistrationPermit(() => file);
        setIsValidRegistrationPermit(true);
        setErrorMessage("");
      } else {
        setIsValidRegistrationPermit(false);
        setErrorMessage("* File size must be less than 15mb. *");
      }
    } else {
      setIsValidRegistrationPermit(true);
      setErrorMessage("");
    }
  }
  function handleChangeDti(e) {
    const file = e.target.files[0];

    if (file !== undefined) {
      if (file.size < 15000000) {
        setDti(() => file);
        setIsValidDti(true);
        setErrorMessage("");
      } else {
        setIsValidDti(false);
        setErrorMessage("* File size must be less than 15mb. *");
      }
    } else {
      setIsValidDti(true);
      setErrorMessage("");
    }
  }
  function handleChangeBusinessPermit(e) {
    const file = e.target.files[0];
    setBusinessPermit(() => file);

    if (file !== undefined) {
      if (file.size < 15000000) {
        setBusinessPermit(() => file);
        setIsValidBusinessPermit(true);
        setErrorMessage("");
      } else {
        setIsValidBusinessPermit(false);
        setErrorMessage("* File size must be less than 15mb. *");
      }
    } else {
      setIsValidBusinessPermit(true);
      setErrorMessage("");
    }
  }
  function handleChangeGeneralInformationSheet(e) {
    const file = e.target.files[0];
    setGeneralInformationSheet(() => file);

    if (file !== undefined) {
      if (file.size < 15000000) {
        setGeneralInformationSheet(() => file);
        setIsValidGeneralInformationSheet(true);
        setErrorMessage("");
      } else {
        setIsValidGeneralInformationSheet(false);
        setErrorMessage("* File size must be less than 15mb. *");
      }
    } else {
      setIsValidGeneralInformationSheet(true);
      setErrorMessage("");
    }
  }
  function handleChangeCompanyProfile(e) {
    const file = e.target.files[0];
    setCompanyProfile(() => file);

    if (file !== undefined) {
      if (file.size < 15000000) {
        setCompanyProfile(() => file);
        setIsValidCompanyProfile(true);
        setErrorMessage("");
      } else {
        setIsValidCompanyProfile(false);
        setErrorMessage("* File size must be less than 15mb. *");
      }
    } else {
      isValidCompanyProfile(true);
      setErrorMessage("");
    }
  }
  function handleChangeFormalQuotation(e) {
    const file = e.target.files[0];

    if (file !== undefined) {
      if (file.size < 15000000) {
        setFormalQuotation(() => file);
        setIsValidFormalQuotation(true);
        setErrorMessage("");
      } else {
        setIsValidFormalQuotation(false);
        setErrorMessage("* File size must be less than 15mb. *");
      }
    } else {
      setIsValidFormalQuotation(true);
      setErrorMessage("");
    }
  }

  async function submitUserInfo() {
    setLoading(true);

    const userProfile = await getUserFacebookProfile();

    //For Input handling
    const validForm2303 = await isValidFileInput(form2303);
    const validRegistrationPermit = await isValidFileInput(registrationPermit);
    const validDti = await isValidFileInput(dti);
    const validBusinessPermit = await isValidFileInput(businessPermit);
    const validGeneralInformationSheet = await isValidFileInput(
      generalInformationSheet
    );
    const validCompanyProfile = await isValidFileInput(companyProfile);
    const validFormalQuotation = await isValidFileInput(formalQuotation);

    let {
      userType,
      locationName,
      locationAddress,
      city,
      firstName,
      lastName,
      mobileNumber,
      emailAddress,
      profileAccount,
      companyId,
      website,
      paymentMethods,
      logistics,
      partner,
    } = accountSetupDetails;

    const userInfo = {
      userType: userType,
      firstName: firstName,
      lastName: lastName,
      mobileNumber: mobileNumber,
      emailAddress: emailAddress,
      locationName: locationName,
      locationAddress: locationAddress,
      city: city,
      profileAccount: profileAccount,
      companyId: companyId,
      website: website,
      paymentMethods: paymentMethods,
      logistics: logistics,
      businessDocuments: {
        form2303: form2303,
        registrationPermit: registrationPermit,
        dti: dti,
        businessPermit: businessPermit,
        generalInformationSheet: generalInformationSheet,
        companyProfile: companyProfile,
        formalQuotation: formalQuotation,
      },
      emailVerified: false,
      uid: userProfile.uid,
      partner: partner,
    };

    if (
      validForm2303 &&
      validRegistrationPermit &&
      validDti &&
      validBusinessPermit &&
      validGeneralInformationSheet &&
      validCompanyProfile &&
      validFormalQuotation
    ) {
      await saveAndSubmit(userInfo);
    } else {
      setLoading(false);
      setIsValidForm2303(false);
      setIsValidRegistrationPermit(false);
      setIsValidDti(false);
      setIsValidBusinessPermit(false);
      setIsValidGeneralInformationSheet(false);
      setIsValidCompanyProfile(false);
      setIsValidFormalQuotation(false);
      setErrorMessage("* All documents are required. *");
    }
  }

  function closeModal(e) {
    if (e.target.className === "modalOpen") {
      setModalDetails({ ...modalDetails, state: false });
    }
  }

  return (
    <React.Fragment>
      <div
        className={modalDetails.state ? "modalOpen" : "modalClose"}
        onClick={(e) => closeModal(e)}
      >
        <ModalState.Provider value={{ modalDetails, setModalDetails }}>
          <Modal />
        </ModalState.Provider>
      </div>
      <div id="BusinessDocumentsFormVendorContainer">
        <div id="BusinessDocumentsFormVendor">
          <div className="BusinessDocumentsFormVendor-Header">
            <div id="BusinessDocumentsFormVendor-Pretitle">
              We want to supply things
            </div>
            <div id="BusinessDocumentsFormVendor-Title">Account Setup</div>
            <div id="BusinessDocumentsFormVendor-Subtitle">Step 4 of 4</div>
          </div>
          <div className="BusinessDocumentsFormVendor-Contents">
            <label id="BusinessDocumentsFormVendor-InputLabel">
              Business Documents{" "}
            </label>
            <label id="BusinessDocumentsFormVendor-InputSubLabel">
              Please note that failure to submit documents may result in your
              account not being verified.
            </label>
            <div id="BusinessDocumentsFormVendor-RequirementsTitle">
              Please provide the following files in PDF, JPEG, or PNG and size
              not more than 15mb.
            </div>

            <div id="BusinessDocumentsFormVendor-RequirementsSections">
              <ul>
                <li>
                  <label id="BusinessDocumentsFormVendor-DocumentLabel">
                    BIR Form 2303
                  </label>
                  <input
                    type="file"
                    accept="application/pdf,image/jpeg,image/png"
                    id={`${
                      isValidForm2303
                        ? "BusinessDocumentsFormVendor-FileInput"
                        : "BusinessDocumentsFormVendor-FileInputError"
                    }`}
                    onChange={handleChangeForm2303}
                  />
                </li>
                <li>
                  <label id="BusinessDocumentsFormVendor-DocumentLabel">
                    SEC Registration Permit
                  </label>
                  <input
                    type="file"
                    accept="application/pdf,image/jpeg,image/png"
                    id={`${
                      isValidRegistrationPermit
                        ? "BusinessDocumentsFormVendor-FileInput"
                        : "BusinessDocumentsFormVendor-FileInputError"
                    }`}
                    onChange={handleChangeRegistrationPermit}
                  />
                </li>
                <li>
                  <label id="BusinessDocumentsFormVendor-DocumentLabel">
                    DTI
                  </label>
                  <input
                    type="file"
                    accept="application/pdf,image/jpeg,image/png"
                    id={`${
                      isValidDti
                        ? "BusinessDocumentsFormVendor-FileInput"
                        : "BusinessDocumentsFormVendor-FileInputError"
                    }`}
                    onChange={handleChangeDti}
                  />
                </li>
                <li>
                  <label id="BusinessDocumentsFormVendor-DocumentLabel">
                    Business Permit
                  </label>
                  <input
                    type="file"
                    accept="application/pdf,image/jpeg,image/png"
                    id={`${
                      isValidBusinessPermit
                        ? "BusinessDocumentsFormVendor-FileInput"
                        : "BusinessDocumentsFormVendor-FileInputError"
                    }`}
                    onChange={handleChangeBusinessPermit}
                  />
                </li>
                <li>
                  <label id="BusinessDocumentsFormVendor-DocumentLabel">
                    General Information Sheet
                  </label>
                  <input
                    type="file"
                    accept="application/pdf,image/jpeg,image/png"
                    id={`${
                      isValidGeneralInformationSheet
                        ? "BusinessDocumentsFormVendor-FileInput"
                        : "BusinessDocumentsFormVendor-FileInputError"
                    }`}
                    onChange={handleChangeGeneralInformationSheet}
                  />
                </li>
                <li>
                  <label id="BusinessDocumentsFormVendor-DocumentLabel">
                    Company Profile
                  </label>
                  <input
                    type="file"
                    accept="application/pdf,image/jpeg,image/png"
                    id={`${
                      isValidCompanyProfile
                        ? "BusinessDocumentsFormVendor-FileInput"
                        : "BusinessDocumentsFormVendor-FileInputError"
                    }`}
                    onChange={handleChangeCompanyProfile}
                  />
                </li>
                <li>
                  <label id="BusinessDocumentsFormVendor-DocumentLabel">
                    Formal Quotation
                  </label>
                  <input
                    type="file"
                    accept="application/pdf,image/jpeg,image/png"
                    id={`${
                      isValidFormalQuotation
                        ? "BusinessDocumentsFormVendor-FileInput"
                        : "BusinessDocumentsFormVendor-FileInputError"
                    }`}
                    onChange={handleChangeFormalQuotation}
                  />
                </li>
              </ul>
              <div
                id={
                  isValidForm2303 &&
                  isValidRegistrationPermit &&
                  isValidDti &&
                  isValidBusinessPermit &&
                  isValidGeneralInformationSheet &&
                  isValidCompanyProfile &&
                  isValidFormalQuotation
                    ? "BusinessDocumentsFormVendor-Gone"
                    : "BusinessDocumentsFormVendor-TextError"
                }
              >
                {errorMessage}
              </div>
            </div>
          </div>
          <div className="BusinessDocumentsFormVendor-ButtonsLayout">
            {loading ? (
              <div id="BusinessDocumentsFormVendor-BackButtonDisabled">
                Back
              </div>
            ) : (
              <div
                id="BusinessDocumentsFormVendor-BackButton"
                onClick={() =>
                  setAccountSetupDetails({ ...accountSetupDetails, step: "4" })
                }
              >
                Back
              </div>
            )}

            {loading ? (
              <div id="BusinessDocumentsFormVendor-SaveButtonDisabled">
                <div id="BusinessDocumentsFormVendor-SaveButtonLoading" />
              </div>
            ) : (
              <div
                id="BusinessDocumentsFormVendor-SaveButton"
                onClick={() => submitUserInfo()}
              >
                Finish
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default BusinessDocumentsFormVendor;
