import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import {db} from "../services/firebase";
import '../styles/UpdateStocksForm.css'
import moment from 'moment-timezone';
import { useEffect } from 'react';

const UpdateStocksForm =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const [stocksRemaining,setStocksRemaining] = useState(0)
    const [totalStocks,setTotalStocks] = useState(0)
    const [errorMessage,setErrorMessage] = useState('')
    const [loading,setLoading] = useState(false)

    function handleChangeStocksRemaining(e){
        setStocksRemaining(e.target.value)
    }

    function handleChangeTotalStocks(e){
        setTotalStocks(e.target.value)
    }

    function changeStatus(){

        if(Number(stocksRemaining) < Number(modalDetails.data.minQuantity) || Number(totalStocks) < Number(modalDetails.data.minQuantity)){
            setErrorMessage("Stocks cannot be lower than minimum order quantity.")
        }
        else if(Number(stocksRemaining) > Number(totalStocks)){
            setErrorMessage("Stocks remaining can't exceed total stocks.")
        }
        else{
            setLoading(true)

            const data = modalDetails.data
            var date = (moment().tz("Asia/Singapore").format()).replace(/T/, ' ').replace("+08:00", '')

            var docRef = db.collection("listingslist").doc(data.listingId);
            db.runTransaction(function(transaction) {
                return transaction.get(docRef).then(function(sfDoc) {
                    if (!sfDoc.exists) {
                        throw "Document does not exist!";
                    }
                    transaction.update(docRef, { progress: stocksRemaining, stock: totalStocks , updatedAt:date});
                });
            }).then(function() {
                setLoading(false)
                setModalDetails({...modalDetails,'data':'UpdateStockSuccess','state':false})
            }).catch(function() {
                setLoading(false)
                setModalDetails({...modalDetails,'data':'UpdateStockFailed','state':false})
            });
        }

    }

    useEffect(()=>{
        setErrorMessage('')
        setStocksRemaining(modalDetails.data.progress)
        setTotalStocks(modalDetails.data.stock)
    },[modalDetails.state])
    return(
        <React.Fragment>
            <div id="UpdateStocksForm">
                <div className="UpdateStocksForm-Header">
                    <div className="UpdateStocksForm-PreHeaderTitle">We want to supply things</div>
                    <div className="UpdateStocksForm-HeaderTitle">Item listing information</div>
                </div>

                <div className="UpdateStocksForm-Body">
                    <label className="UpdateStocksForm-InputLabelSmall">Item</label>
                    <div className="UpdateStocksForm-InputValue">{modalDetails.data.itemName}</div>

                    <label className="UpdateStocksForm-InputLabelSmall">Item specifications</label>
                    <div className="UpdateStocksForm-InputValue">{modalDetails.data.itemSpecs}</div>

                    <label className="UpdateStocksForm-InputLabelSmall">Price per unit in Philippine Peso</label>
                    <div className="UpdateStocksForm-InputValue">{modalDetails.data.price}</div>

                    <label className="UpdateStocksForm-InputLabelSmall">Minimum order unit quantity</label>
                    <div className="UpdateStocksForm-InputValue">{modalDetails.data.minQuantity}</div>
                </div>
                <div className="UpdateStocksForm-InputLayout">
                    <div className="UpdateStocksForm-InputLayout-Left">
                        <div className="UpdateStocksForm-InputLeft">
                            <label className="UpdateStocksForm-InputLabelSmall">Stocks remaining</label>
                            <input type="number" className="AddListing-NumberInput" value={stocksRemaining} disabled={loading}
                                onKeyDown={(e)=>(e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' 
                                || stocksRemaining.length>6 && e.keyCode !== 8) && e.preventDefault()} 
                                onChange={handleChangeStocksRemaining} />
                        </div>
                    </div>
                    <div className="UpdateStocksForm-InputLayout-Right">
                        <div className="UpdateStocksForm-InputRight">
                            <label className="UpdateStocksForm-InputLabelSmall">Total stocks</label>
                            <input type="number" className="AddListing-NumberInput" value={totalStocks} disabled={loading}
                                onKeyDown={(e)=>(e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-' 
                                || stocksRemaining.length>6 && e.keyCode !== 8) && e.preventDefault()} 
                                onChange={handleChangeTotalStocks} />
                        </div>
                    </div>
                </div>
                <div className={errorMessage !== "" ? "UpdateStocksForm-ErrorText" : "UpdateStocksForm-Gone"}>* {errorMessage} *</div>
                <div className="UpdateStocksForm-Footer">
                    <div className={loading ? "UpdateStocksForm-CancelButtonDisabled":"UpdateStocksForm-CancelButton"} onClick={()=>setModalDetails({...modalDetails,'state':false})}>Cancel</div>
                    <div className={loading ? "UpdateStocksForm-UpdateButtonDisabled":"UpdateStocksForm-UpdateButton"} onClick={()=>changeStatus()}>Update</div>
                </div>
                
            </div>
        </React.Fragment>
    )
}
export default UpdateStocksForm