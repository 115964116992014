import React from 'react'
import DonorProfile from '../components/DonorProfile.jsx'
import Footer from '../../../../global/components/Footer.jsx'
import '../styles/MyAccountPageDonor.css'

const MyAccountPageDonor =()=> {

    return(
        <React.Fragment>
            <div id="MyAccountPageDonor">
               <DonorProfile />
            </div>
            <Footer />
        </React.Fragment>
    )
}
export default MyAccountPageDonor