import {
  auth,
  facebookProvider,
  db
} from "../../../../global/services/firebase";
import firebase from 'firebase/app';

export const checkAuth = async () => {
  try {
    const { user, credential } = await auth.getRedirectResult();
    const userAccessToken = credential.accessToken;
    localStorage.setItem("session", userAccessToken);
    return user
  } catch (e) {
    return null
  }
};

export const loginWithFacebook = async () => {
  try {
    let data = await auth.signInWithRedirect(facebookProvider);
    console.log(data);
    return data
  } catch (e) {
    console.log(e);
    alert("Failed to login!");
  }
};

export const loginWithEmail = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password).then(res => {
    
    if (res.credential === null) {
      localStorage.setItem("session", res.user.xa); 
      return window.location.href="/";
    }
    }).catch(e=> e);
};

export const registerWithEmail = (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password).then(res => {
    if (res.credential === null) {
      localStorage.setItem("session", res.user.xa); 
    }
    return res
  }).catch(e => e);
};

export const checkSignInMethod = (email) => {
  console.log(email)
  return auth.fetchSignInMethodsForEmail(email).then(res => {
    console.log("checkUserAPISuccess",res)
    return res
  }).catch(e => {console.log("checkUserAPiFail",e)
    return e});
};

export const resetEmailPassword = (email) => {
  console.log(email)
  return auth.sendPasswordResetEmail(email).then(res => {
    console.log("resetAPISuccess",res)
    return res
  }).catch(e => {console.log("resetAPI",e)
    return e});
};

export const confirmPasswordWithCode = (code,newPassword) => {
  return auth.confirmPasswordReset(code,newPassword).then(res=> {
      console.log(res);
      return res;
    })
    .catch(e =>{
      console.log(e);
      return e;
    })
};

export const isNewUser = async userId => {
  try {
    await db
      .collection("beneficiaries")
      .doc(userId)
      .get()
      .then(async function(doc) {
        if (doc.exists) {
          return (window.location.href = "/");
        } else {
          await db
            .collection("pledgers")
            .doc(userId)
            .get()
            .then(async function(doc) {
              if (doc.exists) {
                return (window.location.href = "/");
              } else {
                await db
                .collection("cashDonor")
                .doc(userId)
                .get()
                .then(async function(doc) {
                  if (doc.exists) {
                    return (window.location.href = "/");
                  } else {
                    return await db
                    .collection("suppliers")
                    .doc(userId)
                    .get()
                    .then(async function(doc) {
                      if (doc.exists) {
                        return (window.location.href = "/");
                      } else {
                        return (window.location.href = "/account-setup");
                      }
                    })
                  }
                })
              }
            })
            .catch(function() {
              console.log("Failed to identify user...");
            });
        }
      })
      .catch(function() {
        console.log("Failed to identify user...");
      });
  } catch (e) {
    console.log("Failed to check user!", e);
  }

  //return window.location.href = "/account-setup"
};
