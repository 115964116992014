import firebase from "firebase/app";
import {db} from "../../../../global/services/firebase";
import {useState, useEffect} from 'react'

const getMyListingsList =async(uid)=>{
    
    let ListingsList = []
    
    await db.collection("listingslist").where("uid", "==", uid)
    .get()
    .then(async function(querySnapshot) {
        querySnapshot.forEach(async function(doc) {
            const docData = doc.data()
            const listingItem = {...docData,listingId:doc.id}
            ListingsList.push(listingItem)
        })
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    return ListingsList
    
}


export const useFetchMyListings = (event) => {
    
    const [state, setState] = useState({data:null,loading:true})

    useEffect(()=>{

        setState(state=>({data:state.data,loading:true}))

        async function fetchMyListings(){
            firebase.auth().onAuthStateChanged(async function(user) {
                if (user) {
                    const listingsList = await getMyListingsList(user.uid)

                    setState({data:listingsList,loading:false})
                } else {
                    console.log("No User Found!")
                }
            })
        }
        
        fetchMyListings()
        
    },[event])

    return state
};


