import React,{useState,useEffect,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/PledgeeInformationPrompt.css'
import {db} from "../services/firebase";

const PledgeeInformationPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const [firstName,setFirstName] = useState('')
    const [lastName,setLastName] = useState('')
    const [mobileNumber,setMobileNumber] = useState('')
    const [email,setEmail] = useState('')

    const [shippingStatus,setShippingStatus] = useState(modalDetails.data.status)
    const [isETA,setIsETA] = useState(false)
    const [etaValue,setEtaValue] = useState('')
    const [loading,setLoading] = useState(false)

    const [isValidETA,setIsValidETA] = useState(true)

    async function closePledgeeInformation(){
        
        const shippingStatusIsWhiteSpace = shippingStatus.replace(/^\s+/, '').replace(/\s+$/, '')

        if(shippingStatus === modalDetails.data.status){
            setEtaValue('')
            setIsValidETA(true)
            setModalDetails({...modalDetails,'state':false})
        }
        else if(shippingStatus === 'ETA' || shippingStatus === '' || shippingStatusIsWhiteSpace === ''){
            setEtaValue('')
            setIsValidETA(false)
        }
        else{
            setLoading(true)

            const data = modalDetails.data

            var docRef = db.collection("pledgelist").doc(data.pledgeId);
            db.runTransaction(function(transaction) {
                return transaction.get(docRef).then(function(sfDoc) {
                    if (!sfDoc.exists) {
                        throw "Document does not exist!";
                    }
                    transaction.update(docRef, { status: shippingStatus });
                });
            }).then(function() {
                setLoading(false)
                setEtaValue('')
                setIsValidETA(true)
                setModalDetails({...modalDetails,'data':'ChangeStatusSuccess','state':false})
            }).catch(function() {
                setLoading(false)
                setEtaValue('')
                setIsValidETA(true)
                setModalDetails({...modalDetails,'data':'ChangeStatusFailed','state':false})
            });
        }
        
    }

    function handleChangeShippingStatus(status){
        if(status === "ETA"){
            setIsETA(true)
            setShippingStatus(status)
        }
        else{
            setIsETA(false)
            setEtaValue('')
            setShippingStatus(status)
        }
    }

    function handleChangeETA(e){
        setEtaValue(e.target.value)
        setShippingStatus(e.target.value)
    }

    useEffect(()=>{
        if(modalDetails.data.pledgeeProfile){
            setFirstName(modalDetails.data.pledgeeProfile.firstName)
            setLastName(modalDetails.data.pledgeeProfile.lastName)
            setMobileNumber(modalDetails.data.pledgeeProfile.mobileNumber)
            setEmail(modalDetails.data.pledgeeProfile.emailAddress)
        }
        setShippingStatus(modalDetails.data.status)
    },[modalDetails.data])
    return(
        <React.Fragment>
            <div id="PledgeeInformationPrompt">
                <div className="PledgeeInformation-Header">
                    <div className="PledgeeInformation-PreHeaderTitle">Pledgee</div>
                    <div className="PledgeeInformation-HeaderTitle">Contact information</div>
                </div>

                <div className="PledgeeInformation-Body">
                    <div className="PledgeeInformation-InputTitle">Pledgee contact details</div>
                    
                    <div className="PledgeeInformation-InputLayout">
                        <div className="PledgeeInformation-InputLayout-Left">
                            <div className="PledgeeInformation-InputLeft">
                                <label className="PledgeeInformation-InputLabelSmall">First name</label>
                                <div className="PledgeeInformation-InputValue">{firstName}</div>
                            </div>
                        </div>
                        <div className="PledgeeInformation-InputLayout-Right">
                            <div className="PledgeeInformation-InputRight">
                                <label className="PledgeeInformation-InputLabelSmall">Last name</label>
                                <div className="PledgeeInformation-InputValue">{lastName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="PledgeeInformation-InputLayout">
                        <div className="PledgeeInformation-InputLayout-Left">
                            <div className="PledgeeInformation-InputLeft">
                                <label className="PledgeeInformation-InputLabelSmall">Mobile number</label>
                                <div className="PledgeeInformation-InputValueBlue">{mobileNumber}</div>
                            </div>
                        </div>
                        <div className="PledgeeInformation-InputLayout-Right">
                            <div className="PledgeeInformation-InputRight">
                                <label className="PledgeeInformation-InputLabelSmall">Email address</label>
                                <div className="PledgeeInformation-InputValueBlue">{email}</div>
                            </div>
                        </div>
                    </div>

                    <div className="PledgeeInformation-ShippingStatusLayout">
                        <div className="PledgeeInformation-InputTitle">Change shipping status</div>

                        <label for="Packing" id="PledgeeInformation-RadioInputLabelLeft">
                        <input type="radio" id="Packing" name="shippingstatus" checked={shippingStatus === "Packing" ? true : false}
                        value="Packing" onClick={()=>handleChangeShippingStatus("Packing")}/>
                        Processing</label>

                        <label for="Shipping" id="PledgeeInformation-RadioInputLabelLeft">
                        <input type="radio" id="Shipping" name="shippingstatus" checked={shippingStatus === "Shipping" ? true : false}
                        value="Shipping" onClick={()=>handleChangeShippingStatus("Shipping")}/>
                        Shipping</label>

                        <label for="ETA" id="PledgeeInformation-RadioInputLabelLeft">
                        <input type="radio" id="ETA" name="shippingstatus" checked={shippingStatus !== "Packing" && shippingStatus !== "Shipping"? true : false}
                        value="ETA" onClick={()=>handleChangeShippingStatus("ETA")}/>
                        ETA</label>
                        {
                            isETA
                            ? <input type="text" className={isValidETA ? "PledgeeInformation-TextInput" : "PledgeeInformation-TextInputError"} maxLength={10} value={etaValue} placeholder="E.g. 2 Days" onChange={handleChangeETA} />
                            : null
                        }
                        <div className={isValidETA ? "PledgeeInformation-Gone" : "PledgeeInformation-TextError"}>Invalid ETA. Please try again.</div>
                    </div>
                </div>

                

                <div className="PledgeeInformation-Footer">
                    {
                        loading
                        ?<div className="PledgeeInformation-CancelButtonDisabled"><div className="PledgeeInformation-CancelButtonLoading" /></div>
                        :<div className="PledgeeInformation-CancelButton" onClick={()=>closePledgeeInformation()}>Close</div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default PledgeeInformationPrompt