import React,{useState,useEffect,useContext} from 'react'
import '../styles/GlobalStyle.css'
import '../styles/Statistics.css'
import {useFetchStatisticsRequests} from '../ui/StatisticsPageViewModel.jsx'

const Statistics =()=>{
    const [fetchTrigger,setFetchTrigger] = useState(0)

    const {data} = useFetchStatisticsRequests(fetchTrigger)

    const [pledgelistCount,setPledgelistCount] = useState('')
    const [wantsToHelpCount,setWantsToHelpCount] = useState('')
    const [requestTotal,setQuantityTotal] = useState('')
    const [pledgeTotal, setPledgeTotal] = useState('')
    const [hospitalCount, setHospitalCount] = useState('')
    const [requestCount, setRequestCount] = useState('')
    const [completedPledgeCount, setCompletedPledgeCount] = useState('')


    useEffect(()=>{
        if(data){
            setPledgelistCount(data.pledgelistCount)
            setWantsToHelpCount(data.wantsToHelpCount)
            setQuantityTotal(data.requestTotal)
            setPledgeTotal(data.pledgeTotal)
            setHospitalCount(data.hospitalCount)
            setRequestCount(data.requestCount)
            setCompletedPledgeCount(data.completedPledgeCount)
        }
    },[data])
 
    return(
        <React.Fragment>
            <div className="page-requests">
                <div className="page-container">
                    <div className="page-row page-header">
                        <div>Statistics</div>
                    </div>
                    <div className="page-row statistic-page-content statistics-header">
                        <label className="statistic-main-count">{requestCount.toLocaleString('en', {useGrouping: true })}</label><br/>

                        <label className="statistic-main-label"># of requests</label>
                    </div>
                    <div className="page-row statistic-page-content statistics-header">
                        <label className="statistic-main-count">{requestTotal.toLocaleString('en', {useGrouping: true })}</label><br/>

                        <label className="statistic-main-label"># of items requested</label>
                    </div>
                    <div className="page-row statistic-page-content statistics-header">
                        <label className="statistic-main-count">{hospitalCount.toLocaleString('en', {useGrouping: true })}</label><br/>
                        <label className="statistic-main-label"># of institutions registered</label>
                    </div>
                    <div className="page-row statistic-page-content statistics-header">
                        <label className="statistic-main-count">{wantsToHelpCount.toLocaleString('en', {useGrouping: true })}</label><br/>
                        <label className="statistic-main-label"># of pledgers</label>
                    </div>
                    <div className="page-row statistic-page-content statistics-header">
                        <label className="statistic-main-count">{pledgelistCount.toLocaleString('en', {useGrouping: true })}</label><br/>
                        <label className="statistic-main-label"># of pledges</label>
                    </div>
                    <div className="page-row statistic-page-content statistics-header">
                        <label className="statistic-main-count">{pledgeTotal.toLocaleString('en', {useGrouping: true })}</label><br/>
                        <label className="statistic-main-label"># of items pledged</label>
                    </div>
                    <div className="page-row statistic-page-content statistics-header">
                        <label className="statistic-main-count">{completedPledgeCount.toLocaleString('en', {useGrouping: true })}</label><br/>
                        <label className="statistic-main-label"># of requests 100% pledged</label>
                    </div>
                </div>
            </div>
          
        </React.Fragment>
    )
}
export default Statistics