import React,{useState,useEffect,useContext} from 'react'
import '../styles/RequestsTracker.css'
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import BlueBackButton from '../../../../assets/blue_back_button.png'
import PledgersListTable from '../components/PledgersListTable.jsx'
import {useFetchMyRequests} from '../ui/MyRequestsPageViewModel.jsx'
import ReactGa from 'react-ga'
import InitializeReactGa from '../../../../global/services/googleanalytics/index.jsx'

const RequestsTracker =({user})=> {

    const [modalDetails,setModalDetails] = useState(useContext(ModalState))

    const [isPageVisible,setIsPageVisible] = useState(false)
    const [isPledgerListVisible,setPledgerListVisible] = useState(false)
    const [itemIndex, setItemIndex] = useState(0)
    const [fetchTrigger,setFetchTrigger] = useState(0)
    const [isAccountVerified,setIsAccountVerified] = useState(false)

    const {data, loading} = useFetchMyRequests(fetchTrigger)

    InitializeReactGa();

    function cancelRequest(item){

        if(Number(item.progress) > 0){
            const promptDetails = {title:'Unable to cancel request',message: 'This request already has a pledger willing to donate. Please coordinate with the pledgers first.',icon:'Alert'}
            setModalDetails({'type':'GenericPrompt','data':promptDetails,'state':true})
        }
        else{
            setModalDetails({...modalDetails,'type':'CancelRequest','data':item,'state':true}) 
        }
        ReactGa.event({category: 'Cancel Request', action: 'Click cancel request on My Request', label: 'Cancel Request'})
    }

    function viewPledgerList(index){
        setPledgerListVisible(true)
        setItemIndex(index)
        ReactGa.event({category: 'View Pledgers', action: 'Click view pledges on My Request', label: 'View Pledgers'})
    }

    function closeModal(e){
        if (e.target.className === "modalOpen") {
            setModalDetails({...modalDetails,'state':false}) 
        }
    }

    useEffect(()=>{
        if(user){
            setIsPageVisible(true)
            setIsAccountVerified(user.emailVerified)
        }
    },[user])

    useEffect(()=>{
        if(modalDetails.data === "SubmitRequestSuccess"){
            setFetchTrigger(fetchTrigger+1)
        }
        if(modalDetails.data === "CancelRequestSuccess"){
            setFetchTrigger(fetchTrigger+1)
        }
    },[modalDetails.state])

    return(
        <React.Fragment>
            <div className={modalDetails.state ? "modalOpen":"modalClose"} onClick={(e)=>closeModal(e)}>
                <ModalState.Provider value={{modalDetails,setModalDetails}}>
                    <Modal />
                </ModalState.Provider>
            </div>
            <div id={isPageVisible?"RequestsTrackerHolder":"Gone"}>
                <div id={`${isPledgerListVisible ? "PledgersPageContainer" : "None"}`}>
                    <div id="PledgersPage">
                        <div className="PledgersPageButtonLayout" onClick={()=>setPledgerListVisible(false)}>
                            <img src={BlueBackButton} />
                            <div id="PledgersPage-BackButton">Back to request list</div>
                        </div>
                        {isPledgerListVisible ? <PledgersListTable item={data[itemIndex]} /> : null}
                    </div>
                </div>
                <div id={`${isPledgerListVisible ? "None": "RequestsTrackerContainer"}`}>
                    <div className="RequestsTracker-Header">
                        <div className="RequestsTracker-HeaderLeft">
                            <div className="RequestsTracker-HeaderTitle">My Requests List</div>
                            <div className="RequestsTracker-HeaderSubtitle">{user && user.locationName}</div>
                        </div>
                        <div className="RequestsTracker-HeaderRight">
                            {
                                isAccountVerified
                                ?<div className="RequestsTracker-AddRequestButton" onClick={()=>setModalDetails({'type':'AddRequest','state':true})}>+ Add New Request</div>
                                :<div className="RequestsTracker-AddRequestButtonDisabled">+ Add New Request</div>
                            }
                            
                        </div>
        
                    </div>
                    {
                    isAccountVerified
                    ? 
                    <div id="RequestsTracker-MainContents">
                            {
                            loading
                            ? <div className="RequestsTracker-Loading">Loading...</div>
                            :
                            <div>
                                {
                                    data && data.length>0 
                                    ?
                                    <table id="RequestsTracker">
                                        <tbody>
                                            <tr>
                                                <th><div className="thRow">Request Item</div></th>
                                                <th><div className="thRow">Quantity</div></th>
                                                <th><div className="thRow">Unit</div></th>
                                                <th><div className="thRow" >Item Specs</div></th>
                                                <th><div className="thRow">Progress</div></th>
                                                <th style={{width:420,whiteSpace:"nowrap"}}><div className="thRowLast">Actions</div></th>
                                            </tr>
                                            {
                                                data.map((item,index) => {
                                                    return <tr key={index}>
                                                        <td>{item.itemName}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.unit}</td>
                                                        <td>{item.itemSpecs}</td>
                                                        <td>
                                                            <div className="RequestsTracker-ProgressValue">{item.progress}/{item.quantity}</div>
                                                            <progress 
                                                            id={`${Number(item.progress) === Number(item.quantity) ? "RequestsTracker-ProgressBarComplete" : "RequestsTracker-ProgressBar"}`}
                                                            value={item.progress} max={item.quantity} />
                                                        </td>
                                                        <td>
                                                            <div className="RequestsTracker-ActionButtonsLayout">
                                                                <div className="RequestsTracker-ActionButtonsLayout-Left">
                                                                    <div className="RequestsTracker-ActionButton-CancelRequest" onClick={()=>cancelRequest(item)}>Cancel Request</div>
                                                                </div>  
                                                                <div className="RequestsTracker-ActionButtonsLayout-Left">
                                                                    {
                                                                        (item.requestImageId) === null || (item.requestImageId) === '' || (item.requestImageId) === undefined || (item.requestImageId) === "null"
                                                                        ?
                                                                        <div className="RequestsTracker-ActionButton-AddRequestImage" onClick={()=>setModalDetails({'type':'AddRequestImage','state':true, 'data':item})}>Add Request Image</div>
                                                                        :
                                                                        <div className="RequestsTracker-ActionButton-ViewRequestImage" onClick={()=>setModalDetails({'type':'ViewRequestImage','state':true, 'data':item})}>View Request Image</div>
                                                                    }
                                                                </div>
                                                                <div className="RequestsTracker-ActionButtonsLayout-Right">
                                                                    {
                                                                        Number(item.progress) === Number(item.quantity)
                                                                        ?
                                                                        <div className="RequestsTracker-ActionButton-ViewPledgersDisable">Edit Request</div>
                                                                        :
                                                                        <div className="RequestsTracker-ActionButton-ViewPledgers" onClick={()=>setModalDetails({'type':'UpdateRequest','state':true, 'data':item})}>Edit Request</div>
                                                                    }
                                                                </div>
                                                                <div className="RequestsTracker-ActionButtonsLayout-Right">
                                                                    <div className="RequestsTracker-ActionButton-ViewPledgers" onClick={()=>viewPledgerList(index)}>View Pledgers</div>
                                                                </div>  
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    :
                                    <div id="RequestsTracker-RequestsPlaceholder">
                                        <div>Your requests will appear here. Click the “Add New Request button” to add a request now.</div>
                                    </div>
                                }
                            </div>
                            }
                        </div>
                    :<div id="RequestsTracker-MainContents">Your account is currently being verified.</div>
                        
                    }
                    
                </div>
            </div>
        </React.Fragment>
    )
}
export default RequestsTracker