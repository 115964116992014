import React,{useState,useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import WarningIcon from '../../assets/ic_error.png'
import '../styles/CancelPledgePrompt.css'
import {db} from "../services/firebase";
import ReactGa from 'react-ga'
import InitializeReactGa from '../services/googleanalytics/index.jsx'
import { timeStamp } from '../services/moment/index.js';

const CancelPledgePrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const [loading,setLoading] = useState(false)

    function cancelPledge(){

        setLoading(true)

        const data = modalDetails.data

        InitializeReactGa();
        ReactGa.event({ category: 'Cancel Pledge (Yes)', action: 'Click yes on Cancel Prompt', label: 'Cancel(Yes)' });
        if(data.status === "Received"){
            db.collection("completedpledges").doc(data.pledgeId).set(data)
            .then(function() {
                db.collection("pledgelist").doc(data.pledgeId).delete().then(function() {
                    setLoading(false)
                    setModalDetails({...modalDetails,'data':'CancelPledgeSuccess','state':false})
                }).catch(function(error) {
                    setLoading(false)
                    setModalDetails({...modalDetails,'data':'CancelPledgeFail','state':false})
                });
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });   
        }
        else {
            var docRef = db.collection("requestlist").doc(data.docId);
            db.runTransaction    (function(transaction) {
                return transaction.get(docRef).then(function(sfDoc) {
                    if (!sfDoc.exists) {
                        throw "Document does not exist!";
                    }
                    const sfDocData = sfDoc.data()
                    const newProgress = Number(sfDocData.progress) - Number(data.pledgeQuantity)
                    transaction.update(docRef, { progress: newProgress, updatedAt: timeStamp });
                });
            }).then(function() {
                db.collection("pledgelist").doc(data.pledgeId).delete().then(function() {
                    setLoading(false)
                    setModalDetails({...modalDetails,'data':'CancelPledgeSuccess','state':false})
                }).catch(function(error) {
                    setLoading(false)
                    setModalDetails({...modalDetails,'data':'CancelPledgeFail','state':false})
                });
            }).catch(function(error) {
                setLoading(false)
                setModalDetails({...modalDetails,'data':'CancelPledgeFail','state':false})
            });
        }
    }

    return(
        <React.Fragment>
            <div id="CancelPledgePrompt">
                <div className="CancelPledge-Body">
                    <img src={WarningIcon} />
                    <div className="CancelPledge-Title">Cancel pledge?</div>
                    <div className="CancelPledge-Description">Make sure you contact the pledgee first before cancelling.</div>
                </div>

                {
                    loading
                    ?
                    <div className="CancelPledge-Footer">
                        <div className="CancelPledge-CancelButtonDisabled">No</div>
                        <div className="CancelPledge-SubmitButtonDisabled">Yes</div>
                    </div>
                    :
                    <div className="CancelPledge-Footer">
                        <div className="CancelPledge-CancelButton" onClick={()=>setModalDetails({...modalDetails,'state':false})}>No</div>
                        <div className="CancelPledge-SubmitButton" onClick={()=>cancelPledge()}>Yes</div>
                    </div>
                }
                
            </div>
        </React.Fragment>
    )
}
export default CancelPledgePrompt