import React,{useState,useEffect,useContext} from 'react'
import '../styles/AccreditorVerificationList.css'
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import {useFetchUnverifiedVendorsList,findInSupplierCollection} from '../ui/AccreditorVerificationListPageViewModel.jsx'
import FilteredAccreditorVerificationList from '../components/FilteredAccreditorVerificationList.jsx'
import { db } from '../../../../global/services/firebase';

const AccreditorVerificationListPage =()=> {

    const [modalDetails,setModalDetails] = useState(useContext(ModalState))
    
    const [fetchTrigger,setFetchTrigger] = useState(0)

    const [search, setSearch] = useState(null);

    const [rawData,setRawData] = useState([])
    const [currentFilter,setCurrentFilter] = useState("All")
    const [vendorList,setVendorList] = useState([])
    const [loading, setLoading] = useState(true)

    const {data} = useFetchUnverifiedVendorsList(fetchTrigger)

    const [firstPage,setFirstPage] = useState(0)
    const [lastPage,setLastPage] = useState(5)
    const [currentPage,setCurrentPage] = useState(1)

    const indexOfLastData = currentPage * 10
    const indexOfFirstData = indexOfLastData - 10
    const tableData = vendorList.slice(indexOfFirstData,indexOfLastData)
    const vendorListCount = vendorList.length
    const tableDataCount = tableData.length

    const pages = []
    for(let x = 1; x <= Math.ceil(vendorList.length/10); x++){
        pages.push(x)
    }
    const numberOfPages = pages.length
    const pagesToBeRendered = pages.slice(firstPage,lastPage)

    function handlePreviousPage(){
        if(currentPage > 1)
        {
            const newPage = currentPage - 1
            setCurrentPage(Number(newPage))
            if(firstPage === 0)
            {

            }
            else{
                if(newPage === firstPage+2){
                    setFirstPage(newPage-3)
                    setLastPage(newPage+2)
                }
            }
        }
        else{

        }
        
    }

    function handleNextPage(e){
        const newPage = currentPage + 1
        if(newPage <= numberOfPages)
        {
            setCurrentPage(Number(newPage))
            if(lastPage === numberOfPages)
            {

            }
            else{
                if(newPage === lastPage-1){
                    setFirstPage(newPage-3)
                    setLastPage(newPage+2)
                }
            }
        }
        else{

        }
    }
    function handlePageChange(e){
        const newPage = Number(e.target.id)
        setCurrentPage(newPage)

        if(numberOfPages > 5){
            const nFirstPage = newPage-3
            const nLastPage = newPage+2
            if(nFirstPage < 0)
            {
                setFirstPage(0)
                setLastPage(5)
            }
            else if(nLastPage > numberOfPages)
            {
                setFirstPage(numberOfPages-5)
                setLastPage(numberOfPages)
            }
            else{
                setFirstPage(nFirstPage)
                setLastPage(nLastPage)
            }

        }
    }

    async function searchSpace(event) {
        let keyword = event.target.value;
        return await setSearch(keyword);
    }

    async function searchTableData(tableData){
        const searchedData = await tableData.filter((data)=>{
            if (search === null || search.length <= 0 || loading)
                return data
            else if (data.firstName.toLowerCase().includes(search.toLowerCase()) 
            || data.locationName.toLowerCase().includes(search.toLowerCase())) {
                setCurrentPage(1)
                return data
            }
        })

        if(currentFilter === "All"){
            setVendorList(searchedData)
        }
        else if(currentFilter === "Pending"){
            filterTablePending(searchedData)
        }
        else if(currentFilter === "Returned"){
            filterTableReturned(searchedData)
        }
        else if(currentFilter === "Rejected"){
            filterTableRejected(searchedData)
        }
        else{
            filterTableVerified(searchedData)
        }
        
    }

    async function filterTablePending(data){
        const filteredTableData = data.filter((dataItem)=>{
           if(!dataItem.emailVerified && dataItem.userType === "WantsToSupply"){
                return dataItem
           }
        })
        setVendorList(filteredTableData)
    }

    async function filterTableReturned(data){
        const filteredTableData = data.filter((dataItem)=>{
           if(!dataItem.emailVerified && (dataItem.rejectionHistory && dataItem.rejectionHistory[dataItem.rejectionHistory.length-1].rejectionType === 1)){
                return dataItem
           }
        })
        setVendorList(filteredTableData)
    }

    async function filterTableRejected(data){
        const filteredTableData = data.filter((dataItem)=>{
            console.log("dataItemRejectionHistory",dataItem.rejectionHistory)
           if(!dataItem.emailVerified && (dataItem.rejectionHistory && dataItem.rejectionHistory[dataItem.rejectionHistory.length-1].rejectionType === 2)){
               console.log("dataItemRejectionHistory",dataItem.rejectionHistory)
                return dataItem
           }
        })
        setVendorList(filteredTableData)
    }

    async function filterTableVerified(data){
        const filteredTableData = data.filter((dataItem)=>{
           if(dataItem.emailVerified && dataItem.userType === "WantsToSupply"){
                return dataItem
           }
        })
        setVendorList(filteredTableData)
    }


    async function sendEmail(item,contents) {
        try {
            return await db.collection('mail').add({
                 to: item.emailAddress,
                 bcc: "support@wecare.ph,supplieraccreditation@wecare.ph,marygrace.caniza@ccac.com.ph,raleigh.yu@ccac.com.ph,sally.danila@condura.com,rosegail.uy@cic.ph",
                 message: {
                   subject: contents.title,
                   html: contents.message,
                 },
               })
               .then(()=>{
                   console.log("Email Sent!")
               })
               .catch((err)=>{
                console.log("Email Error!",err)
               })
        } catch (error) {
            console.log(error);
        }
    }

    async function findVendors(data){

        let currentVendorList = []
        let dataCount = 0
        await data.forEach(async (id) => {
            const vendor = await findInSupplierCollection(id)
            if(vendor !== undefined){
                currentVendorList.push(vendor)
            }

            dataCount++
            if(data.length === dataCount){
                console.log(currentVendorList)
                setRawData(currentVendorList)
                setVendorList(currentVendorList)
                setLoading(false)
            }
        });
        
    }

    function contactInfo(item){
        setModalDetails({...modalDetails,'type':'VendorContactInfo','data':item,'state':true})
    }

    async function verifyUser(item){
        setModalDetails({...modalDetails,'type':'VerifyBusiness','data':item,'state':true})
    }

    async function rejectUser(item){
        setModalDetails({...modalDetails,'type':'RejectBusiness','data':item,'state':true})
    }

    function closeModal(e){
        if (e.target.className === "modalOpen") {
            setModalDetails({...modalDetails,'state':false}) 
        }
    }

    useEffect(()=>{
        if(modalDetails.data === "VendorVerificationSuccess"){
            setLoading(true)
            const emailContents = {
                title: 'WeCare Philippines - Account Verified',
                message: `Hi ${modalDetails.vendor.firstName} ${modalDetails.vendor.lastName}, <br/><br/><b>Congratulations!</b> You have just been Accredited by our team and have been granted Full Access to the Vendor Module. Kindly login to WeCare to start adding your items!<br/><br/>Thank you very much!<br/><br/><i>- WeCare Team -</i><br/>Please follow us on facebook<br/> https://www.facebook.com/WeCAREPlatform/`
            }
            sendEmail(modalDetails.vendor,emailContents);
            setFetchTrigger(fetchTrigger+1)
        }
        if(modalDetails.data === "VendorVerificationFailed"){
            alert("Failed to verify vendor.")
        }
        if(modalDetails.data === "RejectVendorSuccess"){
            setLoading(true)

            if(modalDetails.vendor.rejectionHistory[modalDetails.vendor.rejectionHistory.length-1].rejectionType === 1){
                const emailContents = {
                    title: 'WeCare Philippines - Account Rejected',
                    message: `Hi ${modalDetails.vendor.firstName} ${modalDetails.vendor.lastName}, <br/><br/>We have processed your Registration Request, but unfortunately, we may need to get more information to validate your request.<br/><br/> Accreditor's remarks:<br/>${modalDetails.vendor.rejectionHistory[modalDetails.vendor.rejectionHistory.length-1].remarks}<br/><br/> You may contact supplieraccreditation@wecare.ph for more details.<br/><br/>  Thank you very much!<br/><br/><i>- WeCare Team -</i><br/>Please follow us on facebook<br/> https://www.facebook.com/WeCAREPlatform/`
                }
                sendEmail(modalDetails.vendor,emailContents);
                setFetchTrigger(fetchTrigger+1)
            }else{
                const emailContents = {
                    title: 'WeCare Philippines - Account Rejected',
                    message: `Hi ${modalDetails.vendor.firstName} ${modalDetails.vendor.lastName}, <br/><br/>Thank you for your patience!<br/> We regret to inform you that we can not accredit you as of the moment as there are some irregularities with the information previously provided.<br/><br/> You may contact supplieraccreditation@wecare.ph for more details.<br/><br/>  Thank you very much!<br/><br/><i>- WeCare Team -</i><br/>Please follow us on facebook<br/> https://www.facebook.com/WeCAREPlatform/`
                }
                sendEmail(modalDetails.vendor,emailContents);
                setFetchTrigger(fetchTrigger+1)
            }
            
        }
        if(modalDetails.data === "RejectVendorFailed"){
            alert("Failed to reject vendor application.")
        }
    },[modalDetails.state])

    useEffect(()=> {
        searchTableData(rawData)
    },[search,currentFilter])

    useEffect(()=>{
        if(data && data.length > 0){
            findVendors(data)
        }
    },[data])
    return(
        <React.Fragment>
            <div className={modalDetails.state ? "modalOpenLong":"modalClose"} onClick={(e)=>closeModal(e)}>
                <ModalState.Provider value={{modalDetails,setModalDetails}}>
                    <Modal />
                </ModalState.Provider>
            </div>
            <div id="AccreditorVerificationListContainer">
                <div className="AccreditorVerificationList-Header">
                    <div className="AccreditorVerificationList-HeaderLeft">
                        <div className="AccreditorVerificationList-HeaderTitle">Vendor List</div>
                        <input type="text" placeholder="Search for name or company" id="AccreditorVerificationList-TextInput"  onChange={(event)=>searchSpace(event)}  />
                    </div>
                </div>
                <div className="AccreditorVerificationList-Body">
                    <div className="AccreditorVerificationList-TableFilters">
                        <div className={currentFilter === "All" ? "AccreditorVerificationList-TableFiltersActiveColumn":"AccreditorVerificationList-TableFiltersInactiveColumn"}
                        onClick={()=>setCurrentFilter("All")}>All</div>
                        <div className={currentFilter === "Pending" ? "AccreditorVerificationList-TableFiltersActiveColumn":"AccreditorVerificationList-TableFiltersInactiveColumn"}
                        onClick={()=>setCurrentFilter("Pending")}>Pending Verification</div>
                        <div className={currentFilter === "Returned" ? "AccreditorVerificationList-TableFiltersActiveColumn":"AccreditorVerificationList-TableFiltersInactiveColumn"}
                        onClick={()=>setCurrentFilter("Returned")}>Returned to sender</div>
                        <div className={currentFilter === "Rejected" ? "AccreditorVerificationList-TableFiltersActiveColumn":"AccreditorVerificationList-TableFiltersInactiveColumn"}
                        onClick={()=>setCurrentFilter("Rejected")}>Rejected</div>
                        <div className={currentFilter === "Verified" ? "AccreditorVerificationList-TableFiltersActiveColumn":"AccreditorVerificationList-TableFiltersInactiveColumn"}
                        onClick={()=>setCurrentFilter("Verified")}>Verified</div>
                    </div>
                    <div className="AccreditorVerificationList-ItemCountLabel">Showing {tableDataCount} vendors of {vendorListCount}</div>
                    {
                        loading
                        ? <div className="AccreditorVerificationList-Loading">Loading...</div>
                        :
                        <table id="AccreditorVerificationList">
                            <tbody>
                                <tr>
                                    <th><div className="thRow">Vendor Name</div></th>
                                    <th><div className="thRow">Company</div></th>
                                    <th><div className="thRow">Date Registered</div></th>
                                    <th><div className="thRow">Status</div></th>
                                    <th style={{width:370,whiteSpace:"nowrap"}}><div className="thRowLast">Actions</div></th>
                                </tr>
                                <FilteredAccreditorVerificationList data={tableData} value={search}
                                onClickContactInfo={contactInfo} onClickVerify={verifyUser} onClickReject={rejectUser} />
                            </tbody>
                        </table>
                    }
                    {
                        pagesToBeRendered && pagesToBeRendered.length > 0
                        ?
                        <div id='AccreditorVerificationList-Pagination'>
                            <ul>
                                <li onClick={handlePreviousPage} className="AccreditorVerificationList-InactiveLi"><b>{"<"}</b></li>
                                {pagesToBeRendered.map(page => {
                                    return(<li key={page} id={page} className={currentPage === page? "AccreditorVerificationList-ActiveLi":"AccreditorVerificationList-InactiveLi"} onClick={(e)=>handlePageChange(e)}>{page}</li>)
                                })}
                                <li onClick={handleNextPage} className="AccreditorVerificationList-InactiveLi"><b>{">"}</b></li>
                            </ul>
                        </div>
                        : null
                    }
                </div>
                
            </div>
        </React.Fragment>
    )
}
export default AccreditorVerificationListPage