import React,{useContext} from 'react'
import {ModalState} from '../services/Context.jsx'
import '../styles/ViewIdentificationPrompt.css'

const ViewIdentificationPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    return(
        <React.Fragment>
            <div id="ViewIdentificationForm">
                <div className="ViewIdentification-Header">
                    <div className="ViewIdentification-PreHeaderTitle">ID</div>
                    <div className="ViewIdentification-HeaderTitle">{modalDetails.data.firstName} {modalDetails.data.lastName}</div>
                </div>
                
                <div className="ViewIdentification-Body">
                    <img src={modalDetails.data.companyId} />
                </div>

                <div className="ViewIdentification-Footer">
                    <div className="ViewIdentification-CloseButton" onClick={()=>setModalDetails({...modalDetails,'state':false})} >Close</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ViewIdentificationPrompt