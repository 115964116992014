import React,{useState,useEffect, useContext} from 'react'
import '../styles/DonorProfile.css'
import Modal from '../../../../global/components/Modal.jsx'
import {ModalState} from '../../../../global/services/Context.jsx'
import {useFetchMyAccount} from '../ui/MyAccountDonorPageViewModel.jsx'

const DonorProfile =()=>{
    const {data, loading} = useFetchMyAccount()

    const [modalDetails,setModalDetails] = useState(useContext(ModalState))

    const [firstName,setFirstName] = useState('')
    const [lastName,setLastName] = useState('')
    const [mobileNumber,setMobileNumber] = useState('')
    const [emailAddress,setEmailAddress] = useState('')
    const [intentions,setIntentions] = useState('')

    function closeModal(e){
        if (e.target.className === "modalOpen") {
            setModalDetails({...modalDetails,'state':false}) 
        }
    }

    useEffect(()=>{
        if(data){
            setFirstName(data.firstName)
            setLastName(data.lastName)
            setMobileNumber(data.mobileNumber)
            setEmailAddress(data.emailAddress)
            setIntentions(data.intention)
        }
    },[data])

    return(
        <React.Fragment>
            <div className={modalDetails.state ? "modalOpen":"modalClose"} onClick={(e)=>closeModal(e)}>
                <ModalState.Provider value={{modalDetails,setModalDetails}}>
                    <Modal />
                </ModalState.Provider>
            </div>
            <div id="DonorProfile">
                <div id="DonorProfileContainer">
                    <div className="DonorProfile-Header">
                        <div className="DonorProfile-InputLayout">
                            <div className="DonorProfile-InputLayout-Left">
                                <div className="DonorProfile-PreHeaderTitle">Account Details</div>
                                <div className="DonorProfile-HeaderTitle">Personal Information</div>
                            </div>
                            <div className="DonorProfile-InputLayout-Right">
                                <div className="DonorProfile-ButtonsLayout">
                                    <div id="DonorProfile-SaveButton" onClick={()=>setModalDetails({'type':'UpdateAccountDetails','state':true, 'data':data})}>Update</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        loading
                        ? <div className="DonorProfile-Loading">Loading...</div>
                        :
                        <div className="DonorProfile-Body">
                            <div className="DonorProfile-InputLayout">
                                <div className="DonorProfile-InputLeft">
                                    <label className="DonorProfile-HeaderTitle">Personal Information</label>
                                </div>
                                <div className="DonorProfile-InputLayout-Left">
                                    <div className="DonorProfile-InputLeft">
                                        <label className="DonorProfile-InputLabelSmall">First name</label>
                                        <div className="DonorProfile-InputValue">{firstName}</div>
                                    </div>
                                </div>
                                <div className="DonorProfile-InputLayout-Right">
                                    <div className="DonorProfile-InputRight">
                                        <label className="DonorProfile-InputLabelSmall">Last name</label>
                                        <div className="DonorProfile-InputValue">{lastName}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="DonorProfile-InputLayout">
                                <div className="DonorProfile-InputLayout-Left">
                                    <div className="DonorProfile-InputLeft">
                                        <label className="DonorProfile-InputLabelSmall">Mobile number</label>
                                        <div className="DonorProfile-InputValueBlue">{mobileNumber}</div>
                                    </div>
                                </div>
                                <div className="DonorProfile-InputLayout-Right">
                                    <div className="DonorProfile-InputRight">
                                        <label className="DonorProfile-InputLabelSmall">Email address</label>
                                        <div className="DonorProfile-InputValueBlue">{emailAddress}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="DonorProfile-InputLayout">
                               <hr className="DonorProfile-PaddingMargin"></hr>
                            </div>
                            <div className="DonorProfile-InputLayout">
                                <label className="DonorProfile-InputLabelExtraSmall">Please indicate your intentions in donating funds</label>
                                <p className="DonorProfile-ParagraphStyle">
                                    {intentions}
                                </p>
                            </div>
                        </div>
                    }
                </div>    
            </div>
        </React.Fragment>
    )
}
export default DonorProfile