import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import './index.css';
import LandingPageNew from './pages/authentication/login/ui/LandingPageNew.jsx';
import LandingPage from './pages/authentication/login/ui/LandingPage.jsx';
import AdminLandingPage from './pages/authentication/login/ui/AdminLandingPage.jsx';
import LandingFAQPage from './pages/authentication/login/ui/LandingFAQPage';
import TermsAndConditionPage from './pages/main/screens/ui/TermsAndConditionPage.jsx';
import LoginOption from './pages/authentication/login/components/LoginOption';
import ResetLinkPage from './pages/authentication/login/components/ResetLinkPage';
import MainPage from './pages/main/ui/Main.jsx';

let auth = localStorage.getItem('session');

const reload = () => window.location.reload();

const InvalidUserAction = () => {
	return (
		<Route
			render={() => {
				if (!auth) {
					return <Redirect to={{ pathname: '/' }} />;
				}
			}}
		/>
	);
};

ReactDOM.render(
	<Router>
		{
			auth ? <Route component={MainPage} /> :
			<Switch>
				<Route exact path='/' component={LandingPageNew} />
				<Route exact path='/login' component={LandingPage} />
				<Route exact path='/login/:landingPageId' component={LoginOption} />
				<Route exact path='/auth/action' component={ResetLinkPage} />
				<Route exact path='/admin-login' component={AdminLandingPage} />
				<Route exact path='/faq-page' component={LandingFAQPage} />
				<Route
					exact
					path='/terms-and-conditions'
					component={TermsAndConditionPage}
				/>
                <Route exact path="/d2389885c2cd5eb0347213f4c4143e789ecd7b9d1f307e85b90d55645f3e901e.txt" onEnter={reload} />
				<InvalidUserAction />
			</Switch>}
	</Router>,
	document.getElementById('root'),
);


