import React, { useState, useContext, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { loginWithFacebook, checkAuth ,isNewUser} from "../ui/LandingPageViewModel.jsx";
import FacebookIcon from "../../../../assets/ic_facebook.png";
import WeCareLogo from "../../../../assets/logo_wecare.png";
import "../styles/LoginPage.css";
import Modal from "../../../../global/components/Modal.jsx";
import { ModalState } from "../../../../global/services/Context.jsx";
import ReactGa from 'react-ga';
import InitializeReactGa from '../../../../global/services/googleanalytics/index.jsx';

const LoginPage = () => {
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const history = useHistory();
  const [modalDetails, setModalDetails] = useState(useContext(ModalState));

  async function checkUser(){
    const user = await checkAuth()
  
    if(user){
      console.log("user",user)
      setIsLoggingIn(true);
      isNewUser(user.uid);
    }
    else{
      setIsLoggingIn(false)
    }
  }

  InitializeReactGa();  

  async function doFacebookLogin() {
    setIsLoggingIn(true);
    loginWithFacebook()
    ReactGa.pageview(window.location.pathname + window.location.search);
    ReactGa.event({category: 'Facebook Login', action: 'Click Login using Facebook button', label: 'Login using Facebook'})
  }

  useEffect(() => {
    checkUser()
  },[]);

  function closeModal(e) {
    if (e.target.className === "modalOpen") {
      setModalDetails({ ...modalDetails, state: false });
    }
  }
  return (
    <React.Fragment>
      <div
        className={modalDetails.state ? "modalOpen" : "modalClose"}
        onClick={e => closeModal(e)}
      >
        <ModalState.Provider value={{ modalDetails, setModalDetails }}>
          <Modal />
        </ModalState.Provider>
      </div>
      <div id="LoginPage">
        <div id="LoginPageContents">
          <img src={WeCareLogo} id="LoginPage-Logo" />
          <div id="LoginPage-Title">
            Let’s take an active fight against COVID-19.
          </div>
          <label id="LoginPageContents-Description">
            WeCARE (We Connect, Act and Respond to Emergencies), is a free to
            use, community-based emergency response platform that easily
            connects individuals or groups who are willing to support
            communities that require assistance during a crisis.
          </label>

          {isLoggingIn ? (
            <div id="LoginPageContents-LoginButtonDisabled">
              <div id="LoginPageContents-LoginButtonLoading" />
            </div>
          ) : (
            <div
              id="LoginPageContents-FBLoginButton"
              onClick={() => doFacebookLogin()}
            >
              <img id="FacebookIcon" src={FacebookIcon} />
              Login using Facebook
            </div>
            )}
          <div id="LoginPageContents-DataPrivacy">
            By clicking Login using Facebook, I hereby agree and consent to the{" "}
            <a href="/terms-and-conditions" target="_blank">
              User Agreement
            </a>
            , its policies, and the{" "}
            <a href="https://cortex.ph/privacypolicy" target="_blank">
              Privacy Policy.
            </a>
          </div>
        </div>
        <div id="LoginPageContentsFooter">
          <div
            id="LoginPageContentsFooter-WhatToExpect"
            onClick={() =>
              setModalDetails({ type: "WhatToExpect", state: true })
            }
          >
            What to expect in the next release?
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default LoginPage;
