import React,{useState,useContext} from 'react'
import {db} from "../services/firebase";
import WarningIcon from '../../assets/ic_error.png'
import {ModalState} from '../services/Context.jsx'
import '../styles/RejectVendorPrompt.css'
import {rejectVendorAccount} from '../../pages/main/screens/ui/AccreditorVerificationListPageViewModel.jsx'
import { useEffect } from 'react';
import moment from 'moment-timezone';

const RejectVendorPrompt =()=> {

    const {modalDetails,setModalDetails} = useContext(ModalState)

    const [rejectType,setRejectType] = useState(1)
    const [reasonForRejection,setReasonForRejection] = useState('')
    const [isConfirmed,setIsConfirmed] = useState(false)
    const [loading,setLoading] = useState(false)

    const [error,setError] = useState('')

    function handleChangeReason(e){
        setReasonForRejection(e.target.value)
    }

    function checkInputs(type,reason){
        if(type === 1){
            const reasonnIsWhiteSpace = reasonForRejection.replace(/^\s+/, '').replace(/\s+$/, '')
            if(reason === '' || reasonnIsWhiteSpace === ''){
                return false
            }
            else{
                return true
            }
        }
        else{
            return true
        }
    }

    function clearInputs(){
        setRejectType(1)
        setReasonForRejection('')
        setIsConfirmed(false)
        setError('')
    }

    async function closePrompt(){
        clearInputs()
        setModalDetails({...modalDetails,'state':false})
    }

    async function rejectVendor(item){
        const isValidInputs = await checkInputs(rejectType,reasonForRejection)
        if(isValidInputs){
            setLoading(true)

            let rejectionHistory = []
            var date = (moment().tz("Asia/Singapore").format()).replace(/T/, ' ').replace("+08:00", '')

            if(item.rejectionHistory !== undefined){
                rejectionHistory = item.rejectionHistory

                const vendorRejectionDetails = {
                    dateRejected: date,
                    rejectionType:rejectType,
                    remarks:reasonForRejection
                }
                rejectionHistory.push(vendorRejectionDetails)
            }
            else{
                const vendorRejectionDetails = {
                    dateRejected: date,
                    rejectionType:rejectType,
                    remarks:reasonForRejection
                }
                rejectionHistory.push(vendorRejectionDetails)
            }
            
            const newVendorDetails = {...item, rejectionHistory:rejectionHistory}
        
            const isUserRejected = await rejectVendorAccount(item.uid,rejectionHistory)
            if(isUserRejected){
                clearInputs()
                setLoading(false)
                setModalDetails({...modalDetails,'data':'RejectVendorSuccess','vendor':newVendorDetails,'state':false})
            }
            else{
                clearInputs()
                setLoading(false)
                setModalDetails({...modalDetails,'data':'RejectVendorFailed','state':false})
            }
        }
        else{
            setError("Accreditor's remarks is required.")
        }
    }

    useEffect(()=>{
        setReasonForRejection('')
        setIsConfirmed(false)
    },[rejectType])

    return(
        <React.Fragment>
            <div id="RejectVendorPrompt">
                <div className="RejectVendorPrompt-Header">
                    <img src={WarningIcon} />
                    <div className="RejectVendorPrompt-HeaderLayout">
                        <div className="RejectVendorPrompt-HeaderTitle">Reject Vendor?</div>
                        <div className="RejectVendorPrompt-SubHeaderTitle">Make sure that you already contacted this person before rejecting.</div>
                    </div>
                </div>

                <div className="RejectVendorPrompt-Body">
                    <label className="RejectVendorPrompt-InputLabelSmall">Rejection type</label>
                    <label for="RejectVendorPrompt-Reject" id="RejectVendorPrompt-RadioInputLabelLeft">
                    <input type="radio" id="RejectVendorPrompt-Reject" name="rejectType" checked={rejectType === 1 ? true : false}
                    value={1} onClick={()=>setRejectType(1)}/>
                    Return to sender</label>

                    <label for="RejectVendorPrompt-Ban" id="RejectVendorPrompt-RadioInputLabelLeft">
                    <input type="radio" id="RejectVendorPrompt-Ban" name="rejectType" checked={rejectType === 2 ? true : false}
                    value={2} onClick={()=>setRejectType(2)}/>
                    Reject permanently</label>

                    <div className={rejectType === 1 ? "RejectVendorPrompt-ReasonInputHolder":"RejectVendorPrompt-Gone"}>
                        <label className="RejectVendorPrompt-InputLabelSmall">Remarks</label>
                        <textarea id={error === '' ? "RejectVendorPrompt-ReasonInput" : "RejectVendorPrompt-ReasonInputError"} 
                        placeholder="Input reason for returning the vendor's application. (e.g. Incorrect documents, Blurred documents, etc.)"
                        value={reasonForRejection} onChange={handleChangeReason}/>
                        <div className={error === '' ? "RejectVendorPrompt-Gone" : "RejectVendorPrompt-ReasonInputErrorMessage"}>{error}</div>
                    </div>
                    
                </div>

                <div className="RejectVendorPrompt-RejectionCheckbox">
                    <label for="RejectVendorPrompt-Confirmation" id="RejectVendorPrompt-ConfirmationLabel">
                    <input type="checkbox" id="RejectVendorPrompt-Confirmation" name="RejectVendorPromptConfirmation" checked={isConfirmed} onClick={()=>setIsConfirmed(!isConfirmed)} />
                    I have reviewed the documents and credentials of the vendor</label>
                </div> 

                {
                    isConfirmed
                    ?
                    loading
                        ?
                        <div className="RejectVendorPrompt-Footer">
                            <div className="RejectVendorPrompt-CancelButtonDisabled">Cancel</div>
                            <div className="RejectVendorPrompt-RejectButtonDisabled">Reject</div>
                        </div>
                        :
                        <div className="RejectVendorPrompt-Footer">
                            <div className="RejectVendorPrompt-CancelButton" onClick={()=>closePrompt()}>Cancel</div>
                            <div className="RejectVendorPrompt-RejectButton" onClick={()=>rejectVendor(modalDetails.data)}>Reject</div>
                        </div>
                    :
                    <div className="RejectVendorPrompt-Footer">
                        <div className="RejectVendorPrompt-CancelButton" onClick={()=>closePrompt()}>Cancel</div>
                        <div className="RejectVendorPrompt-RejectButtonDisabled">Reject</div>
                    </div>
                }
                
                
            </div>
        </React.Fragment>
    )
}
export default RejectVendorPrompt